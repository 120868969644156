/** Libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
import { defaultTheme, Icons, LeadingText, Text } from 'cordis-core-ui-planeta';
/** styles */
import { StyledArrowIcon, StyledCounter, StyledFlexCenterBlock } from './style';
/** components */
import LinkWrapper from '~/components/LinkWrapper';
/** constants */
import { OPERATING_STATE } from '~/constants/common';
import { UNIT_TYPE_NAME } from '../../constants';
/** types */
import { ScaleProps } from '../../types';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';
import { getGBtoMB } from '../../utils';

const Counter: FC<ScaleProps> = ({ type }: ScaleProps) => {
  const {
    pab2cMobileStore: {
      subscriptionsMap,
      isConvergentProduct,
      getStorageByType,
      getUnspentUnits,
    },
    contractStateStore: { contractState },
    vacationStore: { isActivated },
  } = useRootStore();
  const { setIsShowDetailingTheLeftovers, getIsRoaming } = useMobileStore();

  const isRoaming = getIsRoaming(type);

  /** Характеристики накопителя */
  const storage = isRoaming ? {} : getStorageByType(type);
  const { currentQuantity, maxQuantity } = storage || {};

  const subscriptions = subscriptionsMap[type] || [];

  /** Договор не обслуживается или на каникулах */
  const isSuspendedCondition =
    contractState !== OPERATING_STATE.ON || isActivated;

  const textColor = isSuspendedCondition
    ? defaultTheme.colors.disable
    : defaultTheme.colors.black;

  const unspentSum = getUnspentUnits(type);

  if (!isConvergentProduct || isRoaming)
    return (
      <StyledCounter>
        {subscriptions.length === 0 && (
          <LeadingText color={textColor}>0 {UNIT_TYPE_NAME[type]}</LeadingText>
        )}
        {subscriptions.length === 1 && (
          <>
            <LeadingText color={textColor}>
              {isRoaming
                ? getGBtoMB(subscriptions[0].currentQuantity)
                : subscriptions[0].currentQuantity}
            </LeadingText>
            <Text color={defaultTheme.colors.gray}>{`${'\u00A0'}/ ${
              isRoaming
                ? getGBtoMB(subscriptions[0].startQuantity)
                : subscriptions[0].startQuantity
            } ${UNIT_TYPE_NAME[type]}`}</Text>
          </>
        )}
        {subscriptions.length > 1 && (
          <StyledFlexCenterBlock
            onClick={() => setIsShowDetailingTheLeftovers(true, type)}
          >
            <LeadingText color={textColor}>
              {isRoaming ? getGBtoMB(unspentSum) : unspentSum}{' '}
              {UNIT_TYPE_NAME[type]}
            </LeadingText>
            <LinkWrapper>
              <StyledArrowIcon icon={<Icons.ArrowDownIcon />} />
            </LinkWrapper>
          </StyledFlexCenterBlock>
        )}
      </StyledCounter>
    );

  return (
    <StyledCounter>
      {subscriptions.length === 0 && (
        <>
          <LeadingText color={textColor}>{`${currentQuantity}`}</LeadingText>
          <Text
            color={defaultTheme.colors.gray}
          >{`${'\u00A0'}/ ${maxQuantity} ${UNIT_TYPE_NAME[type]}`}</Text>
        </>
      )}
      {subscriptions.length > 0 && (
        <StyledFlexCenterBlock
          onClick={() => setIsShowDetailingTheLeftovers(true, type)}
        >
          <LeadingText color={textColor}>
            {unspentSum + currentQuantity} {UNIT_TYPE_NAME[type]}
          </LeadingText>
          <LinkWrapper>
            <StyledArrowIcon icon={<Icons.ArrowDownIcon />} />
          </LinkWrapper>
        </StyledFlexCenterBlock>
      )}
    </StyledCounter>
  );
};

export default observer(Counter);
