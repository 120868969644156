/** библиотеки */
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';

/** стилевые компоненты */
import { StyledDevices } from './style';

/** компоненты */
import Portal from '~/components/Portal/Portal';
import DeviceCards from './Components/DeviceCards/DeviceCards';
import DeviceHeader from './Components/DeviceHeader/DeviceHeader';
import DeviceDetailedCardSP from '../../Shared/DeviceDetailedCard/DeviceDetailedCardSP';
import DeviceRequestErrorSP from '../../Shared/DeviceDetailedCard/DeviceRequestErrorSP';
import AddingContactSidePage from '../../Shared/ContactsAndNotifications/Components/AddingContact/AddingContactSidePage';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import useDeviceStore from './store/useDeviceStore';

const Main: FC = () => {
  const {
    authStore: { isAuth },
  } = useRootStore();
  const { getDevices, resetAuthDevices } = useDeviceStore();

  useEffect(() => {
    if (isAuth) {
      getDevices();
    } else {
      resetAuthDevices();
    }
  }, [isAuth]);

  return (
    <StyledDevices>
      <DeviceHeader />
      <DeviceCards />
      <Portal>
        <DeviceDetailedCardSP />
        <DeviceRequestErrorSP />
        <AddingContactSidePage />
      </Portal>
    </StyledDevices>
  );
};

export default observer(Main);
