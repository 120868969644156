import { Instance, types } from 'mobx-state-tree';
import { toJS } from 'mobx';
/** interfaces */
import { DefaultImgs, FieldModel } from '../models/CallToActionsModel';
import {
  CallToActionDeviceTypes,
  CallToActionFields,
} from '../../CallToAction.types';
import {
  BUSINESS_GROUP_CODE,
  PRODUCT_ABBREVIATION,
  StorageTypes,
  UnitTypes,
} from '../../../Summary/Summary.types';
import { DefaultImgs as DefaultImgsType } from '../../../Device/Device.types';
/** contants */
import { ConnectionMethod } from '~/constants/common';
import { DEFAULT_IMAGE } from '../../../Pab2c/Devices/constants';
/** utils */
import { getPromoPrice } from '~/components/Blocks/Shared/Shared.utils';
import { isExternal, sortTags } from '~/utils/utils';

const MainCallToActionModel = types
  .model('MainCallToActionModel', {
    fields: types.maybeNull(FieldModel),
    defaultImgs: types.array(DefaultImgs),
  })
  .views((self) => ({
    get fieldsData() {
      return toJS(self.fields);
    },
    get marketingGroupCode() {
      return self.fields.marketingGroupCode;
    },
    get marketingGroupName() {
      return self.fields.marketingGroupName;
    },
    get seriesCode() {
      return self.fields.seriesCode;
    },
    get seriesName() {
      return self.fields.seriesName;
    },
    get marketingText() {
      return self.fields.marketingText;
    },
    get tags() {
      const sortedTags = self.fields.tags.slice().sort(sortTags);
      return sortedTags;
    },
    get annualPrice() {
      return self.fields.priceInfo.annual;
    },
    get dailyPrice() {
      return self.fields.priceInfo.daily;
    },
    get thirtyDaysPrice() {
      return self.fields.priceInfo.thirtyDays;
    },
    get migrationCostFirstPrice() {
      return self.fields.priceInfo.migrationCostFirst;
    },
    get promoConnectionInfosPrice() {
      return self.fields.priceInfo.promoConnectionInfos;
    },
    get prepayment() {
      return self.fields.priceInfo.prepayment;
    },
    get connection() {
      return self.fields.priceInfo.connection;
    },
    get uhdChannelCountChannels() {
      return self.fields.channelsInfo.uhdChannelCount;
    },
    get hdChannelCountChannels() {
      return self.fields.channelsInfo.hdChannelCount;
    },
    get allChannelCountChannels() {
      return self.fields.channelsInfo.allChannelCount;
    },
    get channelCountChannels() {
      return self.fields.channelsInfo.channelCount;
    },
    get isAvailableTimeShift() {
      return self.fields.timeShiftInfo.isAvailable;
    },
    /* Управление просмотром за день */
    get timeshiftPrice() {
      return self.fields.timeShiftInfo.price;
    },
    // Флаг бесплатности сервиса
    get isFreeTimeshift() {
      return self.fields.timeShiftInfo.price === 0;
    },
    // нужны?
    get devices() {
      return self.fields.devicesInfo.devices;
    },
    get connectionMethod() {
      return self.fields.connectionMethod;
    },
    get speedBaseText() {
      return self.fields.speedBaseText;
    },
    get isTransformer() {
      return self.fields.isTransformer;
    },
    get isAnnual() {
      return self.fields.isAnnual;
    },
    /* Продолжительность отложенного платежа (в часах) */
    get distortionDuration() {
      return self.fields.distortionDuration;
    },
    get fixedPrice() {
      return self.fields?.fixedPrice;
    },
    get customHeader() {
      return self.fields.customHeader;
    },
    get routerInfo() {
      return toJS(
        self.fields.devicesInfo.devices.find(
          (item) => item.type === CallToActionDeviceTypes.ROUTER,
        ),
      );
    },
    get STBInfo() {
      return toJS(
        self.fields.devicesInfo.devices.find(
          (item) => item.type === CallToActionDeviceTypes.STB,
        ),
      );
    },
    get remoteInfo() {
      return toJS(
        self.fields.devicesInfo.devices.find(
          (item) => item.type === CallToActionDeviceTypes.REMOTE_CONTROL,
        ),
      );
    },
    /* Флаг бизнес продукта */
    get isBusiness() {
      return self.fields.marketingGroupCode === BUSINESS_GROUP_CODE;
    },
    get mobileInfo() {
      return self.fields.mobileInfo;
    },
  }))
  .views((self) => ({
    /* Флаг выключения тумблера телевидения на продукте */
    get isSwitchOffTV() {
      return !self.allChannelCountChannels || self.timeshiftPrice > 0;
    },
    /* Количество роутеров, приобретаемых по акции */
    get maxPromoRouters() {
      return (
        getPromoPrice(self.promoConnectionInfosPrice, {}).maxPromoDevice || 0
      );
    },
    /* Выбирает стоимость подключения для LAN */
    get lanConnectionPrice() {
      return self.connectionMethod === ConnectionMethod.LAN && self.isBusiness
        ? self.connection
        : self.migrationCostFirstPrice;
    },
    // Информация о стоимостях роутера
    get routerPrices() {
      if (!self?.routerInfo) return null;
      return (
        self.routerInfo?.promoPriceInfos &&
        getPromoPrice(self.routerInfo.promoPriceInfos, {
          currentPrice: self.routerInfo.price,
          currentAnnuity: self.routerInfo.annuity,
          currentDownPayment: self.routerInfo.downPayment,
        })
      );
    },
    // Информация о стоимостях приставки
    get STBPrices() {
      if (!self?.STBInfo) return null;
      return (
        self.STBInfo?.promoPriceInfos &&
        getPromoPrice(self.STBInfo.promoPriceInfos, {
          currentPrice: self.STBInfo.price,
          currentAnnuity: self.STBInfo.annuity,
          currentDownPayment: self.STBInfo.downPayment,
        })
      );
    },
    // Информация о стоимостях пульта
    get remotePrices() {
      if (!self?.remoteInfo) return null;
      return (
        self.remoteInfo?.promoPriceInfos &&
        getPromoPrice(self.remoteInfo.promoPriceInfos, {
          currentPrice: self.remoteInfo.price,
          currentAnnuity: self.remoteInfo.annuity,
          currentDownPayment: self.remoteInfo.downPayment,
        })
      );
    },
    // Информация о стоимости подключения
    get connectionPrice() {
      return getPromoPrice(self.promoConnectionInfosPrice, {
        currentPrice: self.connection,
      });
    },
    get mobileText() {
      if (!self.mobileInfo?.storageBalances.length) return null;
      if (self.isBusiness) {
        const minutes = self.mobileInfo.storageBalances.find(
          (item) => item.storageType === StorageTypes.OutGoingCall,
        );
        if (!minutes) return null;
        return `${minutes.maxQuantity} ${PRODUCT_ABBREVIATION.MINUTES}`;
      }
      const text = self.mobileInfo.storageBalances
        .filter((item) =>
          [StorageTypes.MobileInternet, StorageTypes.OutGoingCall].includes(
            item.storageType as StorageTypes,
          ),
        )
        .map((item) => {
          const name = () => {
            switch (item.unitType) {
              case UnitTypes.gigabyte:
                return PRODUCT_ABBREVIATION.GB;
              case UnitTypes.minute:
                return PRODUCT_ABBREVIATION.MINUTES;
              default:
                return '';
            }
          };
          return `${item.maxQuantity} ${name()}`;
        })
        .sort((a, b) => {
          return a === PRODUCT_ABBREVIATION.GB ? 1 : -1;
        })
        .join(' и ');
      return `до ${text}`;
    },
  }))
  .views((self) => ({
    /* Выбирает стоимость подключения */
    get productConnectionPrice() {
      return self.connectionMethod === ConnectionMethod.FTTH
        ? self.connection
        : self.lanConnectionPrice;
    },
    get isAction() {
      return self.connection !== self.connectionPrice.promoPrice;
    },
  }))
  .views((self) => ({
    // Информация о стоимости подключения к оптической сети
    get connectionFiberPrice() {
      return getPromoPrice(self.promoConnectionInfosPrice, {
        currentPrice: self.productConnectionPrice,
      });
    },
  }))
  .actions((self) => ({
    /* Формирует ссылку на изображение устройства */
    getDeviceImg: (code: string) => {
      const image =
        self.defaultImgs.find((item) => item.type === code.toLowerCase())
          ?.imgLink ?? DEFAULT_IMAGE;
      return isExternal(image) ? image : `${process.env.STATIC_SERVER}${image}`;
    },
  }));
const createStore = (
  fields: CallToActionFields,
  defaultImgs: DefaultImgsType[],
): IMainCallToActionStore => {
  return MainCallToActionModel.create({
    fields,
    defaultImgs,
  });
};
export type IMainCallToActionStore = Instance<typeof MainCallToActionModel>;
export default createStore;
