/** libraries */
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { SidePage, Text, Loader } from 'cordis-core-ui-planeta';
/** styles */
import { StyledButton } from './styles';
/** utils */
import { maskPhone } from '~/utils/utils';
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';

const BindNewNumberAgreement = (): JSX.Element => {
  const {
    cityStore: { officeInfos },
    pab2cMobileStore: { getMobileInfo },
  } = useRootStore();

  const {
    bindNewNumberStore: {
      isShowBindNewNumberAgreementSP,
      getBindNumberAgreement,
      agreement,
      bindMobilePhoneNumber,
      isLoading,
      setIsShowBindNewNumberAgreementSP,
    },
    changeNumberStore: { newNumberInfo, getChangeNumberData },
    methodOfReceiptFormStore: { deliveryType, deliveryAddress },
    simCardActivationStore: {
      isShowSimCardActivation,
      bindMobilePhoneNumberToSim,
      selectedSimCardId,
      isLoading: isSetSimCardLoading,
      result,
    },
  } = useMobileStore();

  useEffect(() => {
    if (!isShowBindNewNumberAgreementSP) return;
    (async () => {
      await getBindNumberAgreement(newNumberInfo.id);
    })();
  }, [isShowBindNewNumberAgreementSP]);

  const bindNumberButtonHandler = async () => {
    /** Обработка кейса активации SIM-карты */
    if (isShowSimCardActivation) {
      await bindMobilePhoneNumberToSim(selectedSimCardId, newNumberInfo.id);
      setIsShowBindNewNumberAgreementSP(false);
      if (result.isCorrect) {
        await Promise.all([getChangeNumberData(), getMobileInfo()]);
      }
      return;
    }

    await bindMobilePhoneNumber(
      newNumberInfo.id,
      deliveryType,
      deliveryAddress(officeInfos),
      newNumberInfo.msisdn,
    );
  };

  const closeClickHandler = async () => {
    setIsShowBindNewNumberAgreementSP(false);
  };

  const Footer = () => (
    <StyledButton
      disabled={!agreement}
      onClick={bindNumberButtonHandler}
      loading={isLoading || isSetSimCardLoading}
    >
      Подтвердить соглашение
    </StyledButton>
  );

  if (!isShowBindNewNumberAgreementSP) return null;

  return (
    <SidePage
      show={isShowBindNewNumberAgreementSP}
      headerText={`Соглашение на добавление номера ${maskPhone(
        newNumberInfo.msisdn,
      )}`}
      onCloseClick={closeClickHandler}
      footerContainer={<Footer />}
      showMobileHeader
    >
      {!agreement && <Loader small />}
      {agreement && <Text>{parseHtml(agreement)}</Text>}
    </SidePage>
  );
};

export default observer(BindNewNumberAgreement);
