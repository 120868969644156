/** компоненты библиотеки */
import { H3, defaultTheme } from 'cordis-core-ui-planeta';
import {
  AgreementStyled,
  StyledCompanyDescription,
  StyledLinkWrapper,
  StyledText,
} from './Agreement.style';
/** Константы */
import { URL_PRIVACY_POLICY } from '../ConnectionWizard/constants';

export const Agreement = (): JSX.Element => (
  <AgreementStyled>
    <StyledCompanyDescription color={defaultTheme.colors.gray}>
      Руководителю Оператора связи ООО&nbsp;&laquo;Комтехцентр&raquo; {'\u000A'}
      Коммерческое обозначение и&nbsp;товарный знак &laquo;Планета&raquo;
      {'\u000A'}ИНН&nbsp;6670019135, ОГРН&nbsp;1026604951086 {'\u000A'}
      Юр.&nbsp;адрес:&nbsp;620075, г.&nbsp;Екатеринбург, Бажова, 79-211
    </StyledCompanyDescription>
    <H3>Согласие на&nbsp;обработку персональных данных</H3>
    <StyledText>
      Настоящим&nbsp;я, являясь Субъектом персональных данных,
      в&nbsp;соответствии с&nbsp;Федеральным законом от&nbsp;27.07.2006
      &#8470;&nbsp;152&#8209;ФЗ &laquo;О&nbsp;персональных данных&raquo; даю
      согласие оператору связи ООО&nbsp;&laquo;Комтехцентр&raquo;
      на&nbsp;обработку следующих предоставленных мною персональных данных:
      фамилия, имя, отчество; число, месяц, год рождения; место рождения;
      псевдоним (при наличии); гражданство; адрес регистрации (по&nbsp;месту
      пребывания, месту жительства); адрес фактического проживания; адрес
      установки оконечного оборудования; номер лицевого счета; номер телефона;
      адрес электронной почты; данные документа, удостоверяющего личность;
      идентификатор банковской карты; идентификатор пользовательского
      (оконечного) оборудования иностранного гражданина или лица без
      гражданства.
    </StyledText>
    <StyledText>
      Подтверждаю, что с{' '}
      <StyledLinkWrapper href={URL_PRIVACY_POLICY} target="_blank">
        Политикой в&nbsp;отношении обработки персональных данных
      </StyledLinkWrapper>{' '}
      ознакомлен(а) и&nbsp;согласен(на).
    </StyledText>
    <StyledText>
      Под обработкой персональных данных понимаются предусмотренные Федеральным
      законом от&nbsp;27.07.2006 &#8470;&nbsp;152&#8209;ФЗ
      &laquo;О&nbsp;персональных данных&raquo; действия или совокупность
      действий, совершаемых с&nbsp;использованием средств автоматизации или без
      использования таких средств, с&nbsp;персональными данными, включая сбор,
      запись, систематизацию, накопление, хранение, уточнение (обновление,
      изменение), извлечение, использование, передачу (предоставление, доступ)
      удаление, уничтожение персональных данных.
    </StyledText>
    <StyledText>
      Цель обработки персональных данных: заключение и&nbsp;исполнение договора
      об&nbsp;оказании услуг связи, иных услуг.
    </StyledText>
    <StyledText>
      В&nbsp;случае заказа и&nbsp;получения мною услуг междугородной,
      международной и&nbsp;внутризоновой телефонной связи, использования иных
      сервисов, услуг или ресурсов, предоставляемых абонентам (клиентам)
      ООО&nbsp;&laquo;Комтехцентр&raquo; ПАО&nbsp;&laquo;Ростелеком&raquo;,
      я&nbsp;даю согласие на&nbsp;передачу моих персональных данных публичному
      акционерному обществу &laquo;Ростелеком&raquo;
      (ПАО&nbsp;&laquo;Ростелеком&raquo;), местонахождение: 191167, город
      Санкт-Петербург, вн.&nbsp;тер. г.&nbsp;Муниципальный округ Смольнинское,
      Синопская набережная, дом&nbsp;14, литера&nbsp;А, а&nbsp;в&nbsp;случае
      заказа и&nbsp;получения мною услуг междугородной, международной
      и&nbsp;внутризоновой телефонной связи с&nbsp;использованием кода выбора
      оператора, я&nbsp;даю согласие на&nbsp;передачу моих персональных данных
      выбранному мною оператору телефонной связи.
    </StyledText>
    <StyledText>
      Настоящее согласие даётся до&nbsp;истечения сроков хранения
      соответствующей информации или документов, содержащих указанную выше
      информацию, определяемых в&nbsp;соответствии с&nbsp;действующим
      законодательством Российской Федерации.
    </StyledText>
    <StyledText>
      Отзыв данного согласия может быть осуществлён в&nbsp;любое время
      на&nbsp;основании моего письменного заявления отправленного
      на&nbsp;welcome@planeta.tc.
    </StyledText>
    <StyledText>
      В&nbsp;случае, если настоящее согласие дано посредством отправки
      регистрационной веб-формы с&nbsp;сайта ООО&nbsp;&laquo;Комтехцентр&raquo;,
      датой выдачи настоящего согласия на&nbsp;обработку персональных данных
      является дата отправки регистрационной веб-формы либо дата заключения
      договора об&nbsp;оказании услуг связи (в&nbsp;зависимости от&nbsp;того,
      какое из&nbsp;событий наступило раньше).
    </StyledText>
  </AgreementStyled>
);
