import { ReactNode, useContext } from 'react';
import { useLocalObservable } from 'mobx-react-lite';
import { Provider, MobXProviderContext } from 'mobx-react';
import createStore, { ISubscriptionsStore } from './MainSubscriptionsStore';
import { SubscriptionsFields } from '../../interfaces';

interface SubscriptionsProps {
  children: ReactNode;
  fields: SubscriptionsFields;
}

export const SubscriptionsProvider = ({
  children,
  fields,
}: SubscriptionsProps): JSX.Element => {
  const store = useLocalObservable<ISubscriptionsStore>(() =>
    createStore(fields),
  );
  return <Provider SubscriptionsStore={store}>{children}</Provider>;
};

export const useSubscriptionsStore = (): ISubscriptionsStore => {
  const { SubscriptionsStore } = useContext(MobXProviderContext);
  return SubscriptionsStore;
};
