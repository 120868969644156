/** libraries */
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { defaultTheme } from 'cordis-core-ui-planeta';

export const StyledMiniProductSwitcher = styled.div`
  .unavailable {
    background-color: white;
    padding: 32px;
    min-height: 400px;
    width: 100%;
  }
`;

export const StyledHeader = styled.header<{ $isBigSummary?: boolean }>`
  ${({ $isBigSummary }) =>
    $isBigSummary &&
    css`
      margin-left: 5px;
    `}

  > * {
    margin: 0 0 16px 0 !important;
  }
`;

export const StyledProductSwitcher = styled.div<{ isThemesOpen: boolean }>`
  ${({ isThemesOpen }) => {
    return css`
      .product-switcher {
        &__single-summary {
          margin-bottom: 32px;
        }

        &__tags-block {
          display: flex;
          ${isThemesOpen ? 'flex-direction: column;' : ''}
          align-items: center;
          padding: 24px 32px;
          background: ${defaultTheme.colors.white};
          border-radius: 50px;
          margin-bottom: 32px;

          &__tags {
            width: -webkit-fill-available;
            ${isThemesOpen ? 'margin-top: 15px;' : ''}
          }

          > span,
          label {
            ${isThemesOpen
              ? 'width: 100%; margin-bottom: 32px;'
              : 'margin-right: 12px; margin-top: 8px'};
          }

          &__burger-menu {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;

            H3 {
              margin: 16px 16px 17px 0;
            }
            > span {
              line-height: 0;
            }
          }

          &__button-wrapper {
            width: 100%;
            button {
              width: 100%;
            }
          }
        }

        &__summary {
          > div {
            margin-bottom: 28px;

            .row {
              display: flex;
            }
          }
        }

        &__loader {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .internet {
        &__name-wrapper {
          .icon {
            display: none;
          }
        }
      }
    `;
  }}
`;
