/** Libraries */
import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { defaultTheme, Icons, Text } from 'cordis-core-ui-planeta';
/** styles */
import {
  StyledButton,
  StyledLeadingText,
  StyledMobileInfo,
  StyledRoaming,
  StyledWarning,
} from './style';
/** interfaces */
import { STORAGE_TYPE } from '../../types';
/** components */
import Header from '../Header/Header';
import InfoPanel from '../Header/InfoPanel';
import MobileScale from '../MobileScale/MobileScale';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { OPERATING_STATE } from '~/constants/common';
import { TABS } from '../../constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';

const MobileInfo: FC = () => {
  const {
    pab2cMobileStore: {
      isConvergentProduct,
      notActivatedSims,
      connectedRoamingTypes,
      mobilePhones,
    },
    contractStateStore: { contractState },
    vacationStore: { isActivated },
    summaryDataStore: { seriesCode },
  } = useRootStore();

  const {
    simCardActivationStore: { openSimCardActivation },
    bindSubscriptionStore: { setIsShowBindSubscriptionSP },
    bindNewNumberStore: { setIsShowBindNewNumberSP },
    tabIndex,
    roamingStore: {
      getRoamingZonesBySeriesCode,
      isShowRoamingSP,
      isFetched,
      setIsShowRoamingSP,
    },
  } = useMobileStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Договор не обслуживается или на каникулах */
  const isSuspendedCondition =
    contractState !== OPERATING_STATE.ON || isActivated;

  useEffect(() => {
    if (
      (connectedRoamingTypes.length || isShowRoamingSP) &&
      seriesCode &&
      !isFetched
    ) {
      (async () => {
        await getRoamingZonesBySeriesCode(seriesCode);
      })();
    }
  }, [connectedRoamingTypes.length, seriesCode, isShowRoamingSP]);

  const actionButton = () => {
    if (isSuspendedCondition) return null;
    if (!mobilePhones.length)
      return (
        <StyledButton
          background={defaultTheme.colors.black}
          border={`2px solid ${defaultTheme.colors.black}`}
          icon={<Icons.SimWhiteIcon />}
          onClick={() => setIsShowBindNewNumberSP(true)}
        >
          Заказать SIM
        </StyledButton>
      );
    if (notActivatedSims.length)
      return (
        <StyledButton
          background={defaultTheme.colors.black}
          border={`2px solid ${defaultTheme.colors.black}`}
          onClick={openSimCardActivation}
        >
          Активировать SIM
        </StyledButton>
      );
    if (!isDesktop940 || tabIndex === TABS.MY_CONNECTION)
      return (
        <StyledButton
          background={defaultTheme.colors.black}
          border={`2px solid ${defaultTheme.colors.black}`}
          onClick={() => setIsShowBindSubscriptionSP(true)}
        >
          {isDesktop940 ? 'Добавить пакет минут или ГБ' : 'Добавить пакет'}
        </StyledButton>
      );
    if (tabIndex === TABS.ROAMING)
      return (
        <StyledButton
          background={defaultTheme.colors.black}
          border={`2px solid ${defaultTheme.colors.black}`}
          onClick={() => setIsShowRoamingSP(true)}
        >
          Добавить пакет Мб
        </StyledButton>
      );
    return null;
  };

  return (
    <StyledMobileInfo
      $isConvergent={isConvergentProduct && tabIndex === TABS.MY_CONNECTION}
    >
      <Header />
      {tabIndex === TABS.MY_CONNECTION || !isDesktop940 ? (
        <>
          <MobileScale type={STORAGE_TYPE.INTERNET} />
          <MobileScale type={STORAGE_TYPE.CALL} />
          {isConvergentProduct && <MobileScale type={STORAGE_TYPE.SMS} />}
          {isSuspendedCondition && (
            <StyledWarning
              text={
                <Text>
                  Доступны только входящие звонки и&nbsp;вызовы
                  на&nbsp;специальные номера. Мобильный интернет отключен.
                </Text>
              }
            />
          )}
        </>
      ) : (
        <StyledRoaming>
          {connectedRoamingTypes.map((item) => (
            <MobileScale key={item} type={item} />
          ))}
        </StyledRoaming>
      )}
      {actionButton()}
      {!isDesktop940 && !!connectedRoamingTypes.length && (
        <>
          <StyledLeadingText color={defaultTheme.colors.black}>
            Роуминг
          </StyledLeadingText>
          <StyledRoaming>
            {connectedRoamingTypes.map((item) => (
              <MobileScale key={item} type={item} />
            ))}
          </StyledRoaming>
          <StyledButton
            background={defaultTheme.colors.black}
            border={`2px solid ${defaultTheme.colors.black}`}
            onClick={() => setIsShowRoamingSP(true)}
          >
            Добавить пакет Мб
          </StyledButton>
        </>
      )}
      {!isDesktop940 && <InfoPanel />}
    </StyledMobileInfo>
  );
};

export default observer(MobileInfo);
