/** libraries */
import React, { FC } from 'react';
import { observer } from 'mobx-react';
/** components */
import { Button, defaultTheme, Link } from 'cordis-core-ui-planeta';
/** styles */
import {
  StyledAgreementDescription,
  StyledAuthWizardConfirm,
  StyledLinkWrapper,
} from './AuthWizardConfirm.style';
/** constants */
import { URL_PRIVACY_POLICY } from '~/components/ConnectionWizard/constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMakeAuthStore from '../store/useMakeAuthStore';
import useCreatePasswordStore from '~/components/Blocks/Shared/CreatePassword/store/useCreatePasswordStore';

/** Блок авторизации */
const AuthWizardConfirm: FC = () => {
  const {
    agreementStore: { setIsShowAgreement },
    authStore: { makeAuth },
  } = useRootStore();
  const {
    isLoading,
    login,
    password,
    resetErrors,
    validation,
    setIsLoading,
    setServerError,
  } = useMakeAuthStore();
  const { setIsShowCreatePasswordWizard } = useCreatePasswordStore();
  const openCreatePasswordSidePage = () => setIsShowCreatePasswordWizard(true);

  return (
    <>
      <StyledAuthWizardConfirm>
        <div className="auth__terminal">
          <Link
            onClick={openCreatePasswordSidePage}
            className="link__reset-password"
          >
            Я не помню пароль
          </Link>

          <Button
            type="button"
            onClick={() =>
              makeAuth(
                login,
                password,
                resetErrors,
                validation,
                setIsLoading,
                setServerError,
              )
            }
            loading={isLoading}
          >
            Войти
          </Button>
        </div>

        <StyledAgreementDescription color={defaultTheme.colors.gray}>
          Нажимая кнопку, вы подтверждаете, что ознакомлены с{'\u00A0'}
          <StyledLinkWrapper href={URL_PRIVACY_POLICY} target="_blank">
            Политикой Оператора в&nbsp;отношении обработки персональных данных
          </StyledLinkWrapper>{' '}
          и даёте своё{' '}
          <Link onClick={() => setIsShowAgreement(true)}>согласие</Link>{' '}
          на&nbsp;обработку персональных данных
        </StyledAgreementDescription>
      </StyledAuthWizardConfirm>
    </>
  );
};

export default observer(AuthWizardConfirm);
