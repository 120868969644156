/** libraries */
import { useRouter } from 'next/router';
import { useEffect } from 'react';
/** constants */
import {
  ACTIVATION_METHOD_TYPE,
  ESIA_EXEPTIONS_TEXT,
  GOLD_CATEGORY_INDEX,
  PAB2C_MOBILE_ID,
  QP_PAB2C_MOBILE,
} from '../constants';
/** stores */
import useMobileStore from '../store/useMobileStore';
import { scrollToBlockById } from '~/components/Blocks/Shared/Shared.utils';

export const useQpHandlerHook = (): void => {
  const router = useRouter();

  const {
    simCardActivationStore: { openSimCardActivation },
    bindNewNumberStore: { setIsShowBindNewNumberSP },
    passportDataConfirmationStore: {
      openPassportDataConfirmation,
      handleCallbackFromEsia,
      setGosuslugiError,
    },
    changeNumberStore: {
      setIsShowAvailableNumbersSp,
      setActiveCategoryTabIndex,
    },
    specifyTheTransferNumberStore: { setIsShowSpecifyTheTransferNumber },
    choosingTheActivationMethodStore: { setActivationMethodType },
  } = useMobileStore();

  useEffect(() => {
    if (
      !router.isReady ||
      !Object.values(QP_PAB2C_MOBILE).some((qp) => !!router.query?.[qp])
    )
      return;

    scrollToBlockById(PAB2C_MOBILE_ID, 'smooth');

    if (router.query?.[QP_PAB2C_MOBILE.GOSUSLUGI_CONFIRMATION]) {
      openSimCardActivation();
      openPassportDataConfirmation();
      if (router.query?.code && router.query?.state) {
        handleCallbackFromEsia();
      } else {
        setGosuslugiError(ESIA_EXEPTIONS_TEXT.DEFAULT);
      }
    }

    if (router.query?.[QP_PAB2C_MOBILE.ADD_MOBILE_NUMBER]) {
      setIsShowBindNewNumberSP(true);
    }

    if (router.query?.[QP_PAB2C_MOBILE.SELECT_NUMBER]) {
      setIsShowBindNewNumberSP(true);
      setIsShowAvailableNumbersSp(true);
      setActiveCategoryTabIndex(GOLD_CATEGORY_INDEX);
    }

    if (router.query?.[QP_PAB2C_MOBILE.PORTATION]) {
      setIsShowBindNewNumberSP(true);
      setActivationMethodType(ACTIVATION_METHOD_TYPE.TRANSFER);
      setIsShowSpecifyTheTransferNumber(true);
    }

    // Очищаем query params
    router.push(
      { pathname: router.asPath.split('?')[0], query: {} },
      undefined,
      { shallow: true },
    );
  }, [router.isReady, router.query]);
};
