import styled from '@emotion/styled';
import { Squircle } from '@squircle-js/react';
import { Button, defaultTheme, H3, Text } from 'cordis-core-ui-planeta';
import CONFIG from '../../config';
import { McBannerTemplates } from '../../constants';
import { buttonHover } from '~/components/Blocks/Templates/McBanner/styles';

const { faces } = CONFIG[McBannerTemplates.tenSims].images;

export const StyledSquircle = styled(Squircle)`
  display: flex;
  flex-direction: column;
  height: 400px;
  padding: 38px 10px 38px 37px;
  background-color: ${defaultTheme.colors.white};
  overflow: hidden;
  position: relative;
`;

export const StyledH3 = styled(H3)`
  max-width: 280px;
  margin-bottom: 19px;
`;

export const StyledText = styled(Text)`
  display: block;
  max-width: 270px;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const StyledImg = styled.span`
  background-image: url(${faces});
  background-repeat: no-repeat;
  position: absolute;
  width: 414px;
  height: 127px;
  left: 0;
  top: 106px;
`;

export const StyledButton = styled(Button)`
  border-radius: 106px;
  margin-bottom: 0;
  margin-left: -6px;
  padding: 13px 36px;

  ${buttonHover};

  border: 1px solid transparent;
`;

export const StyledBottomContainer = styled.div`
  margin-top: auto;
`;
