import styled from '@emotion/styled';
import {
  Input,
  Text,
  LeadingText,
  UnorderedList,
} from 'cordis-core-ui-planeta';

export const StyledText = styled(Text)`
  width: 100%;
  margin-bottom: 8px;
`;
export const StyledInput = styled(Input)`
  width: 100%;
  margin-bottom: 16px;
`;
export const StyledLeadingText = styled(LeadingText)`
  width: 100%;
  display: block;
  margin-bottom: 16px;
`;
export const StyledUnorderedList = styled(UnorderedList)`
  li {
    margin-bottom: 4px;
  }
`;
