/** libraries */
import { FC } from 'react';
import { Text } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';
/** components */
import ProductTemplate from '~/components/Blocks/Shared/ProductTemplate/ProductTemplate';
/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
/** interfaces */
import {
  MarketingFieldsProps,
  SummaryFieldsProps,
} from '~/components/Blocks/Shared/ProductTemplate/ProductTemplate.interfaces';
/** styles */
import { StyledHeader, StyledMiniProductSwitcher } from '../../style';
/** stores */
import useProductSwitcherStore from '../../store/useProductSwitcherStore';

const MiniProductSwitcher: FC = () => {
  const {
    fields: { products, productsNotAvailableText, header },
    tariffs,
    isShowProductsNotAvailable,
    isLoading,
    tagsStore: { tags, activeProductTag },
  } = useProductSwitcherStore();

  return (
    <>
      {!isShowProductsNotAvailable && header && (
        <StyledHeader>{parseHtml(header)}</StyledHeader>
      )}
      <StyledMiniProductSwitcher>
        {isShowProductsNotAvailable && !isLoading && (
          <Text className="unavailable" lineHeight="24px">
            {parseHtml(productsNotAvailableText)}
          </Text>
        )}
        {!isShowProductsNotAvailable && tariffs && (
          <ProductTemplate
            blocks={products}
            marketingFields={(tags as unknown) as MarketingFieldsProps[]}
            summaryFields={tariffs as SummaryFieldsProps[]}
            activeProductTag={
              (activeProductTag as unknown) as MarketingFieldsProps
            }
            isMiniProductSwitcher
            isLoading={isLoading}
          />
        )}
      </StyledMiniProductSwitcher>
    </>
  );
};

export default observer(MiniProductSwitcher);
