import styled from '@emotion/styled';
import {
  Autocomplete,
  Button,
  H3,
  LeadingText,
  LinkButton,
  Loader,
  SidePage,
  Text,
} from 'cordis-core-ui-planeta';

export const StyledCountryButton = styled(Button)`
  width: 100%;
  margin-bottom: 18px;
  padding: 12px 12px 12px 15px;

  .text-wrapper {
    margin-left: 6px;

    > span:first-of-type {
      word-wrap: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const StyledLoader = styled(Loader)`
  display: block;
`;

export const StyledDescription = styled(Text)`
  margin-bottom: 32px;
`;

export const StyledText = styled(Text)`
  display: block;
  margin-bottom: 16px;
`;

export const StyledH3 = styled(H3)`
  margin-bottom: 15px;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  margin-bottom: 32px;
  max-width: 420px;
`;

export const StyledSidePage = styled(SidePage)`
  .sidepage__content {
    margin-top: 28px;
  }
`;

export const StyledIcon = styled.div<{
  isHeader?: boolean;
}>`
  ${({ isHeader }) => `
    width: 40px;
    height: 32px;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    ${isHeader && 'margin-top: 30px;'}

    svg {
      width: 48px;
      height: 32px;
      flex-shrink: 0;
      margin-top: 0;
    }
  `}
`;

export const StyledPrice = styled.div<{
  isDetailedPrice?: boolean;
}>`
  ${({ isDetailedPrice }) => `
    margin-bottom: ${isDetailedPrice ? '30px' : '16px'};
    width: 48%;
  `}
`;

export const StyledPriceInfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledPriceLeadingText = styled(LeadingText)`
  display: block;
  margin-bottom: 2px;
`;

export const StyledPriceH3 = styled(H3)`
  margin-bottom: 25px;
  margin-top: 15px;
`;

export const StyledLinkButton = styled(LinkButton)`
  margin-bottom: 33px;
`;

export const StyledButton = styled(Button)`
  margin-top: 14px;
  margin-bottom: 33px;
  padding: 15px 40px;
`;
