/** libraires */
import { Instance, types } from 'mobx-state-tree';
import { toJS } from 'mobx';
/** interfaces */
import { DeviceItemFilterType } from '../DeviceList.types';
import { DevicesFields } from '../../Devices/interfaces';
import { DevicesFieldsModel } from '~/stores/models/DevicesModel';

const DeviceItemFilterModel = types.model({
  id: types.number,
  model: types.maybe(types.string),
  tag: types.maybe(types.string),
});

const DeviceListModel = types
  .model('DeviceListModel', {
    list: types.array(DeviceItemFilterModel),
    allDevices: types.array(DevicesFieldsModel),
  })
  .views((self) => ({
    get filteredDevices() {
      const filterDeviceId = self.list.map((item) => item.id);

      const devices = filterDeviceId.reduce((acc, id) => {
        const newDevice = self.allDevices.filter(
          (device: DevicesFields) => device.id === id,
        );
        if (newDevice) return [...acc, ...newDevice];
        return acc;
      }, []);
      return toJS(devices);
    },
  }));

export type IDeviceListStore = Instance<typeof DeviceListModel>;

export const createDeviceListStore = (
  list: DeviceItemFilterType[],
  allDevices: DevicesFields[],
): IDeviceListStore => {
  return DeviceListModel.create({
    list,
    allDevices,
  });
};
