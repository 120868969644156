/** libraries */
import * as React from 'react';
import { observer } from 'mobx-react';
import {
  Text,
  Switcher,
  Tabs,
  TabsStyleTypes,
  defaultTheme,
  Icons,
} from 'cordis-core-ui-planeta';
/** constants */
import { TABS_VARIANTS } from '../CallToAction.constants';
/** components */
import CallToActionRow from '../CallToActionRow/CallToActionRow';
/** styles */
import {
  StyledFeature,
  StyledH3,
  StyledLeftColumn,
} from '../CallToAction.style';
/** utils */
import { formatNumber } from '~/utils/utils';
/** stores */
import { useCallToActionStore } from '../stores/MainCallToAction/useMainCallToActionStore';
import useCallToActionStateStore from '../stores/CallToActionState/useCallToActionStateStore';

/* Заголовок компонента "Управление просмотром" */
const TimeShiftTitle = (): JSX.Element => {
  const { isFreeTimeshift } = useCallToActionStore();
  const { enabledTimeShift, setEnabledTimeShift } = useCallToActionStateStore();
  /* Меняет статус активации сервиса */
  const changeTimeShiftStatus = () => {
    setEnabledTimeShift(!enabledTimeShift);
  };

  return (
    <Switcher
      checked={enabledTimeShift}
      disabled={isFreeTimeshift}
      onClick={changeTimeShiftStatus}
    >
      <Text>
        Управление просмотром<sup>&nbsp;2</sup>
      </Text>
    </Switcher>
  );
};

/* Дочерний компонент "Управление просмотром", компонента CallToAction */
const CallToActionTimeShift: React.FC = () => {
  const { timeshiftPrice, isFreeTimeshift } = useCallToActionStore();
  const { enabledTimeShift } = useCallToActionStateStore();
  /* Текущий индекс таба */
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
  /* Текущее значение таба */
  const [activeTabValue, setActiveTabValue] = React.useState<number>(
    timeshiftPrice,
  );
  /* Событие при изменении таба */
  const onChangeTab = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
    switch (tabIndex) {
      case 0:
        setActiveTabValue(timeshiftPrice);
        break;
      case 1:
        setActiveTabValue(timeshiftPrice * 30);
        break;
      default:
        setActiveTabValue(timeshiftPrice);
    }
  };
  return (
    <CallToActionRow title={TimeShiftTitle()}>
      <StyledLeftColumn>
        {!isFreeTimeshift && (
          <Tabs
            value={TABS_VARIANTS}
            styleType={TabsStyleTypes.SECONDARY}
            onChange={onChangeTab}
            activeTabIndex={activeTabIndex}
          />
        )}
        <StyledH3
          color={
            enabledTimeShift
              ? defaultTheme.colors.black
              : defaultTheme.colors.disable
          }
        >
          {!isFreeTimeshift
            ? `${formatNumber(activeTabValue)}\u0020\u20bd`
            : 'Бесплатно'}
        </StyledH3>
      </StyledLeftColumn>
      <StyledFeature>
        <Icons.OkIcon />
        <Text color={defaultTheme.colors.shadow}>
          Живая пауза и&nbsp;перемотка на&nbsp;любой фрагмент передачи
          <sup>&nbsp;3</sup>
        </Text>
      </StyledFeature>
      <StyledFeature>
        <Icons.OkIcon />
        <Text color={defaultTheme.colors.shadow}>
          Запись эфира до&nbsp;7&nbsp;дней
        </Text>
      </StyledFeature>
    </CallToActionRow>
  );
};

export default observer(CallToActionTimeShift);
