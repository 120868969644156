/** библиотеки */
import * as React from 'react';
import { useState, useEffect, RefObject, useRef, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import Image from 'next/image';
import { observer } from 'mobx-react';
/** компоненты библиотеки */
import {
  Text,
  Snoska,
  Input,
  Button,
  defaultTheme,
  Checkbox,
  H3,
  SidePage,
  Link,
  LinkButton,
  LeadingText,
  Icon,
  Icons,
  TagButton,
} from 'cordis-core-ui-planeta';
/** стили */
import {
  StyledPaymentX1Form,
  StyledPaymentX2Form,
  StyledQrSidePage,
} from './style';
/** константы */
import { ERROR_MESSAGES } from './constants';
import { PAYMENT_TYPE } from '~/interfaces/PromoInterface';
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';
import { DOCUMENTS_LINK } from '../../Shared/Autopayment/AutoPaymentWizard/constants';
/** типы */
import { AmountProps, PaymentFormProps } from './types';
/** утилиты */
import { formatNumber, removeLeadingZero } from '~/utils/utils';
/** компоненты */
import Promo from './Promo/Promo';
import Portal from '~/components/Portal/Portal';
import PinWizard from '../../Shared/PinWizard/PinWizard';
import ListOfSbpBanks from '../../Shared/ListOfSbpBanks/ListOfSbpBanks';
import CardAndSbpPaymentSelect from '../../Shared/CardAndSbpPaymentSelect/CardAndSbpPaymentSelect';
/** hooks */
import { usePayment } from './PaymentHook';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileSBPStore from './MobileSBP/store/useMobileSBPStore';
import useListOfSbpBanksStore from '../../Shared/ListOfSbpBanks/store/useListOfSbpBanksStore';

/**
 * Компонент формы пополнения баланса X2
 */
const PaymentForm: React.FC<PaymentFormProps> = ({
  promoListContent,
  activePromotion,
  setActivePromotion,
  getPromoInfo,
  setAnswer,
  cardLimit,
  sbpLimit,
  sizeX2,
}) => {
  const {
    paymentInfoStore: { isLoading: isLoadingPaymentInfo },
    authStore: { auth, isAuth },
  } = useRootStore();
  const { isOpenSBPCards, setIsUseSBPBanksList } = useMobileSBPStore();
  const { setIsListOfSbpBanksOpen } = useListOfSbpBanksStore();

  // Показать привязанные счета
  const [activeTag, setActiveTag] = useState<string>(null);
  // Ссылка на поле ввода договора
  const refInput: RefObject<HTMLInputElement> | null = useRef(null);

  // Вычисление ширины экрана
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });
  const isDesktopFrom940To1100 = useMediaQuery({
    query: `(min-width: ${desktop940}px) and (max-width: ${desktop1100}px)`,
  });
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const {
    contractNumber,
    amount,
    setAmount,
    errorMessage,
    setErrorMessage,
    isLoading,
    setIsLoading,
    isSidePageOpen,
    QRcode,
    payHandler,
    paymentViaSBP,
    payByCreditCard,
    isAvailableSBP,
    handleOnChangeInputText,
    onCloseClickSbpDesktop,
    isOpenPromo,
    setIsOpenPromo,
    contractNumberError,
    setContractNumberError,
    amountError,
    setAmountError,
    isRememberPaymentData,
    setIsRememberPaymentData,
    isHideBindings,
    isChangeContract,
    setIsChangeContract,
    paymentSelect,
    setPaymentSelect,
    isNewAccount,
    isSBP,
  } = usePayment({
    promoListContent,
    activePromotion,
    setActivePromotion,
    getPromoInfo,
    cardLimit,
    sbpLimit,
  });

  const showCheckbox = useMemo(() => {
    return (
      !isAuth ||
      (!sizeX2 && !isHideBindings && isNewAccount && !isDesktopFrom940To1100) ||
      (sizeX2 && !isHideBindings && isNewAccount)
    );
  }, [isAuth, sizeX2, isDesktopFrom940To1100, isHideBindings, isNewAccount]);

  const StyledPaymentForm = useMemo(() => {
    return sizeX2 ? StyledPaymentX2Form : StyledPaymentX1Form;
  }, [sizeX2]);

  const props = sizeX2
    ? { isOpenSBPCards, isAuth, isChangeContract, isSBP }
    : {
        isOpenSBPCards,
        isAuth,
        showCheckbox,
        isChangeContract,
      };

  /** Иконка СБП для кнопки оплаты */
  const buttonsSbpIcon = useMemo(() => {
    return isSBP && ((isDesktop940 && sizeX2) || (isDesktop1280 && !sizeX2)) ? (
      <div className="sbp-icon">
        <Icon icon={<Icons.SbpIcon />} />
      </div>
    ) : null;
  }, [
    sizeX2,
    isSBP,
    isAuth,
    isChangeContract,
    isDesktop940,
    isDesktopFrom940To1100,
  ]);

  // При вводе суммы
  const handleOnChangeInputMoney = (params) => {
    const { value, forSend } = params;
    setAmount(
      // eslint-disable-next-line no-nested-ternary
      !isSBP && cardLimit.maxAmount && forSend > cardLimit.maxAmount
        ? {
            value: `${formatNumber(cardLimit.maxAmount)} \u20BD`,
            forSend: `${cardLimit.maxAmount}`,
          }
        : isSBP && sbpLimit.maxAmount && forSend > sbpLimit.maxAmount
        ? {
            value: `${formatNumber(sbpLimit.maxAmount)} \u20BD`,
            forSend: `${sbpLimit.maxAmount}`,
          }
        : { value, forSend },
    );
  };

  // Обработчик клика на тег популярного платежа
  const handlePaymentTag = (tag: AmountProps): void => {
    handleOnChangeInputMoney(tag);
    setActiveTag(tag.forSend);
  };

  // Возвращает значения популярных платежей
  const generatePopularPayments = (): AmountProps[] | null => {
    if (
      !sizeX2 ||
      !isDesktop940 ||
      !sbpLimit?.presetPaymentAmounts ||
      !cardLimit?.presetPaymentAmounts
    )
      return null;
    const amounts = isSBP
      ? sbpLimit.presetPaymentAmounts
      : cardLimit.presetPaymentAmounts;
    return amounts.map((item) => ({
      value: `${formatNumber(item)} \u20BD`,
      forSend: `${item}`,
    }));
  };

  // Сбрасывать активный тег, при изменении суммы платежа руками
  useEffect(() => {
    if (!sbpLimit?.presetPaymentAmounts && !cardLimit?.presetPaymentAmounts)
      return;
    const amounts = isSBP
      ? sbpLimit.presetPaymentAmounts
      : cardLimit.presetPaymentAmounts;
    if (!amounts.includes(Number(amount.forSend))) setActiveTag(null);
  }, [amount]);

  useEffect(() => {
    setErrorMessage('');
    setAmountError(false);
    setIsChangeContract(false);
  }, [isAuth]);

  useEffect(() => {
    if (isChangeContract && refInput) refInput.current.select();
  }, [isChangeContract]);

  const popularPayments = React.useCallback(
    () =>
      generatePopularPayments()?.length &&
      generatePopularPayments().map((tag) => (
        <TagButton
          key={tag.forSend}
          onChange={() => handlePaymentTag(tag)}
          checked={tag.forSend === activeTag}
          className="payment-form__tags"
        >
          {tag.value}
        </TagButton>
      )),
    [isSBP, activeTag],
  );

  const HeaderText = (): JSX.Element => {
    let text: string;
    if (isDesktopFrom940To1100 && isAuth && !isChangeContract && sizeX2) {
      text = `Пополнение баланса ${removeLeadingZero(auth.contractName)}`;
    } else if (isAuth && !isChangeContract) {
      text = `Пополнение баланса договора ${removeLeadingZero(
        auth.contractName,
      )}`;
    } else {
      text = 'Пополнение баланса';
    }

    return isDesktop1280 && sizeX2 ? (
      <H3>{text}</H3>
    ) : (
      <LeadingText color={defaultTheme.colors.black}>{text}</LeadingText>
    );
  };

  const buttonText = () => {
    if (!sizeX2 && isDesktopFrom940To1100) return 'Оплатить';
    return isSBP ? 'Оплатить через СБП' : 'Оплатить картой';
  };

  // Checkbox
  const checkbox = paymentSelect ? (
    <Checkbox
      className="payment-form__button-wrapper__checkbox"
      checked={isRememberPaymentData}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setIsRememberPaymentData(e.target.checked)
      }
    >
      {isSBP ? 'Привязать счёт СБП' : 'Привязать карту'}
    </Checkbox>
  ) : null;

  // Сноска
  const snoska = () => {
    if (
      !sizeX2 &&
      (!isAuth ||
        isDesktopFrom940To1100 ||
        errorMessage ||
        (isAuth && isChangeContract && isNewAccount))
    )
      return null;

    const defaultText = `Сумма платежа от ${
      isSBP
        ? formatNumber(sbpLimit.minAmount)
        : formatNumber(cardLimit.minAmount)
    } до ${
      isSBP
        ? formatNumber(sbpLimit.maxAmount)
        : formatNumber(cardLimit.maxAmount)
    }₽.`;

    if (!sizeX2 && isAuth && isChangeContract)
      return (
        <Snoska
          color={defaultTheme.colors.gray}
          className="payment-form__button-wrapper__snoska"
        >
          {defaultText} Без комиссии.
        </Snoska>
      );
    if (isSBP) {
      return (
        <Snoska
          color={defaultTheme.colors.gray}
          className="payment-form__button-wrapper__snoska"
        >
          {defaultText} Без комиссии.{' '}
          <Snoska
            className="payment-form__button-wrapper__snoska__link"
            color={defaultTheme.colors.planeta}
            onClick={() => setIsListOfSbpBanksOpen(true)}
          >
            Список банков
          </Snoska>{' '}
          поддерживающих подписки СБП.
        </Snoska>
      );
    }
    return (
      <Snoska
        color={defaultTheme.colors.gray}
        className="payment-form__button-wrapper__snoska"
      >
        {defaultText} Вы будете перенаправлены на{'\u00A0'}защищённый сайт
        банка.
      </Snoska>
    );
  };

  return (
    <StyledPaymentForm {...props}>
      <div className="payment-form__header">
        <HeaderText />
        {isAuth && !isChangeContract && (
          <div className="payment-form__header__link">
            <LinkButton
              onClick={() => {
                setIsChangeContract(true);
                setErrorMessage('');
                setAmountError(false);
              }}
            >
              Пополнить другой договор
            </LinkButton>
          </div>
        )}
      </div>
      {isSBP && !isAvailableSBP ? (
        <div className="payment-form__wrapper__errorBlock">
          <div className="payment-form__wrapper">
            <div className="payment-form__wrapper__block">
              <Text>Оплата через СБП временно недоступна.</Text>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="payment-form__wrapper__errorBlock">
            <div className="payment-form__wrapper">
              {(!isAuth || isChangeContract) && (
                <div className="payment-form__wrapper__block">
                  <Text
                    className="payment-form__wrapper__block-text"
                    color={defaultTheme.colors.shadow}
                  >
                    Договор
                  </Text>
                  <Input
                    type="text"
                    placeholder="до 8 цифр"
                    inputMode="numeric"
                    value={removeLeadingZero(contractNumber)}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      handleOnChangeInputText(e);
                    }}
                    width="100%"
                    borderRadius="4px"
                    error={
                      contractNumberError ||
                      errorMessage === ERROR_MESSAGES.CONTRACT_NOT_FOUND
                    }
                    onFocus={() => {
                      setContractNumberError(false);
                      if (
                        [
                          ERROR_MESSAGES.CONTRACT_NOT_FOUND,
                          ERROR_MESSAGES.CONTRACT_NUMBER,
                        ].includes(errorMessage as ERROR_MESSAGES)
                      )
                        setErrorMessage('');
                    }}
                    forwardedRef={refInput}
                  />
                </div>
              )}
              <div className="payment-form__wrapper__block payment-form__input-wrapper_money">
                <Text
                  className="payment-form__wrapper__block-text"
                  color={defaultTheme.colors.shadow}
                >
                  Сумма
                </Text>
                <Input
                  type="money"
                  placeholder="500 &#8381;"
                  value={amount.value}
                  onChangeCustomInput={handleOnChangeInputMoney}
                  error={amountError}
                  onFocus={() => {
                    if (amountError) {
                      setAmountError(false);
                      setErrorMessage('');
                    }
                  }}
                  width="100%"
                />
              </div>
              <div className="payment-form__wrapper__block payment-form__wrapper__block__card-block-half">
                {(sizeX2 ||
                  !(
                    errorMessage &&
                    ((isAuth && isChangeContract) || !isAuth)
                  )) && (
                  <Text
                    className="payment-form__wrapper__block-text"
                    color={defaultTheme.colors.shadow}
                  >
                    Способ оплаты
                  </Text>
                )}

                <CardAndSbpPaymentSelect
                  className="payment-form__wrapper__block-card"
                  setError={setErrorMessage}
                  paymentSelect={paymentSelect}
                  setPaymentSelect={setPaymentSelect}
                  isHideBindings={isHideBindings}
                />
              </div>
            </div>
            {errorMessage && (
              <div className="payment-form__wrapper__text">
                <Text color={defaultTheme.colors.planeta}>{errorMessage}</Text>
              </div>
            )}
          </div>
        </>
      )}
      {popularPayments()}
      <div className="payment-form__button-wrapper">
        {showCheckbox && checkbox}
        <Button
          loading={isLoading || isLoadingPaymentInfo}
          onClick={() => {
            setIsUseSBPBanksList(isSBP && isRememberPaymentData);
            payHandler();
          }}
          disabled={isSBP && !isAvailableSBP}
          background={isSBP ? defaultTheme.colors.black : undefined}
          border={isSBP ? `2px solid ${defaultTheme.colors.black}` : undefined}
          icon={buttonsSbpIcon}
        >
          {buttonText()}
        </Button>
        {snoska()}
      </div>

      <Portal>
        <PinWizard />
        <ListOfSbpBanks />
        <SidePage
          show={isSidePageOpen}
          headerText="Пополнить счёт через СБП"
          onCloseClick={onCloseClickSbpDesktop}
        >
          <StyledQrSidePage>
            <div className="qr__code-block">
              <div>
                <Image
                  src={`data:image/png;base64, ${QRcode}`}
                  alt="qrcode"
                  width={212}
                  height={212}
                  quality={100}
                />
              </div>
              <div className="qr__text-block">
                <Text lineHeight="24px">
                  Отсканируйте QR-код камерой телефона
                  <br />
                  и&nbsp;завершите оплату в&nbsp;мобильном приложении банка.
                </Text>
              </div>
            </div>
            {isRememberPaymentData && (
              <Snoska className="qr__snoska">
                Сканируя QR-код, вы&nbsp;подтверждаете согласие с&nbsp;условиями
                привязки счёта, указанными в{'\u00A0'}
                <Link href={DOCUMENTS_LINK} target="_blank">
                  Регламенте
                </Link>
                .
                <br />
                Вы&nbsp;будете перенаправлены в&nbsp;приложение банка.
              </Snoska>
            )}
          </StyledQrSidePage>
        </SidePage>
        {activePromotion && promoListContent[activePromotion] && (
          <SidePage
            show={isOpenPromo}
            headerText={promoListContent[activePromotion].header}
            onCloseClick={() => {
              setIsOpenPromo(false);
              setActivePromotion(null);
            }}
            width={
              promoListContent[activePromotion]?.isCashbackBottoms
                ? '832px'
                : null
            }
          >
            <Promo
              content={promoListContent[activePromotion].content}
              payByCreditCard={payByCreditCard}
              paymentViaSBP={paymentViaSBP}
              contractName={contractNumber}
              amount={+amount.forSend}
              paymentPromoType={activePromotion}
              setAnswer={setAnswer}
              setIsOpenPromo={setIsOpenPromo}
              setIsLoading={setIsLoading}
              isCashbackBottoms={
                promoListContent[activePromotion]?.isCashbackBottoms
              }
              currentPaymentType={
                isSBP ? PAYMENT_TYPE.SBP : PAYMENT_TYPE.CARD_PAYMENT
              }
            />
          </SidePage>
        )}
      </Portal>
    </StyledPaymentForm>
  );
};

export default observer(PaymentForm);
