/** libraries */
import { FC, useEffect, useState, useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** components */
import Root, { StyledHeader, StyledLink, StyledLoader } from './style';
import { defaultTheme, H3 } from 'cordis-core-ui-planeta';
/** config */
import CONFIG, { CONTRACT_STATE } from './config';
/** constants */
import { OPERATING_STATE } from '~/constants/common';
import { desktop380 } from '~/components/Grid/constants';
import { WORLFLOW_NAMES } from '~/components/MyAppealsSidePage/constants';
import { SUSPEND_STATE } from '../Contract/constants';
/** api */
import { getNewContractInfo, getStrayInfo } from '~/api/apiPab2c';
/** types */
import { NewContractProps } from '~/components/Blocks/Templates/Pab2c/ContractState/types';
import { PrepaymentConnectionProps } from '~/components/Blocks/Templates/Pab2c/ContractState/Components/PrepaymentConnection';
import { PhoneConnectionType } from '~/components/Blocks/Templates/Pab2c/ContractState/Components/PhoneConnection';
import { AppointedConnectionProps } from '~/components/Blocks/Templates/Pab2c/ContractState/Components/AppointedConnection';
import { ZombieInfoProps } from '../Subscriptions/interfaces';
/** store */
import { useRootStore } from '~/stores/RootStore';
import useMyAppealsStore from '~/components/MyAppealsSidePage/store/useMyAppealsStore';

/** Блок ЛК. Обслуживание
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=732821342
 */
const pab2cContractState: FC = () => {
  const [view, setView] = useState<CONTRACT_STATE>(null);
  const [mainProps, setMainProps] = useState<
    | PrepaymentConnectionProps
    | PhoneConnectionType
    | AppointedConnectionProps
    | ZombieInfoProps
  >(null);

  const {
    contractStateStore: { contractState, suspendCondition },
    vacationStore: { activationDate, isOrdered, isActivated },
  } = useRootStore();
  const { setIsShowMyAppeals, setTypeOfApplication } = useMyAppealsStore();

  const isDesktop380 = useMediaQuery({
    query: `(min-width: ${desktop380}px)`,
  });

  const getNewViewByStatus = (newContractData: NewContractProps): void => {
    const { status } = newContractData;

    switch (status) {
      case 'Connecting':
        setView(CONTRACT_STATE.APPOINTED_CONNECTION);
        setMainProps({ dt: newContractData.connectingDate });
        break;
      case 'Insufficiently':
        setView(CONTRACT_STATE.NOT_ENOUGH_PAYMENT);
        setMainProps({
          view: CONTRACT_STATE.NOT_ENOUGH_PAYMENT,
          money: newContractData.leftToPay,
        });
        break;
      case 'MountPlanning':
        setView(CONTRACT_STATE.WAITING_CONNECTION);
        break;
      case 'NoPrepayment':
        setView(CONTRACT_STATE.WAITING_PAYMENT);
        setMainProps({
          view: CONTRACT_STATE.NOT_ENOUGH_PAYMENT,
          money: newContractData.moneyCharge,
        });
        break;
      case 'Unknown':
        setView(CONTRACT_STATE.SUSPENDED_CONNECTION);
        break;
      default:
        setView(CONTRACT_STATE.SUSPENDED_CONNECTION);
        break;
    }
  };

  /** Информация о зомбировании */
  const [zombieInfo, setZombieInfo] = useState<ZombieInfoProps>(null);
  /** Договор близок к зомбированию */
  const isCloseToZombie = useMemo(() => {
    if (
      zombieInfo?.daysLeft &&
      zombieInfo?.daysLeft >= 0 &&
      zombieInfo?.daysMax
    ) {
      return (
        (zombieInfo.daysMax - zombieInfo.daysLeft) / zombieInfo.daysMax >= 0.25
      );
    }
    return false;
  }, [zombieInfo]);

  useEffect(() => {
    (async () => {
      switch (contractState) {
        case OPERATING_STATE.ON:
          setView(
            // eslint-disable-next-line no-nested-ternary
            isOrdered
              ? CONTRACT_STATE.VACATION_PLANNED
              : suspendCondition?.suspendState === SUSPEND_STATE.order
              ? CONTRACT_STATE.SUSPENDED_PLANNED
              : CONTRACT_STATE.SERVICED,
          );
          break;
        case OPERATING_STATE.STRAY:
          setView(CONTRACT_STATE.STRAY);
          break;
        case OPERATING_STATE.PROVIDER_BLOCK_DEBT:
          try {
            const res = await getStrayInfo();
            setZombieInfo(res);
          } catch (e) {
            console.error('getStrayInfoError', e);
          }
          if (isCloseToZombie) {
            setMainProps(zombieInfo);
            setView(CONTRACT_STATE.CLOSE_TO_STRAY);
          } else {
            setView(CONTRACT_STATE.DEBT);
          }
          break;
        case OPERATING_STATE.CLIENT_BLOCK:
          setView(
            isOrdered || isActivated
              ? CONTRACT_STATE.VACATION
              : CONTRACT_STATE.DIVING,
          );
          break;
        case OPERATING_STATE.DREGS:
          setView(CONTRACT_STATE.DREGS);
          break;
        case OPERATING_STATE.NEW:
          try {
            const newData = await getNewContractInfo();
            getNewViewByStatus(newData);
          } catch (e) {
            console.error(e);
          }
          break;
        default:
          setView(null);
          break;
      }
    })();
  }, [
    contractState,
    suspendCondition,
    isOrdered,
    activationDate,
    isCloseToZombie,
  ]);

  const isChangeContractLink = useMemo(() => {
    return [
      CONTRACT_STATE.SERVICED,
      CONTRACT_STATE.SUSPENDED_PLANNED,
      CONTRACT_STATE.VACATION,
      CONTRACT_STATE.VACATION_PLANNED,
      CONTRACT_STATE.DIVING,
      CONTRACT_STATE.DEBT,
    ].includes(view);
  }, [view]);

  if (view === null)
    return (
      <Root>
        <H3>Состояние обслуживания...</H3>
        <StyledLoader />
      </Root>
    );

  const {
    backgroundUrl,
    backgroundUrlMobile,
    headerText,
    component: Main,
  } = CONFIG[view];
  const getHeaderText = () => {
    if (view !== CONTRACT_STATE.SUSPENDED_PLANNED) return headerText;
    const dt = activationDate ?? suspendCondition.scheduledDt;
    const formattedDate = dt
      ? format(parseISO(dt), 'd MMMM', { locale: ru })
      : '';
    return (headerText as (date: string) => string)(formattedDate);
  };
  const getBackgroundUrl = () => {
    if (
      [
        CONTRACT_STATE.WAITING_CONNECTION,
        CONTRACT_STATE.NOT_ENOUGH_PAYMENT,
        CONTRACT_STATE.WAITING_PAYMENT,
      ].includes(view) &&
      !isDesktop380
    )
      return backgroundUrlMobile;

    return backgroundUrl;
  };

  return (
    <Root $backgroundUrl={getBackgroundUrl()}>
      <div className="description">
        <StyledHeader color={defaultTheme.colors.white}>
          {getHeaderText()}
        </StyledHeader>
        {Main ? <Main {...mainProps} /> : <></>}
      </div>
      {isChangeContractLink && (
        <StyledLink
          onClick={() => {
            setTypeOfApplication(WORLFLOW_NAMES.CHANGE_THE_CONTRACT);
            setIsShowMyAppeals(true);
          }}
        >
          Переоформить договор
        </StyledLink>
      )}
    </Root>
  );
};

export default observer(pab2cContractState);
