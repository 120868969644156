/** libraries */
import { observer } from 'mobx-react';
/** styles */
import { StyledRadio } from '../styles';
/** stores */
import useMobileStore from '../../../store/useMobileStore';

const NumbersList = (): JSX.Element => {
  const {
    changeNumberStore: {
      numberCategoryNames,
      unionCategoriesWithNumbers,
      activeCategoryTabIndex,
      candidateToNewNumber,
      setCandidateToNewNumber,
    },
  } = useMobileStore();

  if (!unionCategoriesWithNumbers) return null;

  const categoryName = numberCategoryNames[activeCategoryTabIndex];
  return unionCategoriesWithNumbers[categoryName].map((number) => (
    <StyledRadio
      checked={candidateToNewNumber === number}
      onChange={() => setCandidateToNewNumber(number)}
      title={number}
      description={categoryName}
      key={number}
    />
  ));
};

export default observer(NumbersList);
