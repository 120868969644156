import styled from '@emotion/styled';
import { defaultTheme, Snoska } from 'cordis-core-ui-planeta';
import { desktop1100, desktop1280, desktop940 } from '../Grid/constants';

export const StyledFooter = styled.div`
  max-width: 100%;
  overflow-x: hidden;
`;

export const StyledFooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  padding: 12px 32px 32px;
  background: ${defaultTheme.colors.light};

  .footer-menu {
    width: 600px;
  }
`;

export const StyledAppBlock = styled.div`
  width: 100%;
  margin-bottom: 16px;

  > div:first-of-type {
    margin-bottom: 8px;
  }

  @media (min-width: ${desktop940}px) {
    max-width: 436px;
    margin-bottom: 38px;
  }

  @media (min-width: ${desktop1100}px) {
    max-width: 498px;
  }
`;

export const StyledStores = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const ImageWrapper = styled.div`
  width: 96px;
  height: 96px;
  margin-right: 16px !important;
  flex-shrink: 0;

  @media (min-width: ${desktop1100}px) {
    width: 112px;
    height: 112px;
  }
`;

export const StyledLegal = styled(Snoska)`
  width: 100%;
`;

export const StyledStoreButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 48px;
  background-color: ${defaultTheme.colors.white};
  border-radius: 8px;
`;

export const StyledStoresButtons = styled.ul`
  display: flex;
  flex-wrap: wrap;

  li {
    list-style-type: none;
    margin-right: 8px;
    width: 157px;
    height: 48px;
    margin-bottom: 8px;
    background-color: ${defaultTheme.colors.white};
    border-radius: 8px;
    padding: 10px 0 0 12px;
  }

  li:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${desktop940}px) {
    li {
      width: 138px;
      height: 40px;
      margin-bottom: 16px;
      margin-right: 16px;
      padding: 10px 0 0 15px;
    }
  }

  @media (min-width: ${desktop1100}px) {
    li {
      width: 160px;
      height: 48px;
      padding: 10px 0 0 15px;
    }
  }
`;

export const StyledMenu = styled.div`
  display: flex;

  /* гамбургер */
  > span {
    height: fit-content;
  }

  svg {
    margin-right: 16px;
    margin-top: -4px;
  }

  svg:hover {
    cursor: pointer;
    stroke: ${defaultTheme.colors.white};
    background-color: ${defaultTheme.colors.disable};
  }

  > div {
    height: 149px;
    width: 384px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    & > div {
      margin-bottom: 4px;
    }
    & > div:nth-of-type(5) {
      margin-bottom: 0px;
    }
    span {
      width: 160px;
    }
    span:hover {
      color: ${defaultTheme.colors.planeta};
    }
  }

  @media (min-width: ${desktop940}px) {
    > div {
      width: 320px;
    }
  }
  @media (min-width: ${desktop1100}px) {
    margin-right: 82px;
    > div {
      width: 384px;
    }
  }

  @media (min-width: ${desktop1280}px) {
    margin-right: 144px;
  }
`;

export const StyledSocial = styled.div`
  display: flex;
  margin-top: 12px;
  > div {
    margin-right: 8px;
    width: 48px;
    height: 48px;
    background-color: ${defaultTheme.colors.white};
    border-radius: 8px;
    padding: 10px;
  }
`;

export const StyledTitle = styled.div`
  > span {
    display: flex;
    flex-wrap: wrap;
    & > span,
    > div {
      margin: 0 4px;
    }
  }
`;
