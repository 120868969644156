import styled from '@emotion/styled';
import { Squircle } from '@squircle-js/react';
import { defaultTheme, H3, Text } from 'cordis-core-ui-planeta';
import { McBannerTemplates } from '../../constants';
import {
  desktop1100,
  desktop375,
  desktop940,
} from '~/components/Grid/constants';
import CONFIG from '../../config';

const { circles, circlesSmall } = CONFIG[McBannerTemplates.bonus].images;

export const StyledSquircle = styled(Squircle)`
  height: 400px;
  padding: 39px 0 0 32px;
  background-color: ${defaultTheme.colors.jazzberryJam};
`;

export const StyledH3 = styled(H3)`
  max-width: 280px;
  margin-bottom: 21px;
`;

export const StyledInfo = styled(Text)`
  display: block;
  max-width: 280px;
  line-height: 24px;
  margin-bottom: 25px;
  margin-right: 10px;
`;

export const StyledCircles = styled.div`
  background-image: url(${circles});
  background-repeat: no-repeat;
  height: 169px;
  display: flex;
  align-items: center;

  @media (max-width: ${desktop375}px),
    (min-width: ${desktop940}px) and (max-width: ${desktop1100}px) {
    background-image: url(${circlesSmall});
    height: 132px;
    margin-top: 58px;
  }
`;

export const StyledGigabyte = styled(Text)`
  font-size: 60px;
  font-weight: 500;
  line-height: 68px;
  margin-left: 27px;

  @media (max-width: ${desktop375}px),
    (min-width: ${desktop940}px) and (max-width: ${desktop1100}px) {
    font-size: 42px;
    margin-left: 24px;
  }
`;

export const StyledMinutes = styled.div`
  display: block;
  width: 100px;
  margin-left: 53px;
  margin-top: 24px;

  @media (max-width: ${desktop375}px),
    (min-width: ${desktop940}px) and (max-width: ${desktop1100}px) {
    width: 80px;
    margin-top: 10px;
  }
`;

export const StyledMinutesAmount = styled(Text)`
  font-size: 50px;
  font-weight: 500;

  @media (max-width: ${desktop375}px),
    (min-width: ${desktop940}px) and (max-width: ${desktop1100}px) {
    font-size: 30px;
  }
`;

export const StyledMinutesLabel = styled(Text)`
  font-size: 16px;
  margin-left: 5px;

  @media (max-width: ${desktop375}px),
    (min-width: ${desktop940}px) and (max-width: ${desktop1100}px) {
    font-size: 12px;
    line-height: 12px;
  }
`;
