/** libraries */
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { SidePage, Loader } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** components */
import ErrorHandler from './Components/ErrorHandler';
import SuccessOrder from './Components/SuccessOrder';
import Main from './Components/Main';
import Footer from './Components/Footer';
/** styles */
import { StyledH2 } from './styles';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
/** stores */
import useMobileStore from '../../store/useMobileStore';

const BindSubscription = (): JSX.Element => {
  const {
    bindSubscriptionStore: {
      isShowBindSubscriptionSP,
      resetBindSubscriptionStore,
      getAvailableMobileSubscriptions,
      result,
      isLoadingSubscriptions,
      isBindRoaming,
    },
  } = useMobileStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  useEffect(() => {
    if (!isShowBindSubscriptionSP) return;
    (async () => {
      await getAvailableMobileSubscriptions();
    })();
  }, [isShowBindSubscriptionSP]);

  const headerText = () => {
    if (result.isResult && result.isCorrect) return 'Пакет подключён';
    if (!result.isResult && isBindRoaming) return 'Подключение пакета Мб';

    if (!result.isResult) return 'Подключение пакета Гб или&nbsp;минут';
    return '';
  };

  if (!isShowBindSubscriptionSP) return null;

  return (
    <SidePage
      show={isShowBindSubscriptionSP}
      headerText={headerText()}
      headerReactNode={
        result.isResult &&
        result.isCorrect &&
        isDesktop940 && <StyledH2>Пакет подключён</StyledH2>
      }
      onCloseClick={resetBindSubscriptionStore}
      footerContainer={<Footer />}
      showMobileHeader
    >
      {result.isResult && result.isCorrect && <SuccessOrder />}
      {result.isResult && !result.isCorrect && <ErrorHandler />}
      {isLoadingSubscriptions && !result.isResult && <Loader small />}
      {!isLoadingSubscriptions && !result.isResult && <Main />}
    </SidePage>
  );
};

export default observer(BindSubscription);
