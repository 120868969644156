/** libraries */
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
/** styles */
import { StyledRadio, StyledSubscriptionList } from '../styles';
/** stores */
import useMobileStore from '../../../store/useMobileStore';

const SubscriptionList = (): JSX.Element => {
  const {
    bindSubscriptionStore: {
      subscriptionsWithRoaming,
      subscriptionsWithoutRoaming,
      isBindRoaming,
      selectedSubscriptionId,
      setSelectedSubscriptionId,
    },
    roamingStore: { selectedCountry, roamingZoneInfo },
  } = useMobileStore();

  const subscriptions = isBindRoaming
    ? subscriptionsWithRoaming
    : subscriptionsWithoutRoaming;

  useEffect(() => {
    if (!subscriptions.length || selectedSubscriptionId) return;
    setSelectedSubscriptionId(subscriptions[0].itemId);
  }, [subscriptions]);

  const subscriptionRadios = () => {
    return subscriptions.map((subscription) => {
      const daysCounter = subscription.trimDate
        ? formatDistanceToNowStrict(parseISO(subscription.trimDate), {
            unit: 'day',
            locale: ru,
            roundingMethod: 'round',
          })
        : '';

      const description = isBindRoaming
        ? `Для ${selectedCountry.name} и ещё ${
            roamingZoneInfo.countries.length - 1
          } стран`
        : `За ${subscription.price} ₽${daysCounter && ` на ${daysCounter}`}`;

      return (
        <StyledRadio
          checked={subscription.itemId === selectedSubscriptionId}
          onChange={() => setSelectedSubscriptionId(subscription.itemId)}
          title={subscription.name}
          description={description}
          key={subscription.itemId}
        />
      );
    });
  };

  return (
    <StyledSubscriptionList>{subscriptionRadios()}</StyledSubscriptionList>
  );
};

export default observer(SubscriptionList);
