/** libraries */
import { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
/** components */
import { Loader } from 'cordis-core-ui-planeta';
/** constants */
import { SIDE_PAGES } from '~/components/AuthWizard/constants';
import { VIEW_TYPES } from '../types';
/** stores */
import { useRootStore } from '~/stores/RootStore';
/** styles */
import { StyledLink } from '../style';
/** stores */
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
import useOperatingStateModelStore from './OperatingStateWizard/store/useOperatingStateStore';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Serviced: FC = () => {
  const {
    authStore: { isAuth },
    vacationStore: { getVacation },
    contractStateStore: { updateAuthCondition },
  } = useRootStore();
  const {
    openSPAfterAuthorizationState,
    setOpenSPAfterAuthorizationState,
  } = useMakeAuthStore();
  const {
    isLoadingSuspension,
    setIsSuspendOperationStateWizardShow,
    getOperationStateAgreement,
    setViewType,
    suspendOperatingState,
  } = useOperatingStateModelStore();

  useEffect(() => {
    setViewType(VIEW_TYPES.PAUSE);
  }, []);

  useEffect(() => {
    if (!isAuth) return;
    switch (openSPAfterAuthorizationState) {
      case SIDE_PAGES.SUSPENDING_AGREEMENT:
        getOperationStateAgreement(setOpenSPAfterAuthorizationState);
        break;
      case SIDE_PAGES.SUSPENDING_ACTION:
        setIsSuspendOperationStateWizardShow(true);
        suspendOperatingState(
          getVacation,
          setOpenSPAfterAuthorizationState,
          updateAuthCondition,
        );
        break;
      default:
    }
  }, [isAuth]);

  return (
    <>
      <Root>
        {isLoadingSuspension ? (
          <Loader small />
        ) : (
          <StyledLink
            onClick={() =>
              getOperationStateAgreement(setOpenSPAfterAuthorizationState)
            }
          >
            Приостановить обслуживание
          </StyledLink>
        )}
      </Root>
    </>
  );
};

export default observer(Serviced);
