/** libraries */
import { observer } from 'mobx-react';
import { H2, H3, Icons, Loader, SidePage, Text } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** constants */
import { CHANGE_NUMBER_STATUS_SP_TYPE } from '../../constants';
import { DEFAULT_ERROR } from '~/constants/common';
import { desktop1100 } from '~/components/Grid/constants';
/** style */
import { StyledWarning } from '../../style';
import { StyledIcon } from './styles';
/** stores */
import useMobileStore from '../../store/useMobileStore';
/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
/** components */
import ChangeNumberStatusFooter from './ChangeNumberStatusFooter';

const ChangeNumberStatus = (): JSX.Element => {
  const {
    changeNumberStatusStore: {
      isShowChangeNumStatusSP,
      changeNumStatusSPHeaderText,
      changeNumberStatusType,
      isLoadingAgreement,
      agreement,
      result,
      onCloseChangeNumStatusSP,
    },
  } = useMobileStore();

  const warning = () => {
    switch (changeNumberStatusType) {
      case CHANGE_NUMBER_STATUS_SP_TYPE.LOCK:
        return (
          <StyledWarning>
            <Text lineHeight="24px">
              Номер будет заблокирован, звонки на этот номер поступать не будут.
            </Text>
          </StyledWarning>
        );
      case CHANGE_NUMBER_STATUS_SP_TYPE.DISABLE:
        return (
          <StyledWarning>
            <Text lineHeight="24px">
              Перед отключением номера отвяжите его от банковских карт и других
              аккаунтов. Номер будет отключен от SIM-карты и договора.
            </Text>
          </StyledWarning>
        );
      default:
        return <></>;
    }
  };

  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  if (!isShowChangeNumStatusSP) return null;

  return (
    <SidePage
      show={isShowChangeNumStatusSP}
      headerText={!result.isResult && changeNumStatusSPHeaderText}
      onCloseClick={onCloseChangeNumStatusSP}
      footerContainer={!result.isResult && <ChangeNumberStatusFooter />}
    >
      {result.isResult && result.isCorrect && (
        <>
          <StyledIcon icon={<Icons.SuccessIconAnimated />} />
          {isDesktop1100 ? <H2>{result.text}</H2> : <H3>{result.text}</H3>}
        </>
      )}
      {result.isResult && !result.isCorrect && (
        <>
          <StyledIcon icon={<Icons.NotOkBigIcon />} />
          {isDesktop1100 ? <H2>{DEFAULT_ERROR}</H2> : <H3>{DEFAULT_ERROR}</H3>}
        </>
      )}
      {!result.isResult && (
        <>
          {warning()}
          {isLoadingAgreement ? (
            <Loader />
          ) : (
            <Text lineHeight="24px">{parseHtml(agreement)}</Text>
          )}
        </>
      )}
    </SidePage>
  );
};

export default observer(ChangeNumberStatus);
