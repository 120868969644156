/** libraries */
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, Loader, SidePage } from 'cordis-core-ui-planeta';
/** constants */
import { OPERATING_STATE } from '~/constants/common';
/** components */
import NumbersList from './Components/NumbersList';
import Tabs from './Components/Tabs';
import ServiceStatusWarning from './Components/ServiceStatusWarning';
/** stores */
import useMobileStore from '../../store/useMobileStore';
import { useRootStore } from '~/stores/RootStore';

const AvailableNumbers = (): JSX.Element => {
  const {
    contractStateStore: { contractState },
  } = useRootStore();

  const {
    changeNumberStore: {
      isShowAvailableNumbersSp,
      setIsShowAvailableNumbersSp,
      numberCategoryNames,
      unionCategoriesWithNumbers,
      activeCategoryTabIndex,
      candidateToNewNumber,
      setCandidateToNewNumber,
      setNewNumberButtonHandler,
      isDataLoading,
    },
    bindNewNumberStore: { setIsShowBindNewNumberSP },
  } = useMobileStore();

  const isSuspendedCondition = [
    OPERATING_STATE.PROVIDER_BLOCK_DEBT,
    OPERATING_STATE.NEW,
  ].includes(contractState);

  /** По-умолчанию всегда выбираем первый номер телефона */
  useEffect(() => {
    if (!isShowAvailableNumbersSp || !unionCategoriesWithNumbers) return;

    const categoryName = numberCategoryNames[activeCategoryTabIndex];
    setCandidateToNewNumber(unionCategoriesWithNumbers[categoryName][0]);
  }, [
    activeCategoryTabIndex,
    isShowAvailableNumbersSp,
    unionCategoriesWithNumbers,
  ]);

  const Footer = (): JSX.Element => (
    <Button
      onClick={setNewNumberButtonHandler}
      disabled={!candidateToNewNumber || isSuspendedCondition}
    >
      Выбрать номер
    </Button>
  );

  const onCloseClickHandler = () => {
    if (isSuspendedCondition) {
      setIsShowBindNewNumberSP(false);
    }
    setIsShowAvailableNumbersSp(false);
  };

  if (!isShowAvailableNumbersSp) return null;

  return (
    <SidePage
      show={isShowAvailableNumbersSp}
      headerText="Выберите ваш будущий номер"
      onCloseClick={onCloseClickHandler}
      footerContainer={<Footer />}
      showMobileHeader
    >
      {isSuspendedCondition ? (
        <ServiceStatusWarning />
      ) : (
        <>
          <Tabs />
          {isDataLoading && <Loader small />}
          {!isDataLoading && <NumbersList />}
        </>
      )}
    </SidePage>
  );
};

export default observer(AvailableNumbers);
