/** libraries */
import { useMediaQuery } from 'react-responsive';
import {
  ButtonIconPosition,
  ButtonStyleTypes,
  Icons,
} from 'cordis-core-ui-planeta';
/** styles */
import {
  StyledButton,
  StyledH3,
  StyledImg,
  StyledSquircle,
  StyledText,
} from './styles';
/** constants */
import { McBannerTemplates } from '../../constants';
import { desktop940 } from '~/components/Grid/constants';
/** config */
import CONFIG from '../../config';

const Help = (): JSX.Element => {
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const { header, info, button, cta } = CONFIG[McBannerTemplates.help].text;

  /** вызывает клик по кнопке открытия чата */
  const openChat = (): void => {
    const chatButton = document.getElementsByClassName(
      'sherlock-widget',
    )[0] as HTMLElement;
    if (chatButton) chatButton.click();
  };

  return (
    <StyledSquircle cornerRadius={isDesktop940 ? 86 : 70} cornerSmoothing={1}>
      <StyledH3>{header}</StyledH3>
      <StyledImg />
      <StyledText>{info}</StyledText>
      <StyledText>{cta}</StyledText>
      <StyledButton
        styleType={ButtonStyleTypes.OUTLINE_BLACK}
        icon={<Icons.ChatIcon />}
        iconPosition={ButtonIconPosition.RIGHT}
        onClick={openChat}
      >
        {button}
      </StyledButton>
    </StyledSquircle>
  );
};

export default Help;
