import styled from '@emotion/styled';
import { defaultTheme, H3, Text } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const ImageWrapper = styled.div`
  width: 362px;
  margin: -12px 0 22px -8px;
`;

export const StyledH3 = styled(H3)`
  margin-bottom: 22px;
`;

export const StyledText = styled(Text)`
  margin-bottom: 33px;
`;

export const StyledWarning = styled.div`
  margin-bottom: 0;
  display: block;
  padding: 16px;
  background-color: ${defaultTheme.colors.light};
  margin-bottom: 18px;
  max-width: 720px;

  @media (min-width: ${desktop940}px) {
    margin-bottom: 34px;
  }
`;
