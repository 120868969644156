/** libraries */
import { observer } from 'mobx-react';
/** styles */
import { StyledIcon, StyledSidePage } from './styles';
/** stores */
import useMobileStore from '../../store/useMobileStore';
/** components */
import CountriesList from './Components/CountriesList';
import CountryInfo from './Components/CountryInfo';
import CountryPrices from './Components/CountryPrices';

const Roaming = (): JSX.Element => {
  const {
    roamingStore: {
      isShowRoamingSP,
      onCloseRoamingStoreSP,
      selectedCountryCode,
      selectedCountry,
      isShowCountryPrices,
    },
  } = useMobileStore();

  const isShowCountryInfo = selectedCountryCode && !isShowCountryPrices;

  const headerText = () => {
    if (selectedCountryCode && isShowCountryPrices)
      return `Подробные условия на\u00A0звонки и\u00A0SMS в\u00A0${selectedCountry.name}`;
    if (selectedCountryCode) return selectedCountry.name;
    return 'Выберите страну';
  };

  if (!isShowRoamingSP) return null;

  return (
    <StyledSidePage
      show={isShowRoamingSP}
      headerText={headerText()}
      onCloseClick={onCloseRoamingStoreSP}
      iconToLeftOfHeader={
        isShowCountryInfo && (
          <StyledIcon
            isHeader
            dangerouslySetInnerHTML={{ __html: selectedCountry.icon }}
          />
        )
      }
      showMobileHeader
    >
      {!selectedCountryCode && <CountriesList />}
      {isShowCountryInfo && <CountryInfo />}
      {isShowCountryPrices && <CountryPrices />}
    </StyledSidePage>
  );
};

export default observer(Roaming);
