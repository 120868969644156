/** libraries */
import * as React from 'react';
import { Text, H3, Icons, defaultTheme, Tag } from 'cordis-core-ui-planeta';
/** constants */
import { ConnectionMethod } from '~/constants/common';
/** components */
import CallToActionRow from '../CallToActionRow/CallToActionRow';
/** styles */
import { StyledInternet } from './CallToActionInternet.style';
import { StyledFeature, StyledLeftColumn } from '../CallToAction.style';
/** utils */
import { formatNumber } from '~/utils/utils';
/** stores */
import { useCallToActionStore } from '../stores/MainCallToAction/useMainCallToActionStore';

/* Дочерний компонент "Скорость интернета", компонента CallToAction */
const CallToActionInternet: React.FC = () => {
  const {
    connectionMethod,
    speedBaseText,
    connectionPrice,
    isAction,
  } = useCallToActionStore();
  const { promoPrice, finishDt } = connectionPrice;
  // Период действия акции
  /* yaspeller ignore:start */
  const promoText: string = finishDt ? `\nдо\u0020${finishDt}` : '';
  /* yaspeller ignore:end */
  // Флаг оптического подключения
  const isFTTH: boolean = connectionMethod === ConnectionMethod.FTTH;

  return (
    <CallToActionRow title={<Text>Интернет</Text>}>
      <StyledLeftColumn>
        <StyledInternet>
          <H3>{isFTTH ? 'Гигабит' : speedBaseText}</H3>
          {/* yaspeller ignore:start */}
          <div>
            <Text color={defaultTheme.colors.shadow}>
              {isFTTH &&
                (promoPrice > 0
                  ? `Стоимость подключения\nк оптической сети ${formatNumber(
                      promoPrice,
                    )}\u20bd${promoText}`
                  : `Бесплатное подключение${promoText}`)}
              {isFTTH && promoPrice === 0 && !isAction && <sup>&nbsp;*</sup>}
            </Text>
            {isAction && (
              <Tag
                color={defaultTheme.colors.planeta}
                colorTag={defaultTheme.colors.pink}
              >
                АКЦИЯ
              </Tag>
            )}
          </div>
          {/* yaspeller ignore:end */}
        </StyledInternet>
      </StyledLeftColumn>
      <StyledFeature>
        <Icons.OkIcon />
        <Text color={defaultTheme.colors.shadow}>Белый IP-адрес</Text>
      </StyledFeature>
    </CallToActionRow>
  );
};

export default React.memo(CallToActionInternet);
