/** Libraries */
import { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import { defaultTheme, Text } from 'cordis-core-ui-planeta';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'react-responsive';
/** styles */
import { StyledScales, StyledMobileScale } from './style';
import { ScaleWrapper } from '../../style';
/** components */
import FillableScale from '~/components/Blocks/Shared/FillableScale/FillableScale';
import Counter from './Counter';
/** constants */
import {
  PLURAL_PACKAGES,
  UNIT_TYPE_NAME,
  ZERO_BALANCE_TEXT,
} from '../../constants';
import { COUNTRY, OPERATING_STATE } from '~/constants/common';
import { desktop940 } from '~/components/Grid/constants';
/** interfaces */
import { ScaleProps, STORAGE_TYPE } from '../../types';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';
/** utils */
import { pluralizeAll } from '~/utils/utils';

const MobileScale: FC<ScaleProps> = ({ type }: ScaleProps) => {
  const {
    pab2cMobileStore: {
      subscriptionsMap,
      isConvergentProduct,
      getMaxUnitsStorage,
      getMaxUnitsSub,
      getStorageByType,
    },
    contractStateStore: { contractState },
    vacationStore: { isActivated: isVacationActivated },
    productHistoryStore: { productHistory },
  } = useRootStore();
  const {
    getFillerColorForStorage,
    getIsRoaming,
    roamingStore: { getRoamingCountriesByPackage },
  } = useMobileStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const isRoaming = getIsRoaming(type);

  /** Характеристики накопителя */
  const storage = isRoaming ? {} : getStorageByType(type);
  const { currentQuantity, maxQuantity, accrualQuantity, startQuantity } =
    storage || {};

  /** Текущее значение накопителя + бонус */
  const jointCurrentQuantity = storage && currentQuantity + startQuantity;

  /** Накопитель почти израсходован */
  const isLeftMinimumQuantity =
    storage &&
    currentQuantity + startQuantity !== 0 &&
    currentQuantity + startQuantity < accrualQuantity;

  const subscriptions = subscriptionsMap[type] || [];

  /** Договор не обслуживается или на каникулах */
  const isSuspendedCondition =
    contractState !== OPERATING_STATE.ON || isVacationActivated;

  /** Форматирует дату => число, месяц */
  const getTrimDate = (date: string) =>
    format(new Date(date), 'd MMMM', {
      locale: ru,
    });

  /** Генерация ключей для списка */
  const ids = useMemo(() => subscriptions.map(() => nanoid(5)), [
    subscriptions,
  ]);

  const countryNames = isRoaming
    ? getRoamingCountriesByPackage(subscriptions[0].itemId).sort()
    : '';

  /** Формирует описание для шкалы накопителя */
  const getStorageDescriptionText = () => {
    const messages = [];

    const hasZeroBalance =
      jointCurrentQuantity === 0 && subscriptions.length === 0;
    if (hasZeroBalance && ZERO_BALANCE_TEXT[type]?.length) {
      messages.push(`${ZERO_BALANCE_TEXT[type]}.`);
    }

    if (startQuantity !== 0) {
      messages.push(`Бонус за${'\u00A0'}подключение.`);
    }

    if (subscriptions.length === 1) {
      messages.push('Подключен пакет.');
    } else if (subscriptions.length > 1) {
      messages.push(
        `Подключено${'\u00A0'}${pluralizeAll(
          subscriptions.length,
          PLURAL_PACKAGES,
        )}.`,
      );
    }

    if (currentQuantity > maxQuantity) {
      const previousTariff =
        productHistory[productHistory.length - 2]?.tariffName;
      if (previousTariff) {
        messages.push(`Перенос с${'\u00A0'}продукта ${previousTariff}.`);
      }
    }

    if (currentQuantity >= maxQuantity) {
      messages.push(
        subscriptions.length
          ? `Ежедневные пополнения не${'\u00A0'}поступают.`
          : `Накопитель заполнен, ежедневные пополнения не${'\u00A0'}поступают.`,
      );
    } else if (!isSuspendedCondition) {
      messages.push(
        `Ежедневно ${accrualQuantity}${'\u00A0'}${
          UNIT_TYPE_NAME[type]
        }${'\u00A0'}/${'\u00A0'}день`,
      );
    }

    return messages.join(' ');
  };

  /** Формирует описание для шкалы пакета */
  const getSubscriptionDescriptionText = () => {
    const roamingText = isRoaming
      ? `для ${countryNames[0]} и еще ${pluralizeAll(
          countryNames.length - 1,
          COUNTRY,
        )}`
      : '';
    if (subscriptions.length === 0 && !isSuspendedCondition)
      return ZERO_BALANCE_TEXT[type];
    if (subscriptions.length === 1)
      return `Пакет до ${getTrimDate(subscriptions[0].trimDt)} ${roamingText}`;
    if (subscriptions.length > 1)
      return `Подключено ${subscriptions.length} пакета ${roamingText}`;
    return '';
  };

  const showScale =
    (!isDesktop940 && type !== STORAGE_TYPE.SMS) || isDesktop940;

  return (
    <StyledMobileScale>
      <Counter type={type} />
      {showScale && (
        <StyledScales>
          {(!isConvergentProduct || isRoaming) &&
            subscriptions.map((subscription, index) => (
              <ScaleWrapper
                $flexGrowValue={
                  isConvergentProduct
                    ? subscription.currentQuantity / getMaxUnitsStorage(type)
                    : subscription.startQuantity / getMaxUnitsSub(type)
                }
                $isDisabled={isSuspendedCondition}
                key={ids[index]}
              >
                <FillableScale
                  value={subscription.currentQuantity}
                  maxValue={
                    isConvergentProduct && !isRoaming
                      ? subscription.currentQuantity
                      : subscription.startQuantity
                  }
                  fillerColor={() =>
                    isRoaming
                      ? defaultTheme.colors.lightSeaGreen
                      : defaultTheme.colors.planeta
                  }
                />
              </ScaleWrapper>
            ))}
          {isConvergentProduct && !isRoaming && (
            <ScaleWrapper
              $flexGrowValue={maxQuantity / getMaxUnitsStorage(type)}
              $isDisabled={isSuspendedCondition}
              $isLeftMinimumQuantity={isLeftMinimumQuantity}
            >
              <FillableScale
                value={isLeftMinimumQuantity ? 1 : jointCurrentQuantity}
                maxValue={isLeftMinimumQuantity ? 1 : maxQuantity}
                fillerColor={() =>
                  getFillerColorForStorage(
                    storage,
                    isLeftMinimumQuantity,
                    jointCurrentQuantity,
                  )
                }
              />
            </ScaleWrapper>
          )}
        </StyledScales>
      )}
      <Text color={defaultTheme.colors.gray}>
        {isConvergentProduct && !isRoaming
          ? getStorageDescriptionText()
          : getSubscriptionDescriptionText()}
      </Text>
    </StyledMobileScale>
  );
};

export default observer(MobileScale);
