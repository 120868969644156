/** libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
import Image from 'next/image';
import { useMediaQuery } from 'react-responsive';

/** components */
import LinkWrapper from '~/components/LinkWrapper';
/** styles */
import { StyledStoreButton, StyledStoresButtons } from './style';
/** utils */
import { isExternal } from '~/utils/utils';
/** stores */
import useFooterStore from './store/useFooterStore';
/** constants */
import { iconLinks } from './constants';
import { desktop1100, desktop940 } from '../Grid/constants';

const StoresList: FC = () => {
  const { storeLinks, stores } = useFooterStore();

  const isDesktopBetween940And1280 = useMediaQuery({
    query: `(min-width: ${desktop940}px) and (max-width: ${desktop1100}px)`,
  });

  const imageLoader = ({ src, width, quality }) => {
    const clientSrc = `${src}?w=${width}&q=${quality || 100}`;
    return isExternal(src)
      ? clientSrc
      : `${process.env.STATIC_SERVER}/${clientSrc}`;
  };

  if (stores.length === 1) {
    const store = storeLinks.find((item) => item.name === stores[0]);

    return (
      <StyledStoreButton>
        <LinkWrapper href={store.slug}>
          <Image
            loader={imageLoader}
            src={iconLinks[store.name]}
            alt={store.name}
            width={160}
            height={48}
          />
        </LinkWrapper>
      </StyledStoreButton>
    );
  }
  return (
    <StyledStoresButtons>
      {storeLinks.map((store) => {
        if (!stores.includes(store.name)) return null;
        return (
          <li key={store.name}>
            <LinkWrapper href={store.slug}>
              <Image
                loader={imageLoader}
                src={iconLinks[store.name]}
                alt={store.name}
                width={isDesktopBetween940And1280 ? 85 : 120}
                height={isDesktopBetween940And1280 ? 20 : 28}
              />
            </LinkWrapper>
          </li>
        );
      })}
    </StyledStoresButtons>
  );
};

export default observer(StoresList);
