/** Libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  ButtonIconPosition,
  ButtonStyleTypes,
  defaultTheme,
  Droplist,
  DroplistPosition,
  Icons,
} from 'cordis-core-ui-planeta';
/** styles */
import { StyledActivationSimButton, StyledThreeDotsButton } from './style';
/** components */
import Portal from '~/components/Portal/Portal';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';

const HeaderDroplist: FC = () => {
  const {
    pab2cMobileStore: { notActivatedSims },
  } = useRootStore();

  const {
    setIsShowMyNumbers,
    isShowHeaderDroplist,
    setIsShowHeaderDroplist,
    simCardActivationStore: { openSimCardActivation },
    roamingStore: { setIsShowRoamingSP },
  } = useMobileStore();

  const activationSimButton = () => (
    <StyledActivationSimButton
      styleType={ButtonStyleTypes.ACTION}
      icon={<Icons.SimCardIcon />}
      secondIcon={<Icons.LeftArrowIcon />}
      secondIconPosition={ButtonIconPosition.RIGHT}
      key="1"
      onClick={() => {
        openSimCardActivation();
        setIsShowHeaderDroplist(false);
      }}
      isDroplist
    >
      Активировать SIM
    </StyledActivationSimButton>
  );

  const myNumbersButton = () => (
    <Button
      styleType={ButtonStyleTypes.ACTION}
      icon={<Icons.PhoneNumberIcon />}
      secondIcon={<Icons.LeftArrowIcon />}
      secondIconPosition={ButtonIconPosition.RIGHT}
      key="2"
      onClick={() => {
        setIsShowMyNumbers(true);
        setIsShowHeaderDroplist(false);
      }}
      isDroplist
    >
      Мои номера
    </Button>
  );

  const roamingButton = () => (
    <Button
      styleType={ButtonStyleTypes.ACTION}
      icon={<Icons.RoamingIcon />}
      secondIcon={<Icons.LeftArrowIcon />}
      secondIconPosition={ButtonIconPosition.RIGHT}
      key="3"
      onClick={() => {
        setIsShowRoamingSP(true);
        setIsShowHeaderDroplist(false);
      }}
      isDroplist
    >
      Роуминг
    </Button>
  );

  const makeButtonList = () => {
    const buttonList = [myNumbersButton(), roamingButton()];
    if (notActivatedSims.length) buttonList.push(activationSimButton());
    return buttonList;
  };

  return (
    <Droplist
      Portal={Portal}
      buttons={makeButtonList()}
      mobileTitle="Моя связь"
      isOpen={isShowHeaderDroplist}
      onOpenClick={() => setIsShowHeaderDroplist(true)}
      onCloseClick={() => setIsShowHeaderDroplist(false)}
      position={DroplistPosition.BOTTOM_LEFT}
      width="330px"
    >
      <StyledThreeDotsButton
        background={defaultTheme.colors.light}
        border={defaultTheme.colors.light}
        icon={<Icons.ThreeDotsIcon />}
        iconPosition={ButtonIconPosition.CENTER}
      />
    </Droplist>
  );
};

export default observer(HeaderDroplist);
