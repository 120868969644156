/** libraries */
import { observer } from 'mobx-react';
import { ButtonStyleTypes, defaultTheme, Icons } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** components */
import {
  AnimationWrapper,
  StyledAnimation,
  StyledAppendix,
  StyledButton,
  StyledContainer,
  StyledDescription,
  StyledH2,
  StyledH3,
  StyledSquircle,
  StyledSquircleImg,
  StyledWomanImg,
} from './styles';
import Circles from './Circles';
/** stores */
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { McBannerTemplates } from '../../constants';
/** config */
import CONFIG from '../../config';

const {
  images: { appendix },
  text: { header, button, info },
} = CONFIG[McBannerTemplates.main];

/** Баннер с анимацией Мобильная связь */
const Wow = () => {
  const { toggleConnectionWizardVisible } = useConnectionStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const animationFabric = (components: JSX.Element[], delay: number) =>
    components.map((component, index) => (
      <AnimationWrapper delay={`${index * delay}`} key={component.key}>
        {component}
      </AnimationWrapper>
    ));

  const TextContent = (): JSX.Element => {
    return (
      <>
        {isDesktop940 && (
          <StyledH2 color={defaultTheme.colors.white}>{header}</StyledH2>
        )}
        <StyledDescription color={defaultTheme.colors.white}>
          {info}
        </StyledDescription>
        <Circles />
        <StyledButton
          styleType={ButtonStyleTypes.DARK_BACKGROUND}
          border={`2px, solid, ${defaultTheme.colors.white}`}
          background="unset"
          onClick={toggleConnectionWizardVisible}
        >
          {button}
        </StyledButton>
      </>
    );
  };

  return (
    <StyledContainer>
      {!isDesktop940 && <StyledAppendix src={appendix} />}
      <StyledAnimation>
        {animationFabric(
          [
            <Icons.WowPlanetaIcon key="1" />,
            <Icons.WowPhoneIcon key="2" />,
            <Icons.WowChatIcon key="3" />,
            <Icons.WowLoaderIcon key="4" />,
          ],
          2,
        )}
      </StyledAnimation>
      {isDesktop940 && <TextContent />}
      {!isDesktop940 && (
        <>
          <StyledH3 color={defaultTheme.colors.white}>{header}</StyledH3>
          <StyledSquircle cornerRadius={86} cornerSmoothing={1}>
            <StyledSquircleImg cornerRadius={50} cornerSmoothing={1} asChild>
              <StyledWomanImg />
            </StyledSquircleImg>
            <TextContent />
          </StyledSquircle>
        </>
      )}
    </StyledContainer>
  );
};

export default observer(Wow);
