/** libraries */
import style from '@emotion/styled';
/** types */
import { StyledLeftColumnProps } from './CallToActionTelevision.types';

/* Стилизованная строка с информацией о телевидении блока CTA */
export const StyledTelevision = style.div<StyledLeftColumnProps>`
  ${({ isTransformer }: StyledLeftColumnProps) => `
    h3 {
      margin-top: ${isTransformer ? 0 : 7}px;
      margin-bottom: 0;
    }
  `}
`;
