/** libraries */
import { RefObject, useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { H2, Button, H3 } from 'cordis-core-ui-planeta';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { ConnectionMethod } from '~/constants/common';
import {
  CALL_TO_ACTION_BLOCK_ID,
  SelectAmountData,
  SelectPurchaseData,
} from './CallToAction.constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useCallToActionStore } from './stores/MainCallToAction/useMainCallToActionStore';
import useCallToActionStateStore from './stores/CallToActionState/useCallToActionStateStore';
/** utils */
import { formatNumber } from '~/utils/utils';
import { getPromoPrice, scrollToBlockById } from '../../Shared/Shared.utils';
/** interfaces */
import {
  CallToActionDeviceTypes,
  DataSelectPurchaseTypesProps,
  PurchaseTypes,
} from './CallToAction.types';
/** styles */
import {
  CallToActionContainer,
  CallToActionConnectionButtonSticky,
  CallToActionConnectionButton,
  CallToActionConnectionWizard,
} from './CallToAction.style';
/** components */
import ConnectionWizard from '~/components/ConnectionWizard/ConnectionWizard';
import CallToActionAnnual from './CallToActionAnnual/CallToActionAnnual';
import CallToActionConnection from './CallToActionConnection/CallToActionConnection';
import CallToActionDevice from './CallToActionDevice/CallToActionDevice';
import CallToActionFee from './CallToActionFee/CallToActionFee';
import CallToActionFixedPrice from './CallToActionFixedPrice/CallToActionFixedPrice';
import CallToActionInternet from './CallToActionInternet/CallToActionInternet';
import CallToActionPrepayment from './CallToActionPrepayment/CallToActionPrepayment';
import CallToActionProduct from './CallToActionProduct/CallToActionProduct';
import CallToActionTelevision from './CallToActionTelevision/CallToActionTelevision';
import CallToActionTimeShift from './CallToActionTimeShift/CallToActionTimeShift';
// import CallToActionMobile from './CallToActionMobile/CallToActionMobile';

const CallToActionContent = () => {
  const {
    seriesCode,
    seriesName,
    annualPrice,
    dailyPrice,
    migrationCostFirstPrice,
    promoConnectionInfosPrice,
    prepayment,
    connection,
    allChannelCountChannels,
    channelCountChannels,
    isAvailableTimeShift,
    connectionMethod,
    isAnnual,
    fixedPrice,
    routerInfo,
    remoteInfo,
    STBInfo,
    isBusiness,
    productConnectionPrice,
    routerPrices,
    STBPrices,
    remotePrices,
  } = useCallToActionStore();
  const {
    authStore: { isAuth },
  } = useRootStore();
  const {
    showSettings,
    enabledTimeShift,
    enabledRouter,
    enabledRemote,
    enabledSTB,
    isOpenConnectionWizard,
    feePerDay,
    purchaseRemotePrice,
    selectRouterTypes,
    selectSTBTypes,
    setEnabledRouter,
    setEnabledRemote,
    setEnabledSTB,
    setFeePerDay,
    setPurchaseRemotePrice,
    setSelectRouterTypes,
    setSelectSTBTypes,
    openWizard,
    filterPurchaseTypes,
    isTransferMobileNumber,
  } = useCallToActionStateStore();
  /** Вычисление ширины экрана */
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  // Контейнер с контентом
  const ctaRef: RefObject<HTMLDivElement> | null = useRef(null);

  /* Значение scroll до блока cta  */
  const [ctaScroll, setCtaScroll] = useState<number>(0);
  /* Ширина блока cta */
  const [ctaWidth, setCtaWidth] = useState<number>(0);

  /* Стоимость сервиса "Wi-Fi роутер" */
  const [purchaseRouterPrice, setPurchaseRouterPrice] = useState<number>(
    getPromoPrice(routerInfo?.promoPriceInfos, {
      currentPrice: routerInfo?.price,
    }).promoPrice,
  );

  /* Количество роутеров, приобретаемых в собственность */
  const [
    selectRouterAmount,
    setSelectRouterAmount,
  ] = useState<DataSelectPurchaseTypesProps>(SelectAmountData[0]);

  /* Стоимость сервиса "Телеприставка" */
  const [purchaseSTBPrice, setPurchaseSTBPrice] = useState<number>(
    STBInfo
      ? getPromoPrice(STBInfo.promoPriceInfos, { currentPrice: STBInfo.price })
          .promoPrice
      : 0,
  );

  /* Количество приставок, приобретаемых в собственность */
  const [
    selectSTBAmount,
    setSelectSTBAmount,
  ] = useState<DataSelectPurchaseTypesProps>(SelectAmountData[0]);

  useEffect(() => {
    setPurchaseRemotePrice(
      getPromoPrice(remoteInfo?.promoPriceInfos, {
        currentPrice: remoteInfo?.price,
      }).promoPrice,
    );
    setSelectRouterTypes(
      filterPurchaseTypes(
        SelectPurchaseData,
        routerInfo?.annuity,
        routerInfo?.ownershipPriceOn,
      )[0],
    );
    setSelectSTBTypes(
      STBInfo
        ? filterPurchaseTypes(
            SelectPurchaseData,
            STBInfo.annuity,
            STBInfo.ownershipPriceOn,
          )[0]
        : null,
    );
  }, []);

  /* Вариант приобретения пультов */
  const [
    selectRemoteTypes,
    setSelectRemoteTypes,
  ] = useState<DataSelectPurchaseTypesProps>(
    filterPurchaseTypes(
      SelectPurchaseData,
      remoteInfo?.annuity,
      remoteInfo?.ownershipPriceOn,
    )[0],
  );
  /* Количество пультов, приобретаемых в собственность */
  const [
    selectRemoteAmount,
    setSelectRemoteAmount,
  ] = useState<DataSelectPurchaseTypesProps>(SelectAmountData[0]);

  /* Определяет значение прокрутки страницы относительно блока CallToAction */
  const getContainerScroll = () => {
    if (!ctaRef || !ctaRef.current || isDesktop940) return;
    const offsetTop = ctaRef.current.offsetTop - window.pageYOffset;
    const offset = Math.round(
      ((window.innerHeight - offsetTop) * 100) / ctaRef.current.clientHeight,
    );
    // Делим для получения приемлемых целочисленных значений
    setCtaScroll(offset / 18);
    // Вычитаем ширину с учётом отступов
    setCtaWidth(ctaRef.current.clientWidth - 56);
  };

  /* Обновляет цену единовременного платежа */
  useEffect(() => {
    getPrepaymentPrice();
  }, [
    showSettings,
    enabledTimeShift,
    enabledRouter,
    enabledSTB,
    enabledRemote,
    purchaseRouterPrice,
    purchaseSTBPrice,
    purchaseRemotePrice,
    selectRouterTypes,
    selectSTBTypes,
    selectRemoteTypes,
  ]);

  /* Добавляет слушатель на скролл */
  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', getContainerScroll);
    }
    watchScroll();
    return () => {
      window.removeEventListener('scroll', getContainerScroll);
    };
  }, []);

  /* Scroll до элемента с идентификатором cta */
  useEffect(() => {
    const matcher = new RegExp(`${CALL_TO_ACTION_BLOCK_ID}`, 'g');
    if (matcher.test(window.location.href))
      setTimeout(() => {
        scrollToBlockById(CALL_TO_ACTION_BLOCK_ID);
      }, 200);
  }, []);

  /* Переход к виджету подключения */
  const scrollToWidget = () => {
    ctaRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  /* Флаг отображения визарда подключения */
  const isShowConnectionWizard: boolean =
    !isDesktop940 || showSettings || isOpenConnectionWizard;

  /* Формирует сумму единовременного платежа исходя из выбранных настроек */
  const getPrepaymentPrice = () => {
    let prepaymentPrice =
      prepayment + (fixedPrice?.newPrice ?? migrationCostFirstPrice);

    /* Если бизнес продукт, то добавляем стоимость подключения в сумму единовременного платежа */
    if (isBusiness) {
      prepaymentPrice += productConnectionPrice;
    }

    if (connectionMethod === ConnectionMethod.FTTH)
      prepaymentPrice += getPromoPrice(promoConnectionInfosPrice, {
        currentPrice: connection,
      }).promoPrice;
    if (!showSettings) return prepaymentPrice;

    if (enabledRouter && selectRouterTypes) {
      if (selectRouterTypes.label === PurchaseTypes.PURCHASE) {
        prepaymentPrice += purchaseRouterPrice;
      } else if (selectRouterTypes.label === PurchaseTypes.OWNERSHIP) {
        prepaymentPrice +=
          getPromoPrice(routerInfo?.promoPriceInfos, {
            currentPrice: routerInfo?.price,
          }).promoPrice === 0
            ? 0
            : routerInfo?.ownershipOneTimeCharge;
      } else if (selectRouterTypes.label === PurchaseTypes.ANNUITY) {
        prepaymentPrice +=
          getPromoPrice(routerInfo?.promoPriceInfos, {
            currentPrice: routerInfo?.price,
          }).promoPrice === 0
            ? 0
            : getPromoPrice(routerInfo?.promoPriceInfos, {
                currentDownPayment: routerInfo?.downPayment,
              }).promoDownPayment;
      }
    }
    if (enabledSTB && selectSTBTypes) {
      if (selectSTBTypes.label === PurchaseTypes.PURCHASE) {
        prepaymentPrice += purchaseSTBPrice;
      } else if (selectSTBTypes.label === PurchaseTypes.OWNERSHIP) {
        prepaymentPrice +=
          getPromoPrice(STBInfo?.promoPriceInfos, {
            currentPrice: STBInfo?.price,
          }).promoPrice === 0
            ? 0
            : STBInfo?.ownershipOneTimeCharge;
      } else if (selectSTBTypes.label === PurchaseTypes.ANNUITY) {
        prepaymentPrice +=
          getPromoPrice(STBInfo?.promoPriceInfos, {
            currentPrice: STBInfo?.price,
          }).promoPrice === 0
            ? 0
            : getPromoPrice(STBInfo?.promoPriceInfos, {
                currentDownPayment: STBInfo?.downPayment,
              }).promoDownPayment;
      }
    }
    if (
      enabledRemote &&
      selectRemoteTypes &&
      selectRemoteTypes.label === PurchaseTypes.PURCHASE
    )
      prepaymentPrice += purchaseRemotePrice;

    return prepaymentPrice;
  };

  useEffect(() => {
    setFeePerDay(dailyPrice);
  }, []);

  /* Формирует комментарий для СПА */
  const createSPAComment = (): string => {
    // Формирует информацию о типе приобретения оборудования
    const getDeviceInfo = (deviceType: CallToActionDeviceTypes): string => {
      // Определяет тип переданного устройства
      const expression =
        deviceType === CallToActionDeviceTypes.ROUTER
          ? selectRouterTypes.label
          : selectSTBTypes.label;
      // Определяет количество устройств, приобретаемых в собственность
      const amountDevices =
        deviceType === CallToActionDeviceTypes.ROUTER
          ? selectRouterAmount.label
          : selectSTBAmount.label;

      switch (expression) {
        case PurchaseTypes.PURCHASE:
          return `Покупка_${amountDevices.replace(' ', '_')}`;
        case PurchaseTypes.ANNUITY:
          return 'Рассрочка';
        case PurchaseTypes.OWNERSHIP:
          return 'Аренда';
        default:
          return 'Не нужен';
      }
    };
    // Информация о выбранных устройствах
    /* yaspeller ignore:start */
    // prettier-ignore
    const devicesComment = `Управление просмотром: ${
      enabledTimeShift ? 'Вкл' : 'Выкл'}\nРоутер: ${
      enabledRouter && showSettings
        ? getDeviceInfo(CallToActionDeviceTypes.ROUTER)
        : 'Не нужен'
    }\nТелеприставка: ${
      enabledSTB && showSettings
        ? getDeviceInfo(CallToActionDeviceTypes.STB)
        : 'Не нужен'
    }\nПульт ГУ: ${enabledRemote ? 'Вкл' : 'Выкл'}`;
    // prettier-ignore
    return `${
      showSettings ? `${devicesComment}\n` : ''}Абонентская плата: ${
      formatNumber(feePerDay)} руб. в день\nПодключение к сети: ${
      formatNumber(
      getPromoPrice(promoConnectionInfosPrice, {currentPrice: migrationCostFirstPrice}).promoPrice,
    )} руб.\nПодключение к продукту: ${
      formatNumber(getPrepaymentPrice())} руб.${
      isAnnual ? `\nПодключение на год: ${
        annualPrice === 0 ? 'Бесплатно' : formatNumber(annualPrice)} руб.` : ''
    }${isTransferMobileNumber ? '\nПеренос номера: Да' : ''}`;
    /* yaspeller ignore:end */
  };

  const title = `Заявка на${'\u00A0'}подключение ${
    isAuth ? ` по${'\u00A0'}новому адресу` : ''
  }`;

  return (
    <CallToActionContainer id={CALL_TO_ACTION_BLOCK_ID} ref={ctaRef}>
      <div>
        {isDesktop940 ? <H2>{title}</H2> : <H3>{title}</H3>}
        <CallToActionProduct />
        {showSettings && (
          <>
            <CallToActionInternet />
            {/* todo: открыть по готовности МС */}
            {/* <CallToActionMobile /> */}
            {channelCountChannels && <CallToActionTelevision />}
            {isAvailableTimeShift && allChannelCountChannels && (
              <CallToActionTimeShift />
            )}
            {routerInfo && (
              <CallToActionDevice
                deviceInfo={routerInfo}
                pricesInfo={routerPrices}
                enabledDevice={enabledRouter}
                setEnabledDevice={setEnabledRouter}
                purchaseDevicePrice={purchaseRouterPrice}
                setPurchaseDevicePrice={setPurchaseRouterPrice}
                dataSelectPurchaseTypes={filterPurchaseTypes(
                  SelectPurchaseData,
                  routerInfo.annuity,
                  routerInfo.ownershipPriceOn,
                )}
                setSelectTypes={setSelectRouterTypes}
                selectTypes={selectRouterTypes}
                selectAmount={selectRouterAmount}
                setSelectAmount={setSelectRouterAmount}
              />
            )}
            {STBInfo && (
              <CallToActionDevice
                deviceInfo={STBInfo}
                pricesInfo={STBPrices}
                enabledDevice={enabledSTB}
                setEnabledDevice={setEnabledSTB}
                purchaseDevicePrice={purchaseSTBPrice}
                setPurchaseDevicePrice={setPurchaseSTBPrice}
                dataSelectPurchaseTypes={filterPurchaseTypes(
                  SelectPurchaseData,
                  STBInfo.annuity,
                  STBInfo.ownershipPriceOn,
                )}
                setSelectTypes={setSelectSTBTypes}
                selectTypes={selectSTBTypes}
                selectAmount={selectSTBAmount}
                setSelectAmount={setSelectSTBAmount}
              />
            )}
            {remoteInfo && (
              <CallToActionDevice
                deviceInfo={remoteInfo}
                pricesInfo={remotePrices}
                enabledDevice={enabledRemote}
                setEnabledDevice={setEnabledRemote}
                purchaseDevicePrice={purchaseRemotePrice}
                setPurchaseDevicePrice={setPurchaseRemotePrice}
                dataSelectPurchaseTypes={SelectPurchaseData}
                setSelectTypes={setSelectRemoteTypes}
                selectTypes={selectRemoteTypes}
                selectAmount={selectRemoteAmount}
                setSelectAmount={setSelectRemoteAmount}
              />
            )}
          </>
        )}
        {!isAnnual && <CallToActionFee />}
        {fixedPrice && <CallToActionFixedPrice />}
        {isAnnual && !fixedPrice && <CallToActionAnnual />}
        {((isAnnual && connectionMethod === ConnectionMethod.FTTH) ||
          !isAnnual) && <CallToActionConnection />}
        <CallToActionPrepayment prepaymentPrice={getPrepaymentPrice()} />
        <CallToActionConnectionButtonSticky
          amountScroll={ctaScroll}
          clientWidth={ctaWidth}
          showSettings={showSettings}
        >
          <Button onClick={scrollToWidget}>Подключить</Button>
        </CallToActionConnectionButtonSticky>
        {!showSettings && !isShowConnectionWizard && (
          <CallToActionConnectionButton>
            <Button onClick={openWizard}>Подключить</Button>
          </CallToActionConnectionButton>
        )}
      </div>
      {isShowConnectionWizard && (
        <CallToActionConnectionWizard>
          <H3>Подключение к&nbsp;Планете</H3>
          <ConnectionWizard
            isInCTA
            comment={createSPAComment()}
            tariff={seriesName}
            seriesCode={seriesCode}
          />
        </CallToActionConnectionWizard>
      )}
    </CallToActionContainer>
  );
};

export default observer(CallToActionContent);
