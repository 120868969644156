import { Instance, types } from 'mobx-state-tree';
import { ContractBlocksProps } from '../types';

const ActionModel = types.model('', {
  /** Размер скидки */
  discount: types.number,
  /** Новая цена */
  newPrice: types.number,
});

const FieldsModel = types.model('FieldsModel', {
  /* Код серии продукта */
  seriesCode: types.maybeNull(types.string),
  /* Ид доступных городов */
  cities: types.maybeNull(types.array(types.number)),
  /* Ссылка на маркетинг категорию */
  marketingCategoryFeedLink: types.maybe(types.string),
  /* Ссылка на продукт */
  productFeedLink: types.maybe(types.string),
  /** Акция */
  action: types.maybe(ActionModel),
});

const BlockModel = types.model('BlockModel', {
  fields: FieldsModel,
});

const ContractStore = types
  .model('ContractStoreModel', {
    blocks: types.maybeNull(types.array(BlockModel)),
    /** Ссылка на категорию продукта */
    marketingCategoryFeedLink: types.maybeNull(types.string),
    /** Ссылка на продукт */
    productFeedLink: types.maybeNull(types.string),
    /** LightBox с карточкой продукта */
    isOpenProductCard: (types.boolean, false),
  })
  .actions((self) => ({
    setMarketingCategoryFeedLink: (marketingCategoryFeedLink) => {
      self.marketingCategoryFeedLink = marketingCategoryFeedLink;
    },
    setProductFeedLink: (productFeedLink) => {
      self.productFeedLink = productFeedLink;
    },
    setIsOpenProductCard: (isOpenProductCard) => {
      self.isOpenProductCard = isOpenProductCard;
    },
    findLinksFromMongo(seriesCode) {
      const sameBlock = self.blocks.filter(
        (block) => block.fields?.seriesCode === seriesCode,
      );
      self.marketingCategoryFeedLink = sameBlock
        .map((item) => item.fields.marketingCategoryFeedLink)
        .toString();
      self.productFeedLink = sameBlock
        .map((item) => item.fields.productFeedLink)
        .toString();
    },
  }));

const createStore = (blocks: ContractBlocksProps[]): IContractStore => {
  return ContractStore.create({
    blocks,
  });
};

export type IContractStore = Instance<typeof ContractStore>;
export default createStore;
