/** Библиотеки */
import React, { FC } from 'react';
import { observer } from 'mobx-react';
/** Стили */
import { StyledFooter } from './OperatingStateWizard.style';
/** Компоненты */
import { Button, Snoska, defaultTheme } from 'cordis-core-ui-planeta';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useOperatingStateModelStore from './store/useOperatingStateStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';

const OperatingStateWizardFooter: FC = () => {
  const {
    authStore: { isTemporaryTokenAuth },
    vacationStore: { getVacation },
    contractStateStore: { updateAuthCondition },
  } = useRootStore();
  const {
    isResume,
    isLoadingResume,
    isLoadingSuspension,
    changeOperatingState,
  } = useOperatingStateModelStore();
  const { setOpenSPAfterAuthorizationState } = useMakeAuthStore();
  return (
    <StyledFooter className="wizard__button">
      <Button
        type="button"
        onClick={() =>
          changeOperatingState(
            getVacation,
            setOpenSPAfterAuthorizationState,
            updateAuthCondition,
          )
        }
        loading={isLoadingResume || isLoadingSuspension}
        disabled={isTemporaryTokenAuth}
      >
        {isResume ? 'Возобновить обслуживание' : 'Приостановить'}
      </Button>
      {isTemporaryTokenAuth && (
        <Snoska className="snoska" color={defaultTheme.colors.gray}>
          Действие доступно только клиенту
        </Snoska>
      )}
    </StyledFooter>
  );
};

export default observer(OperatingStateWizardFooter);
