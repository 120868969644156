/** libraries */
import { FC } from 'react';
import Image from 'next/image';
import { useMediaQuery } from 'react-responsive';
import { defaultTheme, LeadingText, Text } from 'cordis-core-ui-planeta';
/** interfaces */
import { MobileProps } from './interfaces';
import { StorageTypes } from '../Summary/Summary.types';
/** styles */
import {
  ImageWrapper,
  StyledH2,
  StyledH3,
  StyledInformationBlock,
  StyledMobile,
  StyledProductFilling,
  StyledSim,
  StyledSimText,
} from './style';
/** constants */
import { imageSettings } from './constants';
import {
  desktop1100,
  desktop400,
  desktop940,
} from '~/components/Grid/constants';

/** Блок «Мобильная связь»»
 *  https://ckb.itmh.ru/pages/viewpage.action?pageId=804434907
 */
const Mobile: FC<MobileProps> = ({ content }: MobileProps) => {
  const { mobileInfo } = content;

  const getStorageMaxQuantity = (storageType: StorageTypes) =>
    mobileInfo.storageBalances.find(
      (store) => store.storageType === storageType,
    ).maxQuantity;

  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktopBetween400And900 = useMediaQuery({
    query: `(min-width: ${desktop400}px) and (max-width: ${desktop940}px)`,
  });

  const imageLoader = ({ src, width, quality }) => {
    return `${process.env.STATIC_SERVER}/${src}?w=${width}&q=${quality || 75}`;
  };

  const storageDescriptionText = (): JSX.Element => {
    const title = mobileInfo.storageBalances?.length
      ? `до\u00A0${getStorageMaxQuantity(
          StorageTypes.MobileInternet,
        )}\u00A0Гб и\u00A0${getStorageMaxQuantity(
          StorageTypes.OutGoingCall,
        )}\u00A0мин`
      : 'Пакеты минут и\u00A0Гб';

    const description = mobileInfo.storageBalances?.length
      ? 'Ежедневное пополнение минут и\u00A0гигабайт'
      : 'Доступны для пользования мобильной связью';

    return (
      <>
        <StyledH3>{title}</StyledH3>
        <Text color={defaultTheme.colors.shadow}>{description}</Text>
      </>
    );
  };

  return (
    <StyledMobile>
      <StyledInformationBlock>
        <StyledH2>Интернет везде!</StyledH2>
        <LeadingText>
          Мобильная связь и&nbsp;интернет от&nbsp;Планеты&nbsp;&mdash; вместе
          выгодно!
        </LeadingText>
        <StyledProductFilling>
          {(isDesktopBetween400And900 || isDesktop1100) && (
            <Image
              loader={imageLoader}
              src={imageSettings.scale.url}
              alt="Пакеты минут и гигабайт"
              width={imageSettings.scale.width}
              height={imageSettings.scale.height}
            />
          )}
          {storageDescriptionText()}
        </StyledProductFilling>
        <StyledSim>
          <Image
            loader={imageLoader}
            src={imageSettings.sim.url}
            alt="Симкарты Планеты"
            width={imageSettings.sim.width}
            height={imageSettings.sim.height}
          />
          <StyledSimText>
            {`${
              mobileInfo.maxCountMobilePhoneNumberForBind - 2
            }\u00A0SIM-карт для семьи и\u00A0две себе\u00A0*`}
          </StyledSimText>
        </StyledSim>
      </StyledInformationBlock>
      <ImageWrapper>
        <Image
          loader={imageLoader}
          src={
            isDesktop940
              ? imageSettings.mainDesktop.url
              : imageSettings.mainMobile.url
          }
          alt="Мобильная связь от Планеты"
          width={
            isDesktop940
              ? imageSettings.mainDesktop.width
              : imageSettings.mainMobile.width
          }
          height={
            isDesktop940
              ? imageSettings.mainDesktop.height
              : imageSettings.mainMobile.height
          }
        />
      </ImageWrapper>
    </StyledMobile>
  );
};

export default Mobile;
