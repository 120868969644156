/** libraries */
import { Instance, types, flow, cast, getParent } from 'mobx-state-tree';
/** api */
import {
  bindMobileSubscription,
  getAvailableMobileSubscriptions,
  getBindMobileSubscriptionAgreement,
} from '~/api/apiPab2c';
/** utils */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
/** types */
import { AvailableItemSubscription, STORAGE_TYPE, UNIT_TYPE } from '../types';
import { IMobileStore } from './MobileStore';
/** constants */
import { INITIAL_RESULT } from '~/constants/common';
import { UNIT_NAME } from '../constants';

const RequestsStateModel = types.model('BindSubscriptionStoreRequestsState', {
  getAvailableMobileSubscriptions: createApiPathModel(
    'GET /Mobile/MobileBalance/GetAvailableItemSubscriptions',
  ),
  getBindMobileSubscriptionAgreement: createApiPathModel(
    'GET /Mobile/MobileBalance/GetBindItemSubscriptionAgreement',
  ),
  bindMobileSubscription: createApiPathModel(
    'GET /Mobile/MobileBalance/BindItemSubscription',
  ),
});

const ResultModel = types.model('BindSubscriptionStoreRequestModel', {
  isResult: (types.boolean, false),
  isCorrect: (types.boolean, false),
});

const AvailableItemSubscriptionProductModel = types.model({
  mobileBalanceType: types.enumeration(Object.values(STORAGE_TYPE)),
  unit: types.enumeration(Object.values(UNIT_TYPE)),
  quantity: types.number,
});

const AvailableItemSubscriptionModel = types.model({
  name: types.string,
  price: types.number,
  itemId: types.number,
  trimDate: types.maybeNull(types.string),
  itemSubscriptionProducts: types.array(AvailableItemSubscriptionProductModel),
});

export const BindSubscriptionStore = types
  .model('BindSubscriptionStore', {
    requestsState: RequestsStateModel,
    isShowBindSubscriptionSP: types.boolean,
    isShowBindSubscriptionAgreementSP: types.boolean,
    roamingSubscriptionIds: types.array(types.number),
    availableMobileSubscriptions: types.array(AvailableItemSubscriptionModel),
    agreement: types.string,
    result: ResultModel,
    selectedSubscriptionId: types.number,
  })
  .views((self) => ({
    get isLoading() {
      return self.requestsState.bindMobileSubscription.isLoading;
    },
    get isLoadingSubscriptions() {
      return self.requestsState.getAvailableMobileSubscriptions.isLoading;
    },
    /** Флаг подключения пакета роуминга */
    get isBindRoaming() {
      return !!self.roamingSubscriptionIds.length;
    },
    get subscriptionsWithoutRoaming() {
      const roamingTypes = [
        STORAGE_TYPE.ROAMING_INTERNET_ZONE_1,
        STORAGE_TYPE.ROAMING_INTERNET_ZONE_2,
        STORAGE_TYPE.ROAMING_INTERNET_ZONE_3,
        STORAGE_TYPE.ROAMING_INTERNET_ZONE_4,
      ];
      const filteredSubs: AvailableItemSubscription[] = self.availableMobileSubscriptions.reduce(
        (acc, subscription) => {
          const filteredProducts = subscription.itemSubscriptionProducts.filter(
            (product) => roamingTypes.includes(product.mobileBalanceType),
          );
          if (filteredProducts.length === 0) acc.push(subscription);
          return acc;
        },
        [],
      );
      return filteredSubs;
    },
    get subscriptionsWithRoaming() {
      return self.roamingSubscriptionIds.reduce<AvailableItemSubscription[]>(
        (subs, id) => {
          const roamingSub = self.availableMobileSubscriptions.find(
            (item) => item.itemId === id,
          );
          return roamingSub ? [...subs, roamingSub] : subs;
        },
        [],
      );
    },
    get priceOfSelectedSubscription() {
      return (
        self.availableMobileSubscriptions.find(
          (subscription) => subscription.itemId === self.selectedSubscriptionId,
        )?.price ?? 0
      );
    },
    get nameOfSelectedSubscription() {
      return (
        self.availableMobileSubscriptions.find(
          (subscription) => subscription.itemId === self.selectedSubscriptionId,
        )?.name ?? 'пакет'
      );
    },
    get unitsOfSelectedSubscription() {
      if (!self.availableMobileSubscriptions || !self.selectedSubscriptionId)
        return '';
      const selectedSubscription = self.availableMobileSubscriptions.find(
        (subscription) => subscription.itemId === self.selectedSubscriptionId,
      );
      return selectedSubscription.itemSubscriptionProducts.reduce(
        (acc, product, index) => {
          let units = acc;
          if (index > 0) units += ', ';
          units += `${UNIT_NAME[product.unit]}`;
          return units;
        },
        '',
      );
    },
  }))
  .actions((self) => ({
    onCloseBindSubscriptionAgreementSP: () => {
      self.isShowBindSubscriptionAgreementSP = false;
      self.agreement = '';
    },
  }))
  .actions((self) => ({
    setIsShowBindSubscriptionSP: (isShow: boolean) => {
      self.isShowBindSubscriptionSP = isShow;
    },
    setIsShowBindSubscriptionAgreementSP: (isShow: boolean) => {
      self.isShowBindSubscriptionAgreementSP = isShow;
    },
    setSelectedSubscriptionId: (itemId: number) => {
      self.selectedSubscriptionId = itemId;
    },
    getAvailableMobileSubscriptions: flow(function* () {
      self.requestsState.getAvailableMobileSubscriptions.reset();
      self.requestsState.getAvailableMobileSubscriptions.setLoading();
      try {
        const res = yield getAvailableMobileSubscriptions();
        self.availableMobileSubscriptions = res;
        self.requestsState.getAvailableMobileSubscriptions.setSuccess();
      } catch (e) {
        console.error('getAvailableMobileSubscriptions', e);
        self.requestsState.getAvailableMobileSubscriptions.setFail();
      }
    }),
    getBindMobileSubscriptionAgreement: flow(function* () {
      self.requestsState.getBindMobileSubscriptionAgreement.reset();
      self.requestsState.getBindMobileSubscriptionAgreement.setLoading();
      try {
        const res = yield getBindMobileSubscriptionAgreement(
          self.selectedSubscriptionId,
        );
        self.agreement = res;
        self.requestsState.getBindMobileSubscriptionAgreement.setSuccess();
      } catch (e) {
        console.error('getBindMobileSubscriptionAgreement', e);
        self.result = {
          isResult: true,
          isCorrect: false,
        };
        self.onCloseBindSubscriptionAgreementSP();
        self.requestsState.getBindMobileSubscriptionAgreement.setFail();
      }
    }),
    bindMobileSubscription: flow(function* () {
      self.requestsState.bindMobileSubscription.reset();
      self.requestsState.bindMobileSubscription.setLoading();
      try {
        yield bindMobileSubscription(self.selectedSubscriptionId);
        self.result = {
          isResult: true,
          isCorrect: true,
        };
        self.requestsState.bindMobileSubscription.setSuccess();
      } catch (e) {
        console.error('bindMobileSubscription', e);
        self.result = {
          isResult: true,
          isCorrect: false,
        };
        self.requestsState.bindMobileSubscription.setFail();
        throw Error(e);
      } finally {
        self.onCloseBindSubscriptionAgreementSP();
      }
    }),
    bindAnotherSubscriptionButtonHandler: () => {
      if (self.isBindRoaming) {
        const {
          roamingStore: { setSelectedCountryCode, setSearchValue },
        }: IMobileStore = getParent(self);
        setSelectedCountryCode('');
        setSearchValue('');
        self.roamingSubscriptionIds.clear();
        self.isShowBindSubscriptionSP = false;
      }
      self.result = INITIAL_RESULT;
      self.selectedSubscriptionId = 0;
    },
    bindRoamingSubscriptionButtonHandler: (ids: number[]) => {
      self.roamingSubscriptionIds = cast(ids);
      self.isShowBindSubscriptionSP = true;
    },
    resetBindSubscriptionStore: () => {
      if (self.isBindRoaming) {
        const {
          roamingStore: { onCloseRoamingStoreSP },
        }: IMobileStore = getParent(self);
        onCloseRoamingStoreSP();
      }
      self.isShowBindSubscriptionSP = false;
      self.result = INITIAL_RESULT;
      self.selectedSubscriptionId = 0;
      self.roamingSubscriptionIds.clear();
    },
  }));

export type IBindSubscriptionStore = Instance<typeof BindSubscriptionStore>;

export const bindSubscriptionStoreInstance = {
  requestsState: {
    getAvailableMobileSubscriptions: defaultModelState,
    getBindMobileSubscriptionAgreement: defaultModelState,
    bindMobileSubscription: defaultModelState,
  },
  isShowBindSubscriptionSP: false,
  isShowBindSubscriptionAgreementSP: false,
  roamingSubscriptionIds: [],
  availableMobileSubscriptions: [],
  agreement: '',
  result: INITIAL_RESULT,
  selectedSubscriptionId: 0,
};
