import { McBannerTemplates } from './constants';

const ROOT_PATH = '/static/upload-files/pages/my-mobile/';

export default {
  [McBannerTemplates.main]: {
    images: {
      maskBig: `${process.env.STATIC_SERVER}${ROOT_PATH}wow-mask@1260.svg`,
      maskMedium: `${process.env.STATIC_SERVER}${ROOT_PATH}wow-mask@1080.svg`,
      maskSmall: `${process.env.STATIC_SERVER}${ROOT_PATH}wow-mask@904.svg`,
      background: `${process.env.STATIC_SERVER}${ROOT_PATH}wow-background.png`,
      backgroundMobile: `${process.env.STATIC_SERVER}${ROOT_PATH}wow-background-mobile.png`,
      circles: `${process.env.STATIC_SERVER}${ROOT_PATH}circles.svg`,
      appendix: `${process.env.STATIC_SERVER}${ROOT_PATH}appendix.svg`,
    },
    text: {
      header: 'Алло, Галочка? Ты не поверишь!',
      info:
        'Теперь Планета\u00A0\u2014 это оператор мобильной связи. Дарим бонусы при подключении',
      button: 'Подключиться',
      circles: {
        gigabytes: {
          amount: 5,
          label: 'Гб',
        },
        minutes: {
          amount: 100,
          label: 'Мин',
        },
      },
    },
  },
  [McBannerTemplates.services]: {
    images: {
      vectorLilac: `${process.env.STATIC_SERVER}${ROOT_PATH}vector_lilac.svg`,
      vectorPink: `${process.env.STATIC_SERVER}${ROOT_PATH}vector_pink.svg`,
      vectorBlack: `${process.env.STATIC_SERVER}${ROOT_PATH}vector_black.svg`,
      vectorLilacMobile: `${process.env.STATIC_SERVER}${ROOT_PATH}vector_lilac_mobile.png`,
      vectorPinkMobile: `${process.env.STATIC_SERVER}${ROOT_PATH}vector_pink_mobile.svg`,
      vectorBlackMobile: `${process.env.STATIC_SERVER}${ROOT_PATH}vector_black_mobile.svg`,
    },
    text: {
      header: 'Такой удобный',
      info:
        'Мобильная связь, быстрый интернет, цифровое\u00A0ТВ и\u00A0легендарное качество Планеты\u00A0\u2014 наслаждайтесь!',
      tabs: ['Интернет', 'Связь', 'ТВ'],
    },
  },
  [McBannerTemplates.storage]: {
    images: {
      storageAnimation: `${process.env.STATIC_SERVER}${ROOT_PATH}storage_animation.gif`,
    },
    text: {
      tag: 'ТОЛЬКО В ПЛАНЕТЕ',
      header: 'Минуты и Гигабайты\u000Aкопятся каждый день!',
      info:
        'Не\u00A0думай о\u00A0лимитах\u00A0\u2014 минуты и\u00A0ГБ пополняются ежедневно',
    },
  },
  [McBannerTemplates.singlePersonalAccount]: {
    images: {
      phone: `${process.env.STATIC_SERVER}${ROOT_PATH}mobile_phone.png`,
    },
    text: {
      header: 'Один оператор,\u000Aодин счёт для\u00A0всего!',
      info:
        'Все услуги Планеты с\u00A0подневной тарификацией по\u00A0одному договору',
    },
  },
  [McBannerTemplates.tenSims]: {
    images: {
      faces: `${process.env.STATIC_SERVER}${ROOT_PATH}faces_in_circles.png`,
    },
    text: {
      header: 'Детям, мужу, бабуле и\u00A0себе два',
      info:
        'Подключите до\u00A010\u00A0номеров к\u00A0одному договору в\u00A0Планете',
      button: 'Оформить SIM',
    },
  },
  [McBannerTemplates.magicalNumber]: {
    images: {
      background: `${process.env.STATIC_SERVER}${ROOT_PATH}magical_numbers.png`,
      backgroundMobile: `${process.env.STATIC_SERVER}${ROOT_PATH}magical_numbers@940.png`,
    },
    text: {
      header: 'Магия цифр',
      info: 'Красивые и\u00A0уникальные номера в\u00A0Планете',
      exampleNumber: '+7\u00A0933\u00A01122222',
      button: 'Выбрать номер',
    },
  },
  [McBannerTemplates.bonus]: {
    images: {
      circles: `${process.env.STATIC_SERVER}${ROOT_PATH}circles@1100.svg`,
      circlesSmall: `${process.env.STATIC_SERVER}${ROOT_PATH}circles@940.svg`,
    },
    text: {
      header: 'Приветственный бонус',
      info:
        'Дарим при\u00A0подключении 5\u00A0Гигабайт, 100\u00A0Минут и\u00A010\u00A0SMS',
      circles: {
        gigabytes: {
          label: '5 Гб',
        },
        minutes: {
          amount: 100,
          label: 'Минут',
        },
      },
    },
  },
  [McBannerTemplates.portation]: {
    images: {
      background: `${process.env.STATIC_SERVER}${ROOT_PATH}pillows.png`,
      backgroundMobile: `${process.env.STATIC_SERVER}${ROOT_PATH}pillows@940.png`,
    },
    text: {
      header: 'Переходите в\u00A0Планету со\u00A0своим номером',
      info:
        'Не\u00A0хотите расставаться со\u00A0своим номером?\u000AПродолжайте пользоваться им в\u00A0Планете!',
      button: 'Перенести номер',
    },
  },
  [McBannerTemplates.help]: {
    images: {
      woman: `${process.env.STATIC_SERVER}${ROOT_PATH}help_woman.png`,
    },
    text: {
      header: 'Так просто',
      info:
        'Нужна консультация по\u00A0выбору продукта или\u00A0техническая поддержка?',
      cta: 'Обращайтесь!',
      button: 'Написать в чат',
    },
  },
};
