/** библиотеки */
import { useEffect, useState, Dispatch, SetStateAction, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';

import * as React from 'react';

import {
  Tabs,
  TabsStyleTypes,
  H3,
  defaultTheme,
  Button,
  Text,
  LeadingText,
  LinkButton,
  Loader,
  ButtonStyleTypes,
  Snoska,
} from 'cordis-core-ui-planeta';
import Image from 'next/image';
import { useRouter } from 'next/router';

/** компоненты */
import Link from 'next/link';
import LinkWrapper from '~/components/LinkWrapper';
import { StyledConnect, StyledSummaryCost, StyledTabs } from '../Summary.style';
import Portal from '~/components/Portal/Portal';
import ChangeConnectionTypeSP from '../../ProductSwitcher/ChangeConnectionTypeSP';
import AddingContactSidePage from '~/components/Blocks/Shared/ContactsAndNotifications/Components/AddingContact/AddingContactSidePage';
import ProductSwitcherWizard from '~/components/Blocks/Shared/ProductSwitcherWizard/ProductSwitcherWizard';

/** утилиты */
import { formatNumber, isExternal } from '~/utils/utils';
import { scrollToBlockById } from '~/components/Blocks/Shared/Shared.utils';

/** константы */
import { CALL_TO_ACTION_BLOCK_ID } from '../../CallToAction/CallToAction.constants';
import {
  desktop1100,
  desktop1280,
  desktop940,
} from '~/components/Grid/constants';
import { Pab2cSlugs, SERIES_CODE } from '~/constants/common';

/** stores */
import { useRootStore } from '~/stores/RootStore';

/** interfaces */
import { ActionFields, PriceInfo } from '../Summary.types';
import {
  AllowedTariffProps,
  TariffProps,
} from '../../ProductSwitcher/interfaces';

/** store */
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';
import useContactsAndNotificationsStore from '~/components/Blocks/Shared/ContactsAndNotifications/store/useContactsAndNotificationsStore';
import useProductSwitcherWizardStore from '~/components/Blocks/Shared/ProductSwitcherWizard/store/useProductSwitcherWizardStore';

/** Имя кнопки для CtA */
const CONNECT_BUTTON_NAME = 'Подключить';
const CHANGE_PRODUCT_BUTTON_NAME = 'Сменить продукт';
const CHANGE_PRODUCT_BUTTON_NAME_SHORT = 'Сменить';

/** названия категорий цен */
const PRICES_CATEGORY = {
  daily: 'День',
  thirtyDays: 'Месяц',
  migrationCostFirst: 'Подключение',
  annual: 'Подключение на год',
  upgrade: 'Апгрейд годового продукта',
  halfAnnual: 'Подключение к продукту',
};

/** интерфейс компонента стоимость тарифа */
interface SummaryCostProps {
  priceInfo: PriceInfo;
  productFeedLink: string;
  isAnnual: boolean;
  action?: ActionFields;
  /** Объект продукта */
  tariff?: TariffProps;
  setAgreement?: Dispatch<SetStateAction<string>>;
  agreement?: string;
  seriesCode: string;
  tariffId: number;
  /** Ошибки смены продукта */
  setProductChangeError?: Dispatch<SetStateAction<string>>;
  /** Флаг публичности продукта */
  isPrivate?: boolean;
}

/**
 * компонент стоимость тарифа
 * @param {SummaryCostProps} priceInfo информация о ценах
 */
const SummaryCost: React.FC<SummaryCostProps> = ({
  priceInfo,
  productFeedLink,
  isAnnual,
  action,
  tariff,
  seriesCode,
  tariffId,
  isPrivate,
}: SummaryCostProps) => {
  const {
    connectionTariffStore: { connectTariff },
    summaryDataStore: { isMonoProduct },
  } = useRootStore();
  const {
    haveVerifiedPhone,
    addingContactStore: { setIsShowAddingContact },
  } = useContactsAndNotificationsStore();

  const { getAgreement, isLoadingAgreement } = useProductSwitcherWizardStore();

  /** Объект router */
  const router = useRouter();
  /** Страница выбора продукта */
  const isCompare = router.query?.slug?.includes(Pab2cSlugs.CHANGE_PLAN_SLUG);

  const isAction = action?.icon;

  const isPromo = useMemo(() => {
    return tariff.tags?.find((item) => item.code === 'Promo');
  }, [tariff]);

  const {
    connectionTariffStore: { toggleChangeTariffWizardVisible },
    summaryDataStore: { seriesCode: summarySeriesCode, annualPriceInfo },
    allowedTariffStore: { allowedTariff },
    authStore: { isAuth, auth, isTemporaryTokenAuth },
  } = useRootStore();

  /** Продукт недоступен для перехода */
  const isNotAvailableProduct = useMemo(() => {
    if (!allowedTariff?.length) return false;
    return !((allowedTariff as unknown) as AllowedTariffProps[]).find(
      (item) => tariff.seriesCode === item.tariffTo.seriesCode,
    );
  }, [allowedTariff, tariff]);

  /** Текущий продукт */
  const isCurrentProduct = summarySeriesCode === seriesCode;

  /** Контекст подключения продукта */
  const {
    toggleConnectionWizardVisible,
    connectionWizardAuto,
  } = useConnectionStore();

  // Флаг показа СП смены типа подключения
  const [showSP, setShowSP] = useState(false);

  const [priceIndex, setPriceIndex] = useState(0);

  /** Узнаем возможность подключения оптического продукта, для Product Switcher */
  const blockSwitchToFtth = useMemo(() => {
    return isAuth && allowedTariff
      ? ((allowedTariff as unknown) as AllowedTariffProps[]).find(
          (item) => item?.tariffTo?.seriesCode === seriesCode,
        )?.tariffTo?.tariffMigrationRefuseReason === 'FtthError'
      : true;
  }, [isAuth, allowedTariff, tariff]);

  // Сбрасываем значение при изменении категории
  useEffect(() => {
    setPriceIndex(0);
  }, [priceInfo]);

  const tabPriceInfo = useMemo(() => {
    if (!priceInfo) return null;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { promoConnectionInfos, connection, ...tabs } = priceInfo;
    return tabs;
  }, [priceInfo, summarySeriesCode]);

  if (priceInfo?.migrationCostFirst === 0)
    delete tabPriceInfo?.migrationCostFirst;

  if (isAnnual) {
    /* В Product Switcher авторизованным пользователям показываем цену подключения годовых
     * с учётом пояса обслуживания
     */
    const costToMigration = allowedTariff.find(
      (item) => item.tariffTo.tariffId === tariffId,
    )?.price;
    tabPriceInfo.annual = costToMigration || priceInfo?.migrationCostFirst;
    delete tabPriceInfo?.migrationCostFirst;
  } else {
    delete tabPriceInfo?.annual;
  }
  if (
    (summarySeriesCode === SERIES_CODE.ONLINE_SUPER &&
      seriesCode === SERIES_CODE.ONLINE_SUPER_2_0) ||
    (summarySeriesCode === SERIES_CODE.HIT_SUPER &&
      seriesCode === SERIES_CODE.HIT_SUPER_2_0)
  ) {
    tabPriceInfo.upgrade = priceInfo.annual - annualPriceInfo;
    delete tabPriceInfo.annual;
  }
  if ([SERIES_CODE.ONLINE_180, SERIES_CODE.HIT_180].includes(seriesCode)) {
    tabPriceInfo.halfAnnual = priceInfo.migrationCostFirst;
    delete tabPriceInfo.daily;
    delete tabPriceInfo.thirtyDays;
  }
  /** В подключённом продукте убрана цена подключения */
  if (isCurrentProduct) {
    delete tabPriceInfo?.migrationCostFirst;
  }
  const showDiscountAnnual = action?.discount && isAnnual;

  const pricesCategoryNames = React.useMemo(() => {
    if (!tabPriceInfo) return [PRICES_CATEGORY.daily];
    return Object.keys(tabPriceInfo).map((name) =>
      showDiscountAnnual ? `${PRICES_CATEGORY[name]}*` : PRICES_CATEGORY[name],
    );
  }, [priceInfo, showDiscountAnnual, tabPriceInfo]);

  const processCategoryValue = tabPriceInfo ? Object.values(tabPriceInfo) : [];

  const scrollToCtA = () => scrollToBlockById(CALL_TO_ACTION_BLOCK_ID);

  const secondActionImage = (link): JSX.Element => {
    return (
      <div className="action-secondIcon">
        <Image
          loader={imageLoader}
          src={`${process.env.STATIC_SERVER}/${link}`}
          width={141}
          height={79}
          quality={100}
        />
      </div>
    );
  };

  const discountPrices = (discount: number, price: number): JSX.Element => {
    return (
      <div className="action-discount">
        <Text color={defaultTheme.colors.planeta}>
          &#8722;&nbsp;{`${discount}%`}
        </Text>
        <Text color={defaultTheme.colors.gray}>{`${price}\u0020\u20bd`}</Text>
      </div>
    );
  };

  /** Загрузчик изображения */
  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });
  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });

  const changeProductText = () => {
    if (isDesktop940 && !isDesktop1280) return CHANGE_PRODUCT_BUTTON_NAME_SHORT;
    return CHANGE_PRODUCT_BUTTON_NAME;
  };

  /** Ссылка на подключение продукта */
  const connectToAnotherAddress = () => {
    if (isCompare && !productFeedLink) return null;
    return (
      <>
        {productFeedLink && !isAction ? (
          <LinkWrapper
            href={
              isExternal(productFeedLink)
                ? productFeedLink
                : `${productFeedLink}${CALL_TO_ACTION_BLOCK_ID}`
            }
            target="_blank"
          >
            {isDesktop1280 || !isDesktop940 ? (
              <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
                <Text lineHeight="24px" color={defaultTheme.colors.planeta}>
                  Подключить
                </Text>{' '}
                на другом адресе
              </Text>
            ) : (
              <Snoska>
                <Snoska lineHeight="24px" color={defaultTheme.colors.planeta}>
                  Подключить
                </Snoska>{' '}
                на другом адресе
              </Snoska>
            )}
          </LinkWrapper>
        ) : (
          <Link href={`${window.location.pathname}${CALL_TO_ACTION_BLOCK_ID}`}>
            <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
              <LinkButton
                onClick={isAction ? toggleConnectionWizardVisible : scrollToCtA}
              >
                Подключить
              </LinkButton>{' '}
              на другом адресе
            </Text>
          </Link>
        )}
      </>
    );
  };

  /** СП заявки на подключение */
  const connect = () => {
    return (
      <div>
        <Button
          className="button"
          onClick={toggleConnectionWizardVisible}
          styleType={
            isDesktop940 ? ButtonStyleTypes.MAIN : ButtonStyleTypes.SECONDARY
          }
        >
          {CONNECT_BUTTON_NAME}
        </Button>
      </div>
    );
  };

  /** Обработчик кнопки смены типа подключения на оптику */
  const switchToFtthButtonHandler = async () => {
    if (isTemporaryTokenAuth) return;
    const havePhone = await haveVerifiedPhone();
    setShowSP(true);
    if (!havePhone) {
      setIsShowAddingContact(true, () =>
        connectionWizardAuto(seriesCode, tariff.seriesName, isAuth, auth),
      );
      return;
    }
    connectionWizardAuto(seriesCode, tariff.seriesName, isAuth, auth);
  };

  /** Обработчик кнопки подключения/смены продукта */
  const changeTariffButtonHandler = () => {
    if (!isAuth) {
      toggleConnectionWizardVisible();
      return;
    }
    getAgreement(
      tariff,
      tariffId,
      toggleChangeTariffWizardVisible,
      auth.contractName,
    );
  };

  /** Подключение продукта */
  const connectButton = useMemo(() => {
    if (!isAuth) {
      return connect();
    }
    if (!allowedTariff) {
      return <Loader small />;
    }
    /** Текущий или промо продукт */
    if (isPromo || isCurrentProduct || isNotAvailableProduct) {
      return (
        <StyledConnect>
          {isDesktop1280 ? (
            <LeadingText
              className="connected-text"
              color={defaultTheme.colors.shadow}
            >
              {
                // eslint-disable-next-line no-nested-ternary
                isCurrentProduct
                  ? 'Текущий продукт'
                  : isPromo
                  ? 'Для новых клиентов'
                  : 'Смена недоступна'
              }
            </LeadingText>
          ) : (
            <Text
              className="connected-text"
              color={defaultTheme.colors.shadow}
              fontWeightBold={!isDesktop940}
            >
              {
                // eslint-disable-next-line no-nested-ternary
                isCurrentProduct
                  ? 'Текущий продукт'
                  : isPromo
                  ? 'Для новых клиентов'
                  : 'Смена недоступна'
              }
            </Text>
          )}
          {!isPrivate && connectToAnotherAddress()}
        </StyledConnect>
      );
    }
    if (isMonoProduct) {
      return (
        <Snoska>
          Для смены продукта необходимо заключение нового договора. Если
          вы&nbsp;хотите оставить заявку или уточнить детали, свяжитесь
          с&nbsp;нами удобным для вас способом.
        </Snoska>
      );
    }
    if (blockSwitchToFtth) {
      return (
        <>
          {isAuth ? (
            <>
              {isDesktop940 ? (
                <Text
                  className="ftth-text"
                  lineHeight="24px"
                  color={defaultTheme.colors.shadow}
                >
                  Для смены продукта необходимо оптическое подключение.
                  <br />
                  <LinkButton
                    onClick={switchToFtthButtonHandler}
                    color={
                      isTemporaryTokenAuth
                        ? defaultTheme.colors.disable
                        : defaultTheme.colors.planeta
                    }
                  >
                    Подать заявку
                  </LinkButton>
                </Text>
              ) : (
                <Text
                  className="ftth-text"
                  lineHeight="24px"
                  color={defaultTheme.colors.shadow}
                >
                  <LinkButton
                    onClick={switchToFtthButtonHandler}
                    color={
                      isTemporaryTokenAuth
                        ? defaultTheme.colors.disable
                        : defaultTheme.colors.planeta
                    }
                  >
                    Подать заявку
                  </LinkButton>{' '}
                  на подключение оптики
                </Text>
              )}
              {isTemporaryTokenAuth && (
                <>
                  <br />
                  <Text lineHeight="24px" color={defaultTheme.colors.disable}>
                    Действие доступно только клиенту
                  </Text>
                </>
              )}
            </>
          ) : (
            connect()
          )}
        </>
      );
    }
    return (
      <div>
        <Button
          className="button"
          onClick={changeTariffButtonHandler}
          loading={isLoadingAgreement}
          styleType={
            isDesktop940 ? ButtonStyleTypes.MAIN : ButtonStyleTypes.SECONDARY
          }
        >
          {isAuth ? changeProductText() : CONNECT_BUTTON_NAME}
        </Button>
      </div>
    );
  }, [
    isAuth,
    blockSwitchToFtth,
    allowedTariff,
    isMonoProduct,
    isPromo,
    isCurrentProduct,
    isPrivate,
    isTemporaryTokenAuth,
    isNotAvailableProduct,
    tariff.price,
    isLoadingAgreement,
    isDesktop940,
    isDesktop1100,
    isDesktop1280,
  ]);

  return (
    <StyledSummaryCost $hasSecondIcon={!!action?.secondIcon}>
      <span>
        <StyledTabs className="tabs">
          <Tabs
            value={pricesCategoryNames}
            onChange={setPriceIndex}
            activeTabIndex={priceIndex}
            styleType={TabsStyleTypes.SECONDARY}
          />
          <span>
            {showDiscountAnnual &&
              discountPrices(action.discount, processCategoryValue[priceIndex])}
            <H3 color={defaultTheme.colors.black}>
              {formatNumber(
                showDiscountAnnual
                  ? action.newPrice
                  : processCategoryValue[priceIndex],
              )}{' '}
              ₽
            </H3>
          </span>
        </StyledTabs>
        {action?.secondIcon && secondActionImage(action.secondIcon)}
      </span>
      {connectButton}
      <Portal wrapperId="portalPrimary">
        {(showSP || connectTariff?.tariffId === tariffId) && (
          <AddingContactSidePage />
        )}
        {showSP && <ChangeConnectionTypeSP />}
      </Portal>
      <Portal>
        {connectTariff?.tariffId === tariffId && <ProductSwitcherWizard />}
      </Portal>
    </StyledSummaryCost>
  );
};

export default observer(SummaryCost);
