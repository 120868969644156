/** libraries */
import { defaultTheme } from 'cordis-core-ui-planeta';
/** styles */
import {
  StyledCircles,
  StyledGigabyteAmount,
  StyledGigabyteLabel,
  StyledMinutesAmount,
  StyledMinutesLabel,
} from './styles';
/** constants */
import { McBannerTemplates } from '../../constants';
/** config */
import CONFIG from '../../config';

const {
  text: {
    circles: { minutes, gigabytes },
  },
} = CONFIG[McBannerTemplates.main];

/** Компонент с информацией о приветственном бонусе */
const Circles = (): JSX.Element => {
  return (
    <StyledCircles>
      <StyledGigabyteAmount color={defaultTheme.colors.white}>
        {gigabytes.amount}
      </StyledGigabyteAmount>
      <StyledGigabyteLabel color={defaultTheme.colors.white}>
        {gigabytes.label}
      </StyledGigabyteLabel>
      <StyledMinutesAmount color={defaultTheme.colors.white}>
        {minutes.amount}
      </StyledMinutesAmount>
      <StyledMinutesLabel color={defaultTheme.colors.white}>
        {minutes.label}
      </StyledMinutesLabel>
    </StyledCircles>
  );
};

export default Circles;
