/** libraries */
import { types, flow, getParent } from 'mobx-state-tree';
/** api */
import { disableMobileRoaming, enableMobileRoaming } from '~/api/apiPab2c';
/** types */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
import { IMobileStore } from './MobileStore';

const RequestsStateModel = types.model('ToggleRoamingStoreRequestsState', {
  enableMobileRoaming: createApiPathModel(
    'POST /Mobile/MobilePhoneNumber/EnableInternationRoaming',
  ),
  disableMobileRoaming: createApiPathModel(
    'POST /Mobile/MobilePhoneNumber/DisableMobileRoaming',
  ),
});

export const ToggleRoamingStore = types
  .model('ToggleRoamingStore', {
    requestsState: RequestsStateModel,
    isShowToggleRoamingSP: types.boolean,
    selectedNumberId: types.string,
  })
  .views((self) => ({
    get isLoading() {
      return (
        self.requestsState.enableMobileRoaming.isLoading ||
        self.requestsState.disableMobileRoaming.isLoading
      );
    },
    get isFailed() {
      return (
        self.requestsState.enableMobileRoaming.isFailed ||
        self.requestsState.disableMobileRoaming.isFailed
      );
    },
  }))
  .actions((self) => ({
    toggleRoamingButtonHandler: (msisdn: string) => {
      const { setDroplistSelectedNumberId }: IMobileStore = getParent(self);
      self.selectedNumberId = msisdn;
      self.isShowToggleRoamingSP = true;
      setDroplistSelectedNumberId('');
    },
    enableMobileRoaming: flow(function* (mobilePhoneNumberId: number) {
      self.requestsState.enableMobileRoaming.reset();
      self.requestsState.disableMobileRoaming.reset();
      self.requestsState.enableMobileRoaming.setLoading();
      try {
        yield enableMobileRoaming(mobilePhoneNumberId);
        self.requestsState.enableMobileRoaming.setSuccess();
      } catch (e) {
        console.error('enableMobileRoaming', e);
        self.requestsState.enableMobileRoaming.setFail();
        throw Error(e);
      }
    }),
    disableMobileRoaming: flow(function* (mobilePhoneNumberId: number) {
      self.requestsState.disableMobileRoaming.reset();
      self.requestsState.enableMobileRoaming.reset();
      self.requestsState.disableMobileRoaming.setLoading();
      try {
        yield disableMobileRoaming(mobilePhoneNumberId);
        self.requestsState.disableMobileRoaming.setSuccess();
      } catch (e) {
        console.error('disableMobileRoaming', e);
        self.requestsState.disableMobileRoaming.setFail();
        throw Error(e);
      }
    }),
    resetToggleRoamingStore: () => {
      self.isShowToggleRoamingSP = false;
      self.requestsState.disableMobileRoaming.reset();
      self.requestsState.enableMobileRoaming.reset();
    },
  }));

export const toggleRoamingStoreInstance = {
  requestsState: {
    enableMobileRoaming: defaultModelState,
    disableMobileRoaming: defaultModelState,
  },
  isShowToggleRoamingSP: false,
  selectedNumberId: '',
};
