/** libraries */
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { defaultTheme, Text } from 'cordis-core-ui-planeta';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
/** styles */
import {
  StyledH3,
  StyledPrice,
  StyledPriceInfoBlock,
  StyledText,
  StyledPriceLeadingText,
  StyledLinkButton,
  StyledDescription,
  StyledButton,
} from '../styles';
import { StyledRoaming } from '../../../Components/MobileInfo/style';
/** utils */
import { formatNumber } from '~/utils/utils';
/** components */
import MobileScale from '../../../Components/MobileScale/MobileScale';
/** stores */
import useMobileStore from '../../../store/useMobileStore';
import { useRootStore } from '~/stores/RootStore';

const CountryInfo = (): JSX.Element => {
  const {
    pab2cMobileStore: { roamingSubscriptions, connectedRoamingTypes },
  } = useRootStore();

  const {
    roamingStore: {
      roamingZoneInfo,
      setIsShowCountryPrices,
      getRoamingCountriesByPackage,
    },
    bindSubscriptionStore: { bindRoamingSubscriptionButtonHandler },
  } = useMobileStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const getUnitedCountryList = () => {
    if (!roamingSubscriptions.length) return [];
    const zoneCounter = [];
    const countryList = roamingSubscriptions.reduce((list, subscription) => {
      if (zoneCounter.includes(subscription.mobileBalanceType)) return list;
      zoneCounter.push(subscription.mobileBalanceType);
      const countries = getRoamingCountriesByPackage(subscription.itemId);
      return [...list, ...countries];
    }, []);
    return countryList;
  };

  const unitedCountryList = useMemo(() => getUnitedCountryList().sort(), [
    roamingSubscriptions.length,
  ]);

  const bindSubButtonHandler = () => {
    const roamingSubIds = roamingZoneInfo.packageRoamingPricesItems.map(
      (info) => info.id,
    );
    bindRoamingSubscriptionButtonHandler(roamingSubIds);
  };

  const priceInfo = () => {
    const [
      outcomingCalls,
      incomingCalls,
    ] = roamingZoneInfo.voiceTrafficRoamingPricesItems;
    const [outcomingSms] = roamingZoneInfo.smsRoamingPricesItems;

    return (
      <>
        <StyledPrice>
          <StyledPriceLeadingText color={defaultTheme.colors.black}>
            {formatNumber(outcomingCalls.price)} ₽/мин
          </StyledPriceLeadingText>
          <Text color={defaultTheme.colors.shadow}>{outcomingCalls.name}</Text>
        </StyledPrice>
        <StyledPrice>
          <StyledPriceLeadingText color={defaultTheme.colors.black}>
            {formatNumber(outcomingSms.price)} ₽
          </StyledPriceLeadingText>
          <Text color={defaultTheme.colors.shadow}>{outcomingSms.name}</Text>
        </StyledPrice>
        {isDesktop940 && (
          <StyledPrice>
            <StyledPriceLeadingText color={defaultTheme.colors.black}>
              {formatNumber(incomingCalls.price)} ₽/мин
            </StyledPriceLeadingText>
            <Text color={defaultTheme.colors.shadow}>{incomingCalls.name}</Text>
          </StyledPrice>
        )}
      </>
    );
  };

  return (
    <>
      {isDesktop940 && (
        <StyledDescription lineHeight="24px">
          Вы&nbsp;можете узнать условия роуминга для выбранной страны или
          приобрести пакет интернета для использования в&nbsp;этой стране.
        </StyledDescription>
      )}
      <StyledH3>Звонки и&nbsp;SMS в&nbsp;роуминге</StyledH3>
      <StyledPriceInfoBlock>{priceInfo()}</StyledPriceInfoBlock>
      <StyledLinkButton onClick={() => setIsShowCountryPrices(true)}>
        Подробные условия
      </StyledLinkButton>
      <StyledH3>Интернет в&nbsp;роуминге</StyledH3>
      <StyledText lineHeight="24px" color={defaultTheme.colors.gray}>
        {roamingSubscriptions.length > 0
          ? `Пакеты для\u00A0${unitedCountryList[0]} и\u00A0ещё\u00A0${
              unitedCountryList.length - 1
            }\u00A0стран`
          : 'Будет доступен после добавления пакета'}
      </StyledText>
      {roamingSubscriptions.length > 0 && (
        <StyledRoaming>
          {connectedRoamingTypes.map((item) => (
            <MobileScale key={item} type={item} />
          ))}
        </StyledRoaming>
      )}
      <StyledButton
        onClick={bindSubButtonHandler}
        background={defaultTheme.colors.black}
        border={defaultTheme.colors.black}
      >
        Подключить пакет Мб
      </StyledButton>
      {roamingSubscriptions.length > 0 && (
        <Text lineHeight="24px" color={defaultTheme.colors.gray}>
          Пакеты действуют также в следующих странах:{' '}
          {unitedCountryList.join(', ')}.
        </Text>
      )}
    </>
  );
};

export default observer(CountryInfo);
