/** libraries */
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { H2, H3, Icons, SidePage } from 'cordis-core-ui-planeta';
/** stores */
import useMobileStore from '../../store/useMobileStore';
/** styles */
import { StyledIcon } from '../../style';
import { StyledButton } from '../BindNewNumber/styles';
/** constants */
import { DEFAULT_ERROR } from '~/constants/common';
import { desktop1100 } from '~/components/Grid/constants';
/** components */
import MethodOfReceiptForm from '../../Components/MethodOfReceiptForm/MethodOfReceiptForm';
import { useRootStore } from '~/stores/RootStore';

const ChoosingTheReceivingMethod = (): JSX.Element => {
  const {
    cityStore: { city, officeInfos },
  } = useRootStore();

  const {
    choosingTheReceivingMethodStore: {
      isShowChoosingTheReceivingMethod,
      result,
      onCloseChoosingTheReceivingMethodSP,
    },
    methodOfReceiptFormStore: {
      setCheckedOfficeId,
      setSelectedCity,
      filteredOfficeInfos,
      selectedCity,
      isAcceptButtonDisabled,
      acceptDeliveryAddress,
      activeTabIndex,
    },
  } = useMobileStore();

  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  useEffect(() => {
    setSelectedCity({
      label: city.name,
      value: city.name,
    });
  }, []);

  useEffect(() => {
    if (selectedCity.value) {
      setCheckedOfficeId(filteredOfficeInfos(officeInfos)[0]?.id);
    }
  }, [selectedCity.value]);

  if (!isShowChoosingTheReceivingMethod) return null;

  const acceptAddressButtonHandler = () => {
    acceptDeliveryAddress();
    onCloseChoosingTheReceivingMethodSP();
  };

  const Footer = () => {
    const buttonDeliveryActionMap = ['Получить здесь', 'Доставить сюда'];
    return (
      <StyledButton
        onClick={acceptAddressButtonHandler}
        disabled={isAcceptButtonDisabled}
      >
        {buttonDeliveryActionMap[activeTabIndex]}
      </StyledButton>
    );
  };

  return (
    <SidePage
      show={isShowChoosingTheReceivingMethod}
      headerText={!result.isResult && 'Способ получения SIM-карты'}
      onCloseClick={onCloseChoosingTheReceivingMethodSP}
      footerContainer={!result.isResult && <Footer />}
    >
      {result.isResult && result.isCorrect && (
        <>
          <StyledIcon icon={<Icons.SuccessIconAnimated />} />
          {isDesktop1100 ? <H2>{result.text}</H2> : <H3>{result.text}</H3>}
        </>
      )}
      {result.isResult && !result.isCorrect && (
        <>
          <StyledIcon icon={<Icons.NotOkBigIcon />} />
          {isDesktop1100 ? <H2>{DEFAULT_ERROR}</H2> : <H3>{DEFAULT_ERROR}</H3>}
        </>
      )}
      {!result.isResult && <MethodOfReceiptForm />}
    </SidePage>
  );
};

export default observer(ChoosingTheReceivingMethod);
