/** libraries */
import { observer } from 'mobx-react';
import {
  Text,
  defaultTheme,
  LeadingText,
  SidePage,
  Loader,
  LinkButton,
  ButtonStyleTypes,
  Icons,
  ButtonIconPosition,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { DEFAULT_ERROR } from '~/constants/common';
/** styles */
import {
  StyledButton,
  StyledError,
  StyledH3,
  StyledSwitcher,
  StyledText,
  StyledToggle,
  StyledWarning,
} from './styles';
/** utils */
import { maskPhone } from '~/utils/utils';
/** stores */
import useMobileStore from '../../store/useMobileStore';
import { useRootStore } from '~/stores/RootStore';

const ToggleRoaming = (): JSX.Element => {
  const {
    pab2cMobileStore: {
      getMobilePhone,
      setIsInternationalRoamingEnabled,
      connectedRoamingTypes,
    },
  } = useRootStore();

  const {
    toggleRoamingStore: {
      isShowToggleRoamingSP,
      resetToggleRoamingStore,
      selectedNumberId,
      enableMobileRoaming,
      disableMobileRoaming,
      isLoading,
      isFailed,
    },
    roamingStore: { setIsShowRoamingSP },
  } = useMobileStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const { subscriberName, isInternationalRoamingEnabled, mobilePhoneNumberId } =
    selectedNumberId && getMobilePhone(selectedNumberId);

  const toggleRoamingClickHandler = async () => {
    try {
      if (isInternationalRoamingEnabled) {
        await disableMobileRoaming(mobilePhoneNumberId);
      } else {
        await enableMobileRoaming(mobilePhoneNumberId);
      }
      setIsInternationalRoamingEnabled(
        selectedNumberId,
        !isInternationalRoamingEnabled,
      );
    } catch (e) {
      console.error('toggleRoamingClickHandler', e);
    }
  };

  const bindSubscriptionButtonHandler = () => {
    setIsShowRoamingSP(true);
    resetToggleRoamingStore();
  };

  const Description = (): JSX.Element => (
    <>
      {!isInternationalRoamingEnabled && !!connectedRoamingTypes.length && (
        <StyledWarning>
          <Text lineHeight="24px">
            Куплены пакеты интернета в&nbsp;роуминге. Для&nbsp;данного номера
            интернет в&nbsp;международном роуминге недоступен.
          </Text>
        </StyledWarning>
      )}
      <StyledText lineHeight="24px">
        Условия использования услуг связи в&nbsp;роуминге различаются
        в&nbsp;зависимости от&nbsp;страны и&nbsp;оператора. Перед поездкой
        ознакомьтесь с&nbsp;этими условиями.
      </StyledText>
      {isInternationalRoamingEnabled && (
        <>
          <Text>
            Собираетесь в&nbsp;поездку?{' '}
            {isDesktop940 && (
              <LinkButton onClick={bindSubscriptionButtonHandler}>
                Подключите пакет Мб
              </LinkButton>
            )}
          </Text>
          {!isDesktop940 && (
            <StyledButton
              styleType={ButtonStyleTypes.ACTION}
              icon={<Icons.AddSubscriptionIcon />}
              secondIcon={<Icons.LeftArrowIcon />}
              secondIconPosition={ButtonIconPosition.RIGHT}
              onClick={bindSubscriptionButtonHandler}
            >
              Подключите пакет Мб
            </StyledButton>
          )}
        </>
      )}
    </>
  );

  if (!isShowToggleRoamingSP) return null;

  return (
    <SidePage
      show={isShowToggleRoamingSP}
      headerText="Международный роуминг"
      onCloseClick={resetToggleRoamingStore}
      showMobileHeader
    >
      <StyledH3>{maskPhone(selectedNumberId)}</StyledH3>
      <LeadingText color={defaultTheme.colors.black}>
        {subscriberName}
      </LeadingText>

      {!isFailed && (
        <StyledToggle>
          <StyledSwitcher
            checked={isInternationalRoamingEnabled}
            onClick={toggleRoamingClickHandler}
          >
            <Text>
              Роуминг {isInternationalRoamingEnabled ? 'включён' : 'выключен'}
            </Text>
          </StyledSwitcher>

          <Text lineHeight="24px">
            {isInternationalRoamingEnabled
              ? 'При выключении входящие и\u00A0исходящие звонки, интернет будут недоступны.'
              : 'Входящие и\u00A0исходящие звонки, интернет недоступны.'}
          </Text>
        </StyledToggle>
      )}

      {isFailed && (
        <StyledError color={defaultTheme.colors.red} lineHeight="24px">
          {DEFAULT_ERROR}
        </StyledError>
      )}
      {isLoading && <Loader small />}
      {!isLoading && <Description />}
    </SidePage>
  );
};

export default observer(ToggleRoaming);
