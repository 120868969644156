/** libraries */
import { useEffect, useMemo } from 'react';
import * as React from 'react';
import { Text, Icons, Tabs, TabsStyleTypes } from 'cordis-core-ui-planeta';
/** constants */
import { TABS_VARIANTS } from '../CallToAction.constants';
/** styles */
import { StyledFeature, StyledLeftColumn } from '../CallToAction.style';
/** components */
import CallToActionRow from '../CallToActionRow/CallToActionRow';
/** utils */
import { formatNumber, pluralizeAll } from '~/utils/utils';
/** stores */
import { useCallToActionStore } from '../stores/MainCallToAction/useMainCallToActionStore';
import useCallToActionStateStore from '../stores/CallToActionState/useCallToActionStateStore';
import { StyledH3 } from './CallToActionFee.style';

/* Дочерний компонент "Абонентская плата", компонента CallToAction */
const CallToActionFee: React.FC = () => {
  const {
    isAnnual,
    dailyPrice,
    thirtyDaysPrice,
    migrationCostFirstPrice,
    timeshiftPrice,
    distortionDuration,
    routerInfo,
    STBInfo,
  } = useCallToActionStore();
  const {
    enabledTimeShift,
    enabledRouter,
    enabledSTB,
    selectRouterTypes,
    selectSTBTypes,
    setFeePerDay,
    getDeviceFee,
  } = useCallToActionStateStore();
  const annual = useMemo(() => {
    return isAnnual && migrationCostFirstPrice;
  }, []);
  const timeshiftPriceOn = useMemo(() => {
    return enabledTimeShift ? timeshiftPrice : 0;
  }, [enabledTimeShift]);

  /* Роутер за день */
  const routerFee = getDeviceFee(enabledRouter, routerInfo, selectRouterTypes);
  /* Приставка за день */
  const STBFee = STBInfo
    ? getDeviceFee(enabledSTB, STBInfo, selectSTBTypes)
    : 0;

  /* Количество дней отложенного платежа */
  const distortionDurationDays = Math.floor(distortionDuration / 24);
  /* Текущий индекс таба */
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
  /* Текущее значение таба */
  const [activeTabValue, setActiveTabValue] = React.useState<number>(
    dailyPrice + timeshiftPriceOn + routerFee + STBFee,
  );
  /* Событие при изменении таба */
  const onChangeTab = (tabIndex: number) => {
    const dailyFee = dailyPrice + timeshiftPriceOn + routerFee + STBFee;
    setActiveTabIndex(tabIndex);
    switch (tabIndex) {
      case 0:
        setFeePerDay(dailyFee);
        setActiveTabValue(dailyFee);
        break;
      case 1:
        setActiveTabValue(
          thirtyDaysPrice +
            timeshiftPriceOn * 30 +
            routerFee * 30 +
            STBFee * 30,
        );
        break;
      default:
        setFeePerDay(dailyFee);
        setActiveTabValue(dailyFee);
    }
  };

  useEffect(() => {
    onChangeTab(activeTabIndex);
  }, [timeshiftPriceOn, routerFee, STBFee]);

  /* Склонение количества дней отложенного платежа */
  const distortionDurationDaysCount = React.useMemo(
    () => pluralizeAll(distortionDurationDays, ['день', 'дня', 'дней']),
    [distortionDurationDays],
  );

  return (
    <CallToActionRow title={<Text>Абонентская плата</Text>}>
      <StyledLeftColumn>
        <div>
          <Tabs
            value={TABS_VARIANTS}
            styleType={TabsStyleTypes.SECONDARY}
            onChange={onChangeTab}
            activeTabIndex={activeTabIndex}
          />
        </div>
        <StyledH3>
          {activeTabValue === 0
            ? 'Отсутствует'
            : `${formatNumber(activeTabValue)}\u0020\u20bd`}
        </StyledH3>
      </StyledLeftColumn>
      <StyledFeature>
        {!annual && <Icons.OkIcon />}
        <Text>
          {annual
            ? `Подключение на год ${annual}\u20bd`
            : `Отложенный платёж на ${distortionDurationDaysCount}`}
        </Text>
        {/* yaspeller ignore:end */}
      </StyledFeature>
    </CallToActionRow>
  );
};

export default React.memo(CallToActionFee);
