import styled from '@emotion/styled';
import { H3, defaultTheme, Icon, Button } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledContainer = styled.div`
  margin-top: -18px;
`;

export const StyledH3 = styled(H3)`
  margin: 18px 18px 24px 0;

  @media (max-width: ${desktop940}px) {
    font-size: 24px;
    margin: 12px 0 12px 0;
  }
`;

export const StyledNewNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 6px;

  @media (min-width: ${desktop940}px) {
    flex-direction: row;
  }
`;

export const StyledWarning = styled.div`
  margin-bottom: 0;
  display: flex;
  flex-direction: column;

  > span {
    padding: 16px;
    background-color: ${defaultTheme.colors.backgroundWarning};
    margin-bottom: 16px;
  }

  @media (min-width: ${desktop940}px) {
    margin-bottom: 16px;
  }
`;

export const StyledIcon = styled(Icon)`
  svg {
    margin-bottom: 16px;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;

  @media (min-width: ${desktop940}px) {
    width: unset;
  }
`;

export const StyledPrice = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-top: 12px;

  div:first-of-type {
    margin-bottom: 12px;

    .colorContainer {
      width: fit-content;
      padding: 12px 16px 12px 6px;
    }
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;
    align-items: center;

    div:first-of-type {
      margin-right: 12px;
      margin-bottom: 0;
    }
  }
`;
