/** Коллекция типов исключений проверки возможности переноса мобильного телефонного номера в Планету */
export enum CHECK_PORTABILITY_NUMBER_ERROR_TYPE {
  /** Дата снятия запрета смены номера на данной SIM-карте еще не наступила */
  WRONG_REGION = 'PortabilityNotAvailableAnotherRegionMobilePhoneNumberException',
  /** Номер уже в Планете */
  IS_USING = 'PortabilityNotAvailablePlanetaMobilePhoneNumberException',
}

export const CHECK_PORTABILITY_NUMBER_ERROR_TEXT = {
  [CHECK_PORTABILITY_NUMBER_ERROR_TYPE.WRONG_REGION]:
    'Номер оформлен в другом регионе',
  [CHECK_PORTABILITY_NUMBER_ERROR_TYPE.IS_USING]:
    'Указанный номер уже используется в Планете',
};
