/** libraries */
import { observer } from 'mobx-react';
import { Button } from 'cordis-core-ui-planeta';
/** constants */
import { CHANGE_NUMBER_STATUS_SP_TYPE } from '../../constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';

const ChangeNumberStatusFooter = () => {
  const {
    pab2cMobileStore: { getMobilePhone, getMobilePhones },
  } = useRootStore();
  const {
    changeNumberStatusStore: {
      changeNumberStatusType,
      isErrorAgreement,
      isLoadingAction,
      blockMobilePhone,
      unbindMobilePhone,
    },
    droplistSelectedNumberId,
  } = useMobileStore();

  const mobilePhone = getMobilePhone(droplistSelectedNumberId);
  const isLock = changeNumberStatusType === CHANGE_NUMBER_STATUS_SP_TYPE.LOCK;

  return (
    <Button
      loading={isLoadingAction}
      disabled={isErrorAgreement}
      onClick={async () => {
        if (isLock) {
          await blockMobilePhone(
            mobilePhone.mobilePhoneNumberId,
            getMobilePhones,
          );
          return;
        }
        await unbindMobilePhone(
          mobilePhone.mobilePhoneNumberId,
          getMobilePhones,
        );
      }}
    >
      {isLock ? 'Заблокировать номер' : 'Отключить номер'}
    </Button>
  );
};

export default observer(ChangeNumberStatusFooter);
