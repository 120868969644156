import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, Text, defaultTheme, ColorsType } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledInfoText = styled(Text)`
  position: absolute;
  top: 80px;
  min-width: 260px;
`;

export const StyledTabs = styled.div`
  display: block;
  margin-top: 22px;
  margin-bottom: 32px;
`;

export const StyledTabButton = styled(Button)`
  width: 96%;
  margin-bottom: 18px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;

  @media (min-width: ${desktop940}px) {
    width: unset;
  }
`;

export const StyledWarning = styled.div<{ color?: ColorsType }>`
  ${({ color = defaultTheme.colors.backgroundWarning }) => css`
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0 16px;
    background-color: ${color};
    max-width: 720px;
    width: 96%;

    > span {
      max-width: 650px;
      margin-bottom: 16px;
    }
  `}
`;

export const WarningsWrapper = styled.div`
  display: block;
  margin-bottom: 32px;
`;

export const StyledPrice = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;

  div:first-of-type {
    margin-bottom: 8px;

    .colorContainer {
      width: fit-content;
      padding: 12px 16px 12px 6px;
    }
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;
    align-items: center;
  }
`;
