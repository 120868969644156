/** libaries */
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import Image from 'next/image';
import {
  Text,
  H3,
  Select,
  Switcher,
  defaultTheme,
  OutsideClickHelper,
  Tabs,
  Tag,
  TabsStyleTypes,
} from 'cordis-core-ui-planeta';
/** types */
import { CallToActionDeviceProps } from './CallToActionDevice.types';
import {
  CallToActionDeviceTypes,
  DataSelectPurchaseTypesProps,
  PurchaseTypes,
} from '../CallToAction.types';
/** constants */
import {
  TABS_VARIANTS,
  REMOTE_NAME,
  SelectAmountData,
} from '../CallToAction.constants';
/** components */
import CallToActionRow from '../CallToActionRow/CallToActionRow';
/** styles */
import { StyledDevice, StyledSelector } from './CallToActionDevice.style';
import { StyledFeature, StyledLeftColumn } from '../CallToAction.style';
/** utils */
import { calcMonth, formatNumber } from '~/utils/utils';
import { getPromoPrice } from '~/components/Blocks/Shared/Shared.utils';
/** stores */
import { useCallToActionStore } from '../stores/MainCallToAction/useMainCallToActionStore';

/* Заголовок компонента "Wi-Fi роутер" */
const DeviceTitle = (
  enabledDevice,
  setEnabledDevice,
  titleName,
  type,
): JSX.Element => {
  /* Меняет статус активации сервиса */
  const changeDeviceStatus = () => {
    setEnabledDevice(!enabledDevice);
  };

  return (
    <Switcher checked={enabledDevice} onClick={changeDeviceStatus}>
      <Text>
        {titleName}
        {(type === CallToActionDeviceTypes.ROUTER ||
          type === CallToActionDeviceTypes.STB) && <sup>&nbsp;4</sup>}
      </Text>
    </Switcher>
  );
};

/* Дочерний компонент "Оборудование", компонента CallToAction */
const CallToActionDevice: React.FC<CallToActionDeviceProps> = ({
  deviceInfo,
  pricesInfo,
  dataSelectPurchaseTypes,
  /** todo: всегда приходит undefined */
  finishDt,
  selectAmount,
  selectTypes,
  setSelectAmount,
  setSelectTypes,
  setPurchaseDevicePrice,
  enabledDevice,
  setEnabledDevice,
}: CallToActionDeviceProps) => {
  const isRouter = deviceInfo.type === CallToActionDeviceTypes.ROUTER;
  const {
    routerPrices,
    maxPromoRouters,
    getDeviceImg,
  } = useCallToActionStore();
  const {
    leasePeriod,
    name,
    ownershipOneTimeCharge,
    ownershipPriceOn,
    price,
    promoPriceInfos,
    type,
  } = deviceInfo;
  /* Количество оборудования по акции */
  const maxPromoDevice = isRouter ? maxPromoRouters : 0;
  /** Годовая цена */
  const annuity = useMemo(() => {
    if (isRouter) return routerPrices.promoAnnuity;
    return deviceInfo.annuity;
  }, []);
  /* Первоначальный взнос */
  const downPayment = useMemo(() => {
    if (isRouter) return routerPrices.promoDownPayment;
    return deviceInfo.downPayment;
  }, []);
  /* Ссылка на изображение */
  const urlDeviceImg = useMemo(() => {
    return getDeviceImg(deviceInfo.type);
  }, []);
  // Станислав из маркетинга: акции у нас будут только на покупку
  const isAction = useMemo(() => {
    return deviceInfo.price !== pricesInfo.promoPrice;
  }, []);
  // Флаг бесплатности сервиса
  const isFree = price === 0;

  // Флаг возможности приобретения оборудования только через покупку
  const buyOnly = !annuity && !ownershipPriceOn;

  // Флаг бесплатного пользования
  const isFreeOwnership = !isFree && !buyOnly && ownershipPriceOn !== 0;

  // Флаг платного пользования
  const isPaidOwnership = !isFree && !buyOnly && ownershipPriceOn === 0;

  // Флаг отображения табов с ценами за использование и рассрочку
  const isTabsShow =
    !isFree && !buyOnly && selectTypes.label !== PurchaseTypes.PURCHASE;

  // Отображаемая цена
  let showingPrice;

  /* Текущий индекс таба */
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);

  /* Текущее значение таба */
  const [activeTabValue, setActiveTabValue] = React.useState<number>(price);

  /** Вернёт цену покупки при наличии акции */
  const getPurchasePriceWithPromo = (): number => {
    // Количество устройств для покупки
    const deviceAmount = Number(selectAmount.label[0]);
    // Цена устройств без акции
    const devicesCost = price * (deviceAmount - maxPromoDevice);
    let devicesCostPromo = 0;
    if (promoPriceInfos) {
      const routerPromoPrice = getPromoPrice(promoPriceInfos, {
        currentPrice: price,
      }).promoPrice;
      // Цена устройств по акции
      devicesCostPromo = routerPromoPrice * maxPromoDevice;
    }
    return devicesCost + devicesCostPromo;
  };

  /* Изменение отображаемой цены при выборе типа приобретения */
  useEffect(() => {
    switch (selectTypes.label) {
      // Рассрочка
      case PurchaseTypes.ANNUITY:
        showingPrice = activeTabIndex === 0 ? annuity : annuity * 30;
        break;
      // Пользование
      case PurchaseTypes.OWNERSHIP:
        showingPrice =
          activeTabIndex === 0 ? ownershipPriceOn : ownershipPriceOn * 30;
        break;
      // Покупка
      case PurchaseTypes.PURCHASE:
        if (isAction) {
          showingPrice = getPurchasePriceWithPromo();
        } else {
          showingPrice = price * Number(selectAmount.label[0]);
        }
        setPurchaseDevicePrice(showingPrice);
        break;
      default:
        showingPrice = price;
        break;
    }
    setActiveTabValue(Number(showingPrice));
  }, [selectTypes, selectAmount, activeTabIndex]);

  /* Формирует наименование оборудования */
  const generateDeviceNames = (deviceType: CallToActionDeviceTypes) => {
    // Наименование оборудования в заголовке
    let titleName;
    // Наименование оборудования в блоке
    let containerName;
    switch (deviceType) {
      case CallToActionDeviceTypes.STB:
        titleName = 'Телеприставка';
        containerName = `4K-телеприставка\n${name}`;
        break;
      case CallToActionDeviceTypes.REMOTE_CONTROL:
        titleName = 'Пульт для голосового управления';
        containerName = `${REMOTE_NAME}\n${name}`;
        break;
      default:
        titleName = 'Wi-Fi-роутер';
        containerName = `Wi-Fi-роутер\n${name}`;
        break;
    }
    return { titleName, containerName };
  };

  /* Изменяет значение, выбранное в Select с типом приобретения */
  const changeSelectTypes = (option: DataSelectPurchaseTypesProps) => {
    setSelectTypes(option);
    if (!enabledDevice) setEnabledDevice(true);
  };

  /* Изменяет значение, выбранное в Select с типом приобретения */
  const changeSelectAmount = (option: DataSelectPurchaseTypesProps) => {
    setSelectAmount(option);
    if (!enabledDevice) setEnabledDevice(true);
  };

  /* Событие при изменении таба */
  const onChangeTab = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
    if (!enabledDevice) setEnabledDevice(true);
  };

  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  return (
    <CallToActionRow
      title={DeviceTitle(
        enabledDevice,
        setEnabledDevice,
        generateDeviceNames(type).titleName,
        type,
      )}
    >
      <StyledLeftColumn>
        <StyledDevice>
          <div className="imageWrapper">
            <Image
              loader={imageLoader}
              src={urlDeviceImg}
              alt={type}
              layout="fill"
              objectFit="contain"
              quality={100}
            />
          </div>
          <Text color={defaultTheme.colors.shadow}>
            {generateDeviceNames(type).containerName}
          </Text>
        </StyledDevice>
      </StyledLeftColumn>
      <StyledFeature>
        <StyledSelector>
          {isFreeOwnership && (
            <div className="device__selectors">
              <Select
                width="100%"
                onOptionClick={changeSelectTypes}
                value={selectTypes.value}
                outsideClickHelper={OutsideClickHelper()}
                data={dataSelectPurchaseTypes}
              />
              {/* Покупка */}
              {selectTypes.label === PurchaseTypes.PURCHASE && (
                <Select
                  width="106px"
                  onOptionClick={changeSelectAmount}
                  value={selectAmount.value}
                  outsideClickHelper={OutsideClickHelper()}
                  data={SelectAmountData}
                />
              )}
            </div>
          )}
          {isPaidOwnership && (
            <div className="device__selectors">
              <Select
                width="169px"
                onOptionClick={changeSelectTypes}
                value={selectTypes.value}
                outsideClickHelper={OutsideClickHelper()}
                data={dataSelectPurchaseTypes}
              />
              {/* Покупка */}
              {selectTypes.label === PurchaseTypes.PURCHASE && (
                <Select
                  width="106px"
                  onOptionClick={changeSelectAmount}
                  value={selectAmount.value}
                  outsideClickHelper={OutsideClickHelper()}
                  data={SelectAmountData}
                />
              )}
            </div>
          )}
          {isTabsShow && !(isFree || activeTabValue === 0) && (
            <Tabs
              value={TABS_VARIANTS}
              styleType={TabsStyleTypes.SECONDARY}
              onChange={onChangeTab}
              activeTabIndex={activeTabIndex}
            />
          )}
          <span>
            <H3
              color={
                enabledDevice
                  ? defaultTheme.colors.black
                  : defaultTheme.colors.disable
              }
            >
              {/* Отображать "Бесплатно" если оборудование входит в состав продукта или пользование за 0 рублей */}
              {isFree ||
              (activeTabValue === 0 &&
                selectTypes.label === PurchaseTypes.OWNERSHIP)
                ? 'Бесплатно'
                : `${formatNumber(
                    buyOnly ? price : activeTabValue,
                  )}\u00A0\u20bd`}
            </H3>
            {isAction && selectTypes.label === PurchaseTypes.PURCHASE && (
              <Tag
                color={defaultTheme.colors.planeta}
                colorTag={defaultTheme.colors.pink}
              >
                АКЦИЯ
              </Tag>
            )}
          </span>
          {/* Пользование */}
          {!isFree &&
            !buyOnly &&
            selectTypes.label === PurchaseTypes.OWNERSHIP &&
            ownershipOneTimeCharge > 0 && (
              <Text
                color={
                  enabledDevice
                    ? defaultTheme.colors.shadow
                    : defaultTheme.colors.disable
                }
              >
                Единовременный платёж{' '}
                {`${formatNumber(ownershipOneTimeCharge)}\u00A0\u20bd`}
              </Text>
            )}
          {/* Рассрочка */}
          {!isFree &&
            !buyOnly &&
            selectTypes.label === PurchaseTypes.ANNUITY &&
            annuity > 0 && (
              <Text
                color={
                  enabledDevice
                    ? defaultTheme.colors.shadow
                    : defaultTheme.colors.disable
                }
              >
                на {calcMonth(leasePeriod)} месяцев,
                {` ${downPayment}\u00A0\u20bd `}
                первоначальный взнос
              </Text>
            )}
          {/* Покупка */}
          {finishDt && selectTypes.label === PurchaseTypes.PURCHASE && (
            <Text>
              {price > 0
                ? `Акция до ${finishDt}`
                : `Бесплатное подключение до ${finishDt}`}
            </Text>
          )}
        </StyledSelector>
      </StyledFeature>
    </CallToActionRow>
  );
};

export default observer(CallToActionDevice);
