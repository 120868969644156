/** libraries */
import { observer } from 'mobx-react';
import {
  defaultTheme,
  LeadingText,
  SidePage,
  Snoska,
  Text,
} from 'cordis-core-ui-planeta';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';
/** components */
import FillableScale from '~/components/Blocks/Shared/FillableScale/FillableScale';
/** styles */
import { StyledScale } from './styles';
/** utils */
import { getDate, pluralizeAll } from '~/utils/utils';
import { getGBtoMB } from '../../utils';
/** constants */
import { COUNTRY, OPERATING_STATE } from '~/constants/common';
import { UNIT_TYPE_NAME } from '../../constants';

const DetailingTheLeftovers = (): JSX.Element => {
  const {
    pab2cMobileStore: {
      subscriptionsMap,
      isConvergentProduct,
      getStorageByType,
    },
    productHistoryStore: { productHistory },
    contractStateStore: { contractState },
    vacationStore: { isActivated },
  } = useRootStore();
  const {
    isShowDetailingTheLeftovers,
    detailingType,
    detailingSPHeaderText,
    setIsShowDetailingTheLeftovers,
    getFillerColorForStorage,
    getIsRoaming,
    roamingStore: { getRoamingCountriesByPackage },
  } = useMobileStore();

  /** Договор не обслуживается или на каникулах */
  const isSuspendedCondition =
    contractState !== OPERATING_STATE.ON || isActivated;

  const isRoaming = getIsRoaming(detailingType);

  /** Характеристики накопителя */
  const storage = isRoaming ? {} : getStorageByType(detailingType);
  const { currentQuantity, maxQuantity, accrualQuantity } = storage || {};

  /** Накопитель почти израсходован */
  const isLeftMinimumQuantity =
    storage && currentQuantity !== 0 && currentQuantity < accrualQuantity;

  const subscriptions = subscriptionsMap[detailingType] || [];

  const countryNames = isRoaming
    ? getRoamingCountriesByPackage(subscriptions[0].itemId)
    : [];
  const roamingHeader = countryNames.length
    ? `Интернет в роуминге для ${countryNames[0]} и еще ${pluralizeAll(
        countryNames.length - 1,
        COUNTRY,
      )}`
    : '';
  const countryNamesWithoutFirst = countryNames.slice(1).join(', ');

  const count = (item) => {
    const maxValue = item?.maxQuantity ?? item?.startQuantity;
    return (
      <div>
        <LeadingText color={defaultTheme.colors.black}>
          {isRoaming ? getGBtoMB(item?.currentQuantity) : item?.currentQuantity}{' '}
        </LeadingText>
        <Text lineHeight="24px" color={defaultTheme.colors.gray}>
          / {isRoaming ? getGBtoMB(maxValue) : maxValue}{' '}
          {UNIT_TYPE_NAME[detailingType]}
        </Text>
      </div>
    );
  };

  /** Формирует описание для шкалы накопителя */
  const getStorageDescriptionText = () => {
    let text = '';
    if (currentQuantity > maxQuantity)
      text += `Перенос с${'\u00A0'}продукта ${
        productHistory[productHistory.length - 2]?.tariffName
      }. `;
    if (currentQuantity >= maxQuantity && subscriptions.length) {
      text += `Ежедневные пополнения не${'\u00A0'}поступают.`;
    }
    if (currentQuantity === maxQuantity && !subscriptions.length) {
      text += `Накопитель заполнен, ежедневные пополнения не${'\u00A0'}поступают.`;
    }
    if (!isSuspendedCondition && currentQuantity < maxQuantity) {
      text += `Ежедневно ${accrualQuantity}${'\u00A0'}${
        UNIT_TYPE_NAME[detailingType]
      }${'\u00A0'}/${'\u00A0'}день`;
    }

    return text;
  };

  if (!isShowDetailingTheLeftovers) return null;

  return (
    <SidePage
      show={isShowDetailingTheLeftovers}
      headerText={isRoaming ? roamingHeader : detailingSPHeaderText}
      onCloseClick={() => setIsShowDetailingTheLeftovers(false, null)}
      footerContainer={
        isRoaming ? (
          <Snoska>
            Пакеты действуют также в следующих странах:{' '}
            {countryNamesWithoutFirst}
          </Snoska>
        ) : null
      }
    >
      {subscriptions.map((subscription) => (
        <StyledScale key={subscription.id}>
          {count(subscription)}
          <FillableScale
            value={subscription.currentQuantity}
            maxValue={subscription?.startQuantity}
            fillerColor={() =>
              isRoaming
                ? defaultTheme.colors.lightSeaGreen
                : defaultTheme.colors.planeta
            }
          />
          {subscription?.trimDt && (
            <Text lineHeight="24px" color={defaultTheme.colors.gray}>
              Пакет до {getDate(subscription.trimDt, 'd MMMM')}
            </Text>
          )}
        </StyledScale>
      ))}
      {isConvergentProduct && !isRoaming && (
        <StyledScale>
          {count(storage)}
          <FillableScale
            value={currentQuantity}
            maxValue={maxQuantity}
            fillerColor={() =>
              getFillerColorForStorage(
                storage,
                isLeftMinimumQuantity,
                currentQuantity,
              )
            }
          />
          <Text lineHeight="24px" color={defaultTheme.colors.gray}>
            {getStorageDescriptionText()}
          </Text>
        </StyledScale>
      )}
    </SidePage>
  );
};

export default observer(DetailingTheLeftovers);
