/** libraries */
import { useMemo } from 'react';
import { Button, Text } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** styles */
import { StyledVoluntarySuspension } from './styles';
/** constants */
import { desktop500 } from '~/components/Grid/constants';
/** components */
import SuspensionPaymentsList from '../SuspensionPaymentsList/SuspensionPaymentsList';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useOperatingStateModelStore from '../../../ContractState/Components/OperatingStateWizard/store/useOperatingStateStore';
/** interfaces */
import { AllowedTariffProps } from '~/components/Blocks/Templates/ProductSwitcher/interfaces';

/** Добровольная приостановка */
const VoluntarySuspension = (): JSX.Element => {
  const {
    summaryDataStore: { seriesCode },
    allowedTariffStore: { allowedTariff },
  } = useRootStore();
  const { setIsResumeOperatingStateWizardShow } = useOperatingStateModelStore();

  const isDesktop = useMediaQuery({
    query: `(min-width: ${desktop500}px)`,
  });

  const tariff = useMemo(() => {
    return allowedTariff?.length
      ? ((allowedTariff as unknown) as AllowedTariffProps[]).find(
          (item) => item.tariffTo.seriesCode === seriesCode,
        )
      : null;
  }, [allowedTariff, seriesCode]);

  return (
    <StyledVoluntarySuspension>
      <div className="button-block">
        <Button
          onClick={() => {
            setIsResumeOperatingStateWizardShow(true);
          }}
        >{`Восстановить ${isDesktop ? 'обслуживание' : ''}`}</Button>
        <Text lineHeight="24px">Восстановите доступ к услугам</Text>
      </div>
      {tariff && (
        <SuspensionPaymentsList linePriceOff={tariff.tariffTo.linePriceOff} />
      )}
    </StyledVoluntarySuspension>
  );
};

export default observer(VoluntarySuspension);
