import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { StyledFillableScale } from '~/components/Blocks/Shared/FillableScale/style';

export const StyledScale = styled.div`
  margin-bottom: 32px;
  > div:first-of-type {
    margin-bottom: 8px;
    height: 25px;
    span {
      line-height: 25px;
    }
  }
  ${StyledFillableScale} {
    width: 90%;
    margin: 0 0 8px 0;
    .scale {
      border: initial;
      background-color: ${defaultTheme.colors.light};
      margin: 0;
    }
  }
`;
