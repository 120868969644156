/** библиотеки */
import styled from '@emotion/styled';

export const StyledSummarySausage = styled.div`
  display: flex;
  flex-wrap: nowrap;

  .swipe-indicator {
    width: 256px;
    height: 60px;
    cursor: default;
    user-select: none;

    div:last-of-type div {
      border: none;
    }
  }
`;
