/** libraries */
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
/** styles */
import { StyledSelect, StyledTabs } from '../styles';
/** stores */
import useMobileStore from '../../../store/useMobileStore';

const Tabs = (): JSX.Element => {
  const {
    changeNumberStore: {
      numberCategoryNames,
      numberCategorieNamesMobile,
      activeCategoryTabIndex,
      setActiveCategoryTabIndex,
    },
  } = useMobileStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  if (isDesktop940)
    return (
      <StyledTabs
        value={numberCategoryNames}
        onChange={(tabIndex) => setActiveCategoryTabIndex(tabIndex)}
        activeTabIndex={activeCategoryTabIndex}
        width="fit-content"
      />
    );

  return (
    <StyledSelect
      placeholder="Выберите категорию"
      value={
        numberCategorieNamesMobile
          ? ((numberCategorieNamesMobile[activeCategoryTabIndex]
              ?.value as unknown) as string)
          : ''
      }
      data={numberCategorieNamesMobile}
      onOptionClick={(option) => {
        setActiveCategoryTabIndex(option.value);
      }}
      visibleOptionCount={4}
      width="200px"
    />
  );
};

export default observer(Tabs);
