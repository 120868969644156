/** libraries */
import style from '@emotion/styled';
/** constants */
import { desktop940 } from '~/components/Grid/constants';

/** Стилизованная строка с наименованием продукта блока CTA */
export const StyledProduct = style.div`
  margin-bottom: 16px;

  > div:first-of-type {
   display: flex;

    div {
      margin: -4px 4px 5px 0;
    }
  }
  h2, h3 {
    margin: 0;
    white-space: pre-line;
  }
  button {
    display: block;
    margin-top: 16px;
  }

  @media (min-width: ${desktop940}px) {
    margin-bottom: 0;
}
`;
