/** libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
/** components */
import { MobileStoreProvider } from './store/useMobileStore';
import MobileContent from './MobileContent';

/** Блок «РМП. Моя связь»
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=803921992
 */
const Mobile: FC = () => {
  return (
    <MobileStoreProvider>
      <MobileContent />
    </MobileStoreProvider>
  );
};

export default observer(Mobile);
