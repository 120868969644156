import styled from '@emotion/styled';
import { Button } from 'cordis-core-ui-planeta';

export const StyledAddNumber = styled.div`
  display: flex;
  align-items: end;
  margin-left: 32px;
  cursor: pointer;
  white-space: nowrap;

  a {
    margin-bottom: 6px;
    margin-left: 6px;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const StyledNumbers = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 16px;
  }
`;
