import styled from '@emotion/styled';
import { Text, Icon } from 'cordis-core-ui-planeta';

export const StyledText = styled(Text)`
  display: block;
  margin-bottom: 12px;
`;

export const StyledIcon = styled(Icon)`
  svg {
    margin-bottom: 16px;
  }
`;
