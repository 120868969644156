/** библиотеки */
import React, { FC, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { parseISO } from 'date-fns';
import { observer } from 'mobx-react';

/** компоненты библиотеки */
import {
  Button,
  ButtonStyleTypes,
  defaultTheme,
  H3,
  Icons,
  Loader,
  Popup,
  Tabs,
  TabsStyleTypes,
  Tag,
  Text,
} from 'cordis-core-ui-planeta';
import ProductChangeHistory from '../ProductChangeHistory/ProductChangeHistory';
import BasicPackage from '../BasicPackage/BasicPackage';
import FinancialCalculations, {
  MoneyHistoryDataState,
} from '../FinancialCalculations/FinancialCalculations';
import Receipts from '../FinancialCalculations/Receipts';
import DailyWriteOffs from '../DailyWriteOffs/DailyWriteOffs';
import TransformLimitExceeded from '../TransformLimitExceeded/TransformLimitExceeded';

/** типы */
import { ProductCardProps } from './types';
import { TagsProps } from '../types';
import {
  TransformerHistory,
  ReceiptsState,
  MoneyHistoryDataProps,
} from '../FinancialCalculations/interfaces';

/** стили */
import { StyledProductCard } from './style';

/** константы */
import { desktop940 } from '~/components/Grid/constants';
import { DAYS, OPERATING_STATE, SUSPEND_STATE } from '../constants';
import { PRODUCT_CARD_STATE } from './constants';
import { MOUNTHS } from '../../../MyStoriesContest/constants';
import {
  MONTHS_PREPOSITIONAL,
  MONTHS,
} from '../FinancialCalculations/constants';
import { Pab2cSlugs, SERIES_CODE } from '~/constants/common';

/** утилиты */
import { HooksTyping } from '~/utils/typeScriptHelpers';
import { formatNumber, pluralizeAll, getDotAddress } from '~/utils/utils';
import LinkWrapper from '~/components/LinkWrapper';

/** stores */
import { useRootStore } from '~/stores/RootStore';
import { useContractStore } from '../store/useContractStore';
import useAnnualProductRenewalStore from '~/components/Blocks/Shared/AnnualProductRenewalWizard/store/useAnnualProductRenewalStore';

/**
 * Блок «ЛК. Договор». Карточка продукта.
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=597419196
 */
const ProductCard: FC<ProductCardProps> = ({
  pricesCategoryNames,
  processCategoryValue,
}: ProductCardProps) => {
  const {
    authStore: { auth },
    contractInfoStore: {
      promoTariffStateTrimDate,
      promoTariffStateAutoTariffName,
      promoTariffStateAutoDaysLeft,
      promoTariffStateProlongationPrice,
      area,
    },
    contractStateStore: { contractState, suspendCondition },
    summaryDataStore: {
      isMonoProduct,
      seriesCode,
      seriesName,
      marketingGroupName,
      tags,
    },
    prolongationStore: {
      prolongationInfo,
      isLoadingAgreement,
      getAgreement,
      isLoadingProlongationInfo,
    },
    vacationStore: { isOrdered },
  } = useRootStore();
  const {
    marketingCategoryFeedLink,
    productFeedLink,
    isOpenProductCard,
    setIsOpenProductCard,
  } = useContractStore();
  const {
    setIsAnnualProductRenewal,
    setSeriesName,
  } = useAnnualProductRenewalStore();

  /** Индекс активного таба цен */
  const [priceIndex, setPriceIndex]: HooksTyping<number> = useState<number>(0);

  /** Ежедневные списания */
  const [dailyWriteOffs, setDailyWriteOffs] = useState<MoneyHistoryDataProps>(
    null,
  );

  /** Начало периода финансовых расчётов */
  const [
    startOfFinancialSettlementPeriod,
    setStartOfFinancialSettlementPeriod,
  ] = useState<string>('');
  /** Конец периода финансовых расчётов */
  const [
    endOfFinancialSettlementPeriod,
    setEndOfFinancialSettlementPeriod,
  ] = useState<string>('');

  /** Чеки поступления денежных средств */
  const [receipts, setReceipts] = useState<ReceiptsState>(null);

  /** Вычисление ширины экрана */
  const isMinDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  /** Флаг изменения продукта */
  const canChangedContract =
    ![
      OPERATING_STATE.NEW,
      OPERATING_STATE.DREGS,
      OPERATING_STATE.STRAY,
    ].includes(contractState) &&
    seriesCode !== SERIES_CODE.EMPLOYEE &&
    !isMonoProduct;

  /** Состояние договора для промо текста или для просмотра истории */
  const stateForPromo =
    [
      OPERATING_STATE.ON,
      OPERATING_STATE.CLIENT_BLOCK,
      OPERATING_STATE.PROVIDER_BLOCK_DEBT,
    ].includes(contractState) ||
    (suspendCondition?.suspendState === SUSPEND_STATE.order &&
      contractState === OPERATING_STATE.ON);

  /** Окончание действия промо продуктов */
  const isPromoProduct =
    stateForPromo &&
    promoTariffStateTrimDate &&
    promoTariffStateAutoTariffName &&
    !prolongationInfo?.prolongation;

  /** Промо годового продукта  */
  const isAnnualPromo =
    stateForPromo &&
    prolongationInfo?.prolongation?.promoDaysInSeries === 365 &&
    promoTariffStateAutoDaysLeft;

  /** Годовой продукт с разделением платежа  */
  const isSeparatePayAnnual =
    promoTariffStateProlongationPrice !== prolongationInfo?.prolongation?.price;

  /** Кнопка продления продукта */
  const isProlongationButton =
    isAnnualPromo &&
    promoTariffStateAutoDaysLeft <= 180 &&
    !isSeparatePayAnnual;

  /** Дата окончания действия промо продуктов */
  const productExpirationDate = useMemo(() => {
    if (isPromoProduct) {
      const date = new Date(parseISO(promoTariffStateTrimDate));
      return `${date.getDate().toString()} ${
        MOUNTHS[date.getMonth()]
      } ${date.getFullYear().toString()}`;
    }
    return '';
  }, [isPromoProduct]);

  /** Состояние карточки */
  const [cardState, setCardState] = useState<PRODUCT_CARD_STATE>(
    PRODUCT_CARD_STATE.MAIN,
  );

  /** Заголовок popup */
  const popupTitle = () => {
    switch (cardState) {
      case PRODUCT_CARD_STATE.MAIN:
        return `Информация о${'\u00A0'}вашем${'\u00A0'}продукте`;
      case PRODUCT_CARD_STATE.HISTORY:
        return 'История смены продуктов';
      case PRODUCT_CARD_STATE.BASIC_PACKAGE:
        return 'Состав Базового пакета';
      case PRODUCT_CARD_STATE.FINANCE:
        return 'Финансовые расчёты';
      case PRODUCT_CARD_STATE.RECEIPTS:
        return 'Чеки поступления денежных средств';
      case PRODUCT_CARD_STATE.TRANSFORM_LIMIT_EXCEEDED:
        return 'Превышение лимита трансформации';
      case PRODUCT_CARD_STATE.DAILY_WRITE_OFFS:
        /** месяц в предложном падеже */
        const month =
          MONTHS_PREPOSITIONAL[
            (Object.values(MONTHS) as Array<keyof typeof MONTHS>).findIndex(
              (key) => key === dailyWriteOffs.month,
            )
          ];
        return `Ежедневные списания в ${month}`;
      default:
        return '';
    }
  };

  /** Закрытие popup */
  const onClosePopup = () => {
    setIsOpenProductCard(false);
    setCardState(PRODUCT_CARD_STATE.MAIN);
  };

  const showTransformerLimit =
    cardState === PRODUCT_CARD_STATE.TRANSFORM_LIMIT_EXCEEDED;

  /** Для сохранения состояния вкладки "Финансовые расчёты" */
  const [
    moneyHistoryDataState,
    setMoneyHistoryDataState,
  ] = useState<MoneyHistoryDataState>(null);

  /** История трансформера */
  const [transformerHistory, setTransformerHistory] = useState<
    TransformerHistory[]
  >([]);

  /** Превышение лимита трансформера */
  const [TRLimitData, setTRLimitData] = useState<TransformerHistory>(null);

  /** id для состава базового пакета */
  const [basicPackageTariffId, setBasicPackageTariffId] = useState<number>(
    null,
  );

  return (
    <StyledProductCard isTagsLength={!!tags.length}>
      <Popup
        show={isOpenProductCard}
        title={popupTitle()}
        onCloseClick={onClosePopup}
        onOutsideClick={onClosePopup}
        width={(isMinDesktop940 && '832px') || undefined}
        iconToLeftOfHeader={
          [
            PRODUCT_CARD_STATE.HISTORY,
            PRODUCT_CARD_STATE.BASIC_PACKAGE,
            PRODUCT_CARD_STATE.FINANCE,
            PRODUCT_CARD_STATE.TRANSFORM_LIMIT_EXCEEDED,
            PRODUCT_CARD_STATE.DAILY_WRITE_OFFS,
            PRODUCT_CARD_STATE.RECEIPTS,
          ].includes(cardState) ? (
            <Icons.BackIcon />
          ) : null
        }
        onClickIconToLeftOfHeader={() =>
          setCardState(
            // eslint-disable-next-line no-nested-ternary
            cardState === PRODUCT_CARD_STATE.BASIC_PACKAGE ||
              showTransformerLimit
              ? PRODUCT_CARD_STATE.DAILY_WRITE_OFFS
              : [
                  PRODUCT_CARD_STATE.DAILY_WRITE_OFFS,
                  PRODUCT_CARD_STATE.RECEIPTS,
                ].includes(cardState)
              ? PRODUCT_CARD_STATE.FINANCE
              : PRODUCT_CARD_STATE.MAIN,
          )
        }
      >
        {cardState === PRODUCT_CARD_STATE.HISTORY && <ProductChangeHistory />}
        {showTransformerLimit && (
          <TransformLimitExceeded
            TRLimitData={TRLimitData}
            startOfFinancialSettlementPeriod={startOfFinancialSettlementPeriod}
            endOfFinancialSettlementPeriod={endOfFinancialSettlementPeriod}
          />
        )}
        {cardState === PRODUCT_CARD_STATE.BASIC_PACKAGE && (
          <BasicPackage basicPackageTariffId={basicPackageTariffId} />
        )}
        {cardState === PRODUCT_CARD_STATE.FINANCE && (
          <FinancialCalculations
            setCardState={setCardState}
            setDailyWriteOffs={setDailyWriteOffs}
            setStartOfFinancialSettlementPeriod={
              setStartOfFinancialSettlementPeriod
            }
            setEndOfFinancialSettlementPeriod={
              setEndOfFinancialSettlementPeriod
            }
            moneyHistoryDataState={moneyHistoryDataState}
            setMoneyHistoryDataState={setMoneyHistoryDataState}
            setTransformerHistory={setTransformerHistory}
            setReceipts={setReceipts}
          />
        )}
        {/* Чеки поступления денежных средств */}
        {cardState === PRODUCT_CARD_STATE.RECEIPTS && (
          <Receipts {...receipts} />
        )}
        {/* Ежедневные списания */}
        {cardState === PRODUCT_CARD_STATE.DAILY_WRITE_OFFS && (
          <DailyWriteOffs
            data={dailyWriteOffs}
            setCardState={setCardState}
            setStartOfFinancialSettlementPeriod={
              setStartOfFinancialSettlementPeriod
            }
            setEndOfFinancialSettlementPeriod={
              setEndOfFinancialSettlementPeriod
            }
            transformerHistory={transformerHistory}
            setTRLimitData={setTRLimitData}
            setBasicPackageTariffId={setBasicPackageTariffId}
          />
        )}
        {cardState === PRODUCT_CARD_STATE.MAIN && (
          <>
            <div className="product-card__block-wrapper">
              <div className="product-card__left-side">
                <Text
                  className="product-card__left-side__header"
                  lineHeight="24px"
                >
                  Продукт
                </Text>
                <div className="product-card__left-side__tags">
                  {tags?.length > 0 &&
                    tags.map((item: TagsProps) => {
                      return (
                        <Tag
                          key={item.name}
                          color={defaultTheme.colors.planeta}
                          colorTag={defaultTheme.colors.pink}
                        >
                          {item.name.toUpperCase()}
                        </Tag>
                      );
                    })}
                </div>
                <LinkWrapper href={productFeedLink}>
                  <H3 className="product-card__left-side__product-name">
                    {seriesName}
                  </H3>
                </LinkWrapper>
                <LinkWrapper href={marketingCategoryFeedLink}>
                  <Text
                    className="product-card__left-side__marketing-group-name"
                    lineHeight="24px"
                    color={defaultTheme.colors.shadow}
                  >
                    {marketingGroupName}
                  </Text>
                </LinkWrapper>
              </div>
              <div className="product-card__right-side info-block">
                {isLoadingProlongationInfo ? (
                  <Loader small />
                ) : (
                  <>
                    {isAnnualPromo && (
                      <Text
                        className="product-card__right-side__expiration-text"
                        lineHeight="24px"
                      >
                        До окончания срока действия продукта «{seriesName}»
                        осталось{' '}
                        {pluralizeAll(promoTariffStateAutoDaysLeft, DAYS)}
                      </Text>
                    )}
                    {isPromoProduct && (
                      <Text
                        className="product-card__right-side__second-expiration-text"
                        lineHeight="24px"
                      >
                        Продукт действителен до {productExpirationDate} г.
                        <br />
                        {contractState !== OPERATING_STATE.CLIENT_BLOCK &&
                          `Далее будет действовать продукт
                      ${promoTariffStateAutoTariffName}`}
                      </Text>
                    )}
                  </>
                )}
                {(isProlongationButton ||
                  stateForPromo ||
                  contractState === OPERATING_STATE.STRAY) && (
                  <div className="product-card__right-side__button-block">
                    {isProlongationButton && (
                      <Button
                        className="product-card__right-side__button prolongation"
                        onClick={() =>
                          getAgreement(
                            setIsAnnualProductRenewal,
                            seriesName,
                            setSeriesName,
                          )
                        }
                        loading={isLoadingAgreement}
                      >
                        Продлить продукт
                      </Button>
                    )}
                    {canChangedContract && (
                      <>
                        {!isMinDesktop940 && (
                          <LinkWrapper href={Pab2cSlugs.CHANGE_PLAN_SLUG}>
                            <Button
                              className="product-card__right-side__change-button"
                              styleType={ButtonStyleTypes.SECONDARY}
                              disabled={
                                isOrdered ||
                                [
                                  OPERATING_STATE.CLIENT_BLOCK,
                                  OPERATING_STATE.PROVIDER_BLOCK_DEBT,
                                ].includes(contractState)
                              }
                            >
                              Изменить
                            </Button>
                          </LinkWrapper>
                        )}
                        {isMinDesktop940 &&
                          (isOrdered ||
                            [
                              OPERATING_STATE.CLIENT_BLOCK,
                              OPERATING_STATE.PROVIDER_BLOCK_DEBT,
                            ].includes(contractState)) && (
                            <Text
                              lineHeight="24px"
                              color={defaultTheme.colors.disable}
                            >
                              Изменить продукт
                            </Text>
                          )}
                        {isMinDesktop940 &&
                          !isOrdered &&
                          ![
                            OPERATING_STATE.CLIENT_BLOCK,
                            OPERATING_STATE.PROVIDER_BLOCK_DEBT,
                          ].includes(contractState) && (
                            <LinkWrapper href={Pab2cSlugs.CHANGE_PLAN_SLUG}>
                              <Text
                                lineHeight="24px"
                                color={defaultTheme.colors.planeta}
                              >
                                Изменить продукт
                              </Text>
                            </LinkWrapper>
                          )}
                      </>
                    )}
                    {(stateForPromo ||
                      contractState === OPERATING_STATE.STRAY) && (
                      <>
                        {isMinDesktop940 && (
                          <Text
                            className="product-card__right-side__history-button"
                            lineHeight="24px"
                            color={defaultTheme.colors.planeta}
                            onClick={() =>
                              setCardState(PRODUCT_CARD_STATE.HISTORY)
                            }
                          >
                            История смены Продуктов
                          </Text>
                        )}
                        {!isMinDesktop940 && (
                          <Button
                            className="product-card__right-side__button"
                            styleType={ButtonStyleTypes.SECONDARY}
                            onClick={() =>
                              setCardState(PRODUCT_CARD_STATE.HISTORY)
                            }
                          >
                            История смены
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="product-card__block-wrapper">
              <div className="product-card__left-side">
                <Text
                  className="product-card__left-side__header"
                  lineHeight="24px"
                >
                  Плата за продукт
                  <br />
                  {contractState === OPERATING_STATE.NEW && 'при подключении'}
                  {[OPERATING_STATE.DREGS, OPERATING_STATE.STRAY].includes(
                    contractState,
                  ) && 'при возобновлении обслуживания'}
                </Text>
                <div className="product-card__left-side__price-block">
                  <Tabs
                    value={pricesCategoryNames}
                    onChange={setPriceIndex}
                    activeTabIndex={priceIndex}
                    styleType={TabsStyleTypes.SECONDARY}
                  />
                  <H3 className="value" color={defaultTheme.colors.black}>
                    {formatNumber(processCategoryValue[priceIndex])} ₽
                  </H3>
                </div>
                {isMinDesktop940 && (
                  <Text
                    className="product-card__right-side__finance"
                    lineHeight="24px"
                    color={defaultTheme.colors.planeta}
                    onClick={() => setCardState(PRODUCT_CARD_STATE.FINANCE)}
                  >
                    Финансовые расчёты
                  </Text>
                )}
                {!isMinDesktop940 && (
                  <Button
                    className="product-card__right-side__finance"
                    styleType={ButtonStyleTypes.SECONDARY}
                    onClick={() => setCardState(PRODUCT_CARD_STATE.FINANCE)}
                  >
                    Финансовые расчёты
                  </Button>
                )}
              </div>
              <div className="product-card__right-side">
                {!isMonoProduct && (
                  <div className="product-card__right-side__product-property">
                    <Text
                      className="product-card__right-side__product-property__header"
                      lineHeight="24px"
                      color={defaultTheme.colors.shadow}
                    >
                      Пояс обслуживания
                    </Text>
                    <Text lineHeight="24px">{area}</Text>
                  </div>
                )}
                {auth.dotInfo?.buildingName && (
                  <div className="product-card__right-side__product-property">
                    <Text
                      className="product-card__right-side__product-property__header"
                      lineHeight="24px"
                      color={defaultTheme.colors.shadow}
                    >
                      Адрес точки подключения
                    </Text>
                    <Text lineHeight="24px">{getDotAddress(auth.dotInfo)}</Text>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </Popup>
    </StyledProductCard>
  );
};

export default observer(ProductCard);
