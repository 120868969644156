/** libraries */
import { addDays, format } from 'date-fns';
/** constants */
import {
  VACATION_BLOCKS,
  PAB2C_DEVICES_BLOCKS,
  DEFERRED_PAYMENT_BLOCKS,
  SUSPENDED_CONDITION_BLOCKS,
  CONTRACT_INFO_BLOCKS,
  PRODUCT_HISTORY_BLOCKS,
  CONTRACT_STATE_BLOCKS,
  SUMMARY_DATA_BLOCKS,
  TV_DATA_BLOCKS,
  TV_CHANNEL_AND_PACKAGES_DATA_BLOCKS,
  ALLOWED_TARIFF_BLOCKS,
  VOICE_BLOCKS,
  PAYMENT_BLOCKS,
  AUTO_PAYMENT_DATA_BLOCKS,
  SBP_BANKS_BLOCKS,
  SOFTLINE_BLOCKS,
  PROLONGATION_BLOCKS,
  MOBILE_BLOCKS,
} from '~/components/Feed/useBlockDataFetching/config';
import { BLOCK_NAMES, DATE_FROM, SERIES_CODE } from '~/constants/common';
/** stores */
import { useRootStore } from '~/stores/RootStore';

interface ConfigReturnProps {
  condition: boolean;
  blocks: BLOCK_NAMES[];
  action: () => Promise<void>;
}
/** Хук для конфигурации методов и их условий вызова из сторов */
export const useConfig = (blockTemplates: string[]): ConfigReturnProps[] => {
  const {
    pab2cBalanceStore: { pab2cBalance, getBalance },
    pab2cDevicesStore: { pab2cDevicesList, updatePab2cDevices },
    deferredPaymentStore: { isDeferredPayment, getContractTrustedResume },
    contractStateStore: {
      getSuspendCondition,
      getOperatingState,
      suspendCondition,
      contractState,
    },
    contractInfoStore: { contractInfo, getWeburgMeContractInfo },
    productHistoryStore: { productHistory, getTariffHistory },
    summaryDataStore: {
      summaryData,
      tariffId,
      seriesCode,
      getContractTariff,
      getSummariesByTariffIds,
    },
    tvStore: {
      isTVData,
      channelsListData,
      isFetchedTransformerStatus,
      updateChannelsAndPackages,
      getTransformerStatus,
      setIsLoadingChannelsAndPackages,
      getWink,
      getViewControlInfo,
    },
    allowedTariffStore: { allowedTariff, getTariffAllowed },
    pab2cVoiceStore: { getVoiceState, satelliteCode },
    cityStore: { city },
    paymentInfoStore: { getPaymentInfo, isFetched },
    autoPaymentInfoStore: { autoPaymentData, getAutoPaymentData },
    sbpBanksStore: { sbpBanks, getSbpBanks },
    softlineStore: { isFetchedSubscriptionsInfos, getSubscriptionInfos },
    vacationStore: { isVacationInfo, getVacation },
    prolongationStore: { isFetchedProlongationInfo, getProlongation },
    pab2cMobileStore: { getMobileInfo, isMobileInfoFetched },
  } = useRootStore();

  return [
    {
      condition: !isVacationInfo,
      blocks: VACATION_BLOCKS,
      action: getVacation,
    },
    {
      condition: !pab2cBalance,
      blocks: VACATION_BLOCKS,
      action: getBalance,
    },
    {
      condition: !pab2cDevicesList.length,
      blocks: PAB2C_DEVICES_BLOCKS,
      action: updatePab2cDevices,
    },
    {
      condition: !isDeferredPayment,
      blocks: DEFERRED_PAYMENT_BLOCKS,
      action: getContractTrustedResume,
    },
    {
      condition: !suspendCondition,
      blocks: SUSPENDED_CONDITION_BLOCKS,
      action: getSuspendCondition,
    },
    {
      condition: !contractInfo,
      blocks: [...CONTRACT_INFO_BLOCKS],
      action: getWeburgMeContractInfo,
    },
    {
      condition:
        !productHistory?.length &&
        CONTRACT_INFO_BLOCKS.some((item) => blockTemplates.includes(item)),
      blocks: PRODUCT_HISTORY_BLOCKS,
      action: async () => {
        await getTariffHistory(
          DATE_FROM,
          format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        );
      },
    },
    {
      condition: !contractState,
      blocks: CONTRACT_STATE_BLOCKS,
      action: getOperatingState,
    },
    {
      condition: !summaryData,
      blocks: SUMMARY_DATA_BLOCKS,
      action: getSummariesByTariffIds,
    },
    {
      condition: !isTVData || !channelsListData.length,
      blocks: TV_CHANNEL_AND_PACKAGES_DATA_BLOCKS,
      action: async () => {
        setIsLoadingChannelsAndPackages(true);
        const contractTariff =
          !tariffId && !seriesCode ? await getContractTariff() : null;
        const id = tariffId ?? contractTariff.tariffId;
        const series = seriesCode ?? contractTariff.seriesCode;
        if (series === SERIES_CODE.MONO) return;
        updateChannelsAndPackages(id);
        getWink();
        getViewControlInfo();
      },
    },
    {
      condition: !isFetchedTransformerStatus,
      blocks: TV_DATA_BLOCKS,
      action: async () => {
        const series = seriesCode ?? (await getContractTariff()).seriesCode;
        if (series === SERIES_CODE.MONO) return;
        getTransformerStatus();
      },
    },
    {
      condition: !allowedTariff.length,
      blocks: ALLOWED_TARIFF_BLOCKS,
      action: getTariffAllowed,
    },
    {
      condition: !satelliteCode,
      blocks: VOICE_BLOCKS,
      action: async () => getVoiceState(city.satellite),
    },
    {
      condition: !isFetched,
      blocks: PAYMENT_BLOCKS,
      action: getPaymentInfo,
    },
    {
      condition: !autoPaymentData,
      blocks: AUTO_PAYMENT_DATA_BLOCKS,
      action: getAutoPaymentData,
    },
    {
      condition: !sbpBanks.length,
      blocks: SBP_BANKS_BLOCKS,
      action: getSbpBanks,
    },
    {
      condition: !isFetchedSubscriptionsInfos,
      blocks: SOFTLINE_BLOCKS,
      action: getSubscriptionInfos,
    },
    {
      condition: !isFetchedProlongationInfo,
      blocks: PROLONGATION_BLOCKS,
      action: getProlongation,
    },
    {
      condition: !isMobileInfoFetched,
      blocks: MOBILE_BLOCKS,
      action: getMobileInfo,
    },
  ];
};
