/** libraries */
import { observer } from 'mobx-react';
import { defaultTheme, Text } from 'cordis-core-ui-planeta';
/** styles */
import {
  StyledPrice,
  StyledPriceH3,
  StyledPriceInfoBlock,
  StyledPriceLeadingText,
} from '../styles';
/** types */
import { RoamingPrice } from '../../../types';
/** utils */
import { formatNumber } from '~/utils/utils';
/** stores */
import useMobileStore from '../../../store/useMobileStore';

const CountryPrices = (): JSX.Element => {
  const {
    roamingStore: { roamingZoneInfo },
  } = useMobileStore();

  const priceInfoFabric = (pricesInfo: RoamingPrice[], units = '') => {
    return pricesInfo.map((priceInfo) => (
      <StyledPrice isDetailedPrice>
        <StyledPriceLeadingText color={defaultTheme.colors.black}>
          {formatNumber(priceInfo.price)} ₽ {priceInfo.price ? units : ''}
        </StyledPriceLeadingText>
        <Text color={defaultTheme.colors.shadow}>{priceInfo.name}</Text>
      </StyledPrice>
    ));
  };

  return (
    <>
      <StyledPriceH3>Звонки</StyledPriceH3>
      <StyledPriceInfoBlock>
        {priceInfoFabric(
          roamingZoneInfo.voiceTrafficRoamingPricesItems,
          'в минуту',
        )}
      </StyledPriceInfoBlock>
      <StyledPriceH3>SMS</StyledPriceH3>
      <StyledPriceInfoBlock>
        {priceInfoFabric(roamingZoneInfo.smsRoamingPricesItems)}
      </StyledPriceInfoBlock>
    </>
  );
};

export default observer(CountryPrices);
