/** libraries */
import { Instance, types, flow, getParent } from 'mobx-state-tree';
/** api */
import {
  bindMobilePhoneNumber,
  bindTemporaryMobilePhoneNumberForPortation,
  getBindMobilePhoneNumberAgreement,
} from '~/api/apiPab2c';
/** utils */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
/** constants */
import { INITIAL_RESULT } from '~/constants/common';
import { DELIVERY_TYPE } from '../types';
import { IMobileStore } from './MobileStore';

const RequestsStateModel = types.model('State', {
  getBindNumberAgreement: createApiPathModel(
    'GET /Mobile/MobilePhoneNumber/GetBindMobilePhoneNumberAgreement',
  ),
  bindMobilePhoneNumber: createApiPathModel(
    'POST /Mobile/MobilePhoneNumber/BindMobilePhoneNumber',
  ),
  bindTemporaryNumberForPortation: createApiPathModel(
    'POST /Mobile/MobileNumberPortability/InitMobilePhoneNumberPortability',
  ),
});

const ResultModel = types.model('', {
  isResult: (types.boolean, false),
  isCorrect: (types.boolean, false),
  text: types.maybe(types.string),
});

export const BindNewNumberStore = types
  .model('BindNewNumberStore', {
    requestsState: RequestsStateModel,
    isShowBindNewNumberSP: types.boolean,
    isShowBindNewNumberAgreementSP: types.boolean,
    agreement: types.string,
    result: ResultModel,
    bindedPhoneNumberMsisdn: types.string,
    errorType: types.string,
  })
  .views((self) => ({
    get isLoading() {
      return (
        self.requestsState.bindMobilePhoneNumber.isLoading ||
        self.requestsState.bindTemporaryNumberForPortation.isLoading
      );
    },
  }))
  .actions((self) => ({
    setIsShowBindNewNumberSP: (isShow: boolean) => {
      self.isShowBindNewNumberSP = isShow;
    },
    setIsShowBindNewNumberAgreementSP: (isShow: boolean) => {
      self.isShowBindNewNumberAgreementSP = isShow;
    },
    getBindNumberAgreement: flow(function* (mobilePhoneNumberId: number) {
      self.requestsState.getBindNumberAgreement.reset();
      self.requestsState.getBindNumberAgreement.setLoading();
      try {
        const res = yield getBindMobilePhoneNumberAgreement();
        self.agreement = res;
        self.requestsState.getBindNumberAgreement.setSuccess();
      } catch (e) {
        self.result = {
          isResult: true,
          isCorrect: false,
          text: '',
        };
        const error = JSON.parse(e.errorMessage);
        self.errorType = error.Type;
        self.isShowBindNewNumberAgreementSP = false;
        self.requestsState.getBindNumberAgreement.setFail();
        throw e;
      }
    }),
    bindMobilePhoneNumber: flow(function* (
      mobilePhoneNumberId: number,
      deliveryType: DELIVERY_TYPE,
      deliveryAddress: string,
      msisdn: string,
    ) {
      self.requestsState.bindMobilePhoneNumber.reset();
      self.requestsState.bindMobilePhoneNumber.setLoading();
      try {
        yield bindMobilePhoneNumber(
          mobilePhoneNumberId,
          deliveryType,
          deliveryAddress,
        );
        self.bindedPhoneNumberMsisdn = msisdn;
        self.result = {
          isResult: true,
          isCorrect: true,
          text: '',
        };
        self.requestsState.bindMobilePhoneNumber.setSuccess();
      } catch (e) {
        self.result = {
          isResult: true,
          isCorrect: false,
          text: '',
        };
        self.requestsState.bindMobilePhoneNumber.setFail();
        throw e;
      } finally {
        self.isShowBindNewNumberAgreementSP = false;
      }
    }),
    bindTemporaryNumberForPortation: flow(function* (
      mobilePhoneNumber: string,
      deliveryType: DELIVERY_TYPE,
      deliveryAddress: string,
    ) {
      self.requestsState.bindTemporaryNumberForPortation.reset();
      self.requestsState.bindTemporaryNumberForPortation.setLoading();
      try {
        const res = yield bindTemporaryMobilePhoneNumberForPortation(
          mobilePhoneNumber,
          deliveryType,
          deliveryAddress,
        );
        self.bindedPhoneNumberMsisdn = res;
        self.result = {
          isResult: true,
          isCorrect: true,
          text: '',
        };
        self.requestsState.bindTemporaryNumberForPortation.setSuccess();
      } catch (e) {
        self.result = {
          isResult: true,
          isCorrect: false,
          text: '',
        };
        const error = JSON.parse(e.errorMessage);
        self.errorType = error.Type;
        self.requestsState.bindTemporaryNumberForPortation.setFail();
        throw e;
      }
    }),
    resetBindNewNumberStore: () => {
      self.isShowBindNewNumberSP = false;
      self.isShowBindNewNumberAgreementSP = false;
      self.agreement = '';
      self.result = INITIAL_RESULT;
      self.bindedPhoneNumberMsisdn = '';
      self.errorType = '';
    },
  }))
  .actions((self) => ({
    onCloseClick: flow(function* (): Generator<Promise<void>, void> {
      self.resetBindNewNumberStore();
      if (self.result.isResult && self.result.isCorrect) {
        const {
          changeNumberStore: { getChangeNumberData, resetChangeNumberStore },
        }: IMobileStore = getParent(self);
        yield getChangeNumberData();
        resetChangeNumberStore();
      }
    }),
  }));

export type IBindNewNumberStore = Instance<typeof BindNewNumberStore>;

export const bindNewNumberStoreInstance = {
  requestsState: {
    getBindNumberAgreement: defaultModelState,
    bindMobilePhoneNumber: defaultModelState,
    bindTemporaryNumberForPortation: defaultModelState,
  },
  isShowBindNewNumberSP: false,
  isShowBindNewNumberAgreementSP: false,
  agreement: '',
  result: INITIAL_RESULT,
  bindedPhoneNumberMsisdn: '',
  errorType: '',
};
