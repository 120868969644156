import styled from '@emotion/styled';
import { Squircle } from '@squircle-js/react';

import { defaultTheme, H3, Text } from 'cordis-core-ui-planeta';
import { desktop1100, desktop940 } from '~/components/Grid/constants';
import { McBannerTemplates } from '../../constants';
import CONFIG from '../../config';

const { storageAnimation } = CONFIG[McBannerTemplates.storage].images;

export const StyledStorage = styled(Squircle)`
  height: 400px;
  padding: 40px 0 0 32px;
  background-color: ${defaultTheme.colors.white};
  border-radius: 60px;
`;

export const StyledH3 = styled(H3)`
  max-width: 349px;
  margin: 15px 0 35px;

  @media (min-width: ${desktop940}px) {
    margin: 15px 0;
  }

  @media (min-width: ${desktop1100}px) {
    margin: 15px 0 35px;
  }
`;

export const StyledText = styled(Text)`
  max-width: 311px;
  line-height: 24px;
`;

export const StyledAnimation = styled.div`
  height: 150px;
  background: url(${storageAnimation}) no-repeat;
  border-radius: 60px;
  margin-left: -11px;
`;
