/** libraries */
import { observer } from 'mobx-react';
/** styles */
import { StyledRadioList } from '../../style';
import { StyledLeadingText, StyledRadio } from './styles';
/** utils */
import { maskPhone } from '~/utils/utils';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';

const ActivationPoints = (): JSX.Element => {
  const {
    pab2cMobileStore: { notActivatedSims },
  } = useRootStore();

  const {
    simCardActivationStore: { setSelectedSimCardId, isDocumentVerified },
    passportDataConfirmationStore: { openPassportDataConfirmation },
    changeNumberStore: { setIsShowAvailableNumbersSp },
  } = useMobileStore();

  const currentPointsCounter =
    (isDocumentVerified ? 1 : 0) +
    notActivatedSims.filter((item) => item.msisdn).length;

  const maxPointsCounter = notActivatedSims.length + 1;

  return (
    <>
      <StyledLeadingText>
        выполнено {currentPointsCounter} из {maxPointsCounter}
      </StyledLeadingText>
      <StyledRadioList>
        <StyledRadio
          checked={isDocumentVerified}
          onClick={(e) => {
            openPassportDataConfirmation();
            e.preventDefault();
          }}
          title="Подтвердить паспортные данные"
          description="С помощью Госуслуг"
        />
        {/* <StyledRadio
          checked={isCheckedAgreement}
          onChange={() => {
            // if (isCheckedAgreement) return;
          }}
          title="Подтвердить соглашение"
          description="Прочитайте условия предоставления мобильной связи"
        /> */}
        {notActivatedSims.length > 0 &&
          notActivatedSims.map((notActivatedMobilePhone) => (
            <StyledRadio
              checked={!!notActivatedMobilePhone.msisdn}
              onChange={() => {
                if (notActivatedMobilePhone.msisdn) return;
                setIsShowAvailableNumbersSp(true);
                setSelectedSimCardId(notActivatedMobilePhone.simCardId);
              }}
              title="Подключить номер к SIM"
              description={
                notActivatedMobilePhone.msisdn
                  ? `Выбран номер ${maskPhone(notActivatedMobilePhone.msisdn)}`
                  : 'Выберите новый номер'
              }
              key={notActivatedMobilePhone.simCardId}
            />
          ))}
      </StyledRadioList>
    </>
  );
};

export default observer(ActivationPoints);
