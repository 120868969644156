/** libraries */
import { observer } from 'mobx-react';
/** components */
import Portal from '~/components/Portal/Portal';
import MyNumbersSidePage from './MyNumbers/MyNumbersSidePage';
import DetailingTheLeftovers from './DetailingTheLeftovers/DetailingTheLeftovers';
import ChangeNumberStatus from './ChangeNumberStatus/ChangeNumberStatus';
import ChangeName from './ChangeName/ChangeName';
import ChoosingTheReceivingMethod from './ChoosingTheReceivingMethod/ChoosingTheReceivingMethod';
import SIMCardReplacement from './SIMCardReplacement/SIMCardReplacement';
import AvailableNumbers from './AvailableNumbers/AvailableNumbers';
import ChoosingTheActivationMethod from './ChoosingTheActivationMethod/ChoosingTheActivationMethod';
import SpecifyTheTransferNumber from './SpecifyTheTransferNumber/SpecifyTheTransferNumber';
import ChangeNumber from './ChangeNumber/ChangeNumber';
import SimCardActivation from './SimCardActivation/SimCardActivation';
import PassportDataConfirmation from './PassportDataConfirmations/PassportDataConfirmation';
import BindNewNumber from './BindNewNumber/BindNewNumber';
import BindNewNumberAgreement from './BindNewNumberAgreement/BindNewNumberAgreement';
import BindSubscription from './BindSubscription/BindSubscription';
import BindSubscriptionAgreement from './BindSubscriptionAgreement/BindSubscriptionAgreement';
import ToggleRoaming from './ToggleRoaming/ToggleRoaming';
import Roaming from './Roaming/Roaming';

const SidePages = (): JSX.Element => {
  return (
    <>
      <Portal wrapperId="portalPrimary">
        {/* Активировать SIM */}
        <SimCardActivation />
        {/* Изменить статус номера */}
        <ChangeNumberStatus />
        <ChangeName />
        {/* Выбор способа получения */}
        <ChoosingTheReceivingMethod />
        {/* Заказ замены SIM */}
        <SIMCardReplacement />
        <ChangeNumber />
        <AvailableNumbers />
        {/* Выбор способа подключения */}
        <ChoosingTheActivationMethod />
        {/* Указать номер переноса */}
        <SpecifyTheTransferNumber />
        {/* Подтверждение паспортных данных */}
        <PassportDataConfirmation />
        <BindNewNumberAgreement />
        <ToggleRoaming />
        <Roaming />
        <BindSubscription />
        <BindSubscriptionAgreement />
        {/* Детализация остатков */}
        <DetailingTheLeftovers />
      </Portal>
      <Portal>
        {/* Мои номера */}
        <MyNumbersSidePage />
        <BindNewNumber />
      </Portal>
    </>
  );
};

export default observer(SidePages);
