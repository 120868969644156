/** libraries */
import { useMediaQuery } from 'react-responsive';
import { H2, H3, LeadingText } from 'cordis-core-ui-planeta';
/** styles */
import {
  StyledHeader,
  StyledInfo,
  StyledRangeOfServices,
  StyledTabs,
} from './styles';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import { McBannerTemplates } from '../../constants';
/** config */
import CONFIG from '../../config';

const RangeOfServices = (): JSX.Element => {
  const { header, info, tabs } = CONFIG[McBannerTemplates.services].text;
  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  return (
    <StyledRangeOfServices>
      <StyledHeader>
        {isDesktop940 ? <H2>{header}</H2> : <H3>{header}</H3>}
        {isDesktop940 ? (
          <StyledTabs>
            {tabs.map((item) => (
              <LeadingText key={item}>{item}</LeadingText>
            ))}
          </StyledTabs>
        ) : (
          <LeadingText>{tabs[0]}</LeadingText>
        )}
      </StyledHeader>
      {isDesktop940 ? (
        <LeadingText>{info}</LeadingText>
      ) : (
        <StyledInfo>{info}</StyledInfo>
      )}
      {!isDesktop940 && (
        <StyledTabs>
          <LeadingText>{tabs[2]}</LeadingText>
          <LeadingText>{tabs[1]}</LeadingText>
        </StyledTabs>
      )}
    </StyledRangeOfServices>
  );
};

export default RangeOfServices;
