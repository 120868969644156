import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { desktop500 } from '~/components/Grid/constants';

export const StyledMethodOfReceiptForm = styled.div``;

export const StyledTabs = styled.div`
  margin-bottom: 16px;

  .select-wrapper {
    width: 100%;
  }

  .select-overlay {
    width: 100% !important;
  }

  > div {
    display: block;
  }

  div[data-test-tabs] {
    display: inline-flex;
    div {
      span {
        color: ${defaultTheme.colors.shadow};
        font-weight: 700;
      }
    }
    .active {
      padding: 10px 32px;
      span {
        color: white;
        font-weight: 700;
      }
    }
  }

  @media (min-width: ${desktop500}px) {
    .select-wrapper {
      width: 328px;
    }
  }
`;

export const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  > span:first-of-type {
    margin-bottom: 16px;
  }

  .select-wrapper {
    width: 100%;
  }

  @media (min-width: ${desktop500}px) {
    .select-wrapper {
      width: 328px;
    }
  }
`;

export const StyledAddress = styled.div`
  display: flex;
  flex-wrap: wrap;

  > ${StyledBlock} {
    width: 100%;
  }

  @media (min-width: ${desktop500}px) {
    > ${StyledBlock} {
      width: 328px;
      margin-right: 16px;
    }
  }
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;

  ${StyledBlock} {
    width: 48%;
  }

  @media (min-width: ${desktop500}px) {
    ${StyledBlock} {
      width: 120px;
      margin-right: 16px;
    }
  }
`;

export const StyledWarning = styled.div`
  padding: 16px;
  background-color: ${defaultTheme.colors.light};
`;
