/** libraries */
import { useMediaQuery } from 'react-responsive';
import { defaultTheme } from 'cordis-core-ui-planeta';
/** styles */
import {
  StyledCircles,
  StyledGigabyte,
  StyledH3,
  StyledMinutes,
  StyledMinutesAmount,
  StyledMinutesLabel,
  StyledSquircle,
  StyledInfo,
} from './styles';
/** constants */
import { McBannerTemplates } from '../../constants';
/** config */
import CONFIG from '../../config';
/** stores */
import { desktop940 } from '~/components/Grid/constants';

const Bonus = (): JSX.Element => {
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const {
    header,
    info,
    circles: { gigabytes, minutes },
  } = CONFIG[McBannerTemplates.bonus].text;

  return (
    <StyledSquircle cornerRadius={isDesktop940 ? 86 : 70} cornerSmoothing={1}>
      <StyledH3 color={defaultTheme.colors.white}>{header}</StyledH3>
      <StyledInfo color={defaultTheme.colors.white}>{info}</StyledInfo>
      <StyledCircles>
        <StyledGigabyte color={defaultTheme.colors.white}>
          {gigabytes.label}
        </StyledGigabyte>
        <StyledMinutes>
          <StyledMinutesAmount color={defaultTheme.colors.white}>
            {minutes.amount}
          </StyledMinutesAmount>
          <StyledMinutesLabel color={defaultTheme.colors.white}>
            {minutes.label}
          </StyledMinutesLabel>
        </StyledMinutes>
      </StyledCircles>
    </StyledSquircle>
  );
};

export default Bonus;
