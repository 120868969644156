/** libraries */
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { ButtonStyleTypes, defaultTheme } from 'cordis-core-ui-planeta';
/** components */
import {
  StyledButton,
  StyledH2,
  StyledNumber,
  StyledSquircle,
  StyledText,
} from './styles';
import LinkWrapper from '~/components/LinkWrapper';
/** constants */
import { MOBILE_SLUG, Pab2cSlugs } from '~/constants/common';
import { QP_PAB2C_MOBILE } from '~/components/Blocks/Templates/Pab2c/Mobile/constants';
import { McBannerTemplates } from '../../constants';
import { desktop940 } from '~/components/Grid/constants';
/** config */
import CONFIG from '../../config';
/** stores */
import { useRootStore } from '~/stores/RootStore';

const MagicalNumbers = (): JSX.Element => {
  const {
    authStore: { isAuth },
  } = useRootStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const { header, info, button, exampleNumber } = CONFIG[
    McBannerTemplates.magicalNumber
  ].text;

  const buttonLink = isAuth
    ? `/${Pab2cSlugs.MAIN_SLUG}?${QP_PAB2C_MOBILE.SELECT_NUMBER}=true`
    : `/${MOBILE_SLUG}`;

  return (
    <StyledSquircle cornerRadius={isDesktop940 ? 86 : 70} cornerSmoothing={1}>
      <StyledH2 color={defaultTheme.colors.white}>{header}</StyledH2>
      <StyledText color={defaultTheme.colors.white}>{info}</StyledText>
      <StyledNumber color={defaultTheme.colors.white}>
        {exampleNumber}
      </StyledNumber>
      <LinkWrapper href={buttonLink}>
        <StyledButton
          styleType={ButtonStyleTypes.DARK_BACKGROUND}
          border={`2px, solid, ${defaultTheme.colors.white}`}
          background="unset"
        >
          {button}
        </StyledButton>
      </LinkWrapper>
    </StyledSquircle>
  );
};

export default observer(MagicalNumbers);
