/** libraries */
import { observer } from 'mobx-react';
import { Icons, H2, H3 } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** styles */
import { StyledIcon } from '../../../style';
/** constants */
import { DEFAULT_ERROR } from '~/constants/common';
import { desktop1100 } from '~/components/Grid/constants';

const ErrorHandler = (): JSX.Element => {
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  return (
    <>
      <StyledIcon icon={<Icons.NotOkBigIcon />} />
      {isDesktop1100 ? <H2>{DEFAULT_ERROR}</H2> : <H3>{DEFAULT_ERROR}</H3>}
    </>
  );
};

export default observer(ErrorHandler);
