/** libraries */
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
import {
  Button,
  ButtonIconPosition,
  ButtonStyleTypes,
  defaultTheme,
  H2,
  H3,
  Icons,
  Tag,
  Text,
} from 'cordis-core-ui-planeta';
/** components */
import CallToActionRow from '../CallToActionRow/CallToActionRow';
/** styles */
import { StyledProduct } from './CallToActionProduct.styles';
import { StyledFeature, StyledLeftColumn } from '../CallToAction.style';
/** constants */
import { desktop1100, desktop940 } from '~/components/Grid/constants';
import { ConnectionMethod } from '~/constants/common';
/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
/** stores */
import { useCallToActionStore } from '../stores/MainCallToAction/useMainCallToActionStore';
import useCallToActionStateStore from '../stores/CallToActionState/useCallToActionStateStore';

/* Дочерний компонент "Продукт", компонента CallToAction */
const CallToActionProduct: React.FC = () => {
  const {
    marketingGroupName,
    seriesName,
    marketingText,
    tags,
    isAnnual,
    customHeader,
    migrationCostFirstPrice,
    connectionMethod,
    isSwitchOffTV,
    routerInfo,
    STBInfo,
    remoteInfo,
    routerPrices,
    STBPrices,
    remotePrices,
  } = useCallToActionStore();
  const {
    showSettings,
    setShowSettings,
    setEnabledTimeShift,
    setEnabledRouter,
    setEnabledRemote,
    setEnabledSTB,
    isFreeDevice,
  } = useCallToActionStateStore();

  /* Вычисление ширины экрана */
  const isDesktopBetween940And1100 = useMediaQuery({
    query: `(min-width: ${desktop940}px) and (max-width: ${desktop1100}px)`,
  });

  /* Флаг выключения тумблера роутера на продукте */
  const isSwitchOffRouter =
    !routerInfo || !isFreeDevice(routerPrices, routerInfo.ownershipPriceOn);

  /* Флаг выключения тумблера телеприставки на продукте */
  const isSwitchOffSTB =
    !STBInfo || !isFreeDevice(STBPrices, STBInfo.ownershipPriceOn);

  /* Флаг выключения тумблера пульта на продукте */
  const isSwitchOffRemote =
    !remoteInfo || !isFreeDevice(remotePrices, remoteInfo.ownershipPriceOn);

  /* Показывает настройки продукта */
  const openSettings = () => {
    setShowSettings(true);
    setEnabledTimeShift(!isSwitchOffTV);
    setEnabledRouter(!isSwitchOffRouter);
    setEnabledSTB(!isSwitchOffSTB);
    setEnabledRemote(!isSwitchOffRemote);
  };

  const showBenefit: boolean =
    connectionMethod === ConnectionMethod.LAN &&
    !isAnnual &&
    migrationCostFirstPrice > 0 &&
    showSettings;

  const header = customHeader ? parseHtml(customHeader) : seriesName;

  return (
    <CallToActionRow title={<Text>Продукт</Text>}>
      <StyledLeftColumn>
        <StyledProduct>
          {tags?.length > 0 && (
            <div>
              {tags.map((item) => (
                <Tag
                  // имя тега уникально, повторений не будет
                  key={item.name}
                  color={defaultTheme.colors.planeta}
                  colorTag={defaultTheme.colors.pink}
                >
                  {item.name.toUpperCase()}
                </Tag>
              ))}
            </div>
          )}
          {isDesktopBetween940And1100 ? <H3>{header}</H3> : <H2>{header}</H2>}
          {/* yaspeller ignore:start */}
          <Text color={defaultTheme.colors.gray}>
            {showBenefit
              ? `Стоимость подключения\nк продукту ${migrationCostFirstPrice}\u0020\u20bd`
              : marketingGroupName}
          </Text>
          {!showSettings && (
            <Button
              styleType={ButtonStyleTypes.OPENED}
              icon={<Icons.OpenIcon />}
              iconPosition={ButtonIconPosition.RIGHT}
              onClick={openSettings}
            >
              Настроить
            </Button>
          )}
        </StyledProduct>
      </StyledLeftColumn>
      <StyledFeature>
        <Text>{parseHtml(marketingText)}</Text>
      </StyledFeature>
    </CallToActionRow>
  );
};

export default observer(CallToActionProduct);
