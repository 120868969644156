/** libraries */
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import {
  Autocomplete,
  Input,
  Radio,
  Select,
  Tabs,
  TabsStyleTypes,
  Text,
} from 'cordis-core-ui-planeta';
/** styles */
import {
  StyledAddress,
  StyledBlock,
  StyledMethodOfReceiptForm,
  StyledRow,
  StyledTabs,
  StyledWarning,
} from './styles';
import { StyledRadioList } from '../../style';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';
/** interfaces */
import { AutocompleteItem } from '~/components/ConnectionWizard/ConnectionWizard.types';
/** utils */
import { useDebouncedEffect } from '~/utils/useDebouncedEffect';
/** api */
import { searchBuildingName, searchStreetName } from '~/api/api';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
import {
  DELIVERY_TYPE_TABS,
  DELIVERY_TYPE_TABS_LABELS,
  DELIVERY_TYPE_TABS_VALUES,
} from '../../constants';

const MethodOfReceiptForm = (): JSX.Element => {
  const {
    cityStore: { cities, officeInfos },
  } = useRootStore();
  const {
    methodOfReceiptFormStore: {
      activeTabIndex,
      selectedCity,
      selectedStreet,
      selectedHouse,
      streetLoading,
      houseLoading,
      streets,
      buildings,
      street,
      house,
      apartment,
      checkedOfficeId,
      setActiveTabIndex,
      setSelectedCity,
      setSelectedStreet,
      setSelectedHouse,
      setStreetLoading,
      setHouseLoading,
      setStreets,
      setBuildings,
      setCheckedOfficeId,
      onStreetInputChange,
      onHouseChange,
      apartmentChange,
      filteredOfficeInfos,
    },
  } = useMobileStore();

  /** Список городов */
  const cityData = useMemo(() => {
    return cities.map((item) => {
      return {
        label: item.name,
        value: item.name,
      };
    });
  }, [cities]);

  // чтобы запросы не отправлялись после ввода каждой буквы, сделаем debounce для улицы и дома
  useDebouncedEffect(
    async () => {
      if (street.length > 2) {
        setStreetLoading(true);
        const streetNames = await searchStreetName(selectedCity.label, street);
        const mappedNames = streetNames.map((item, index) => ({
          value: index.toString(),
          label: item,
        }));
        setStreets(mappedNames);
        setStreetLoading(false);
        const match = mappedNames.find(
          (item) => item.label.toLowerCase() === street.toLowerCase(),
        );
        if (match) setSelectedStreet(match);
      }
    },
    300,
    [street],
  );

  useDebouncedEffect(
    async () => {
      if (house.length > 0) {
        setHouseLoading(true);
        const buildingNames = await searchBuildingName(
          selectedCity.label,
          street,
          house,
        );
        const mappedNames = buildingNames.map((building, index) => ({
          value: index.toString(),
          label: building,
        }));
        setBuildings(mappedNames);
        setHouseLoading(false);
        const match = mappedNames.find(
          (item) => item.label.toLowerCase() === house.toLowerCase(),
        );
        if (match) setSelectedHouse(match);
      }
    },
    300,
    [house],
  );

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  return (
    <StyledMethodOfReceiptForm>
      <StyledTabs>
        {isDesktop940 ? (
          <Tabs
            value={DELIVERY_TYPE_TABS_LABELS}
            onChange={(tabIndex) => setActiveTabIndex(tabIndex)}
            activeTabIndex={activeTabIndex}
            styleType={TabsStyleTypes.BLACK}
          />
        ) : (
          <Select
            value={
              (DELIVERY_TYPE_TABS_VALUES[activeTabIndex] as unknown) as string
            }
            data={DELIVERY_TYPE_TABS}
            onOptionClick={(option) => {
              setActiveTabIndex(option.value);
            }}
            visibleOptionCount={4}
          />
        )}
      </StyledTabs>
      <StyledBlock>
        <Text lineHeight="24px">Город получения</Text>
        <Select
          placeholder="Выберите город получения"
          onOptionClick={(option) => setSelectedCity(option)}
          visibleOptionCount={4}
          value={selectedCity ? selectedCity.value : ''}
          data={cityData}
        />
      </StyledBlock>
      {activeTabIndex === 1 ? (
        <>
          <StyledAddress>
            <StyledBlock>
              <Text lineHeight="24px">Улица</Text>
              <Autocomplete
                className="wizard__input wizard__input-street"
                items={streets}
                text={street}
                value={selectedStreet}
                loading={streetLoading}
                onInputChange={(val: string) => onStreetInputChange(val)}
                onChange={(item: AutocompleteItem): void => {
                  // избегаем лишний эффект и лишний запрос
                  if (item.label === street) return;
                  onStreetInputChange(item.label);
                }}
              />
            </StyledBlock>
            <StyledRow>
              <StyledBlock>
                <Text lineHeight="24px">Дом</Text>
                <Autocomplete
                  className="wizard__input wizard__input-house"
                  items={buildings}
                  text={house}
                  value={selectedHouse}
                  loading={houseLoading}
                  onInputChange={(val: string): void => onHouseChange(val)}
                  onChange={(item: AutocompleteItem): void => {
                    // избегаем лишний эффект и лишний запрос
                    if (item.label === house) return;
                    onHouseChange(item.label);
                  }}
                  searchSensitivity={1}
                  disabled={!street.length}
                  width="100%"
                />
              </StyledBlock>
              <StyledBlock>
                <Text lineHeight="24px">Квартира</Text>
                <Input
                  className="wizard__input wizard__input-flat"
                  width="100%"
                  onChange={apartmentChange}
                  value={apartment}
                  disabled={!street.length || !house.length}
                />
              </StyledBlock>
            </StyledRow>
          </StyledAddress>
          <StyledWarning>
            <Text lineHeight="24px">
              Менеджер свяжется с вами для согласования даты и времени доставки.
            </Text>
          </StyledWarning>
        </>
      ) : (
        <StyledRadioList>
          {filteredOfficeInfos(officeInfos).map((item) => {
            return (
              <Radio
                checked={checkedOfficeId === item.id}
                onChange={() => setCheckedOfficeId(item.id)}
                title={`${item.streetName}, ${item.buildingNumber}`}
                description={item.workTime}
                key={item.id}
              />
            );
          })}
        </StyledRadioList>
      )}
    </StyledMethodOfReceiptForm>
  );
};

export default observer(MethodOfReceiptForm);
