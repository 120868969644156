/** Libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
import { Icon, Icons, TabsStyleTypes } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
/** styles */
import { StyledH3, StyledHeader, StyledTabs, StyledTitle } from './style';
/** components */
import InfoPanel from './InfoPanel';
import HeaderDroplist from './HeaderDroplist';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';

const Header: FC = () => {
  const {
    pab2cMobileStore: { connectedRoamingTypes },
  } = useRootStore();
  const { tabIndex, setTabIndex } = useMobileStore();
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  return (
    <StyledHeader>
      <StyledTitle>
        <Icon icon={<Icons.MobileIcon />} />
        {!!connectedRoamingTypes.length && isDesktop940 ? (
          <StyledTabs
            value={['Моя связь', 'Роуминг']}
            onChange={(index: number) => setTabIndex(index)}
            activeTabIndex={tabIndex}
            styleType={TabsStyleTypes.BLACK}
          />
        ) : (
          <StyledH3>Моя связь</StyledH3>
        )}
      </StyledTitle>
      {isDesktop940 && <InfoPanel />}
      {!isDesktop940 && <HeaderDroplist />}
    </StyledHeader>
  );
};

export default observer(Header);
