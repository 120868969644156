import styled from '@emotion/styled';
import {
  H3,
  Button,
  LeadingText,
  Text,
  Radio,
  defaultTheme,
  H2,
} from 'cordis-core-ui-planeta';
import { desktop940, desktop475 } from '~/components/Grid/constants';

export const StyledButton = styled(Button)`
  width: 100%;

  @media (min-width: ${desktop940}px) {
    width: unset;
  }
`;

export const ImageWrapper = styled.div`
  margin: -51px 0 -12px -32px;
  position: relative;
  z-index: 1000;
  display: flex;
  justify-content: center;

  @media (min-width: ${desktop940}px) {
    display: block;
  }
`;

export const StyledLeadingText = styled(LeadingText)`
  display: block;
  margin-bottom: 15px;
`;

export const StyledH3 = styled(H3)`
  margin-bottom: 18px;
`;

export const StyledContainer = styled.div`
  margin-top: -33px;
`;

export const StyledDescriptionText = styled(Text)`
  margin-bottom: 31px;
`;

export const StyledSubscriptionList = styled.div`
  display: block;
  margin-bottom: 21px;

  @media (min-width: ${desktop475}px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledRadio = styled(Radio)`
  margin-bottom: 12px;

  @media (min-width: ${desktop475}px) {
    max-width: 49%;
  }
`;

export const StyledPrice = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-top: 12px;

  div:first-of-type {
    margin-bottom: 12px;

    .colorContainer {
      width: fit-content;
      padding: 12px 16px 12px 6px;
    }
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;
    align-items: center;

    div:first-of-type {
      margin-right: 12px;
      margin-bottom: 0;
    }
  }
`;

export const StyledWarning = styled.div`
  display: flex;
  flex-direction: column;
  > span {
    padding: 16px;
    max-width: 576px;
    background-color: ${defaultTheme.colors.backgroundWarning};
  }
`;

export const StyledH2 = styled(H2)`
  margin-top: 33px;
`;

export const StyledRoamingText = styled(Text)`
  margin-top: 31px;
`;
