/** libraries */
import { observer } from 'mobx-react';
import {
  ButtonIconPosition,
  ButtonStyleTypes,
  Icons,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
/** styles */
import {
  StyledAutocomplete,
  StyledCountryButton,
  StyledIcon,
  StyledLoader,
  StyledText,
} from '../styles';
/** stores */
import useMobileStore from '../../../store/useMobileStore';

const CountriesList = (): JSX.Element => {
  const {
    roamingStore: {
      filteredCountries,
      searchValue,
      setSearchValue,
      isLoading,
      setSelectedCountryCode,
    },
  } = useMobileStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const countryButtons = () => {
    return filteredCountries.map(({ code, name, icon }) => {
      return (
        <StyledCountryButton
          styleType={ButtonStyleTypes.ACTION}
          icon={<StyledIcon dangerouslySetInnerHTML={{ __html: icon }} />}
          secondIcon={<Icons.LeftArrowIcon />}
          secondIconPosition={ButtonIconPosition.RIGHT}
          key={code}
          onClick={() => setSelectedCountryCode(code)}
        >
          {name}
        </StyledCountryButton>
      );
    });
  };

  return (
    <>
      {isDesktop940 && (
        <StyledText lineHeight="24px">
          Чтобы найти нужную информацию, выберите страну из&nbsp;списка или
          воспользуйтесь поиском.
        </StyledText>
      )}
      <StyledAutocomplete
        showSearchIcon
        items={[]}
        text={searchValue}
        placeholder="Например, Турция или Алжир"
        onInputChange={(value) => setSearchValue(value)}
        hideOptions
      />
      {isLoading && <StyledLoader small />}
      {!isLoading && countryButtons()}
    </>
  );
};

export default observer(CountriesList);
