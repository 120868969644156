import { ReactNode, useContext } from 'react';
import { useLocalObservable } from 'mobx-react-lite';
import { Provider, MobXProviderContext } from 'mobx-react';
import { VacationContentProps } from '../../interfaces';
import createStore, { IMainVacationStore } from './MainVacationStore';

interface VacationProps {
  children: ReactNode;
  content: VacationContentProps;
}

export const VacationProvider = ({
  children,
  content,
}: VacationProps): JSX.Element => {
  const store = useLocalObservable<IMainVacationStore>(() =>
    createStore(content),
  );
  return <Provider VacationStore={store}>{children}</Provider>;
};

export const useMainVacationStore = (): IMainVacationStore => {
  const { VacationStore } = useContext(MobXProviderContext);
  return VacationStore;
};
