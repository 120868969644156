/** libraries */
import styled from '@emotion/styled';
import { StyledMdxParagraph } from '~/components/Blocks/Shared/Shared.style';
import { desktop940 } from '~/components/Grid/constants';
import { StyledNotificationCard } from './Components/NotificationCard/styles';

export const StyledSubscriptions = styled.div<{
  isManyPassiveMessages: boolean;
  isManyNotification: boolean;
}>`
  .subscriptions {
    &__block:not(:last-of-type) {
      margin-bottom: 32px;
    }

    &__carousel-wrapper {
      display: flex;
      position: relative;
      > div {
        & > div:nth-of-type(2) {
          top: 20px;
          left: 24px;
        }
      }
    }

    &__notification {
      > div {
        .notification-card {
          &__header {
            padding-top: ${({ isManyNotification }) =>
              isManyNotification ? '38px' : ''};
          }
        }
      }
    }

    &__passive-messages {
      max-height: 1000px;
      overflow: hidden;
      transition: max-height 1s ease-out;
      ${StyledMdxParagraph} {
        margin-bottom: 8px;
      }

      &__content {
        width: -webkit-fill-available;
        word-break: break-word;
      }
      > div {
        .notification-card {
          &__header {
            padding-top: ${({ isManyPassiveMessages }) =>
              isManyPassiveMessages ? '38px' : ''};
          }
        }
      }
    }
  }

  .trans {
    max-height: 200px;
    overflow: hidden;
    transition: max-height 1s ease-out;
    ${StyledNotificationCard} {
      .notification-card {
        &__children {
          animation-name: hide;
          animation-duration: 100ms;
          animation-delay: 1s;
          animation-fill-mode: forwards;
        }
      }
    }
  }

  @keyframes hide {
    0% {
      max-height: 60px;
      overflow: hidden;
    }
    100% {
      max-height: 53px;
      overflow: hidden;
    }
  }

  @media (min-width: ${desktop940}px) {
    .subscriptions {
      &__carousel-wrapper {
        > div {
          .notification-card {
            &__header {
              padding-top: 0;
            }
          }
          & > div:nth-of-type(2) {
            top: 1rem;
            left: auto;
            width: auto;
          }
        }
      }
    }
  }
`;
