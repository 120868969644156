/** libraries */
import { ReactNode, useContext } from 'react';
import { MobXProviderContext, Provider, useLocalObservable } from 'mobx-react';
/** components */
import { createMobileStore, IMobileStore } from './MobileStore';

interface DeviceProviderProps {
  children: ReactNode;
}

export const MobileStoreProvider = ({
  children,
}: DeviceProviderProps): JSX.Element => {
  const store = useLocalObservable(() => createMobileStore());
  return <Provider MobileStore={store}>{children}</Provider>;
};

const useMobileStore = (): IMobileStore => {
  const { MobileStore } = useContext(MobXProviderContext);
  return MobileStore;
};

export default useMobileStore;
