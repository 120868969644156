/** libraries */
import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { nanoid } from 'nanoid';
import {
  Button,
  defaultTheme,
  Icon,
  Icons,
  LeadingText,
  Link,
  Select,
  Tabs,
  TabsStyleTypes,
  TagButton,
  Text,
} from 'cordis-core-ui-planeta';
import {
  addDays,
  eachMonthOfInterval,
  endOfMonth,
  isAfter,
  isBefore,
  isSameMonth,
  parseISO,
  startOfMonth,
  startOfTomorrow,
  subMonths,
} from 'date-fns';
import { observer } from 'mobx-react';
/** styles */
import { StyledFinancialCalculations, StyledLineName } from './styles';
/** constants */
import {
  MOBILE_SELECT_TYPE,
  MONTHS,
  MONTHS_PREPOSITIONAL,
  TABS_VARIANTS,
} from './constants';
import { desktop940 } from '~/components/Grid/constants';
import { PRODUCT_CARD_STATE } from '../ProductCard/constants';
import { ADMISSION, CHATLE, DAY_TAX, EXTRACT, FOLDS } from '../constants';
import { DEFAULT_ERROR } from '~/constants/common';
/** interfaces */
import {
  ChatleHistoryDataProps,
  ExpandAdvantageProps,
  MoneyHistoryDataProps,
  MoneyHistoryProps,
  SelectProps,
  TransformerHistory,
  ReceiptsState,
} from './interfaces';
/** utils */
import { formatNumber, pluralizeAll } from '~/utils/utils';
import { dateString, withCapitalLetter } from '../utils';
import { outsideClickHelper } from '~/utils/outsideClickHelper';
/** components */
import Table from '../Components/Table/Table';
import Tr from '../Components/Table/Tr';
import Th from '../Components/Table/Th';
import Td from '../Components/Table/Td';
import MobileTable from '../Components/MobileTable/MobileTable';
/** api */
import {
  getChatleHistory,
  getEstimate,
  getExpandAdvantage,
  getMoneyHistory,
  getTransformerHistory,
} from '~/api/apiPab2c';
/** stores */
import { useRootStore } from '~/stores/RootStore';

export interface MoneyHistoryDataState {
  history: MoneyHistoryDataProps[];
  income: number;
  writeOff: number;
  chatles: number;
  beginning: SelectProps;
  end: SelectProps;
}

interface FinancialCalculationsProps {
  setCardState: Dispatch<SetStateAction<PRODUCT_CARD_STATE>>;
  setDailyWriteOffs: Dispatch<SetStateAction<MoneyHistoryDataProps>>;
  setStartOfFinancialSettlementPeriod: Dispatch<SetStateAction<string>>;
  setEndOfFinancialSettlementPeriod: Dispatch<SetStateAction<string>>;
  moneyHistoryDataState: MoneyHistoryDataState;
  setMoneyHistoryDataState: Dispatch<SetStateAction<MoneyHistoryDataState>>;
  setTransformerHistory: Dispatch<SetStateAction<TransformerHistory[]>>;
  setReceipts: Dispatch<SetStateAction<ReceiptsState>>;
}

/** Финансовые расчёты */
// todo: переехать на новые методы, разделить на компоненты
const FinancialCalculations: FC<FinancialCalculationsProps> = ({
  setCardState,
  setDailyWriteOffs,
  setStartOfFinancialSettlementPeriod,
  setEndOfFinancialSettlementPeriod,
  moneyHistoryDataState,
  setMoneyHistoryDataState,
  setTransformerHistory,
  setReceipts,
}: FinancialCalculationsProps): JSX.Element => {
  const {
    productHistoryStore: { productHistory },
  } = useRootStore();

  /** Значения фильтров по месяцам */
  const periodData: SelectProps[] = useMemo(() => {
    const currentDate = new Date();
    const startDate = subMonths(currentDate, 3);

    const monthsInYear = eachMonthOfInterval({
      start: startDate,
      end: currentDate,
    });

    const tomorrow = startOfTomorrow();
    return monthsInYear.map((month, index) => {
      const label = dateString(month, 'LLLL yyyy');
      const value = `${dateString(month, 'yyyy-MM-dd')} ${dateString(
        index === monthsInYear?.length - 1
          ? tomorrow
          : addDays(endOfMonth(month), 1),
        'yyyy-MM-dd',
      )}`;
      return { label: withCapitalLetter(label), value };
    });
  }, []);

  useEffect(() => {
    if (periodData.length) {
      setBeginning(periodData[periodData.length - 2]);
      setEnd(periodData[periodData.length - 1]);
    }
  }, [periodData]);

  /* Текущий индекс таба */
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  /* Событие при изменении таба */
  const onChangeTab = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  };
  /** Select начала периода */
  const [beginning, setBeginning] = useState<SelectProps>(null);
  // Выбираемый, но не подтверждённый select начала периода в мобильной версии
  const [
    candidateForBeginning,
    setCandidateForBeginning,
  ] = useState<SelectProps>(periodData[periodData.length - 2]);
  /** Select конец периода */
  const [end, setEnd] = useState<SelectProps>(null);
  /** Выбираемый, но не подтверждённый select конца периода в мобильной версии */
  const [candidateForEnd, setCandidateForEnd] = useState<SelectProps>(
    periodData[periodData.length - 1],
  );
  /** Вид блока с select в мобильной версии */
  const [isMobileSelectOpen, setIsMobileSelectOpen] = useState<boolean>(false);
  /** Тип select */
  const [mobileSelectType, setMobileSelectType] = useState<MOBILE_SELECT_TYPE>(
    null,
  );
  /** Клик по кнопке "Показать" в денежных расчётах */
  const [isShowMoneyPeriodData, setIsShowMoneyPeriodData] = useState<boolean>(
    false,
  );
  /** Клик по кнопке "Показать" в chatles */
  const [
    isShowChatlesPeriodData,
    setIsShowChatlesMoneyPeriodData,
  ] = useState<boolean>(false);
  /** История финансовых расчётов */
  const [moneyHistoryData, setMoneyHistoryData] = useState<
    MoneyHistoryDataProps[]
  >([]);
  /** История чатлов */
  const [chatleHistoryData, setChatleHistoryData] = useState<
    ChatleHistoryDataProps[]
  >([]);
  /** Итого поступления */
  const [totalIncome, setTotalIncome] = useState<number>(null);
  /** Итого списания */
  const [totalWriteOff, setTotalWriteOff] = useState<number>(null);
  /** Итого поступления */
  const [totalIncomeChatles, setTotalIncomeChatles] = useState<number>(null);
  /** Итого списания */
  const [totalWriteOffChatles, setTotalWriteOffChatles] = useState<number>(
    null,
  );
  /** Итого чатлы */
  const [totalChatles, setTotalChatles] = useState<number>(0);
  /** Загрузка финансовых расчётов */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  /** Ошибка получения финансовых расчётов */
  const [isErrorMoneyHistory, setIsErrorMoneyHistory] = useState<boolean>(
    false,
  );

  /** Генерация ключей для списка */
  const moneyHistoryIds = useMemo(() => {
    return !moneyHistoryData.length
      ? []
      : moneyHistoryData.reduce((acc, item) => {
          const productIds = item.products.map(() => nanoid(5));
          const lineIds = item.lines.map(() => nanoid(5));
          const id = nanoid(5);
          return [...acc, { id, productIds, lineIds }];
        }, []);
  }, [moneyHistoryData]);
  const chatleHistoryIds = useMemo(() => {
    return !chatleHistoryData.length
      ? []
      : chatleHistoryData.reduce((acc, item) => {
          const lineIds = item.lines.map(() => nanoid(5));
          const id = nanoid(5);
          return [...acc, { id, lineIds }];
        }, []);
  }, [chatleHistoryData]);

  /** Варианты select для начала периода */
  const beginningPeriodData = useMemo(() => {
    return end
      ? periodData.filter(
          (item) => !isBefore(parseISO(end.value), parseISO(item.value)),
        )
      : periodData;
  }, [periodData, end]);

  /** Варианты select для конца периода */
  const endPeriodData = useMemo(() => {
    return beginning
      ? periodData.filter(
          (item) => !isBefore(parseISO(item.value), parseISO(beginning.value)),
        )
      : periodData;
  }, [periodData, beginning]);

  /** Если было состояние финансовых расчётов */
  useEffect(() => {
    if (moneyHistoryDataState) {
      setIsShowMoneyPeriodData(true);
      setMoneyHistoryData(moneyHistoryDataState.history);
      setTotalIncome(moneyHistoryDataState.income);
      setTotalWriteOff(moneyHistoryDataState.writeOff);
      setTotalChatles(moneyHistoryDataState.chatles);
      setBeginning(moneyHistoryDataState.beginning);
      setEnd(moneyHistoryDataState.end);
      setMoneyHistoryDataState(null);
    }
  }, []);

  /** Кнопка "Показать" */
  const onShow = () => {
    setIsErrorMoneyHistory(false);
    if (beginning && end) {
      if (activeTabIndex === 0) {
        getMoneyHistoryData();
        transformerHistory();
        setIsShowMoneyPeriodData(true);
      } else {
        getChatleHistoryData();
        setIsShowChatlesMoneyPeriodData(true);
      }
    }
  };

  // Вычисление ширины экрана
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  useEffect(() => {
    if (isDesktop940 && isMobileSelectOpen) setIsMobileSelectOpen(false);
  }, [isDesktop940]);

  const applyMobileSelect = () => {
    setBeginning(candidateForBeginning);
    setEnd(candidateForEnd);
    setIsMobileSelectOpen(false);
  };

  /** Расчёты за последний месяц */
  const getEstimateData = async () => {
    try {
      const res = await getEstimate();
      return res;
    } catch (e) {
      setIsErrorMoneyHistory(true);
      return [];
    }
  };

  /** Строки для таблицы расходов */
  const lines = (data: ExpandAdvantageProps[], act?: MoneyHistoryProps) => {
    if (!data.length) return [];
    /** Строки вне пакета */
    const linesOutsidePackage = data.filter(
      (item) => !item.isPacket && item.tariffName !== 'Закрытый договор ЧК',
    );
    /** Базовый пакет */
    const dayTax = linesOutsidePackage.filter(
      (item) => item.lineCode === DAY_TAX,
    );
    /** Компенсация чатлов */
    const folds = linesOutsidePackage
      .filter((item) => item.lineCode === FOLDS)
      .map((item) => {
        /** Сумма чатлов с минус на плюс */
        return {
          ...item,
          sum: -item.sum,
        };
      });
    /** Услуги вне пакета без компенсации чатлов */
    const services = linesOutsidePackage.filter(
      (item) => item.lineCode !== FOLDS,
    );
    /** Сумма многострочного акта */
    const sum = (servicesData) => {
      return -servicesData.reduce((acc, item) => {
        return acc + item.sum;
      }, 0);
    };
    /** Многострочный акт */
    const multilineServices = dayTax.length
      ? [
          {
            caption: 'Ежедневные списания',
            fromDate: act ? act.date : '',
            sum: sum(services),
            lines: services,
          },
        ]
      : [];
    /** Однострочная или многострочная строка в основной таблице */
    const line = multilineServices.length
      ? multilineServices
      : services.map((item) => {
          return {
            ...item,
            sum: [ADMISSION, EXTRACT].includes(item.caption)
              ? item.sum
              : -item.sum,
            fromDate: act ? act.date : null,
          };
        });
    /** Строки в основной таблице */
    return [...folds, ...line];
  };

  /** История финансовых расчётов */
  const getMoneyHistoryData = async () => {
    setIsLoading(true);
    setMoneyHistoryData([]);
    try {
      const res = await getMoneyHistory(
        beginning.value.split(' ')[0],
        end.value.split(' ')[1],
      );
      /** Текущий месяц в формате "Январь" */
      const currentMonth = withCapitalLetter(dateString(new Date(), 'LLLL'));

      const moneyHistory = await res.reduce(async (acc, item) => {
        let accum = await acc;

        /** Подробная информация об актах */
        const expandData: ExpandAdvantageProps[] =
          item.metaDocumentName === 'Акт'
            ? (await getExpandAdvantage(item.page)) ?? []
            : [
                {
                  caption: item.metaDocumentName,
                  fromDate: item.date,
                  sum: item.moneySum,
                  receiptsInfos: item.receiptsInfos,
                  unitName: null,
                  rate: null,
                  isPacket: null,
                  trimDate: null,
                  tariffName: null,
                  lineCode: null,
                },
              ];

        /** Месяц акта в формате "Январь" */
        const month = withCapitalLetter(dateString(item.date, 'LLLL'));
        const sameMonth = accum.find((i) => i.month === month);
        /** Флаг текущего месяца */
        const isCurrentMonth = month === currentMonth;
        /** Расчёты за текущий месяц */
        const expensesThisMonth: ExpandAdvantageProps[] =
          isCurrentMonth && !sameMonth ? await getEstimateData() : [];

        if (sameMonth) {
          accum = accum.map((j) => {
            if (j === sameMonth) {
              return {
                ...j,
                /** Строки в основной таблице */
                lines: [
                  ...lines(expandData, item),
                  ...lines(expensesThisMonth),
                  ...j.lines,
                ],
              };
            }
            return j;
          });
        } else {
          accum.push({
            month: withCapitalLetter(dateString(item.date, 'LLLL')),
            dateMonth: startOfMonth(parseISO(item.date)),
            /** Строки в основной таблице */
            lines: [...lines(expandData, item), ...lines(expensesThisMonth)],
            date: `${dateString(
              startOfMonth(parseISO(item.date)),
              'dd MMM yyyy',
            )} -\n${dateString(item.date, 'dd MMM yyyy')}`,
          });
        }
        return accum;
      }, Promise.resolve([]));
      /** Текущий месяц в moneyHistory */
      const currentMonthData = moneyHistory.find(
        (item) => item.month === currentMonth,
      );
      /** Добавляет ежедневные расходы за текущий месяц */
      if (!currentMonthData && end === periodData[periodData.length - 1]) {
        const expensesThisMonth: ExpandAdvantageProps[] = await getEstimateData();
        if (expensesThisMonth?.length)
          moneyHistory.unshift({
            month: currentMonth,
            dateMonth: startOfMonth(new Date()),
            lines: lines(expensesThisMonth),
            date: `${dateString(
              startOfMonth(new Date()),
              'dd MMM yyyy',
            )} -\n${dateString(new Date(), 'dd MMM yyyy')}`,
          });
      }

      /** Добавляет информацию о продуктах */
      const moneyHistoryWithProducts = moneyHistory
        .reverse()
        .reduce((acc, item) => {
          const products = productHistory.filter((product) => {
            const sameMonth = isSameMonth(
              parseISO(product.date),
              item.dateMonth,
            );
            /** Возвращает продукты этого месяца или продукты предыдущих месяцев */
            return sameMonth
              ? product
              : isBefore(parseISO(product.date), item.dateMonth);
          });
          /** Все пред продукты */
          const prevProducts = acc.reduce((accum, i) => {
            accum.push(...i.products);
            return accum;
          }, []);
          const findMonth = products.find((product) =>
            isSameMonth(parseISO(product.date), item.dateMonth),
          );
          const isStartDateOfMonth = findMonth
            ? !isAfter(parseISO(findMonth.date), startOfMonth(item.dateMonth))
            : undefined;
          const filterProducts = products.filter((product, i) => {
            /** Все продукты без продуктов в пред месяцах */
            const withoutPrevProducts = !prevProducts.includes(product);
            /** Первый месяц, продукт берётся с прошлого года */
            if (!findMonth && !prevProducts.length && products.length) {
              return i === products.length - 1;
            }
            /** Если в месяце несколько продуктов */
            if (isStartDateOfMonth === false && findMonth === products[i + 1])
              return product;
            if (
              isBefore(
                parseISO(product.date),
                parseISO(beginning.value.split(' ')[0]),
              ) &&
              withoutPrevProducts
            )
              return undefined;
            return prevProducts.length ? withoutPrevProducts : findMonth;
          });
          acc.push({
            ...item,
            products: filterProducts,
            prevProducts: products?.length
              ? products[products.length - 1]
              : prevProducts[prevProducts.length - 1],
          });
          return acc;
        }, []);

      /** Поступления */
      let income = 0;
      /** Списания */
      let writeOff = 0;
      /** Чатлы */
      let chatles = 0;

      /** Все списания и поступления */
      writeOff = moneyHistoryWithProducts.reduce((accum, item) => {
        return (
          accum +
          item.lines.reduce((acc, i) => {
            /** Чатлы */
            if (i.lineCode === FOLDS) {
              chatles += Math.abs(i.sum);
              return acc;
            }
            /** Поступления */
            if (i.sum > 0) {
              income += i.sum;
              return acc;
            }
            /** Списания */
            return acc + i.sum;
          }, 0)
        );
      }, 0);

      /** Сортировка строк по датам */
      const sortedMoneyHistoryWithProducts = moneyHistoryWithProducts.map(
        (item) => {
          const sortedLines = item.lines.sort((a, b) =>
            isAfter(parseISO(a.fromDate), parseISO(b.fromDate)) ? 1 : -1,
          );
          return {
            ...item,
            lines: sortedLines,
          };
        },
      );

      setTotalIncome(income);
      setTotalWriteOff(writeOff);
      setTotalChatles(chatles);
      setMoneyHistoryData(sortedMoneyHistoryWithProducts);
      setIsLoading(false);
    } catch (e) {
      console.error('getMoneyHistoryData', e);
      setIsLoading(false);
      setIsErrorMoneyHistory(true);
    }
  };

  const getChatleHistoryData = async () => {
    setIsLoading(true);
    try {
      const res = await getChatleHistory(
        beginning.value.split(' ')[0],
        end.value.split(' ')[1],
      );

      /** Поступления */
      let income = 0;
      /** Списания */
      let writeOff = 0;

      const chatleHistory = res.reduceRight((acc, item) => {
        let accum = acc;
        if (item.reluxSum > 0) {
          income += item.reluxSum;
        } else {
          writeOff += item.reluxSum;
        }
        const month = withCapitalLetter(dateString(item.date, 'LLLL'));
        const sameMonth = accum.find((i) => i.month === month);
        const metaDocumentName = (data) => {
          if (data.metaDocumentName === 'Аннулирование чатлов')
            return data.metaDocumentName;
          return data.reluxSum < 0
            ? 'Резервирование чатлов'
            : 'Начисление чатлов';
        };
        if (sameMonth) {
          accum = accum.map((j) => {
            if (j === sameMonth) {
              return {
                ...j,
                lines: [
                  ...j.lines,
                  {
                    ...item,
                    metaDocumentName: metaDocumentName(item),
                  },
                ],
              };
            }
            return j;
          });
        } else {
          accum.push({
            month: withCapitalLetter(dateString(item.date, 'LLLL')),
            lines: [
              {
                ...item,
                metaDocumentName: metaDocumentName(item),
              },
            ],
          });
        }
        return accum;
      }, []);
      setTotalIncomeChatles(income);
      setTotalWriteOffChatles(writeOff);
      setChatleHistoryData(chatleHistory);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const transformerHistory = async () => {
    try {
      const res = await getTransformerHistory(
        beginning.value.split(' ')[0],
        end.value.split(' ')[1],
      );
      setTransformerHistory(res);
    } catch (e) {
      console.error('getTransformerHistory', e);
    }
  };

  const selectPeriod = () => {
    return (
      <div className="finance__select-period">
        <LeadingText color={defaultTheme.colors.black}>
          Выберите период расчётов
        </LeadingText>
        <Text lineHeight="24px" color={defaultTheme.colors.shadow}>
          Следите за балансом, контролируйте ваши расходы
        </Text>
      </div>
    );
  };

  const loader = () => {
    return (
      <Text lineHeight="24px">Загрузка данных. Пожалуйста, подождите...</Text>
    );
  };

  const noDataAvailable = () => {
    return <Text lineHeight="24px">Нет данных за выбранный период</Text>;
  };

  const error = () => {
    return <Text lineHeight="24px">{DEFAULT_ERROR}</Text>;
  };

  const mobileSelect = () => {
    return (
      <StyledFinancialCalculations>
        <div className="finance__mobile-select">
          <div className="setCardState__mobile-select">
            <div className="finance__mobile-select__header">
              <LeadingText color={defaultTheme.colors.black}>
                {mobileSelectType === MOBILE_SELECT_TYPE.BEGIN
                  ? 'Начало периода'
                  : 'Окончание периода'}
              </LeadingText>
              <Icon
                icon={<Icons.CloseIcon />}
                onClick={() => {
                  setIsMobileSelectOpen(!isMobileSelectOpen);
                  setCandidateForBeginning(beginning);
                  setCandidateForEnd(end);
                }}
                highlight
              />
            </div>
            {periodData?.map((item) => {
              return (
                <TagButton
                  key={item.label}
                  className="finance__mobile-select__tag-button"
                  onChange={() =>
                    mobileSelectType === MOBILE_SELECT_TYPE.BEGIN
                      ? setCandidateForBeginning(item)
                      : setCandidateForEnd(item)
                  }
                  checked={
                    mobileSelectType === MOBILE_SELECT_TYPE.BEGIN
                      ? item === candidateForBeginning
                      : item === candidateForEnd
                  }
                  disabled={
                    (mobileSelectType === MOBILE_SELECT_TYPE.BEGIN &&
                      end &&
                      isBefore(parseISO(end.value), parseISO(item.value))) ||
                    (mobileSelectType === MOBILE_SELECT_TYPE.END &&
                      beginning &&
                      isBefore(parseISO(item.value), parseISO(beginning.value)))
                  }
                >
                  {item.label}
                </TagButton>
              );
            })}
            <Button onClick={applyMobileSelect}>Применить</Button>
          </div>
        </div>
      </StyledFinancialCalculations>
    );
  };

  /** Итого баланс чатлов */
  const chatleBalance = useMemo(() => {
    if (chatleHistoryData.length) {
      const lastChatleHistory = chatleHistoryData[chatleHistoryData.length - 1];
      return lastChatleHistory.lines[lastChatleHistory.lines.length - 1]
        .fakeReluxSaldo;
    }
    return null;
  }, [chatleHistoryData]);

  useEffect(() => {
    if (beginning)
      setStartOfFinancialSettlementPeriod(beginning.value.split(' ')[0]);
    if (end) setEndOfFinancialSettlementPeriod(end.value.split(' ')[1]);
  }, [beginning, end]);

  if (isMobileSelectOpen && !isDesktop940 && periodData?.length > 0) {
    return mobileSelect();
  }

  /** Select`ы заполнены */
  const isFullPeriod = beginning && end;

  /** Показать данные */
  const isShow =
    (isShowMoneyPeriodData && activeTabIndex === 0) ||
    (isShowChatlesPeriodData && activeTabIndex === 1);

  /** Есть данные */
  const isData =
    (moneyHistoryData.length > 0 && activeTabIndex === 0) ||
    (chatleHistoryData.length > 0 && activeTabIndex === 1);

  const metaDocumentMapper = {
    [EXTRACT]: 'Пополнение счёта',
    [ADMISSION]: 'Пополнение счёта',
  };

  /** Раскрытие "услуга за месяц" */
  const multilineClick = (item, line) => {
    if (!line?.lines) return;
    setMoneyHistoryDataState({
      history: moneyHistoryData,
      income: totalIncome,
      writeOff: totalWriteOff,
      chatles: totalChatles,
      beginning,
      end,
    });
    setCardState(PRODUCT_CARD_STATE.DAILY_WRITE_OFFS);
    setDailyWriteOffs({
      ...item,
      lines: line.lines,
      products: !item.products.length ? [item.prevProducts] : item.products,
    });
  };

  const receiptsClick = (links, sum, fromDate) => {
    setReceipts({ links, sum, fromDate });
    setMoneyHistoryDataState({
      history: moneyHistoryData,
      income: totalIncome,
      writeOff: totalWriteOff,
      chatles: totalChatles,
      beginning,
      end,
    });
    setCardState(PRODUCT_CARD_STATE.RECEIPTS);
  };

  const ReceiptLinks = ({ links, caption, sum, fromDate }) => {
    if (!links.length) {
      return metaDocumentMapper[caption] ?? caption;
    }

    if (links.length === 1) {
      return (
        <Link href={links[0].receiptHyperLink} target="_blank">
          {metaDocumentMapper[caption] ?? caption}
        </Link>
      );
    }

    return (
      <Link onClick={() => receiptsClick(links, sum, fromDate)}>
        {metaDocumentMapper[caption] ?? caption}
      </Link>
    );
  };

  const table = (): JSX.Element => {
    if (activeTabIndex === 0) {
      if (isDesktop940) {
        return (
          <Table>
            <thead>
              <Tr>
                <Th width={450}>Комментарий</Th>
                <Th width={200}>Дата</Th>
                <Th width={140} textAlign="right">
                  Сумма, ₽
                </Th>
              </Tr>
            </thead>
            <tbody>
              {moneyHistoryData.map((item, index) => {
                return (
                  <Fragment key={moneyHistoryIds[index].id}>
                    <Tr backgroundColor={defaultTheme.colors.light}>
                      <Td>
                        <Text
                          className="finance__table__date"
                          lineHeight="24px"
                          fontWeightBold
                        >
                          {item.month}
                        </Text>
                        <br />
                        {item.products.map((product, i) => {
                          return (
                            <Fragment
                              key={moneyHistoryIds[index].productIds[i]}
                            >
                              <Text
                                color={defaultTheme.colors.gray}
                                lineHeight="24px"
                              >
                                Продукт «{product.tariffName}»
                              </Text>
                              <br />
                            </Fragment>
                          );
                        })}
                      </Td>
                      <Td verticalAlign="bottom">
                        {item.products.map((product, i) => {
                          return (
                            <Fragment
                              key={moneyHistoryIds[index].productIds[i]}
                            >
                              <Text
                                lineHeight="24px"
                                color={defaultTheme.colors.gray}
                              >
                                действует с&nbsp;
                                {dateString(product.date, 'dd MMM yyyy')}
                              </Text>
                              <br />
                            </Fragment>
                          );
                        })}
                      </Td>
                      <Td>&nbsp;</Td>
                    </Tr>
                    {item.lines.length > 0 &&
                      item.lines.map((line: ExpandAdvantageProps, i) => {
                        /** Месяц в предложном падеже */
                        const month =
                          line.caption === 'Ежедневные списания'
                            ? MONTHS_PREPOSITIONAL[
                                (Object.values(MONTHS) as Array<
                                  keyof typeof MONTHS
                                >).findIndex(
                                  (key) =>
                                    key ===
                                    withCapitalLetter(
                                      dateString(
                                        line.fromDate
                                          ? line.fromDate
                                          : new Date(),
                                        'LLLL',
                                      ),
                                    ),
                                )
                              ]
                            : undefined;
                        return (
                          <Tr key={moneyHistoryIds[index].lineIds[i]}>
                            <StyledLineName
                              className={line?.lines ? 'finance__cursor' : ''}
                              color={
                                line?.lines
                                  ? defaultTheme.colors.planeta
                                  : undefined
                              }
                              onClick={() => multilineClick(item, line)}
                            >
                              {line?.receiptsInfos ? (
                                <ReceiptLinks
                                  links={line.receiptsInfos}
                                  caption={line.caption}
                                  fromDate={line.fromDate}
                                  sum={line.sum}
                                />
                              ) : (
                                metaDocumentMapper[line.caption] ?? line.caption
                              )}
                              {month && (
                                <Text
                                  className="finance__cursor-default"
                                  lineHeight="24px"
                                >
                                  &nbsp;в {month}
                                </Text>
                              )}
                            </StyledLineName>
                            <Td>
                              {line?.fromDate
                                ? dateString(line.fromDate, 'dd MMM yyyy')
                                : dateString(item.dateMonth, 'MMM yyyy')}
                            </Td>
                            <Td textAlign="right">
                              <Text
                                color={
                                  // eslint-disable-next-line no-nested-ternary
                                  line.lineCode === FOLDS
                                    ? defaultTheme.colors.graphite
                                    : line.sum > 0
                                    ? defaultTheme.colors.green
                                    : defaultTheme.colors.down
                                }
                                lineHeight="24px"
                                fontWeightBold
                              >
                                {formatNumber(line.sum, false, ',', true)}
                              </Text>
                            </Td>
                          </Tr>
                        );
                      })}
                  </Fragment>
                );
              })}
              <Tr backgroundColor={defaultTheme.colors.light}>
                <Td verticalAlign="top">
                  <Text lineHeight="24px" fontWeightBold>
                    Итого за период
                  </Text>
                </Td>
                <Td>&nbsp;</Td>
                <Td textAlign="right">
                  <Text
                    className="finance__table__total"
                    color={defaultTheme.colors.green}
                    lineHeight="24px"
                    fontWeightBold
                  >
                    {formatNumber(totalIncome, false, ',', true)}
                  </Text>
                  <br />
                  <Text
                    className="finance__table__total"
                    color={defaultTheme.colors.down}
                    lineHeight="24px"
                    fontWeightBold
                  >
                    −{formatNumber(Math.abs(totalWriteOff), false, ',', true)}
                  </Text>
                  <br />
                  <Text
                    color={defaultTheme.colors.shadow}
                    lineHeight="24px"
                    fontWeightBold
                  >
                    −
                    {pluralizeAll(
                      totalChatles,
                      CHATLE,
                      undefined,
                      undefined,
                      true,
                    )}
                  </Text>
                </Td>
              </Tr>
            </tbody>
          </Table>
        );
      }
      return (
        <>
          {moneyHistoryData.map((item, index) => {
            return (
              <Fragment key={moneyHistoryIds[index].id}>
                <MobileTable
                  products={item.products.map((product) => {
                    return {
                      name: product.tariffName,
                      date: product.date,
                    };
                  })}
                  content={{
                    header: item.month,
                    lines: item.lines.map((line) => {
                      return {
                        ...line,
                        amount: line.sum,
                        amountColor:
                          line?.amountColor ??
                          (line.lineCode === FOLDS
                            ? defaultTheme.colors.graphite
                            : undefined),
                        info: line?.receiptsInfos ? (
                          <ReceiptLinks
                            links={line.receiptsInfos}
                            caption={line.caption}
                            fromDate={line.fromDate}
                            sum={line.sum}
                          />
                        ) : (
                          metaDocumentMapper[line.caption] ?? line.caption
                        ),
                        date: line?.fromDate ?? '',
                        isFormatDate: line?.isFormatDate === undefined,
                        lineClick: line?.lines?.length
                          ? () => multilineClick(item, line)
                          : undefined,
                      };
                    }),
                  }}
                />
              </Fragment>
            );
          })}
          <div className="finance__table__mobile__total">
            <Text
              className="finance__table__mobile__total__header"
              lineHeight="24px"
            >
              ИТОГО ЗА ПЕРИОД
            </Text>
            <br />
            <LeadingText color={defaultTheme.colors.green}>
              {formatNumber(totalIncome, false, ',', true)} ₽
            </LeadingText>
            <br />
            <LeadingText color={defaultTheme.colors.shadow}>
              {formatNumber(totalChatles, false, ',', true)} ₽
            </LeadingText>
            <br />
            <LeadingText color={defaultTheme.colors.down}>
              {formatNumber(totalWriteOff, false, ',', true)} ₽
            </LeadingText>
          </div>
        </>
      );
    }
    if (isDesktop940) {
      return (
        <Table>
          <thead>
            <Tr>
              <Th width={250}>Комментарий</Th>
              <Th width={140}>Дата</Th>
              <Th width={140} textAlign="right">
                Сумма, Чатлы
              </Th>
              <Th width={140} textAlign="right">
                Баланс, Чатлы
              </Th>
            </Tr>
          </thead>
          <tbody>
            {chatleHistoryData.map((item, index) => {
              return (
                <Fragment key={chatleHistoryIds[index].id}>
                  <Tr backgroundColor={defaultTheme.colors.light}>
                    <Td>
                      <Text lineHeight="24px" fontWeightBold>
                        {item.month}
                      </Text>
                    </Td>
                    <Td>&nbsp;</Td>
                    <Td>&nbsp;</Td>
                    <Td>&nbsp;</Td>
                  </Tr>
                  {item.lines.map((line, i) => {
                    return (
                      <Tr key={chatleHistoryIds[index].lineIds[i]}>
                        <Td>{line.metaDocumentName}</Td>
                        <Td>{dateString(line.date, 'dd MMM yyyy')}</Td>
                        <Td textAlign="right">
                          <Text
                            color={
                              line.reluxSum < 0
                                ? defaultTheme.colors.down
                                : defaultTheme.colors.green
                            }
                            lineHeight="24px"
                            fontWeightBold
                          >
                            {line.reluxSum}
                          </Text>
                        </Td>
                        <Td textAlign="right">
                          <Text
                            color={defaultTheme.colors.shadow}
                            lineHeight="24px"
                            fontWeightBold
                          >
                            {line.fakeReluxSaldo}
                          </Text>
                        </Td>
                      </Tr>
                    );
                  })}
                </Fragment>
              );
            })}
            <Tr backgroundColor={defaultTheme.colors.light}>
              <Td verticalAlign="top">
                <Text lineHeight="24px" fontWeightBold>
                  Итого за период
                </Text>
              </Td>
              <Td>&nbsp;</Td>
              <Td textAlign="right">
                <Text
                  color={defaultTheme.colors.green}
                  lineHeight="24px"
                  fontWeightBold
                >
                  {totalIncomeChatles}
                </Text>
                <br />
                <Text
                  color={defaultTheme.colors.down}
                  lineHeight="24px"
                  fontWeightBold
                >
                  {totalWriteOffChatles}
                </Text>
              </Td>
              <Td textAlign="right" verticalAlign="top">
                <Text
                  color={defaultTheme.colors.shadow}
                  lineHeight="24px"
                  fontWeightBold
                >
                  {chatleBalance}
                </Text>
              </Td>
            </Tr>
          </tbody>
        </Table>
      );
    }
    return (
      <>
        {chatleHistoryData.map((item, index) => {
          return (
            <Fragment key={chatleHistoryIds[index].id}>
              <MobileTable
                content={{
                  header: item.month,
                  lines: item.lines.map((line) => {
                    return {
                      ...line,
                      amount: line.reluxSum,
                      balance: line.fakeReluxSaldo,
                      comment:
                        line.reluxSum > 0 ? 'Начисление' : 'Резервирование',
                      isFormatDate: true,
                    };
                  }),
                }}
              />
            </Fragment>
          );
        })}
        <div className="finance__table__mobile__total">
          <Text
            className="finance__table__mobile__total__header"
            lineHeight="24px"
          >
            ВСЕГО ЗА ПЕРИОД
          </Text>
          <br />
          <LeadingText color={defaultTheme.colors.green}>
            {totalIncomeChatles}
          </LeadingText>
          <br />
          <LeadingText color={defaultTheme.colors.down}>
            {totalWriteOffChatles}
          </LeadingText>
          <br />
          <Text
            className="finance__table__mobile__total__balance"
            color={defaultTheme.colors.shadow}
            lineHeight="24px"
          >
            Баланс: {pluralizeAll(chatleBalance, CHATLE)}
          </Text>
        </div>
      </>
    );
  };

  const content = () => {
    if (isErrorMoneyHistory) return error();
    if (isLoading) return loader();
    if (isFullPeriod && isShow) {
      return isData ? table() : noDataAvailable();
    }
    return selectPeriod();
  };

  return (
    <StyledFinancialCalculations>
      <Tabs
        value={TABS_VARIANTS}
        styleType={TabsStyleTypes.MAIN}
        onChange={onChangeTab}
        activeTabIndex={activeTabIndex}
      />
      <div className="finance__select">
        <div
          onClick={() => {
            if (!isDesktop940) {
              setIsMobileSelectOpen(true);
              setMobileSelectType(MOBILE_SELECT_TYPE.BEGIN);
            }
          }}
        >
          <Select
            placeholder="Начало периода"
            onOptionClick={(option) => {
              setBeginning(option);
              setCandidateForBeginning(option);
            }}
            visibleOptionCount={4}
            value={beginning ? beginning.value : ''}
            outsideClickHelper={outsideClickHelper}
            data={beginningPeriodData}
          />
        </div>
        <div
          onClick={() => {
            if (!isDesktop940) {
              setIsMobileSelectOpen(true);
              setMobileSelectType(MOBILE_SELECT_TYPE.END);
            }
          }}
        >
          <Select
            placeholder="Окончание периода"
            onOptionClick={(option) => {
              setEnd(option);
              setCandidateForEnd(option);
            }}
            visibleOptionCount={4}
            value={end ? end.value : ''}
            outsideClickHelper={outsideClickHelper}
            data={endPeriodData}
          />
        </div>
        <Button onClick={onShow} loading={isLoading}>
          Показать
        </Button>
      </div>
      {content()}
    </StyledFinancialCalculations>
  );
};

export default observer(FinancialCalculations);
