import styled from '@emotion/styled';
import { defaultTheme, LeadingText, Radio } from 'cordis-core-ui-planeta';

export const StyledLeadingText = styled(LeadingText)`
  display: block;
  margin-bottom: 32px;
  color: ${defaultTheme.colors.black};
`;

export const StyledRadio = styled(Radio)`
  label:has(input:checked) {
    border-color: ${defaultTheme.colors.disable} !important;

    > span {
      background-color: ${defaultTheme.colors.green} !important;
    }
  }
`;
