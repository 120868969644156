/** libraries */
import styled from '@emotion/styled';
import {
  Button,
  defaultTheme,
  H3,
  Icon,
  Link,
  Tabs,
} from 'cordis-core-ui-planeta';
/** constants */
import { desktop940 } from '~/components/Grid/constants';

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 6px;
  margin-bottom: 32px;

  @media (min-width: ${desktop940}px) {
    margin-bottom: 26px;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledH3 = styled(H3)`
  margin: 0 0 0 14px;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

export const StyledInfoPanel = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: 0;
  width: 100%;
  border-top: 1px solid ${defaultTheme.colors.line};
  margin-top: 16px;
  padding-top: 21px;

  @media (min-width: ${desktop940}px) {
    align-items: center;
    width: initial;
    border-top: initial;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 3px;
  }
`;

export const StyledPhoneNumberInfo = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 17px;
  margin-bottom: -1px;

  ${StyledIcon} {
    margin-right: 12px;
  }
`;

export const StyledLinkButton = styled(Link)`
  margin-right: 0;
  margin-top: 0;

  @media (min-width: ${desktop940}px) {
    margin-right: 18px;
    margin-top: 4px;
  }
`;

export const StyledThreeDotsButton = styled(Button)`
  width: 32px;
  min-width: 32px;
  padding: 0;
  border-radius: 16px;

  &:hover {
    background-color: ${defaultTheme.colors.light};
    border-color: ${defaultTheme.colors.light};
  }

  svg * {
    fill: ${defaultTheme.colors.shadow};
  }
`;

export const StyledActivationSimButton = styled(Button)`
  svg {
    margin-top: 5px;

    & * {
      fill: ${defaultTheme.colors.black};
    }
  }
`;

export const StyledTabs = styled(Tabs)`
  margin-left: 15px;
  span {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: ${defaultTheme.colors.shadow};
  }

  > div {
    padding: 10px 32px;
  }

  .active {
    span {
      color: ${defaultTheme.colors.white};
    }
  }

  > div:nth-of-type(2).active {
    background-color: ${defaultTheme.colors.lightSeaGreen};
  }
`;
