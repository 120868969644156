/** libraries */
import { useEffect, useState } from 'react';
/** constants */
import { OPERATING_STATE } from '~/constants/common';
/** stores */
import { useRootStore } from '~/stores/RootStore';

interface MobileHookReturnProps {
  timerId: NodeJS.Timeout;
  isProviderBlockForNonPayment: boolean;
  checkOperatingState: () => Promise<void>;
}

export const useMobileHook = (): MobileHookReturnProps => {
  const {
    contractStateStore: { contractState, getOperatingState },
    pab2cBalanceStore: { moneyBalance },
  } = useRootStore();
  // id таймера для проверки статуса договора
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  /** Блокировка оператором по неуплате */
  const isProviderBlockForNonPayment =
    contractState === OPERATING_STATE.PROVIDER_BLOCK_DEBT && moneyBalance <= 0;

  const checkOperatingState = async () => {
    if (timerId) return;
    const id = setInterval(() => getOperatingState(), 3000);
    setTimerId((id as unknown) as NodeJS.Timeout);
  };

  useEffect(() => {
    if (contractState !== OPERATING_STATE.PROVIDER_BLOCK_DEBT && timerId) {
      clearInterval(timerId);
    }
  }, [contractState]);

  return { timerId, isProviderBlockForNonPayment, checkOperatingState };
};
