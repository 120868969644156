/** libraries */
import { flow, Instance, types, getParent } from 'mobx-state-tree';
import RouterContext from 'next/router';
/** utils */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
/** api */
import { getRedirectUrlToEsia, handleCallbackFromEsia } from '~/api/apiPab2c';
/** constants */
import { PASSPORT_CONFIRM, ESIA_EXEPTIONS_TEXT } from '../constants';
import { Pab2cSlugs } from '~/constants/common';
/** stores */
import { IMobileStore } from './MobileStore';

const RequestsStateModel = types.model('State', {
  getRedirectUrlToEsia: createApiPathModel(
    'GET /DocumentVerification/GetRedirectUrlToEsia',
  ),
  handleCallbackFromEsia: createApiPathModel(
    'POST /DocumentVerification/HandleCallbackFromEsia',
  ),
});

export const PassportDataConfirmationStore = types
  .model('PassportDataConfirmationStore', {
    requestsState: RequestsStateModel,
    isShowPassportDataConfirmation: types.boolean,
    passportConfirmationMethod: types.maybeNull(
      types.enumeration(Object.values(PASSPORT_CONFIRM)),
    ),
    isLoadingConfirmation: types.boolean,
    gosuslugiError: types.string,
  })
  .actions((self) => ({
    onClosePassportDataConfirmation: () => {
      self.isLoadingConfirmation = false;
      self.gosuslugiError = '';
      self.isShowPassportDataConfirmation = false;
    },
  }))
  .actions((self) => ({
    openPassportDataConfirmation: () => {
      self.isShowPassportDataConfirmation = true;
    },
    setPassportConfirmationMethod: (passportConfirmationMethod) => {
      self.passportConfirmationMethod = passportConfirmationMethod;
    },
    setGosuslugiError: (gosuslugiError) => {
      self.isLoadingConfirmation = false;
      self.gosuslugiError = gosuslugiError;
    },
    getRedirectUrlToEsia: flow(function* () {
      self.requestsState.getRedirectUrlToEsia.reset();
      self.requestsState.getRedirectUrlToEsia.setLoading();
      self.gosuslugiError = '';
      self.isLoadingConfirmation = true;
      try {
        const data = yield getRedirectUrlToEsia();
        if (data?.redirectUrl) {
          window.location.assign(data.redirectUrl);
        } else {
          self.gosuslugiError = ESIA_EXEPTIONS_TEXT.DEFAULT;
        }
        self.requestsState.getRedirectUrlToEsia.setSuccess();
      } catch (e) {
        console.error('getRedirectUrlToEsia', e);
        self.gosuslugiError = ESIA_EXEPTIONS_TEXT.DEFAULT;
        self.isLoadingConfirmation = false;
        self.requestsState.getRedirectUrlToEsia.setFail();
      }
    }),
    handleCallbackFromEsia: flow(function* () {
      self.isLoadingConfirmation = true;
      self.requestsState.handleCallbackFromEsia.reset();
      self.requestsState.handleCallbackFromEsia.setLoading();
      try {
        const { code, state } = RouterContext.router.query;
        yield handleCallbackFromEsia(code as string, state as string);
        const {
          simCardActivationStore: { setIsDocumentVerified },
        }: IMobileStore = getParent(self);
        setIsDocumentVerified(true);
        self.onClosePassportDataConfirmation();
        self.requestsState.handleCallbackFromEsia.setSuccess();
      } catch (e) {
        console.error('handleCallbackFromEsia', e);
        const error = e?.errorMessage ? JSON.parse(e?.errorMessage) : null;
        self.gosuslugiError =
          ESIA_EXEPTIONS_TEXT[error?.Type] ?? ESIA_EXEPTIONS_TEXT.DEFAULT;
        self.requestsState.handleCallbackFromEsia.setFail();
      } finally {
        self.isLoadingConfirmation = false;
        RouterContext.router.replace(`/${Pab2cSlugs.MAIN_SLUG}`);
      }
    }),
  }));

export type IPassportDataConfirmationStore = Instance<
  typeof PassportDataConfirmationStore
>;

export const passportDataConfirmationStoreInstance = {
  requestsState: {
    getRedirectUrlToEsia: defaultModelState,
    handleCallbackFromEsia: defaultModelState,
  },
  isShowPassportDataConfirmation: false,
  passportConfirmationMethod: null,
  isLoadingConfirmation: false,
  gosuslugiError: '',
};
