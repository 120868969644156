/** libraries */
import { observer } from 'mobx-react';
import {
  Button,
  defaultTheme,
  Loader,
  Radio,
  SidePage,
  Text,
} from 'cordis-core-ui-planeta';
/** stores */
import useMobileStore from '../../store/useMobileStore';
/** constants */
import { PASSPORT_CONFIRM } from '../../constants';
/** styles */
import { StyledFooter, StyledLoader, StyledRadioList } from '../../style';

const PassportDataConfirmation = (): JSX.Element => {
  const {
    simCardActivationStore: { setIsDocumentVerified },
    passportDataConfirmationStore: {
      isShowPassportDataConfirmation,
      passportConfirmationMethod,
      gosuslugiError,
      isLoadingConfirmation,
      setPassportConfirmationMethod,
      onClosePassportDataConfirmation,
      getRedirectUrlToEsia,
    },
  } = useMobileStore();

  const isOffice = passportConfirmationMethod === PASSPORT_CONFIRM.OFFICE;

  if (!isShowPassportDataConfirmation) return null;

  return (
    <SidePage
      show={isShowPassportDataConfirmation}
      headerText="Подтвердите паспортные данные"
      onCloseClick={onClosePassportDataConfirmation}
      footerContainer={
        <StyledFooter>
          <Button
            disabled={passportConfirmationMethod === null}
            onClick={() => {
              if (isOffice) {
                setIsDocumentVerified(true);
                onClosePassportDataConfirmation();
                return;
              }
              getRedirectUrlToEsia();
            }}
            loading={isLoadingConfirmation}
          >
            Подтвердить
          </Button>
          {gosuslugiError && (
            <Text lineHeight="24px" color={defaultTheme.colors.planeta}>
              {gosuslugiError}
            </Text>
          )}
        </StyledFooter>
      }
    >
      {isLoadingConfirmation ? (
        <StyledLoader>
          <Loader />
        </StyledLoader>
      ) : (
        <StyledRadioList>
          <Radio
            checked={passportConfirmationMethod === PASSPORT_CONFIRM.GOSUSLUGI}
            onChange={() => {
              setPassportConfirmationMethod(PASSPORT_CONFIRM.GOSUSLUGI);
            }}
            title="С помощью Госуслуг"
          />
          <Radio
            checked={isOffice}
            onChange={() =>
              setPassportConfirmationMethod(PASSPORT_CONFIRM.OFFICE)
            }
            title="В офисе Планеты"
          />
        </StyledRadioList>
      )}
    </SidePage>
  );
};

export default observer(PassportDataConfirmation);
