/** libraries */
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import {
  ColorsType,
  defaultTheme,
  H3,
  PriceTag,
  PriceTagBackgroundColor,
  Text,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** styles */
import { StyledPrice, StyledWarning, WarningsWrapper } from '../styles';
/** constants */
import { OPERATING_STATE } from '~/constants/common';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../../store/useMobileStore';
import { formatNumber } from '~/utils/utils';
import { desktop940 } from '~/components/Grid/constants';
import { DELIVERY_TYPE } from '../../../types';

/** Компонент с текстом предупреждения, если договор заблокирован или ожидает подключения */
const Notifications = (): JSX.Element => {
  const {
    pab2cMobileStore: {
      isConvergentProduct,
      mobilePhonesInfoData,
      mobilePhones,
    },
    contractStateStore: { contractState },
    authStore: {
      auth: { balance },
    },
    summaryDataStore: {
      summaryData: { mobileInfo },
    },
  } = useRootStore();

  const {
    changeNumberStore: { newNumberInfo },
    methodOfReceiptFormStore: { deliveryType },
  } = useMobileStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const totalPrice = useMemo(() => {
    const deliveryPrice =
      deliveryType === DELIVERY_TYPE.COURIER
        ? mobilePhonesInfoData.simCardCourierDeliveryPrice
        : 0;
    return deliveryPrice + (newNumberInfo?.price ?? 0);
  }, [deliveryType, newNumberInfo?.price]);

  const priceFontColor = (): ColorsType => {
    if (contractState === OPERATING_STATE.PROVIDER_BLOCK_DEBT)
      return defaultTheme.colors.down;

    return balance >= totalPrice
      ? defaultTheme.colors.black
      : defaultTheme.colors.warning;
  };

  const priceBackgroundColor = (): PriceTagBackgroundColor => {
    if (contractState === OPERATING_STATE.PROVIDER_BLOCK_DEBT)
      return PriceTagBackgroundColor.DOWN;

    return balance >= totalPrice
      ? PriceTagBackgroundColor.OK
      : PriceTagBackgroundColor.WARNING;
  };

  const priceOnMobileNumber = useMemo(() => {
    if (!mobileInfo) return 0;
    const {
      baseMobilePhoneNumberCount,
      priceOnBaseMobilePhoneNumber,
      priceOnAdditionalMobilePhoneNumber,
    } = mobileInfo;
    return baseMobilePhoneNumberCount >= mobilePhones.length
      ? priceOnBaseMobilePhoneNumber
      : priceOnAdditionalMobilePhoneNumber;
  }, [mobilePhones.length, mobileInfo.baseMobilePhoneNumberCount]);

  return (
    <>
      <WarningsWrapper>
        {!isConvergentProduct && (
          <StyledWarning>
            <Text lineHeight="24px">
              Для пользования услугами мобильной связи требуется заказать пакеты
              минут и&nbsp;Гб. Для более выгодных условий переходите
              на&nbsp;продукты из&nbsp;категории Сверхсвязь.
            </Text>
          </StyledWarning>
        )}
        <StyledWarning color={defaultTheme.colors.light}>
          <Text lineHeight="24px" fontWeightBold>
            Переходите в&nbsp;Планету со&nbsp;своим номером
          </Text>
          <Text lineHeight="24px">
            Не&nbsp;хотите расставаться со&nbsp;своим номером? Продолжайте
            пользоваться им&nbsp;в&nbsp;Планете! Перенос номера возможен после
            получения и&nbsp;активации SIM-карты. Срок переноса составляет
            от&nbsp;8&nbsp;календарных дней с&nbsp;момента активации SIM-карты.
          </Text>
        </StyledWarning>
      </WarningsWrapper>
      {totalPrice > 0 && (
        <StyledPrice>
          <PriceTag
            header="Сумма списания"
            value={`${formatNumber(totalPrice)} ₽`}
            backgroundColor={priceBackgroundColor()}
            subscription={`На вашем\u000Aсчету ${formatNumber(balance)} ₽`}
            fontColor={priceFontColor()}
            headerType={isDesktop940 ? 'H2' : 'H3'}
            width="fit-content"
          />
        </StyledPrice>
      )}
      {priceOnMobileNumber > 0 && (
        <>
          <Text lineHeight="24px" color={defaultTheme.colors.gray}>
            Стоимость пользования номером
          </Text>
          <H3>{`${formatNumber(priceOnMobileNumber)}\u00A0₽ в\u00A0день`}</H3>
        </>
      )}
    </>
  );
};
export default observer(Notifications);
