/** libraries */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { defaultTheme, Icon } from 'cordis-core-ui-planeta';
import { StyledFillableScale } from '~/components/Blocks/Shared/FillableScale/style';
import { desktop940 } from '~/components/Grid/constants';

export const StyledMobile = styled.div`
  min-height: 400px;
  background-color: ${defaultTheme.colors.white};
  padding: 32px 0 32px 32px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 336px;
`;

export const StyledWarning = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  > span {
    padding: 16px;
    max-width: 576px;
    background-color: ${defaultTheme.colors.backgroundWarning};
    margin-bottom: 16px;
  }
  > button {
    width: max-content;
  }
`;

export const StyledIcon = styled(Icon)`
  svg {
    margin-bottom: 16px;
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  button {
    margin: 0 0 16px 0;
  }
  > span {
    text-align: center;
    width: fit-content;
  }
  @media (min-width: ${desktop940}px) {
    flex-direction: row;
    justify-content: start;
    button {
      margin: 0 16px 0 0;
    }
    > span {
      text-align: start;
    }
  }
`;

export const StyledRadioList = styled.div`
  > div {
    min-height: auto;
    margin-bottom: 16px;
  }
`;

export const StyledLoader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ScaleWrapper = styled.div<{
  $flexGrowValue: number;
  $isDisabled?: boolean;
  $isLeftMinimumQuantity?: boolean;
}>`
  ${({ $flexGrowValue, $isDisabled, $isLeftMinimumQuantity }) => {
    return css`
      display: flex;
      flex-grow: ${$flexGrowValue};

      ${StyledFillableScale} {
        width: 100%;
        margin: 0;

        ${$isDisabled && 'opacity: 60%;'}

        .scale {
          margin: 0;
          border: initial;
          ${$isLeftMinimumQuantity && 'width: 12px;'}

          &__filler {
            min-width: 12px;
          }
        }
      }
    `;
  }}
`;
