/** libraries */
import { Instance, types } from 'mobx-state-tree';
import { SELECT_VALUE } from '../constants';
import { DELIVERY_TYPE } from '../types';
import { IOfficeInfoModel } from '~/stores/CityStore';

const SelectModel = types.model({
  label: types.string,
  value: types.string,
});

const AcceptedAddressModel = types.model({
  city: types.string,
  street: types.string,
  house: types.string,
  apartment: types.maybe(types.string),
});

export const MethodOfReceiptFormStore = types
  .model('MethodOfReceiptFormStore', {
    activeTabIndex: types.number,
    deliveryType: types.maybeNull(
      types.enumeration(Object.values(DELIVERY_TYPE)),
    ),
    selectedCity: SelectModel,
    selectedStreet: SelectModel,
    selectedHouse: SelectModel,
    streetLoading: types.boolean,
    houseLoading: types.boolean,
    streets: types.array(SelectModel),
    buildings: types.array(SelectModel),
    street: types.string,
    house: types.string,
    apartment: types.string,
    checkedOfficeId: types.number,
    officeId: types.maybeNull(types.number),
    acceptedAddress: types.maybeNull(AcceptedAddressModel),
  })
  .views((self) => ({
    get isAcceptButtonDisabled() {
      if (self.activeTabIndex === 0) {
        return !self.checkedOfficeId;
      }
      return !self.street || !self.house;
    },
    deliveryAddress: (officeInfos: IOfficeInfoModel[]) => {
      if (self.deliveryType === DELIVERY_TYPE.SELF_PICKUP) {
        const office = officeInfos?.length
          ? officeInfos.find((item) => item.id === self.officeId)
          : null;
        return office
          ? `${office.cityName}, ${office.streetName}, ${office.buildingNumber}`
          : null;
      }
      return `${self.acceptedAddress?.city}, ${
        self.acceptedAddress?.street
      }, д. ${self.acceptedAddress?.house}${
        self.acceptedAddress?.apartment &&
        `, кв. ${self.acceptedAddress?.apartment}`
      }`;
    },
    filteredOfficeInfos: (officeInfos: IOfficeInfoModel[]) => {
      return officeInfos.filter(
        (item) => item.cityName === self.selectedCity.value,
      );
    },
  }))
  .views((self) => ({
    deliveryInfoForSimReplacement: (officeInfos: IOfficeInfoModel[]) => {
      return {
        deliveryAddress: self.deliveryAddress(officeInfos),
        deliveryType: self.deliveryType,
      };
    },
  }))
  .actions((self) => ({
    setActiveTabIndex: (activeTabIndex) => {
      self.activeTabIndex = activeTabIndex;
    },
    setSelectedCity: (selectedCity) => {
      self.selectedCity = selectedCity;
    },
    setSelectedStreet: (selectedStreet) => {
      self.selectedStreet = selectedStreet;
    },
    setSelectedHouse: (selectedHouse) => {
      self.selectedHouse = selectedHouse;
    },
    setStreetLoading: (streetLoading) => {
      self.streetLoading = streetLoading;
    },
    setHouseLoading: (houseLoading) => {
      self.houseLoading = houseLoading;
    },
    setStreets: (streets) => {
      self.streets = streets;
    },
    setBuildings: (buildings) => {
      self.buildings = buildings;
    },
    setStreet: (street) => {
      self.street = street;
    },
    setHouse: (house) => {
      self.house = house;
    },
    setApartment: (apartment) => {
      self.apartment = apartment;
    },
    setCheckedOfficeId: (checkedOfficeId?: number) => {
      if (!checkedOfficeId) {
        self.checkedOfficeId = 0;
        return;
      }
      /** Устанавливаем дефолтное значение офиса */
      if (!self.officeId) self.officeId = checkedOfficeId;
      self.checkedOfficeId = checkedOfficeId;
    },
    /** Изменение названия улицы */
    onStreetInputChange: (value: string): void => {
      self.selectedStreet = SELECT_VALUE;
      self.selectedHouse = SELECT_VALUE;
      self.house = '';
      self.apartment = '';
      self.street = value;
    },
    /** Изменение номера дома */
    onHouseChange: (value: string): void => {
      self.selectedHouse = SELECT_VALUE;
      self.house = value;
      self.apartment = '';
    },
    /** Обработчик для поля ввода номера квартиры */
    apartmentChange: (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (/^\d+$/.test(e.target.value) || e.target.value === '') {
        self.apartment = e.target.value;
      }
    },
    acceptDeliveryAddress: () => {
      if (self.activeTabIndex === 0) {
        self.deliveryType = DELIVERY_TYPE.SELF_PICKUP;
        self.officeId = self.checkedOfficeId;
        return;
      }
      self.deliveryType = DELIVERY_TYPE.COURIER;
      self.acceptedAddress = {
        city: self.selectedCity.label,
        street: self.street,
        house: self.house,
        apartment: self.apartment,
      };
    },
  }));

export type IMethodOfReceiptFormStore = Instance<
  typeof MethodOfReceiptFormStore
>;

export const methodOfReceiptFormStoreInstance = {
  activeTabIndex: 0,
  deliveryType: null,
  selectedCity: SELECT_VALUE,
  selectedStreet: SELECT_VALUE,
  selectedHouse: SELECT_VALUE,
  streetLoading: false,
  houseLoading: false,
  streets: [],
  buildings: [],
  street: '',
  house: '',
  apartment: '',
  checkedOfficeId: 0,
  officeId: null,
  acceptedAddress: null,
};
