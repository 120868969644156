/** libraries */
import styled from '@emotion/styled';
import { defaultTheme, H2, H3, Text } from 'cordis-core-ui-planeta';

/** constants */
import {
  desktop1100,
  desktop1280,
  desktop400,
  desktop430,
  desktop475,
  desktop609,
  desktop750,
  desktop940,
} from '~/components/Grid/constants';

export const StyledMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 810px;
  background-color: ${defaultTheme.colors.mimiPink};
  padding: 32px;

  @media (min-width: ${desktop400}px) {
    min-height: 740px;
  }

  @media (min-width: ${desktop430}px) {
    min-height: 680px;
  }

  @media (min-width: ${desktop475}px) {
    min-height: 630px;
  }

  @media (min-width: ${desktop609}px) {
    min-height: 590px;
  }

  @media (min-width: ${desktop750}px) {
    min-height: 537px;
  }

  @media (min-width: ${desktop940}px) {
    min-height: 400px;
    align-items: start;
  }
`;

export const StyledInformationBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${desktop940}px) {
    width: 312px;
  }

  @media (min-width: ${desktop1100}px) {
    width: 364px;
  }

  @media (min-width: ${desktop1280}px) {
    width: 410px;
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;
  right: -17px;
  bottom: -4px;
  width: calc(100% + 40px);

  @media (min-width: ${desktop400}px) {
    right: -29px;
  }

  @media (min-width: ${desktop430}px) {
    right: initial;
    width: initial;
  }

  @media (min-width: ${desktop940}px) {
    right: -8px;
    bottom: 0;
    height: 486px;
  }

  @media (min-width: ${desktop1100}px) {
    right: 18px;
  }

  @media (min-width: ${desktop1280}px) {
    right: 113px;
  }
`;

export const StyledProductFilling = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: start;
  margin-top: 14px;

  @media (min-width: ${desktop400}px) {
    width: 50%;
    margin-top: 32px;
  }

  @media (min-width: ${desktop430}px) {
    margin-top: 35px;
  }

  @media (min-width: ${desktop940}px) {
    margin-top: 21px;
  }

  @media (min-width: ${desktop1100}px) {
    margin-top: 40px;
  }

  @media (min-width: ${desktop1280}px) {
    margin-top: 60px;
  }
`;

export const StyledSim = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  align-self: end;
  margin-top: 18px;

  @media (min-width: ${desktop400}px) {
    width: 43%;
    margin-top: 0;
  }

  @media (min-width: ${desktop940}px) {
    width: 40%;
  }
`;

export const StyledSimText = styled(Text)`
  margin-top: 18px;
  width: 100%;
  font-weight: 500;
  line-height: 20px;

  @media (min-width: ${desktop940}px) {
    width: 150px;
  }

  @media (min-width: ${desktop1100}px) {
    width: 180px;
  }
`;

export const StyledH2 = styled(H2)`
  margin-bottom: 16px;
`;

export const StyledH3 = styled(H3)`
  margin: 17px 0 16px 0;
`;
