import { FC } from 'react';
import { observer } from 'mobx-react';
import { SubscriptionsProps } from './interfaces';
import { SubscriptionsProvider } from './stores/mainStore/useMainSubscriptionsStore';
import SubscriptionsContent from './SubscriptionsContent';

/** Блок "Уведомления"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=719161428
 */
const Subscriptions: FC<SubscriptionsProps> = ({
  content,
}: SubscriptionsProps) => {
  return (
    <SubscriptionsProvider fields={content.fields}>
      <SubscriptionsContent />
    </SubscriptionsProvider>
  );
};

export default observer(Subscriptions);
