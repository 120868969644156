export enum STORES {
  RU_STORE = 'ruStore',
  GOOGLE_PLAY = 'googlePlay',
  APP_GALLERY = 'appGallery',
  APP_STORE = 'appStore',
}

export enum SOCIAL {
  VKONTAKTE = 'vkontakte',
  TELEGRAM = 'telegram',
}

export const iconLinks = {
  [STORES.RU_STORE]: 'static/images/footer/ru-store.svg',
  [STORES.GOOGLE_PLAY]: 'static/images/footer/google-play.svg',
  [STORES.APP_GALLERY]: 'static/images/footer/app-gallery.svg',
  [STORES.APP_STORE]: 'static/images/footer/app-store.svg',
  [SOCIAL.VKONTAKTE]: 'static/images/footer/vkontakte.svg',
  [SOCIAL.TELEGRAM]: 'static/images/footer/telegram.svg',
};
