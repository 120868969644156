/** libraries */
import * as React from 'react';
import {
  Text,
  H3,
  defaultTheme,
  Tabs,
  Icons,
  TabsStyleTypes,
} from 'cordis-core-ui-planeta';
import { CHANNEL_NAMES } from './CallToActionTelevision.types';
/** components */
import CallToActionRow from '../CallToActionRow/CallToActionRow';
/** styles */
import { StyledTelevision } from './CallToActionTelevision.style';
import { StyledFeature, StyledLeftColumn } from '../CallToAction.style';
/** utils */
import { pluralizeAll } from '~/utils/utils';
/** stores */
import { useCallToActionStore } from '../stores/MainCallToAction/useMainCallToActionStore';

/* Дочерний компонент "Цифровое телевидение", компонента CallToAction */
const CallToActionTelevision: React.FC = () => {
  const {
    allChannelCountChannels,
    channelCountChannels,
    hdChannelCountChannels,
    uhdChannelCountChannels,
    isTransformer,
  } = useCallToActionStore();
  /* Варианты склонений количества каналов */
  const CHANNELS_VARIANTS: string[] = [
    'телеканал',
    'телеканала',
    'телеканалов',
  ];
  /* Варианты вкладок таба */
  const tabsValue = [
    ...Object.values(CHANNEL_NAMES).filter((k) => {
      const isChannelName = typeof k === 'string';
      // Скрываем вкладку 4K в случае отсутствия телеканалов соответствующего качества
      return !uhdChannelCountChannels
        ? isChannelName && k !== CHANNEL_NAMES.uhd
        : isChannelName;
    }),
  ];
  /* Текущий индекс таба */
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
  /* Текущее значение таба */
  const [activeTabValue, setActiveTabValue] = React.useState<string>(
    pluralizeAll(channelCountChannels, CHANNELS_VARIANTS),
  );
  /* Событие при изменении таба */
  const onChangeTab = (tabIndex: number, tabValue: string) => {
    setActiveTabIndex(tabIndex);
    switch (tabIndex) {
      case 0:
        setActiveTabValue(
          pluralizeAll(channelCountChannels, CHANNELS_VARIANTS),
        );
        break;
      case 1:
        setActiveTabValue(
          pluralizeAll(hdChannelCountChannels, CHANNELS_VARIANTS),
        );
        break;
      case 2:
        setActiveTabValue(
          pluralizeAll(uhdChannelCountChannels, CHANNELS_VARIANTS),
        );
        break;
      default:
        setActiveTabValue(
          pluralizeAll(channelCountChannels, CHANNELS_VARIANTS),
        );
    }
  };

  return (
    <CallToActionRow
      title={
        <Text>
          Цифровое телевидение<sup>&nbsp;1</sup>
        </Text>
      }
    >
      <StyledLeftColumn>
        <StyledTelevision isTransformer={isTransformer}>
          {!isTransformer && (
            <Tabs
              value={tabsValue}
              onChange={onChangeTab}
              activeTabIndex={activeTabIndex}
              width="100px"
              styleType={TabsStyleTypes.SECONDARY}
            />
          )}
          <H3>{!isTransformer ? activeTabValue : 'Твой набор каналов'}</H3>
          {isTransformer && (
            <Text
              color={defaultTheme.colors.shadow}
            >{`Выбирай из ${pluralizeAll(
              allChannelCountChannels,
              CHANNELS_VARIANTS,
            )}`}</Text>
          )}
        </StyledTelevision>
      </StyledLeftColumn>
      <StyledFeature>
        <Icons.OkIcon />
        <Text color={defaultTheme.colors.shadow}>
          Портал цифрового телевидения Планеты
        </Text>
      </StyledFeature>
    </CallToActionRow>
  );
};

export default React.memo(CallToActionTelevision);
