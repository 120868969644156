/** libraries */
import { observer } from 'mobx-react';
import {
  ButtonStyleTypes,
  Icons,
  ButtonIconPosition,
} from 'cordis-core-ui-planeta';
/** styles */
import { StyledTabButton, StyledTabs } from '../styles';
/** stores */
import useMobileStore from '../../../store/useMobileStore';
import { useRootStore } from '~/stores/RootStore';
/** utils */
import { maskPhone } from '~/utils/utils';
/** constants */
import { DELIVERY_TYPE } from '../../../types';

const Tabs = (): JSX.Element => {
  const {
    cityStore: { officeInfos },
    pab2cMobileStore: { mobilePhonesInfoData },
  } = useRootStore();

  const {
    changeNumberStore: { newNumberInfo, setIsShowAvailableNumbersSp },
    methodOfReceiptFormStore: { deliveryType, deliveryAddress },
    choosingTheReceivingMethodStore: { setIsShowChoosingTheReceivingMethod },
  } = useMobileStore();

  const getNumberSnoska = () => {
    if (!newNumberInfo) return 'Подходящий вам';
    return newNumberInfo?.price > 0
      ? `${newNumberInfo.categoryName} — ${newNumberInfo.price} ₽`
      : 'Бесплатный';
  };

  const getDeliverySnoska = () => {
    switch (deliveryType) {
      case DELIVERY_TYPE.SELF_PICKUP:
        return 'Получение в офисе';
      case DELIVERY_TYPE.COURIER:
        return `Курьер — ${mobilePhonesInfoData.simCardCourierDeliveryPrice} ₽`;
      default:
        return 'Курьер или в офисе';
    }
  };

  return (
    <StyledTabs>
      <StyledTabButton
        styleType={ButtonStyleTypes.ACTION}
        icon={
          newNumberInfo ? <Icons.CheckPinkIcon /> : <Icons.PhoneNumberIcon />
        }
        secondIcon={<Icons.LeftArrowIcon />}
        secondIconPosition={ButtonIconPosition.RIGHT}
        snoska={getNumberSnoska()}
        key="1"
        onClick={() => setIsShowAvailableNumbersSp(true)}
      >
        {newNumberInfo ? maskPhone(newNumberInfo.msisdn) : 'Выберите номер'}
      </StyledTabButton>
      <StyledTabButton
        styleType={ButtonStyleTypes.ACTION}
        icon={
          deliveryType ? <Icons.CheckPinkIcon /> : <Icons.DeliverySimIcon />
        }
        secondIcon={<Icons.LeftArrowIcon />}
        secondIconPosition={ButtonIconPosition.RIGHT}
        snoska={getDeliverySnoska()}
        key="2"
        onClick={() => setIsShowChoosingTheReceivingMethod(true)}
      >
        {deliveryType ? deliveryAddress(officeInfos) : 'Способ получения'}
      </StyledTabButton>
    </StyledTabs>
  );
};

export default observer(Tabs);
