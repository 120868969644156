import { Instance, types } from 'mobx-state-tree';
import { toJS } from 'mobx';
/** interfaces */
import { SubscriptionsFields } from '../../interfaces';
import { DevicesFieldsModel } from '~/stores/models/DevicesModel';

const ProlongationModel = types.model('ProlongationModel', {
  header: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  image: types.maybeNull(types.string),
  legal: types.maybeNull(types.string),
});

const SubscriptionsFieldsModel = types.model('SubscriptionsFieldsModel', {
  prolongation: types.maybe(ProlongationModel),
  allDevices: types.array(DevicesFieldsModel),
});

const SubscriptionsModel = types
  .model('SubscriptionsModel', {
    fieldsData: types.maybeNull(SubscriptionsFieldsModel),
  })
  .views((self) => ({
    get fields() {
      return toJS(self.fieldsData);
    },
    get allDevices() {
      return toJS(self.fieldsData.allDevices);
    },
    get prolongationHeader() {
      return self.fieldsData.prolongation.header;
    },
    get prolongationText() {
      return self.fieldsData.prolongation.text;
    },
    get prolongationImage() {
      return self.fieldsData.prolongation.image;
    },
    get prolongationLegal() {
      return self.fieldsData.prolongation.legal;
    },
  }));

const createStore = (fieldsData: SubscriptionsFields): ISubscriptionsStore => {
  return SubscriptionsModel.create({
    fieldsData,
  });
};
export type ISubscriptionsStore = Instance<typeof SubscriptionsModel>;
export default createStore;
