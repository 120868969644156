import { STORAGE_TYPE, UNIT_TYPE } from './types';

export const NO_SIM_IMAGE = 'static/images/mobile-buy-sim.svg';
export const SUCCESS_IMAGE = 'static/images/mobile/success-order.svg';
export const SUCCESS_SMALL_IMAGE =
  'static/images/mobile/success-order-small.svg';

export const UNIT_TYPE_NAME = {
  [STORAGE_TYPE.INTERNET]: 'Гб',
  [STORAGE_TYPE.CALL]: 'минут',
  [STORAGE_TYPE.SMS]: 'СМС',
  [STORAGE_TYPE.ROAMING_INTERNET_ZONE_1]: 'Мб',
  [STORAGE_TYPE.ROAMING_INTERNET_ZONE_2]: 'Мб',
  [STORAGE_TYPE.ROAMING_INTERNET_ZONE_3]: 'Мб',
  [STORAGE_TYPE.ROAMING_INTERNET_ZONE_4]: 'Мб',
};

export const UNIT_NAME = {
  [UNIT_TYPE.GigaByte]: 'Гб',
  [UNIT_TYPE.Minute]: 'минут',
};

export const ZERO_BALANCE_TEXT = {
  [STORAGE_TYPE.INTERNET]: 'Мобильный интернет отключен',
  [STORAGE_TYPE.CALL]: 'Действует поминутная тарификация',
  [STORAGE_TYPE.SMS]: '',
};

export const PLURAL_PACKAGES = ['пакет', 'пакета', 'пакетов'];

export enum SERVICE_STATUS {
  ON = 'Обслуживается',
  STOP = 'Приостановлен',
  BLOCK = 'Заблокирован',
  SIM_NOT_ACTIVATED = 'Услуга не активирована',
  NOT_LINKED_TO_SIM = 'Не привязан к SIM',
}

export enum CHANGE_NUMBER_STATUS_SP_TYPE {
  /** Блокировка номера */
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK',
  /** Отключение номера */
  DISABLE = 'DISABLE',
}

/** Подтверждение паспортных данных */
export enum PASSPORT_CONFIRM {
  GOSUSLUGI = 'GOSUSLUGI',
  OFFICE = 'OFFICE',
}

export enum ACTIVATION_METHOD_TYPE {
  /** Новый номер */
  NEW,
  /** Перенос номера */
  TRANSFER,
}

export enum TABS {
  MY_CONNECTION,
  ROAMING,
}

export const SELECT_VALUE = {
  label: '',
  value: '',
};

export const DELIVERY_TYPE_TABS = [
  {
    label: 'В офисе',
    value: 0,
  },
  {
    label: 'Курьером',
    value: 1,
  },
];

export const DELIVERY_TYPE_TABS_LABELS = DELIVERY_TYPE_TABS.map(
  (item) => item.label,
);

export const DELIVERY_TYPE_TABS_VALUES = DELIVERY_TYPE_TABS.map(
  (item) => item.value,
);

export enum ESIA_EXEPTIONS_TEXT {
  VerifierHasNoDataException = 'Паспортные данные на портале Госуслуг отличаются от указанных в соглашении. Проверьте актуальность ПД на госуслугах и повторите.',
  ClientDataDoesNotMatchException = 'Паспортные данные на портале Госуслуги и паспортные данные на договоре с Планетой отличаются.  Проверьте данные на Госуслугах. Если всё корректно, то обратитесь по телефону ХХХ для решения данного вопроса.',
  DEFAULT = 'Не получилось обновить ваши данные. Попробуйте ещё раз или обратитесь в тех поддержку.',
}

export enum QP_PAB2C_MOBILE {
  ADD_MOBILE_NUMBER = 'addMobileNumber',
  SELECT_NUMBER = 'selectNumber',
  GOSUSLUGI_CONFIRMATION = 'isOpenGosuslugiConfirmation',
  PORTATION = 'portationNumber',
}

export const GOLD_CATEGORY_INDEX = 3;

/** идентификатор элемента блока «РМП. Моя связь» */
export const PAB2C_MOBILE_ID = '#mobile';
