/** libraries */
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, Loader, SidePage } from 'cordis-core-ui-planeta';
/** utils */
/** components */
import ErrorHandler from './ErrorHandler';
import SuccessActivation from '../../Components/SuccessActivation/SuccessActivation';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';
import ActivationPoints from './ActivationPoints';

const SimCardActivation = (): JSX.Element => {
  const {
    pab2cMobileStore: { notActivatedSims },
  } = useRootStore();

  const {
    simCardActivationStore: {
      isShowSimCardActivation,
      getDocumentVerificationInfo,
      resetSimCardActivationStoreSP,
      isDocumentVerified,
      activateSimCards,
      isActivationLoading,
      isGetActivationInfoLoading,
      result,
    },
    changeNumberStore: { getChangeNumberData },
    bindNewNumberStore: { result: bindNumberResult },
  } = useMobileStore();

  useEffect(() => {
    if (!isShowSimCardActivation) return;
    (async () => {
      await Promise.all([getChangeNumberData(), getDocumentVerificationInfo()]);
    })();
  }, [isShowSimCardActivation]);

  const isShowErrorHandler =
    (bindNumberResult.isResult && !bindNumberResult.isCorrect) ||
    (result.isResult && !result.isCorrect);

  const isShowLoader =
    isGetActivationInfoLoading &&
    !result.isResult &&
    !bindNumberResult.isResult;

  const isShowActivationPoints =
    !isGetActivationInfoLoading &&
    !result.isResult &&
    !bindNumberResult.isResult;

  const currentPointsCounter =
    (isDocumentVerified ? 1 : 0) +
    notActivatedSims.filter((item) => item.msisdn).length;

  const maxPointsCounter = notActivatedSims.length + 1;

  const Footer = () => (
    <Button
      onClick={() =>
        activateSimCards(notActivatedSims.map((item) => item.simCardId))
      }
      disabled={currentPointsCounter !== maxPointsCounter}
      loading={isActivationLoading}
    >
      Активировать SIM
    </Button>
  );

  if (!isShowSimCardActivation) return null;

  return (
    <SidePage
      show={isShowSimCardActivation}
      headerText={
        !isShowErrorHandler &&
        `Активация SIM-карт${notActivatedSims.length > 1 ? '' : 'ы'}`
      }
      onCloseClick={resetSimCardActivationStoreSP}
      footerContainer={!isShowErrorHandler && !result.isResult && <Footer />}
    >
      {isShowErrorHandler && <ErrorHandler />}
      {result.isResult && result.isCorrect && <SuccessActivation />}
      {isShowLoader && <Loader small />}
      {isShowActivationPoints && <ActivationPoints />}
    </SidePage>
  );
};

export default observer(SimCardActivation);
