/** libraries */
import { types, flow } from 'mobx-state-tree';
import { toJS } from 'mobx';
import * as flags from 'country-flag-icons/string/3x2';
/** utils */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
/** api */
import { getRoamingZonesBySeriesCode } from '~/api/api';

const RequestsStateModel = types.model('RoamingStoreStoreRequestsState', {
  getRoamingZonesBySeriesCode: createApiPathModel(
    'GET /Mobile/MobilePhoneNumber/getRoamingZonesBySeriesCode',
  ),
});

const CountryModel = types.model({
  name: types.string,
  code: types.string,
});

const ExpandedCountryModel = types.compose(
  CountryModel,
  types.model({
    icon: types.maybe(types.string),
  }),
);

const RoamingPriceModel = types.model({
  id: types.number,
  name: types.string,
  price: types.number,
});

const RoamingZonesModel = types.model({
  name: types.string,
  countries: types.array(CountryModel),
  voiceTrafficRoamingPricesItems: types.array(RoamingPriceModel),
  smsRoamingPricesItems: types.array(RoamingPriceModel),
  packageRoamingPricesItems: types.array(RoamingPriceModel),
});

export const RoamingStore = types
  .model('RoamingStore', {
    requestsState: RequestsStateModel,
    isShowRoamingSP: types.boolean,
    isShowCountryPrices: types.boolean,
    countries: types.array(ExpandedCountryModel),
    searchValue: types.string,
    selectedCountryCode: types.string,
    roamingZones: types.array(RoamingZonesModel),
  })
  .views((self) => ({
    get isLoading() {
      return self.requestsState.getRoamingZonesBySeriesCode.isLoading;
    },
    get isFetched() {
      return self.requestsState.getRoamingZonesBySeriesCode.isFetched;
    },
    get filteredCountries() {
      if (!self.searchValue) return toJS(self.countries);
      const filteredItems = self.countries.filter(({ name }) => {
        return name
          .toLowerCase()
          .includes(self.searchValue.trim().toLowerCase());
      });
      return toJS(filteredItems);
    },
    get selectedCountry() {
      return self.countries.find(
        (country) => country.code === self.selectedCountryCode,
      );
    },
    /** Информация о роуминговой зоне, которой принадлежит выборанная пользователем страна */
    get roamingZoneInfo() {
      const roamingZoneInfo = self.roamingZones.find((zone) =>
        zone.countries.some(
          (country) => country.code === self.selectedCountryCode,
        ),
      );
      return roamingZoneInfo;
    },
    getRoamingCountriesByPackage(id: number) {
      const zone = self.roamingZones.find((item) =>
        item.packageRoamingPricesItems.find(
          (packageItem) => packageItem.id === id,
        ),
      );
      return zone.countries.map((country) => country.name);
    },
  }))
  .actions((self) => ({
    setIsShowRoamingSP: (isShow: boolean) => {
      self.isShowRoamingSP = isShow;
    },
    setIsShowCountryPrices: (isShow: boolean) => {
      self.isShowCountryPrices = isShow;
    },
    setSearchValue: (searchValue: string) => {
      self.searchValue = searchValue;
    },
    setSelectedCountryCode: (code: string) => {
      self.selectedCountryCode = code;
    },
    getRoamingZonesBySeriesCode: flow(function* (seriesCode: string) {
      self.requestsState.getRoamingZonesBySeriesCode.reset();
      self.requestsState.getRoamingZonesBySeriesCode.setLoading();
      try {
        const res = yield getRoamingZonesBySeriesCode(seriesCode);
        self.roamingZones = res;
        const countryList = res.flatMap(
          (roamingZoneInfo) => roamingZoneInfo.countries,
        );
        const expandedCountryList = countryList.map(({ code, name }) => ({
          code,
          name,
          icon: flags[code],
        }));
        self.countries = expandedCountryList;
        self.requestsState.getRoamingZonesBySeriesCode.setSuccess();
      } catch (e) {
        console.error('getRoamingZonesBySeriesCode', e);
        self.requestsState.getRoamingZonesBySeriesCode.setFail();
      }
    }),
    onCloseRoamingStoreSP: () => {
      if (self.isShowCountryPrices) {
        self.isShowCountryPrices = false;
        return;
      }
      self.isShowRoamingSP = false;
      self.searchValue = '';
      self.selectedCountryCode = '';
    },
  }));

export const roamingStoreInstance = {
  requestsState: {
    getRoamingZonesBySeriesCode: defaultModelState,
  },
  isShowRoamingSP: false,
  isShowCountryPrices: false,
  countries: [],
  searchValue: '',
  selectedCountryCode: '',
  roamingZones: [],
};
