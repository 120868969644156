/* Интерфейсы */
import { Dispatch, SetStateAction } from 'react';
import {
  ChannelsInfoProps,
  PriceInfoProps,
  TagsProps,
  SummaryFieldsProps,
} from '../ProductTemplate.interfaces';
import {
  ActionFields,
  MobileInfo,
} from '~/components/Blocks/Templates/Summary/Summary.types';
import { SausageProps } from '~/components/Blocks/Shared/SummarySausages/types';

/* Свойства компонента ProductSelectorItem */
export interface ProductSelectorItemProps {
  /* Теги продукта */
  tags?: TagsProps[];
  /* Наименование продукта */
  seriesName: string;
  /* Наименование маркетинг категории */
  marketingName: string;
  /* Описание продукта */
  marketingText: string;
  /* Заявленная скорость */
  speedBaseText: string;
  /* Информация о стоимости обслуживания */
  priceInfo: PriceInfoProps;
  /* Информация о каналах на продукте */
  channelsInfo: ChannelsInfoProps;
  /* Ссылка на продукт */
  productFeedLink?: string;
  marketingGroupCode: string;
  /* Флаг годового продукта */
  isAnnual: boolean;
  /* Флаг бизнес продукта */
  isBusiness: boolean;
  tariffId: number;
  /** Убрать шапку с фильтрами */
  withoutFilters?: boolean;
  /** Информация об акции */
  action?: ActionFields;
  /** Соглашение */
  setAgreement?: Dispatch<SetStateAction<string>>;
  /** Ошибки смены продукта * */
  setProductChangeError?: Dispatch<SetStateAction<string>>;
  tariff: SummaryFieldsProps;
  mobileInfo?: MobileInfo;
  stripColor?: string;
  /** Преимущества */
  features?: SausageProps[];
}

/* Наименование вкладок таба */
export enum TabNames {
  DAILY = 'День',
  MONTH = 'Месяц',
}
