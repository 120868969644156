/** libraries */
import { observer } from 'mobx-react';
import { Text } from 'cordis-core-ui-planeta';
/** styles */
import { StyledWarning } from '../styles';
/** constants */
import { OPERATING_STATE } from '~/constants/common';
/** stores */
import { useRootStore } from '~/stores/RootStore';

/** Компонент с текстом предупреждения, если договор заблокирован или ожидает подключения */
const ServiceStatusWarning = (): JSX.Element => {
  const {
    contractStateStore: { contractState },
  } = useRootStore();

  return (
    <StyledWarning>
      <Text lineHeight="24px">
        {contractState === OPERATING_STATE.PROVIDER_BLOCK_DEBT &&
          'Выбор номера будет доступен после возобновления договора.'}
        {contractState === OPERATING_STATE.NEW &&
          'Выбор номера будет доступен после подключения договора.'}
      </Text>
    </StyledWarning>
  );
};
export default observer(ServiceStatusWarning);
