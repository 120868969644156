import styled from '@emotion/styled';
import {
  defaultTheme,
  H3,
  Switcher,
  Text,
  Button,
} from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledWarning = styled.div`
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  > span {
    padding: 16px;
    background-color: ${defaultTheme.colors.backgroundWarning};
  }
`;

export const StyledH3 = styled(H3)`
  margin-top: -18px;
  margin-bottom: 9px;
`;

export const StyledToggle = styled.div`
  margin-top: 30px;
  margin-bottom: 32px;
  max-width: 430px;
`;

export const StyledSwitcher = styled(Switcher)`
  display: block;
  margin-bottom: 16px;
`;

export const StyledText = styled(Text)`
  margin-bottom: 12px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 12px;

  @media (min-width: ${desktop940}px) {
    width: unset;
  }
`;

export const StyledError = styled(Text)`
  display: block;
  margin: 30px 0;
`;
