import { defaultTheme } from 'cordis-core-ui-planeta';
import { css } from '@emotion/react';

export const buttonHover = css`
  border: 1px solid ${defaultTheme.colors.white};

  &:hover {
    color: ${defaultTheme.colors.white};
    background: ${defaultTheme.colors.black};
    border: 1px solid ${defaultTheme.colors.black};
  }
`;
