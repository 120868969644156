export const imageSettings = {
  mainDesktop: {
    url: 'static/images/mobile-oversized.png',
    width: 615,
    height: 486,
  },
  mainMobile: {
    url: 'static/images/mobile-oversized-small.png',
    width: 393,
    height: 287,
  },
  scale: { url: 'static/images/mobile-scale.png', width: 157, height: 26 },
  sim: { url: 'static/images/mobile-sim.png', width: 94, height: 92 },
};
