/** libraries */
import { Instance, types } from 'mobx-state-tree';
import { ColorsType, defaultTheme } from 'cordis-core-ui-planeta';
/** types */
import { STORAGE_TYPE } from '../types';
import {
  ChangeNumberStatusStore,
  changeNumberStatusStoreInstance,
} from './ChangeNumberStatusStore';
import { ChangeNameStore, changeNameStoreInstance } from './ChangeNameStore';
import {
  ChoosingTheReceivingMethodStore,
  choosingTheReceivingMethodStoreInstance,
} from './ChoosingTheReceivingMethodStore';
import {
  MethodOfReceiptFormStore,
  methodOfReceiptFormStoreInstance,
} from './MethodOfReceiptFormStore';
import {
  SIMCardReplacementStore,
  SIMCardReplacementStoreInstance,
} from './SIMCardReplacementStore';
import {
  ChangeNumberStore,
  changeNumberStoreInstance,
} from './ChangeNumberStore';
import {
  ChoosingTheActivationMethodStore,
  choosingTheActivationMethodStoreInstance,
} from './ChoosingTheActivationMethodStore';
import {
  SpecifyTheTransferNumberStore,
  specifyTheTransferNumberStoreInstance,
} from './SpecifyTheTransferNumberStore';
import {
  SimCardActivationStore,
  simCardActivationStoreInstance,
} from './SimCardActivationStore';
import {
  PassportDataConfirmationStore,
  passportDataConfirmationStoreInstance,
} from './PassportDataConfirmationStore';
import {
  BindNewNumberStore,
  bindNewNumberStoreInstance,
} from './BindNewNumberStore';
import {
  BindSubscriptionStore,
  bindSubscriptionStoreInstance,
} from './BindSubscriptionStore';
import {
  ToggleRoamingStore,
  toggleRoamingStoreInstance,
} from './ToggleRoamingStore';
import { RoamingStore, roamingStoreInstance } from './RoamingStore';

const MobileStore = types
  .model('MobileStore', {
    changeNumberStatusStore: ChangeNumberStatusStore,
    changeNameStore: ChangeNameStore,
    choosingTheReceivingMethodStore: ChoosingTheReceivingMethodStore,
    methodOfReceiptFormStore: MethodOfReceiptFormStore,
    simCardReplacementStore: SIMCardReplacementStore,
    changeNumberStore: ChangeNumberStore,
    bindNewNumberStore: BindNewNumberStore,
    bindSubscriptionStore: BindSubscriptionStore,
    choosingTheActivationMethodStore: ChoosingTheActivationMethodStore,
    specifyTheTransferNumberStore: SpecifyTheTransferNumberStore,
    simCardActivationStore: SimCardActivationStore,
    passportDataConfirmationStore: PassportDataConfirmationStore,
    roamingStore: RoamingStore,
    toggleRoamingStore: ToggleRoamingStore,
    isShowMyNumbers: types.boolean,
    isShowDetailingTheLeftovers: types.boolean,
    /** Тип детализации */
    detailingType: types.maybeNull(
      types.enumeration(Object.values(STORAGE_TYPE)),
    ),
    droplistSelectedNumberId: types.string,
    isShowHeaderDroplist: types.boolean,
    tabIndex: types.number,
  })
  .views((self) => ({
    get detailingSPHeaderText() {
      const headers = {
        [STORAGE_TYPE.CALL]: 'Звонки',
        [STORAGE_TYPE.INTERNET]: 'Мобильный интернет',
      };
      return headers[self.detailingType] || null;
    },
    getIsRoaming(type) {
      return [
        STORAGE_TYPE.ROAMING_INTERNET_ZONE_1,
        STORAGE_TYPE.ROAMING_INTERNET_ZONE_2,
        STORAGE_TYPE.ROAMING_INTERNET_ZONE_3,
        STORAGE_TYPE.ROAMING_INTERNET_ZONE_4,
      ].includes(type);
    },
  }))
  .actions((self) => ({
    /** Открыть сп "Мои номера" */
    setIsShowMyNumbers: (isShowMyNumbers) => {
      self.isShowMyNumbers = isShowMyNumbers;
    },
    /** Открыть сп детализация расходов */
    setIsShowDetailingTheLeftovers: (
      isShowDetailingTheLeftovers: boolean,
      type: STORAGE_TYPE,
    ) => {
      if (type) self.detailingType = type;
      self.isShowDetailingTheLeftovers = isShowDetailingTheLeftovers;
    },
    setDroplistSelectedNumberId: (msisdn: string) => {
      self.droplistSelectedNumberId = msisdn;
    },
    setIsShowHeaderDroplist: (isShow: boolean) => {
      self.isShowHeaderDroplist = isShow;
    },
    setTabIndex: (tabIndex: number) => {
      self.tabIndex = tabIndex;
    },
    /** Цвет шкалы накопителя */
    getFillerColorForStorage: (
      item,
      isLeftMinimumQuantity,
      jointCurrentQuantity,
    ) => {
      if (self.getIsRoaming(item?.mobileBalanceType))
        return defaultTheme.colors.lightSeaGreen;
      if (item?.currentQuantity === item?.maxQuantity)
        return `linear-gradient(90deg, ${defaultTheme.colors.green} 78%, ${defaultTheme.colors.cadmiumYellow} 100%)` as ColorsType;
      if (isLeftMinimumQuantity) return defaultTheme.colors.warning;
      if (jointCurrentQuantity === 0) return defaultTheme.colors.light;
      return defaultTheme.colors.green;
    },
  }));

export type IMobileStore = Instance<typeof MobileStore>;

export const createMobileStore = (): IMobileStore => {
  return MobileStore.create({
    changeNumberStatusStore: ChangeNumberStatusStore.create(
      changeNumberStatusStoreInstance,
    ),
    changeNameStore: ChangeNameStore.create(changeNameStoreInstance),
    choosingTheReceivingMethodStore: ChoosingTheReceivingMethodStore.create(
      choosingTheReceivingMethodStoreInstance,
    ),
    methodOfReceiptFormStore: MethodOfReceiptFormStore.create(
      methodOfReceiptFormStoreInstance,
    ),
    simCardReplacementStore: SIMCardReplacementStore.create(
      SIMCardReplacementStoreInstance,
    ),
    changeNumberStore: ChangeNumberStore.create(changeNumberStoreInstance),
    bindNewNumberStore: BindNewNumberStore.create(bindNewNumberStoreInstance),
    bindSubscriptionStore: BindSubscriptionStore.create(
      bindSubscriptionStoreInstance,
    ),
    choosingTheActivationMethodStore: ChoosingTheActivationMethodStore.create(
      choosingTheActivationMethodStoreInstance,
    ),
    specifyTheTransferNumberStore: SpecifyTheTransferNumberStore.create(
      specifyTheTransferNumberStoreInstance,
    ),
    simCardActivationStore: SimCardActivationStore.create(
      simCardActivationStoreInstance,
    ),
    passportDataConfirmationStore: PassportDataConfirmationStore.create(
      passportDataConfirmationStoreInstance,
    ),
    roamingStore: RoamingStore.create(roamingStoreInstance),
    toggleRoamingStore: ToggleRoamingStore.create(toggleRoamingStoreInstance),
    isShowMyNumbers: false,
    isShowDetailingTheLeftovers: false,
    detailingType: null,
    droplistSelectedNumberId: '',
    isShowHeaderDroplist: false,
    tabIndex: 0,
  });
};
