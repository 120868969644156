import styled from '@emotion/styled';
import { defaultTheme, Radio, Select, Tabs } from 'cordis-core-ui-planeta';

export const StyledTabs = styled(Tabs)`
  display: block;
  margin-bottom: 12px;
`;

export const StyledSelect = styled(Select)`
  margin-bottom: 12px;
`;

export const StyledRadio = styled(Radio)`
  margin-bottom: 12px;
`;

export const StyledWarning = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  > span {
    padding: 16px;
    max-width: 576px;
    background-color: ${defaultTheme.colors.backgroundWarning};
    margin-bottom: 16px;
  }
`;
