/** libraries */
import { useMediaQuery } from 'react-responsive';
import { defaultTheme } from 'cordis-core-ui-planeta';
/** styles */
import { StyledH3, StyledImg, StyledSquircle, StyledText } from './styles';
/** constants */
import { McBannerTemplates } from '../../constants';
import { desktop940 } from '~/components/Grid/constants';
/** config */
import CONFIG from '../../config';

const SinglePersonalAccount = (): JSX.Element => {
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const { header, info } = CONFIG[McBannerTemplates.singlePersonalAccount].text;
  return (
    <StyledSquircle cornerRadius={isDesktop940 ? 86 : 70} cornerSmoothing={1}>
      <StyledH3 color={defaultTheme.colors.white}>{header}</StyledH3>
      <StyledText color={defaultTheme.colors.white}>{info}</StyledText>
      <StyledImg />
    </StyledSquircle>
  );
};

export default SinglePersonalAccount;
