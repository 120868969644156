import styled from '@emotion/styled';
import { Button } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledButton = styled(Button)`
  width: 100%;

  @media (min-width: ${desktop940}px) {
    width: unset;
  }
`;
