/** Libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
import { defaultTheme, Icons, LeadingText, Text } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** styles */
import {
  StyledIcon,
  StyledInfoPanel,
  StyledLinkButton,
  StyledPhoneNumberInfo,
} from './style';
/** components */
import HeaderDroplist from './HeaderDroplist';
/** constants */
import { desktop1100, desktop940 } from '~/components/Grid/constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../store/useMobileStore';
import { OPERATING_STATE } from '~/constants/common';

const BaseProduct: FC = () => {
  const {
    pab2cMobileStore: { numberOfPhones },
    summaryDataStore: { maxCountMobilePhoneNumberForBind },
    contractStateStore: { contractState },
  } = useRootStore();
  const { setIsShowMyNumbers } = useMobileStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  const isNewContract = contractState === OPERATING_STATE.NEW;

  return (
    <StyledInfoPanel>
      <StyledPhoneNumberInfo>
        <StyledIcon icon={<Icons.SimPinkIcon />} />
        <div>
          <LeadingText color={defaultTheme.colors.black}>
            {numberOfPhones}
            <Text
              color={defaultTheme.colors.gray}
            >{`\u00A0/\u00A0${maxCountMobilePhoneNumberForBind}`}</Text>
          </LeadingText>
        </div>
      </StyledPhoneNumberInfo>
      {isDesktop1100 && !isNewContract && (
        <StyledLinkButton
          onClick={() => {
            setIsShowMyNumbers(true);
          }}
        >
          Мои номера
        </StyledLinkButton>
      )}
      {isDesktop940 && <HeaderDroplist />}
    </StyledInfoPanel>
  );
};

export default observer(BaseProduct);
