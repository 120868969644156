/** libraries */
import { observer } from 'mobx-react';
import { Text, LinkButton, Icons, H2, H3 } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** styles */
import { StyledIcon } from '../../../style';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../../store/useMobileStore';
import useConnectionStore from '~/components/ConnectionWizard/store/useConnectionStore';
/** constants */
import { DEFAULT_ERROR } from '~/constants/common';
import { desktop1100 } from '~/components/Grid/constants';
import { BIND_NUMBER_ERROR_TYPE } from '../constants';

const ErrorHandler = (): JSX.Element => {
  const {
    authStore: { auth },
    summaryDataStore: { maxCountMobilePhoneNumberForBind },
  } = useRootStore();

  const { toggleConnectionWizardVisible } = useConnectionStore();

  const {
    bindNewNumberStore: { errorType },
  } = useMobileStore();

  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  return (
    <>
      <StyledIcon icon={<Icons.NotOkBigIcon />} />
      {isDesktop1100 ? <H2>{DEFAULT_ERROR}</H2> : <H3>{DEFAULT_ERROR}</H3>}
      {errorType === BIND_NUMBER_ERROR_TYPE.SIM_LIMIT && (
        <>
          <Text>
            {`На договоре ${auth.contractName} уже заказано ${maxCountMobilePhoneNumberForBind} номеров.
            Вы можете заменить один или несколько номеров на текущих Sim-картах.
            Если необходим дополнительный номер, заключите новый договор.`}
          </Text>
          <LinkButton onClick={toggleConnectionWizardVisible}>
            Подать заявку на новый договор
          </LinkButton>
        </>
      )}
    </>
  );
};

export default observer(ErrorHandler);
