import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { defaultTheme } from 'cordis-core-ui-planeta';

export const StyledNumberListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  background-color: ${defaultTheme.colors.light};
  padding: 16px;

  > span {
    margin-right: 16px;
    & > span {
      & > button {
        justify-content: center;
        background: white;
        width: 60px;
        height: 54px;
        padding: 0;
        .wrapper {
          width: auto;
        }
      }
    }
  }
`;
export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledHead = styled.div`
  display: flex;
  margin-bottom: 16px;
  svg {
    margin-right: 16px;
  }
`;
export const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-right: 16px;
  }
`;
export const StyledServiceStatus = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledStatus = styled.div<{ color: string }>`
  ${({ color }) => {
    return css`
      background-color: ${color};
      width: 14px;
      height: 14px;
      border-radius: 50%;
      margin-right: 8px;
    `;
  }}
`;

export const StyledMobileTitle = styled.header`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const StyledMobileStatus = styled.span`
  display: flex;
  align-items: center;
`;
