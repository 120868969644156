import styled from '@emotion/styled';
import { Squircle } from '@squircle-js/react';
import { defaultTheme, H3, Text } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop940,
  desktop370,
} from '~/components/Grid/constants';
import { McBannerTemplates } from '../../constants';
import CONFIG from '../../config';

const { phone } = CONFIG[McBannerTemplates.singlePersonalAccount].images;

export const StyledSquircle = styled(Squircle)`
  height: 400px;
  padding: 40px 32px;
  background-color: ${defaultTheme.colors.outerSpace};
  overflow: hidden;
  position: relative;
`;

export const StyledH3 = styled(H3)`
  max-width: 265px;
  margin-bottom: 19px;
`;

export const StyledText = styled(Text)`
  max-width: 311px;
  line-height: 24px;
`;

export const StyledImg = styled.span`
  background-image: url(${phone});
  background-repeat: no-repeat;
  position: absolute;
  width: 305px;
  height: 232px;
  right: 0;
  bottom: 0;

  @media (max-width: ${desktop370}px),
    (min-width: ${desktop940}px) and (max-width: ${desktop1100}px) {
    right: -55px;
    bottom: -66px;
  }
`;
