/** libraries */
import { FC, memo, useEffect } from 'react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react';
/** components */
import { Button, ButtonStyleTypes } from 'cordis-core-ui-planeta';
import {
  desktop1100,
  desktop1280,
  desktop500,
  desktop940,
} from '~/components/Grid/constants';
import { SIDE_PAGES } from '~/components/AuthWizard/constants';
import { VIEW_TYPES } from '../types';
/** store */
import useVacationStateStore from '../../../Vacation/store/useVacationStore';
import { useRootStore } from '~/stores/RootStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
import useOperatingStateModelStore from './OperatingStateWizard/store/useOperatingStateStore';

const Root = styled.div`
  margin-top: 16px;
`;

const Suspended: FC = () => {
  const {
    authStore: { isAuth },
    vacationStore: { isActivated, isOrdered, getVacation },
    contractStateStore: { updateAuthCondition },
  } = useRootStore();
  const { setIsVacationShutdownOpen } = useVacationStateStore();
  const {
    openSPAfterAuthorizationState,
    setOpenSPAfterAuthorizationState,
  } = useMakeAuthStore();
  const {
    setIsResumeOperatingStateWizardShow,
    setViewType,
    resumeOperatingState,
  } = useOperatingStateModelStore();

  const isDesktop1280 = useMediaQuery({
    query: `(min-width: ${desktop1280}px)`,
  });
  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });
  const isDesktop500 = useMediaQuery({
    query: `(min-width: ${desktop500}px)`,
  });

  const getButtonText = () => {
    if (isOrdered)
      return `Отменить ${
        isDesktop1100 || (!isDesktop940 && isDesktop500) ? 'Каникулы' : ''
      }`;
    return `Возобновить ${
      isDesktop1280 || (!isDesktop940 && isDesktop500) ? 'обслуживание' : ''
    }`;
  };

  const onButtonClick = () => {
    if (isOrdered || isActivated) {
      setIsVacationShutdownOpen(true);
      return;
    }
    setIsResumeOperatingStateWizardShow(true);
  };

  useEffect(() => {
    setViewType(VIEW_TYPES.RESUME);
  }, []);

  useEffect(() => {
    if (!isAuth) return;
    if (openSPAfterAuthorizationState === SIDE_PAGES.RESUMPTION_ACTION) {
      setIsResumeOperatingStateWizardShow(true);
      resumeOperatingState(
        getVacation,
        setOpenSPAfterAuthorizationState,
        updateAuthCondition,
      );
    }
  }, [isAuth]);

  return (
    <>
      <Root>
        <Button styleType={ButtonStyleTypes.MAIN} onClick={onButtonClick}>
          {getButtonText()}
        </Button>
      </Root>
    </>
  );
};

export default memo(observer(Suspended));
