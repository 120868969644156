import styled from '@emotion/styled';
import { defaultTheme, Text } from 'cordis-core-ui-planeta';
import {
  desktop940,
  desktop1100,
  desktop1280,
} from '~/components/Grid/constants';
import { McBannerTemplates } from '../../constants';
import CONFIG from '../../config';

const {
  vectorLilac,
  vectorPink,
  vectorBlack,
  vectorLilacMobile,
  vectorPinkMobile,
  vectorBlackMobile,
} = CONFIG[McBannerTemplates.services].images;

export const StyledRangeOfServices = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vw * 1.41);
  padding: 36px 37px 21px 50px;
  border-radius: 60px;
  background-image: url(${vectorLilacMobile}), url(${vectorPinkMobile}),
    url(${vectorBlackMobile});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: top, 15vw, bottom;
  h2,
  h3,
  span {
    display: block;
    color: ${defaultTheme.colors.white};
    letter-spacing: -0.03em;
  }
  h2,
  h3 {
    margin-bottom: 12px;
  }
  span {
    font-size: 20px;
  }

  @media (min-width: ${desktop940}px) {
    justify-content: space-between;
    height: 400px;
    width: 592px;
    border-radius: 86px;
    padding: 40px 50px 46px 50px;
    background-image: url(${vectorLilac}), url(${vectorPink}),
      url(${vectorBlack});
    background-repeat: no-repeat;
    background-position: left, 100px, 100px;
    background-position-y: initial;
    background-size: auto;
    h2,
    h3,
    span {
      margin: 0;
      max-width: 563px;
    }
  }

  @media (min-width: ${desktop1100}px) {
    width: 712px;
  }

  @media (min-width: ${desktop1280}px) {
    width: 832px;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${desktop940}px) {
    margin-bottom: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const StyledTabs = styled.div`
  display: flex;
  flex-direction: column;
  > span {
    position: absolute;
  }
  > span:first-of-type {
    bottom: 25vw;
  }
  > span:last-of-type {
    bottom: 7vw;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;
    align-items: center;
    > span {
      position: initial;
    }
    > span:first-of-type {
      margin-right: 62px;
    }
    > span:nth-of-type(2) {
      margin-right: 68px;
    }
  }
`;

export const StyledInfo = styled(Text)`
  position: absolute;
  bottom: 45vw;
  font-size: 16px !important;
  line-height: 24px;
`;
