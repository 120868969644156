import styled from '@emotion/styled';
import { Squircle } from '@squircle-js/react';
import { Button, defaultTheme, H3, Text } from 'cordis-core-ui-planeta';
import CONFIG from '../../config';
import { McBannerTemplates } from '../../constants';
import {
  desktop1100,
  desktop430,
  desktop940,
} from '~/components/Grid/constants';
import { buttonHover } from '~/components/Blocks/Templates/McBanner/styles';

const { woman } = CONFIG[McBannerTemplates.help].images;

export const StyledSquircle = styled(Squircle)`
  display: flex;
  flex-direction: column;
  height: 400px;
  padding: 42px 10px 38px 32px;
  background-color: ${defaultTheme.colors.classicRose};
  position: relative;
`;

export const StyledH3 = styled(H3)`
  margin-bottom: 13px;
`;

export const StyledText = styled(Text)`
  display: block;
  max-width: 176px;
  line-height: 24px;
  margin-bottom: 32px;
`;

export const StyledImg = styled.span`
  background-image: url(${woman});
  background-repeat: no-repeat;
  position: absolute;
  width: 400px;
  height: 370px;
  bottom: 0;
  left: unset;
  right: 0;

  @media (max-width: ${desktop430}px), (min-width: ${desktop940}px) {
    left: 0;
    right: unset;
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 106px;
  margin-top: auto;
  margin-bottom: 0;
  padding: 13px 15px;
  width: 244px;

  ${buttonHover};

  svg {
    fill: ${defaultTheme.colors.white};
  }

  @media (min-width: ${desktop940}px) {
    width: 220px;
  }
  @media (min-width: ${desktop1100}px) {
    width: 244px;
  }
`;
