/** libraries */
import { Instance, types, flow, getParent } from 'mobx-state-tree';
/** api */
import { changeNameForMobilePhone } from '~/api/apiPab2c';
/** utils */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
/** constants */
import { INITIAL_RESULT } from '~/constants/common';
import { IMobileStore } from './MobileStore';

const RequestsStateModel = types.model('State', {
  changeNameForMobilePhone: createApiPathModel(
    'POST /Mobile/MobilePhoneNumber/SetSubscriberNameForMobileResource',
  ),
});

const ResultModel = types.model('', {
  isResult: (types.boolean, false),
  isCorrect: (types.boolean, false),
  text: types.maybe(types.string),
});

export const ChangeNameStore = types
  .model('ChangeNameStore', {
    requestsState: RequestsStateModel,
    isShowChangeNameSP: types.boolean,
    selectedNumberId: types.string,
    result: ResultModel,
    name: types.string,
    error: types.string,
  })
  .views((self) => ({
    get isLoading() {
      return self.requestsState.changeNameForMobilePhone.isLoading;
    },
  }))
  .actions((self) => ({
    setName: (name: string) => {
      self.name = name;
    },
    setError: (error: string) => {
      self.error = error;
    },
    changeNameButtonHandler: (msisdn: string) => {
      const { setDroplistSelectedNumberId }: IMobileStore = getParent(self);
      self.selectedNumberId = msisdn;
      self.isShowChangeNameSP = true;
      setDroplistSelectedNumberId('');
    },
    changeNameForMobilePhone: flow(function* (
      mobilePhoneNumberId: number,
      subscriberName: string,
    ) {
      self.requestsState.changeNameForMobilePhone.reset();
      self.requestsState.changeNameForMobilePhone.setLoading();
      try {
        yield changeNameForMobilePhone(mobilePhoneNumberId, subscriberName);
        self.result = {
          isResult: true,
          isCorrect: true,
          text: 'Имя номера успешно изменено',
        };
        self.requestsState.changeNameForMobilePhone.setSuccess();
      } catch (e) {
        self.result = {
          isResult: true,
          isCorrect: false,
          text: '',
        };
        self.requestsState.changeNameForMobilePhone.setFail();
        throw e;
      }
    }),
    resetChangeNameStore: () => {
      self.isShowChangeNameSP = false;
      self.selectedNumberId = '';
      self.result = INITIAL_RESULT;
      self.name = '';
      self.error = '';
    },
  }));

export type IChangeNameStore = Instance<typeof ChangeNameStore>;

export const changeNameStoreInstance = {
  requestsState: {
    changeNameForMobilePhone: defaultModelState,
  },
  isShowChangeNameSP: false,
  selectedNumberId: '',
  result: INITIAL_RESULT,
  name: '',
  error: '',
};
