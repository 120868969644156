/** libraries */
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { SidePage, Loader } from 'cordis-core-ui-planeta';
/** components */
import { StyledInfoText } from './styles';
import Tabs from './Components/Tabs';
import SuccessOrder from '../../Components/SuccessOrder/SuccessOrder';
import ErrorHandler from './Components/ErrorHandler';
import Notifications from './Components/Notifications';
import Footer from './Components/Footer';
/** stores */
import useMobileStore from '../../store/useMobileStore';
import { useRootStore } from '~/stores/RootStore';
/** constants */
import { OPERATING_STATE } from '~/constants/common';

const BindNewNumber = (): JSX.Element => {
  const {
    contractStateStore: { contractState },
  } = useRootStore();

  const {
    bindNewNumberStore: { isShowBindNewNumberSP, onCloseClick, result },
    changeNumberStore: { getChangeNumberData, isDataLoading },
  } = useMobileStore();

  const isSuspendedCondition = [
    OPERATING_STATE.PROVIDER_BLOCK_DEBT,
    OPERATING_STATE.NEW,
  ].includes(contractState);

  useEffect(() => {
    if (!isShowBindNewNumberSP) return;
    (async () => {
      await getChangeNumberData();
    })();
  }, [isShowBindNewNumberSP]);

  return (
    <SidePage
      show={isShowBindNewNumberSP}
      headerText="Заказ SIM-карты"
      onCloseClick={onCloseClick}
      footerContainer={<Footer />}
      showMobileHeader
      removeScrollBar
    >
      {!isSuspendedCondition && !result.isResult && (
        <StyledInfoText>
          Для заказа SIM-карты выберите номер и способ получения
        </StyledInfoText>
      )}
      {isDataLoading && <Loader small />}

      {!isDataLoading && !result.isResult && (
        <>
          <Tabs />
          <Notifications />
        </>
      )}

      {result.isResult && result.isCorrect && <SuccessOrder />}
      {result.isResult && !result.isCorrect && <ErrorHandler />}
    </SidePage>
  );
};

export default observer(BindNewNumber);
