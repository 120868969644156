/** libraries */
import { observer } from 'mobx-react';
import {
  ButtonIconPosition,
  ButtonStyleTypes,
  defaultTheme,
  Icons,
  LinkButton,
} from 'cordis-core-ui-planeta';
import Image from 'next/image';
import { useMediaQuery } from 'react-responsive';
/** constants */
import { SUCCESS_SMALL_IMAGE } from '../../../constants';
import { desktop940 } from '~/components/Grid/constants';
/** styles */
import {
  ImageWrapper,
  StyledButton,
  StyledH3,
  StyledLeadingText,
  StyledRoamingText,
} from '../styles';
/** stores */
import useMobileStore from '../../../store/useMobileStore';

const SuccessOrder = (): JSX.Element => {
  const {
    bindSubscriptionStore: {
      bindAnotherSubscriptionButtonHandler,
      nameOfSelectedSubscription,
      isBindRoaming,
      selectedSubscriptionId,
    },
    roamingStore: { selectedCountry, getRoamingCountriesByPackage },
  } = useMobileStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const imageLoader = ({ src, width, quality }) => {
    return `${process.env.STATIC_SERVER}/${src}?w=${width}&q=${quality}`;
  };

  return (
    <>
      <ImageWrapper>
        <Image
          loader={imageLoader}
          src={SUCCESS_SMALL_IMAGE}
          alt="Заказ совершён успешно"
          width={336}
          height={355}
          quality={100}
          layout={isDesktop940 ? 'fixed' : 'intrinsic'}
        />
      </ImageWrapper>
      <StyledLeadingText color={defaultTheme.colors.black}>
        Вы подключили:
      </StyledLeadingText>
      <StyledH3>
        {nameOfSelectedSubscription}
        {isBindRoaming && ` в ${selectedCountry.name}`}
      </StyledH3>
      {isDesktop940 ? (
        <LinkButton onClick={bindAnotherSubscriptionButtonHandler}>
          {isBindRoaming
            ? 'Подключить ещё пакет Мб'
            : 'Подключить ещё пакет Гб или\u00A0минут'}
        </LinkButton>
      ) : (
        <StyledButton
          styleType={ButtonStyleTypes.ACTION}
          icon={<Icons.AddSubscriptionIcon />}
          secondIcon={<Icons.LeftArrowIcon />}
          secondIconPosition={ButtonIconPosition.RIGHT}
          onClick={bindAnotherSubscriptionButtonHandler}
        >
          Подключить ещё пакет
        </StyledButton>
      )}
      {isBindRoaming && (
        <>
          <StyledRoamingText>
            Пакет начнет действовать сразу после подключения. При исчерпании
            пакета доступ в&nbsp;интернет будет отключен. По&nbsp;возвращении
            в&nbsp;Россию пакет автоматически отключится по&nbsp;истечении срока
            его действия.
          </StyledRoamingText>
          <StyledRoamingText>
            Пакет действует также в&nbsp;следующих странах:{' '}
            {getRoamingCountriesByPackage(selectedSubscriptionId).join(', ')}.
          </StyledRoamingText>
        </>
      )}
    </>
  );
};

export default observer(SuccessOrder);
