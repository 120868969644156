/** libraries */
import style from '@emotion/styled';

/* Стилизованная строка со скоростью интернета компонента CTA */
export const StyledInternet = style.div`
  h3 {
    margin: 0 0 16px 0;
  }
  
  h3 + div {
    display: flex;
    align-items: center;

    > span {
      width: min-content;
    }
  }
  
  div[data-test-tag] {
    margin-left: 10px;
  }
`;
