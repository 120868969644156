import styled from '@emotion/styled';
import { Squircle } from '@squircle-js/react';
import { Button, H2, LeadingText, Text } from 'cordis-core-ui-planeta';
import CONFIG from '../../config';
import { McBannerTemplates } from '../../constants';
import {
  desktop1280,
  desktop1100,
  desktop940,
  desktop475,
  desktop375,
} from '~/components/Grid/constants';
import { buttonHover } from '~/components/Blocks/Templates/McBanner/styles';

const { background, backgroundMobile } = CONFIG[
  McBannerTemplates.magicalNumber
].images;

export const StyledSquircle = styled(Squircle)`
  height: 400px;
  padding: 43px 10px 38px 33px;
  background-image: url(${backgroundMobile});
  background-repeat: no-repeat;
  background-position: center;

  @media (min-width: ${desktop940}px) {
    background-image: url(${background});
  }
`;

export const StyledH2 = styled(H2)`
  max-width: 280px;
  margin-bottom: 19px;

  @media (max-width: ${desktop375}px),
    (min-width: ${desktop940}px) and (max-width: ${desktop1100}px) {
    font-size: 28px;
  }
`;

export const StyledNumber = styled(Text)`
  display: block;
  font-size: 30px;
  font-weight: 700;
  line-height: 65px;
  margin-bottom: 33px;
  margin-left: -10px;

  @media (min-width: ${desktop375}px) {
    font-size: 40px;
  }
  @media (min-width: ${desktop475}px) {
    font-size: 48px;
  }
  @media (min-width: ${desktop940}px) {
    font-size: 30px;
  }
  @media (min-width: ${desktop1100}px) {
    font-size: 40px;
  }
  @media (min-width: ${desktop1280}px) {
    font-size: 48px;
  }
`;

export const StyledText = styled(LeadingText)`
  display: block;
  max-width: 250px;
  margin-bottom: 54px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
`;

export const StyledButton = styled(Button)`
  border-radius: 106px;
  margin-bottom: 0;
  padding: 13px 36px;
  ${buttonHover};
`;
