/** libraries */
import { observer } from 'mobx-react';
import { SidePage, Button, Radio } from 'cordis-core-ui-planeta';
/** constants */
import { ACTIVATION_METHOD_TYPE } from '../../constants';
/** styles */
import { StyledText } from './styles';
import { StyledRadioList } from '../../style';
/** stores */
import useMobileStore from '../../store/useMobileStore';

const ChoosingTheActivationMethod = () => {
  const {
    choosingTheActivationMethodStore: {
      isShowChoosingTheActivationMethod,
      activationMethodType,
      onCloseChoosingTheActivationMethod,
      setActivationMethodType,
    },
    changeNumberStore: { setIsShowAvailableNumbersSp },
    specifyTheTransferNumberStore: { setIsShowSpecifyTheTransferNumber },
  } = useMobileStore();

  const choose = () => {
    onCloseChoosingTheActivationMethod();
    if (activationMethodType === ACTIVATION_METHOD_TYPE.NEW) {
      setIsShowAvailableNumbersSp(true);
      return;
    }
    setIsShowSpecifyTheTransferNumber(true);
  };

  if (!isShowChoosingTheActivationMethod) return null;

  return (
    <SidePage
      show={isShowChoosingTheActivationMethod}
      headerText="Выберите способ подключения"
      onCloseClick={onCloseChoosingTheActivationMethod}
      footerContainer={<Button onClick={choose}>Выбрать</Button>}
    >
      <StyledText lineHeight="24px">
        Для подключения номера к сим-карте выберите вариант получения номера
      </StyledText>
      <StyledRadioList>
        <Radio
          checked={activationMethodType === ACTIVATION_METHOD_TYPE.NEW}
          onChange={() => setActivationMethodType(ACTIVATION_METHOD_TYPE.NEW)}
          title="Новый номер в Планете"
          description="Вы получите новую SIM-карту и новый номер"
        />
        <Radio
          checked={activationMethodType === ACTIVATION_METHOD_TYPE.TRANSFER}
          onChange={() =>
            setActivationMethodType(ACTIVATION_METHOD_TYPE.TRANSFER)
          }
          title="Перенести свой номер в Планету"
          description="Вы сохраните свой номер на новой SIM-карте"
        />
      </StyledRadioList>
    </SidePage>
  );
};

export default observer(ChoosingTheActivationMethod);
