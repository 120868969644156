import { observer } from 'mobx-react';
import { SidePage } from 'cordis-core-ui-planeta';
/** components */
import OperatingStateWizard from '~/components/Blocks/Templates/Pab2c/ContractState/Components/OperatingStateWizard/OperatingStateWizard';
import OperatingStateWizardFooter from '~/components/Blocks/Templates/Pab2c/ContractState/Components/OperatingStateWizard/OperatingStateWizardFooter';
/** store */
import useOperatingStateModelStore from '~/components/Blocks/Templates/Pab2c/ContractState/Components/OperatingStateWizard/store/useOperatingStateStore';
import useMakeAuthStore from '~/components/AuthWizard/store/useMakeAuthStore';
/** constants */
import { VIEW_TYPES } from '~/components/Blocks/Templates/Pab2c/ContractState/types';

const OperatingStateResumeSP = () => {
  const {
    isOperatingStateWizardShow,
    viewType,
    operatingStateTitle,
    onCloseClick,
  } = useOperatingStateModelStore();
  const { setOpenSPAfterAuthorizationState } = useMakeAuthStore();

  return (
    <SidePage
      show={isOperatingStateWizardShow}
      width="832px"
      headerText={operatingStateTitle}
      onCloseClick={() => onCloseClick(setOpenSPAfterAuthorizationState)}
      footerContainer={
        [VIEW_TYPES.PAUSE, VIEW_TYPES.RESUME].includes(viewType) ? (
          <OperatingStateWizardFooter />
        ) : (
          <></>
        )
      }
      isOnlyMobileFooter
      removeScrollBar
    >
      <OperatingStateWizard />
    </SidePage>
  );
};

export default observer(OperatingStateResumeSP);
