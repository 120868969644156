/** libraries */
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { LinkButton, Text } from 'cordis-core-ui-planeta';
/** styles */
import { StyledButton, StyledWarning } from '../styles';
/** constants */
import { OPERATING_STATE, PAYMENT_SLUG } from '~/constants/common';
import { ACTIVATION_METHOD_TYPE } from '../../../constants';
/** types */
import { DELIVERY_TYPE } from '../../../types';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../../store/useMobileStore';

const Footer = (): JSX.Element => {
  const {
    contractStateStore: { contractState },
    pab2cBalanceStore: { moneyRecommended },
    pab2cMobileStore: { mobilePhonesInfoData },
    authStore: {
      auth: { balance },
    },
    cityStore: { officeInfos },
  } = useRootStore();

  const {
    changeNumberStore: { newNumberInfo },
    methodOfReceiptFormStore: { deliveryType, deliveryAddress },
    bindNewNumberStore: {
      isLoading,
      setIsShowBindNewNumberAgreementSP,
      bindTemporaryNumberForPortation,
      result,
      onCloseClick,
    },
    choosingTheActivationMethodStore: { activationMethodType },
    specifyTheTransferNumberStore: { phoneNumber },
  } = useMobileStore();

  const totalPrice = useMemo(() => {
    const deliveryPrice =
      deliveryType === DELIVERY_TYPE.COURIER
        ? mobilePhonesInfoData.simCardCourierDeliveryPrice
        : 0;
    return deliveryPrice + (newNumberInfo?.price ?? 0);
  }, [deliveryType, newNumberInfo?.price]);

  const isAvailableToOrderSim = [
    OPERATING_STATE.ON,
    OPERATING_STATE.CLIENT_BLOCK,
  ].includes(contractState);

  const buttonHandlerMap = {
    [ACTIVATION_METHOD_TYPE.NEW]: () => setIsShowBindNewNumberAgreementSP(true),
    [ACTIVATION_METHOD_TYPE.TRANSFER]: async () => {
      await bindTemporaryNumberForPortation(
        phoneNumber,
        deliveryType,
        deliveryAddress(officeInfos),
      );
    },
  };

  if (result.isResult && !result.isCorrect) return null;

  if (result.isResult && result.isCorrect)
    return <StyledButton onClick={onCloseClick}>Понятно</StyledButton>;

  if (isAvailableToOrderSim && balance >= totalPrice)
    return (
      <StyledButton
        onClick={buttonHandlerMap[activationMethodType]}
        disabled={!deliveryType || !newNumberInfo}
        loading={isLoading}
      >
        Заказать SIM-карту
      </StyledButton>
    );

  return (
    <StyledWarning>
      <Text lineHeight="24px">
        {isAvailableToOrderSim && balance < totalPrice && (
          <>
            <LinkButton href={`/${PAYMENT_SLUG}`} target="_blank">
              Пополните баланс
            </LinkButton>{' '}
            для заказа SIM-карты
          </>
        )}
        {contractState === OPERATING_STATE.PROVIDER_BLOCK_DEBT && (
          <>
            Для заказа SIM возобновите обслуживание.{' '}
            <LinkButton href={`/${PAYMENT_SLUG}`} target="_blank">
              Пополните баланс
            </LinkButton>{' '}
            на рекомендуемый платеж {moneyRecommended} ₽.
          </>
        )}
        {contractState === OPERATING_STATE.NEW &&
          'Добавление номера будет доступно после подключения договора.'}
      </Text>
    </StyledWarning>
  );
};
export default observer(Footer);
