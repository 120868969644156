/** libraries */
import { observer } from 'mobx-react';
import { LinkButton, Text } from 'cordis-core-ui-planeta';
/** styles */
import { StyledButton, StyledWarning } from '../styles';
/** constants */
import { PAYMENT_SLUG } from '~/constants/common';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../../store/useMobileStore';

const Footer = (): JSX.Element => {
  const {
    authStore: { auth },
  } = useRootStore();

  const {
    bindSubscriptionStore: {
      resetBindSubscriptionStore,
      setIsShowBindSubscriptionAgreementSP,
      result,
      selectedSubscriptionId,
      priceOfSelectedSubscription,
    },
  } = useMobileStore();

  const isBalanceMoreThanBindPrice =
    auth.balance - priceOfSelectedSubscription >= 0;

  if (result.isResult && !result.isCorrect) return null;

  if (result.isResult && result.isCorrect)
    return (
      <StyledButton onClick={resetBindSubscriptionStore}>Понятно</StyledButton>
    );

  if (!isBalanceMoreThanBindPrice)
    return (
      <StyledWarning>
        <Text>
          <LinkButton href={`/${PAYMENT_SLUG}`} target="_blank">
            Пополните баланс
          </LinkButton>{' '}
          для подключения пакета
        </Text>
      </StyledWarning>
    );

  return (
    <StyledButton
      onClick={() => setIsShowBindSubscriptionAgreementSP(true)}
      disabled={!selectedSubscriptionId || !isBalanceMoreThanBindPrice}
    >
      Подключить пакет
    </StyledButton>
  );
};

export default observer(Footer);
