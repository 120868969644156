/** libraries */
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { ButtonStyleTypes, defaultTheme } from 'cordis-core-ui-planeta';
/** styles */
import {
  StyledButton,
  StyledH3,
  StyledLinkWrapper,
  StyledSquircle,
  StyledInfo,
} from './styles';
/** constants */
import { MOBILE_SLUG, Pab2cSlugs } from '~/constants/common';
import { QP_PAB2C_MOBILE } from '~/components/Blocks/Templates/Pab2c/Mobile/constants';
import { McBannerTemplates } from '../../constants';
import { desktop940 } from '~/components/Grid/constants';
/** config */
import CONFIG from '../../config';
/** stores */
import { useRootStore } from '~/stores/RootStore';

const Portation = (): JSX.Element => {
  const {
    authStore: { isAuth },
  } = useRootStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const { header, info, button } = CONFIG[McBannerTemplates.portation].text;

  const buttonLink = isAuth
    ? `/${Pab2cSlugs.MAIN_SLUG}?${QP_PAB2C_MOBILE.PORTATION}=true`
    : `/${MOBILE_SLUG}`;

  return (
    <StyledSquircle cornerRadius={isDesktop940 ? 86 : 70} cornerSmoothing={1}>
      <StyledH3 color={defaultTheme.colors.white}>{header}</StyledH3>
      <StyledInfo color={defaultTheme.colors.white}>{info}</StyledInfo>
      <StyledLinkWrapper href={buttonLink}>
        <StyledButton
          styleType={ButtonStyleTypes.DARK_BACKGROUND}
          border={`2px, solid, ${defaultTheme.colors.white}`}
          background="unset"
        >
          {button}
        </StyledButton>
      </StyledLinkWrapper>
    </StyledSquircle>
  );
};

export default observer(Portation);
