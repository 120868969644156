/** Библиотеки */
import * as React from 'react';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
/** компоненты */
import SummaryDescription from './SummaryDescription/SummaryDescription';
import SummaryCost from './SummaryCost/SummaryCost';
import SummaryInfo from './SummaryInfo/SummaryInfo';
/** styles */
import {
  StyledSummary,
  StyledInfoWrapper,
} from '~/components/Blocks/Templates/Summary/Summary.style';
/** interfaces */
import {
  SummaryProps,
  BUSINESS_GROUP_CODE,
  CLOSED_GROUP_CODE,
} from './Summary.types';
import { TariffProps } from '../ProductSwitcher/interfaces';

/** stores */
import { useRootStore } from '~/stores/RootStore';

/**
 * Блок Summary
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=373690564
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=375090529
 * @param {SummaryProps} content
 * @constructor
 */
const Summary: React.FC<SummaryProps> = ({
  content,
  isLinkInNewTab,
  isPrivate,
}: SummaryProps) => {
  const {
    summaryDataStore: { seriesCode },
    authStore: { isAuth },
    allowedTariffStore: { allowedTariff },
  } = useRootStore();
  const { fields, marketingGroups, defaultImgs } = content;
  const isBusiness = fields.marketingGroupCode === BUSINESS_GROUP_CODE;
  const isClosed = fields.marketingGroupCode === CLOSED_GROUP_CODE;

  /** удаляет лишние поля если тариф годовой */
  const priceData = useMemo(() => {
    const prices = { ...fields?.priceInfo };
    if (fields.isAnnual) {
      delete prices?.daily;
      delete prices?.thirtyDays;
    } else {
      delete prices?.annual;
    }
    if (!prices?.upgrade) {
      delete prices?.upgrade;
    }
    return prices;
  }, [fields, allowedTariff.length]);

  /** Непубличный продукт */
  const isPrivateProduct = useMemo(() => {
    if (isPrivate !== undefined || !marketingGroups?.length || !isAuth)
      return isPrivate;
    const seriesCodes = marketingGroups.reduce((acc, item) => {
      acc.push(...item.marketingSeries.map((series) => series.seriesCode));
      return acc;
    }, []);
    return !seriesCodes.includes(fields.seriesCode);
  }, [isPrivate, marketingGroups, isAuth, seriesCode]);

  return (
    <StyledSummary $isBusiness={isBusiness} stripColor={fields?.stripColor}>
      <SummaryDescription
        tags={fields.tags}
        seriesName={fields.seriesName}
        marketingGroupName={fields.marketingGroupName}
        marketingText={fields.marketingText}
        isBusiness={isBusiness}
        productFeedLink={fields.productFeedLink}
        action={fields.action}
        marketingGroupCode={fields.marketingGroupCode}
        isLinkInNewTab={isLinkInNewTab}
        isClosed={isClosed}
      />
      <StyledInfoWrapper>
        <SummaryInfo
          connectionMethod={fields.connectionMethod}
          speedBaseText={fields.speedBaseText}
          channelsInfo={fields.channelsInfo}
          isTransformer={fields.isTransformer}
          seriesCode={fields.seriesCode}
          defaultImgs={defaultImgs}
          features={fields.features}
          mobileInfo={fields.mobileInfo}
          isBusiness={isBusiness}
        />
        <SummaryCost
          priceInfo={priceData}
          productFeedLink={fields.productFeedLink}
          isAnnual={fields.isAnnual}
          action={fields.action}
          tariff={fields as TariffProps}
          seriesCode={fields.seriesCode}
          tariffId={fields.tariffId}
          isPrivate={isPrivateProduct}
        />
      </StyledInfoWrapper>
    </StyledSummary>
  );
};

export default observer(Summary);
