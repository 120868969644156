/** libraries */
import style from '@emotion/styled';
/** constants */
import { desktop400 } from '~/components/Grid/constants';

/* Стилизованная строка с настройками оборудования блока CTA */
export const StyledDevice = style.div`
  display: flex;
  align-items: center;

  .imageWrapper {
    width: 72px;
    height: 64px;
    position: relative;
    margin-right: 16px;
  }
`;

export const StyledSelector = style.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  h3 {
    margin: 4px 8px 19px 0;
  }

  .device__selectors {
    display: flex;
    flex-direction: column;
    margin-top: -4px;
    margin-bottom: 19px;
    align-items: baseline;
    width: 100%;
    
    > div:first-of-type {
      width: 100%;
      max-width: 214px;
    }

    > div:nth-of-type(2) {
      margin-top: 16px;
      margin-left: 0;

      @media (min-width: ${desktop400}px) {
        margin-left: 16px;
        margin-top: 0;
      }
    }

    @media (min-width: ${desktop400}px) {
      flex-direction: row;
    }
  }
`;
