/** libraries */
import { FC } from 'react';
import { Loader } from 'cordis-core-ui-planeta';
import { observer } from 'mobx-react';

/** stores */
import { useRootStore } from '~/stores/RootStore';

/** components */

/** interfaces */

/** styles */
import { StyledHeader, StyledProductSwitcher } from '../../style';

/** constants */
import useProductSwitcherStore from '../../store/useProductSwitcherStore';
import Tags from './Tags';
import SingleSummary from './SingleSummary';
import SummaryList from './SummaryList';
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';

const BigProductSwitcher: FC = () => {
  const {
    authStore: { isAuth },
  } = useRootStore();

  const {
    extendedSummaryData,
    isLoading,
    isShowProductsNotAvailable,
    tagsStore: { tags, isThemesOpen },
    fields: { header },
  } = useProductSwitcherStore();

  return (
    <>
      {!isShowProductsNotAvailable && header && (
        <StyledHeader $isBigSummary>{parseHtml(header)}</StyledHeader>
      )}
      <StyledProductSwitcher isThemesOpen={isThemesOpen}>
        {isLoading ? (
          <div className="product-switcher__loader">
            <Loader />
          </div>
        ) : (
          <>
            {extendedSummaryData?.seriesCode && isAuth && <SingleSummary />}
            {tags.length > 0 && <Tags />}
            <SummaryList />
          </>
        )}
      </StyledProductSwitcher>
    </>
  );
};

export default observer(BigProductSwitcher);
