/** Библиотеки */
import React, { FC, useState } from 'react';
import {
  Button,
  defaultTheme,
  Input,
  Text,
  H2,
  Link,
} from 'cordis-core-ui-planeta';
import { onChangeCustomInputProps } from 'cordis-core-ui-planeta/dist/components/Input/interfaces';

/** компоненты */
import SidePageResult from '~/components/Blocks/Templates/Pab2c/MyTV/Components/common/SidePageResult';

/** интерфейсы */
import { WinkConnectionWizardProps } from './types';

/** стили */
import {
  StyledResultSuccess,
  StyledWizard,
  StyledWizardWarning,
} from './styles';

/** константы */
import { ERROR_MESSAGE, HELP_WINK_URL, WIZARD_STATE } from './constants';
import { WINK_URL } from '~/components/Blocks/Templates/Pab2c/MyTV/Components/Services/Wink/constants';
import { observer } from 'mobx-react';
import { useRootStore } from '~/stores/RootStore';

const WinkConnectionWizard: FC<WinkConnectionWizardProps> = ({
  result,
  state,
  handleSubmit,
  setWizardState,
  isLoading,
}: WinkConnectionWizardProps) => {
  const {
    tvStore: { winkInfo },
  } = useRootStore();

  const [phone, setPhone] = useState<string>('');
  const [errorPhone, setErrorPhone] = useState<boolean>(false);
  const hasPhoneError = phone.length < 16;
  const resultCode = winkInfo?.ottCode;

  /** Изменение номера телефона */
  const onPhoneChange = (event: onChangeCustomInputProps): void => {
    setErrorPhone(false);
    setPhone(event.value);
    if (state === WIZARD_STATE.CODE_SEND) {
      setWizardState(WIZARD_STATE.PHONE);
    }
  };

  const validateTelephone = (): void => {
    setErrorPhone(hasPhoneError);
  };

  const getAction = (): string => {
    return state === WIZARD_STATE.CODE_SEND
      ? 'Получить код активации'
      : 'Подключить';
  };

  return (
    <StyledWizard>
      {state === WIZARD_STATE.RESULT && (
        <SidePageResult result={result}>
          {result.isCorrect && (
            <StyledResultSuccess>
              <Text color={defaultTheme.colors.gray}>
                Доступные фильмы и сериалы
              </Text>
              <Text fontWeightBold className="bold">
                более 50 тыс.
              </Text>
              <Text className="paragraph">
                Вы&nbsp;можете смотреть ТВ-каналы Планеты прямо
                <br /> в&nbsp;приложении Wink после{' '}
                <a href={WINK_URL} target="_blank" rel="noreferrer">
                  авторизации
                </a>
                .
              </Text>
            </StyledResultSuccess>
          )}
        </SidePageResult>
      )}

      {state === WIZARD_STATE.CODE_VALIDATE && resultCode && (
        <>
          <Text>Ваш код активации Wink</Text>
          <div className="wizard__container">
            <H2>{resultCode}</H2>
          </div>
          <div className="wizard__footer">
            <Link
              href={`${WINK_URL}/activation?ott_tv_code=${resultCode}`}
              target="_blank"
            >
              <Button>Активировать код</Button>
            </Link>
            <Text className="wizard__footer-text">
              Активируйте код и&nbsp;получите доступ к&nbsp;огромному выбору
              фильмов, сериалов и&nbsp;телеканалов. Если у&nbsp;вас возникли
              сложности с&nbsp;активацией кода&nbsp;&mdash; воспользуйтесь{' '}
              <a href={HELP_WINK_URL} target="_blank" rel="noreferrer">
                инструкцией
              </a>
              .
            </Text>
          </div>
        </>
      )}

      {(state === WIZARD_STATE.PHONE || state === WIZARD_STATE.CODE_SEND) && (
        <>
          <Text>
            Введите номер телефона, чтобы пользоваться Wink на&nbsp;разных
            устройствах
          </Text>

          <div className="wizard__container">
            <Text>Ваш телефон</Text>
            <Input
              className="wizard__input wizard__input-phone"
              type="phone"
              color={defaultTheme.colors.black}
              value={phone}
              onChangeCustomInput={onPhoneChange}
              onBlur={validateTelephone}
              width="100%"
              error={errorPhone}
            />
            {errorPhone && (
              <Text
                className="wizard__error"
                color={defaultTheme.colors.planeta}
              >
                {ERROR_MESSAGE.EMPTY_PHONE}
              </Text>
            )}
          </div>
          {state === WIZARD_STATE.CODE_SEND && (
            <StyledWizardWarning>
              Данный номер уже зарегистрирован в&nbsp;Wink. Пожалуйста, укажите
              другой номер или воспользуйтесь кодом активации.
            </StyledWizardWarning>
          )}
          <div className="wizard__footer">
            <Button
              disabled={errorPhone}
              onClick={() => handleSubmit(phone)}
              loading={isLoading}
            >
              {getAction()}
            </Button>
          </div>
        </>
      )}
    </StyledWizard>
  );
};

export default observer(WinkConnectionWizard);
