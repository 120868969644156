/** libraries */
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import {
  Button,
  defaultTheme,
  H2,
  H3,
  LeadingText,
  Text,
} from 'cordis-core-ui-planeta';
import { Squircle } from '@squircle-js/react';
/** constants */
import {
  desktop940,
  desktop1100,
  desktop1280,
} from '~/components/Grid/constants';
import { McBannerTemplates } from '../../constants';
/** config */
import CONFIG from '../../config';
import { buttonHover } from '~/components/Blocks/Templates/McBanner/styles';

const { background, maskSmall, maskMedium, maskBig, backgroundMobile } = CONFIG[
  McBannerTemplates.main
].images;

export const StyledContainer = styled.div`
  position: relative;
  min-height: 944px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${desktop940}px) {
    display: block;
    min-height: 455px;
    background-image: url(${background});
    background-repeat: no-repeat;
    mask-image: url(${maskSmall});
    mask-repeat: no-repeat;
    mask-size: auto 455px;
    padding: 47px 0 0 281px;
  }

  @media (min-width: ${desktop1100}px) {
    mask-image: url(${maskMedium});
  }

  @media (min-width: ${desktop1280}px) {
    mask-image: url(${maskBig});
  }
`;

export const StyledAppendix = styled.img`
  width: 155px;
  margin-bottom: -3px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledWomanImg = styled.div`
  display: block;
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 445px;
  background: linear-gradient(to top, transparent, 90%, #a31659),
    url(${backgroundMobile});
  background-position: center;
`;

export const StyledSquircle = styled(Squircle)`
  min-height: 400px;
  background: ${defaultTheme.colors.jazzberryJam};
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledSquircleImg = styled(Squircle)`
  width: 100%;
  height: 445px;
  margin-bottom: 35px;
`;

export const StyledH2 = styled(H2)`
  max-width: 360px;
`;

export const StyledH3 = styled(H3)`
  max-width: 220px;
  position: absolute;
  top: 200px;
  z-index: 1;
`;

export const StyledDescription = styled(LeadingText)`
  display: block;
  max-width: 250px;
  margin-bottom: 22px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;

  @media (min-width: ${desktop940}px) {
    max-width: 420px;
    margin-bottom: 22px;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
  }
`;

export const StyledCircles = styled.div`
  background-image: url(${CONFIG[McBannerTemplates.main].images.circles});
  background-repeat: no-repeat;
  height: 73px;
  display: flex;
  align-items: center;
  margin-bottom: 27px;
`;

export const StyledButton = styled(Button)`
  border-radius: 106px;
  padding: 13px 70px;
  margin-left: 6px;
  margin-bottom: 40px;

  ${buttonHover};

  @media (min-width: ${desktop940}px) {
    margin-bottom: 0;
    padding: 13px 36px;
  }
`;

const slideAnimation = keyframes`
  from {
    transform: translateY(-180px);
  }
  5%, 25% {
    transform: translateY(0px);
  }
  30%{
    transform: translateY(180px);
  }
  to {
    transform: translateY(180px);
  }
`;

export const AnimationWrapper = styled.div<{ delay: string }>`
  ${({ delay }) => {
    return css`
      position: absolute;
      top: 23px;
      left: 23px;
      width: 100%;
      height: 100%;
      transform: translateY(-180px);
      animation: ${slideAnimation} 8s ease infinite;
      animation-delay: ${delay}s;
    `;
  }}
`;

export const StyledAnimation = styled.div`
  width: 146px;
  height: 146px;
  border-radius: 74px;
  overflow: hidden;
  position: absolute;

  @media (min-width: ${desktop940}px) {
    left: 21px;
    top: 31px;
  }
`;

export const StyledGigabyteAmount = styled(Text)`
  font-size: 30px;
  font-weight: 500;
  margin-left: 28px;

  @media (min-width: ${desktop940}px) {
    font-size: 45px;
    margin-left: 24px;
  }
`;

export const StyledGigabyteLabel = styled(Text)`
  font-size: 11px;
  margin-left: 3px;
`;

export const StyledMinutesAmount = styled(Text)`
  font-size: 30px;
  font-weight: 500;
  margin-left: 24px;

  @media (min-width: ${desktop940}px) {
    font-size: 20px;
    margin-left: 28px;
  }
`;

export const StyledMinutesLabel = styled(Text)`
  font-size: 11px;
  margin-left: 10px;

  @media (min-width: ${desktop940}px) {
    margin-left: 17px;
  }
`;
