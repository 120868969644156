import styled from '@emotion/styled';
import { Text } from 'cordis-core-ui-planeta';
import LinkWrapper from '../LinkWrapper';
/** константы */
import { desktop940 } from '~/components/Grid/constants';

export const AgreementStyled = styled.div`
  max-width: 900px;
  h3 {
    margin-top: 30px;
  }

  ol {
    margin: 8px 0 0 16px;
  }

  @media (min-width: ${desktop940}px) {
    margin: 50px;
  }
`;

export const StyledCompanyDescription = styled(Text)`
  display: block;
  max-width: 430px;
`;

export const StyledText = styled(Text)`
  margin-bottom: 16px;
`;

export const StyledLinkWrapper = styled(LinkWrapper)`
  display: inline;

  a:hover {
    text-decoration: underline;
  }
`;
