/** libraries */
import styled from '@emotion/styled';
import { defaultTheme, Icon } from 'cordis-core-ui-planeta';

export const StyledMobileScale = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 18px;
  padding-bottom: 18px;
`;

export const StyledScales = styled.div`
  display: flex;
  width: 100%;
  height: 12px;
  border-radius: 20px;
  background-color: ${defaultTheme.colors.light};
  margin: 0 0 9px 0;
`;

export const StyledArrowIcon = styled(Icon)`
  svg {
    rotate: 270deg;
    margin-left: 8px;
  }
`;

export const StyledFlexCenterBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledCounter = styled.div`
  margin-bottom: 9px;
`;
