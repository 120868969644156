/* Libraries */
import styled from '@emotion/styled';
/* Constants */
import { desktop940 } from '~/components/Grid/constants';

export const StyledFixedPrice = styled.span`
  > span {
    display: flex;
    min-width: 35%;

    h3 {
      margin-bottom: 6px;
    }

    h3:first-of-type {
      margin-right: 15px;
      text-decoration: line-through;
    }
  }

  @media (min-width: ${desktop940}px) {
    margin-bottom: 0;
  }
`;
