/** libraries */
import { observer } from 'mobx-react';
import {
  LeadingText,
  PriceTag,
  PriceTagBackgroundColor,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** components */
import SubscriptionList from './SubscriptionList';
/** styles */
import {
  StyledContainer,
  StyledDescriptionText,
  StyledLeadingText,
  StyledPrice,
  StyledRoamingText,
} from '../styles';
/** utils */
import { formatNumber } from '~/utils/utils';
/** constants */
import { desktop940 } from '~/components/Grid/constants';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useMobileStore from '../../../store/useMobileStore';

const Main = (): JSX.Element => {
  const {
    authStore: { auth },
  } = useRootStore();

  const {
    bindSubscriptionStore: {
      selectedSubscriptionId,
      priceOfSelectedSubscription,
      isBindRoaming,
    },
    roamingStore: { getRoamingCountriesByPackage },
  } = useMobileStore();

  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const isBalanceMoreThanBindPrice =
    auth.balance - priceOfSelectedSubscription >= 0;

  return (
    <StyledContainer>
      <StyledDescriptionText lineHeight="24px">
        {isBindRoaming
          ? 'Мобильный интернет в\u00A0выбранной стране доступен только после покупки пакета. При исчерпании пакета доступ в\u00A0интернет будет отключен.'
          : 'Дополнительные гигабайты и\u00A0минуты, чтобы вы всегда оставались на\u00A0связи. Пакет начнёт действовать сразу после подключения.'}
      </StyledDescriptionText>
      {!isBindRoaming && (
        <StyledLeadingText color={defaultTheme.colors.black}>
          Выберите пакет
        </StyledLeadingText>
      )}
      <SubscriptionList />
      {!!selectedSubscriptionId && (
        <>
          <LeadingText color={defaultTheme.colors.black}>
            Стоимость пакета
          </LeadingText>
          <StyledPrice>
            <PriceTag
              value={`${formatNumber(priceOfSelectedSubscription)} ₽`}
              backgroundColor={
                isBalanceMoreThanBindPrice
                  ? PriceTagBackgroundColor.OK
                  : PriceTagBackgroundColor.WARNING
              }
              subscription={
                isBalanceMoreThanBindPrice
                  ? `На вашем\u000Aсчету ${formatNumber(auth.balance)}\u00A0₽`
                  : `Не\u00A0хватает ${formatNumber(
                      priceOfSelectedSubscription - auth.balance,
                    )}\u00A0₽`
              }
              fontColor={
                isBalanceMoreThanBindPrice
                  ? defaultTheme.colors.black
                  : defaultTheme.colors.warning
              }
              headerType={isDesktop940 ? 'H2' : 'H3'}
              width="fit-content"
            />
          </StyledPrice>
          {isBindRoaming && (
            <>
              <StyledRoamingText>
                Пакет начнет действовать сразу после подключения.
                По&nbsp;возвращении в&nbsp;Россию пакет автоматически отключится
                по&nbsp;истечении срока его действия.
              </StyledRoamingText>
              <StyledRoamingText>
                Пакет действует также в&nbsp;следующих странах:{' '}
                {getRoamingCountriesByPackage(selectedSubscriptionId)
                  .sort()
                  .join(', ')}
                .
              </StyledRoamingText>
            </>
          )}
        </>
      )}
    </StyledContainer>
  );
};

export default observer(Main);
