/** Коллекция типов ошибок получения соглашения на присоединение/замену нового мобильного телефонного номера */
export enum BIND_NUMBER_ERROR_TYPE {
  /** Дата снятия запрета смены номера на данной SIM-карте еще не наступила */
  TIME = 'MobileBanTrimDaysForReplaceException',
  /** Недостаточно денег для смены номера */
  MONEY = 'MobileNotEnoughMoneyException',
  /** ConfigurationError */
  CONFIG = 'MobileException',
  /** Номер телефона уже используется */
  IS_USING = 'MobilePhoneNumberIsAlreadyUsedException',
  /** Превышен лимит созданных заявок в день */
  DAY_LIMIT = 'ClientRequestDemandsException',
  /** Превышен лимит прикрепленных номеров */
  SIM_LIMIT = 'MaxMobilePhoneNumberCountForBindLimitExceededException',
  /** Договор не обслуживается */
  CONTRACT_NOT_SERVICED = 'ContractNotServicedException',
}
