/** libraries */
import { useEffect, FC } from 'react';
import router from 'next/router';
import { observer } from 'mobx-react';
/** utils */
import Portal from '~/components/Portal/Portal';
/** components */
import {
  H3,
  Icons,
  LinkButton,
  Loader,
  Switcher,
  Text,
  defaultTheme,
} from 'cordis-core-ui-planeta';
import ChangePasswordSidePage from './SidePages/ChangePassword/ChangePasswordSidePage';
import ContactsAndNotificationsSidePage from '../../../Shared/ContactsAndNotifications/ContactsAndNotificationsSidePage';
import CreatePasswordSidePage from '~/components/Blocks/Shared/CreatePassword/CreatePasswordSidePage';
import PinSidePage from './SidePages/Pin/PinSidePage';
import AllowAccessWithoutPasswordSidePage from './SidePages/AllowAccessWithoutPassword/AllowAccessWithoutPasswordSidePage';
import EmailConfirm from '~/components/Blocks/Templates/Pab2c/Settings/SidePages/EmailConfirm';
/** styles */
import { StyledSettings } from './style';
/** api */
import { verifyContact } from '~/api/apiPab2c';
/** constants */
import { EMAIL_CONFIRM_QS } from '~/components/Blocks/Templates/Pab2c/Settings/constants';
import { DEFAULT_LK_LINK } from '~/constants/common';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import useContactsAndNotificationsStore from '../../../Shared/ContactsAndNotifications/store/useContactsAndNotificationsStore';
import useSettingsStore from './store/useSettingsStore';

const Settings: FC = () => {
  const {
    summaryDataStore: { isMonoProduct },
  } = useRootStore();
  const {
    setIsShowContactsAndNotifications,
  } = useContactsAndNotificationsStore();

  const {
    changePasswordStore: { setIsShowChangePasswordWizard },
    pinStore: { setIsShowPinWizard },
    emailConfirmStore: { setIsShowEmailConfirm },
    allowAccessWithoutPasswordStore: {
      isLoginWithoutPassword,
      setIsLoginWithoutPassword,
      setIsShowAllowAccessWizard,
      isLoading,
      getIsIpAuthAllowed,
    },
  } = useSettingsStore();

  /** Переключение "Вход без пароля из дома" */
  const loginWithoutPasswordSwitcher = (e) => {
    setIsLoginWithoutPassword(e.target.checked);
    setIsShowAllowAccessWizard(true);
  };

  useEffect(() => {
    getIsIpAuthAllowed();
  }, []);

  useEffect(() => {
    if (!router.isReady) return;
    (async () => {
      const contactId = router.query?.[EMAIL_CONFIRM_QS.CONTACT_ID];
      const verifyToken = router.query?.[EMAIL_CONFIRM_QS.CODE];
      if (!contactId || !verifyToken) return;
      try {
        await verifyContact(
          (contactId as unknown) as number,
          (verifyToken as unknown) as string,
        );
        setIsShowEmailConfirm({
          show: true,
          hasError: false,
        });
      } catch (e) {
        console.error(e);
        setIsShowEmailConfirm({ show: true, hasError: true });
      } finally {
        router.replace(DEFAULT_LK_LINK, undefined, {
          shallow: true,
        });
      }
    })();
  }, [router.query]);

  return (
    <StyledSettings>
      <H3>Настройки</H3>
      {!isMonoProduct && (
        <div className="settings__entrance-password">
          {isLoading ? (
            <Loader small />
          ) : (
            <Switcher
              checked={isLoginWithoutPassword}
              onClick={(e) => loginWithoutPasswordSwitcher(e)}
            >
              <Text lineHeight="24px">Вход без пароля из&nbsp;дома</Text>
            </Switcher>
          )}
        </div>
      )}

      <div className="settings__action-block">
        <Icons.LockIcon />
        <LinkButton onClick={() => setIsShowChangePasswordWizard(true)}>
          <Text lineHeight="24px" color={defaultTheme.colors.planeta}>
            Изменить пароль
          </Text>
        </LinkButton>
      </div>
      <div className="settings__action-block">
        <Icons.PinIcon />
        <LinkButton>
          <Text
            lineHeight="24px"
            color={defaultTheme.colors.planeta}
            onClick={() => setIsShowPinWizard(true)}
          >
            Изменить ПИН-код
          </Text>
        </LinkButton>
      </div>
      <div className="settings__action-block">
        <Icons.TelephoneIcon />
        <LinkButton onClick={() => setIsShowContactsAndNotifications(true)}>
          <Text lineHeight="24px" color={defaultTheme.colors.planeta}>
            Контакты и&nbsp;уведомления
          </Text>
        </LinkButton>
      </div>
      <Portal>
        <EmailConfirm />
        <ChangePasswordSidePage />
        <CreatePasswordSidePage />
        <PinSidePage />
        <AllowAccessWithoutPasswordSidePage />
        <ContactsAndNotificationsSidePage />
      </Portal>
    </StyledSettings>
  );
};

export default observer(Settings);
