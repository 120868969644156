/* Библиотеки */
import style from '@emotion/styled';

export const StyledConnection = style.div`
  > span {
    display: flex;
    align-items: center;
    div[data-test-tag] {
      margin-left: 10px;
    }
  }
`;
