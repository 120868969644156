/** libraries */
import { defaultTheme, H3 } from 'cordis-core-ui-planeta';
import styled from '@emotion/styled';
/** types */
import { CallToActionButtonStyleProps } from './CallToAction.types';
/** constants */
import {
  desktop1280,
  desktop1100,
  desktop940,
} from '~/components/Grid/constants';

export const CallToActionContainer = styled.div`
  ${() => `
      display: flex;
      flex-direction: column;
      background: ${defaultTheme.colors.white};
      width: 100%;
      > div {
        padding: 0 32px 0 32px;

        > h2 {
          margin-top: 32px;
        }

        > h3 {
          margin-top: 32px;
        }
      }
    `}
`;

export const CallToActionConnectionWizard = styled.div`
  ${() => `
      margin-top: -32px;
      background: ${defaultTheme.colors.white};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `}
`;

export const CallToActionConnectionButtonSticky = styled.div<CallToActionButtonStyleProps>`
  ${({
    amountScroll,
    clientWidth,
    showSettings,
  }: CallToActionButtonStyleProps) => {
    // Значение прокрутки, начиная с которого следует показывать кнопку
    const scrollFrom = showSettings ? 0.8 : 1.8;
    // Значение прокрутки, начиная с которого следует скрывать кнопку
    const scrollTo = showSettings ? 4.3 : 3;
    return `
      width: ${clientWidth}px;
      position: fixed;
      bottom: 0;
      display: ${
        amountScroll > scrollFrom && amountScroll <= scrollTo ? 'block' : 'none'
      };
      padding: 13px 0 13px 0;
      button {
        width: 100%;
      }

      @media (min-width: ${desktop940}px) {
        display: none;
      }
    `;
  }}
`;

export const CallToActionConnectionButton = styled.div`
  ${() => `
      width: 100%;
      button {
        width: 100%;
      }
      @media (min-width: ${desktop940}px) {
        width: 50%;
        button {
          width: auto;
        }
        margin: 0 0 32px 0;
      }
    `}
`;

export const StyledLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  width: 100%;
  margin-right: 32px;

  @media (min-width: ${desktop940}px) {
    min-width: 216px;
    width: 216px;
  }

  @media (min-width: ${desktop1100}px) {
    min-width: 276px;
    width: 276px;
  }

  @media (min-width: ${desktop1280}px) {
    min-width: 336px;
    width: 336px;
  }
`;

export const StyledFeature = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  margin-right: 32px;
  margin-top: 16px;

  span {
    white-space: pre-line;
  }

  svg {
    margin-right: 12px;
    flex-shrink: 0;
  }

  @media (min-width: ${desktop940}px) {
    min-width: 184px;
    width: 184px;
    margin-top: 0;
  }

  @media (min-width: ${desktop1100}px) {
    min-width: 242px;
    width: 242px;
  }

  @media (min-width: ${desktop1280}px) {
    min-width: 304px;
    width: 304px;
  }
`;

export const StyledH3 = styled(H3)`
  margin-bottom: 0;
`;
