/* Библиотеки */
import * as React from 'react';

/* Стили */
import {
  StyledRow,
  StyledRowContent,
  StyledTitleContent,
} from './CallToActionRow.style';

/* Типы */
import { CallToActionRowProps } from './CallToActionRow.types';

/* Строка с элементами продукта */
const CallToActionRow: React.FC<CallToActionRowProps> = ({
  title,
  children,
}) => (
  <StyledRow>
    <StyledTitleContent>{title}</StyledTitleContent>
    <StyledRowContent>{children}</StyledRowContent>
  </StyledRow>
);

export default CallToActionRow;
