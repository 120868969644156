/** библиотеки */
import { FC, createElement } from 'react';

/** компоненты */
import {
  HeaderDescription,
  Summary,
  ExampleBlock,
  Device,
  Devices,
  DeviceList,
  Pab2cDevices,
  Legal,
  FeedbackCustom,
  FeedbackYandex1,
  FeedbackYandex2,
  FeedbackYandex3,
  FeedbackYandex4,
  FeedbackYandex5,
  FeedbackYandex6,
  FeedbackFlamp1,
  FeedbackFlamp2,
  FeedbackFlamp3,
  FeedbackFlamp4,
  FeedbackFlamp5,
  FeedbackFlamp6,
  Speed,
  Banner,
  CallToAction,
  ResiliencyBanner,
  VoiceControlBanner,
  AutoPayBanner,
  ProductSelector,
  Announcements,
  Teaser,
  VoiceCommunication,
  BonusProgramm,
  Timeshift,
  UltraHD,
  TvScreen,
  SmartTV,
  PaymentInfo,
  Payment,
  AutoPayment,
  TextHtml,
  SberATM,
  SberSMS,
  AnyPayments,
  DeferredPayment,
  Avatar,
  Video,
  MyStoriesContest,
  SingleAnnouncement,
  Pab2cContract,
  Softline,
  Igrovoy,
  Vacation,
  MyTV,
  SecondMenu,
  Timeline,
  ProductSwitcher,
  Settings,
  ChatlesExp,
  Subscriptions,
  PaymentResult,
  Internet,
  ContractState,
  Help,
  Voice,
  SendWish,
  Pab2cSoftline,
  Pab2cMobile,
  Mobile,
  McBanner,
} from './Templates/index';
import ErrorBoundary from './ErrorBoundary';

/** типы */
import { BlockSelectorProps } from './BlockSelector.types';
import ProductPage from './Templates/ProductPage/ProductPage';

/** константы */
import { BLOCK_NAMES } from '~/constants/common';

/** список компонентов */
const Components = {
  [BLOCK_NAMES.headerDescription]: HeaderDescription,
  [BLOCK_NAMES.summary]: Summary,
  [BLOCK_NAMES.device]: Device,
  [BLOCK_NAMES.devices]: Devices,
  [BLOCK_NAMES.deviceList]: DeviceList,
  [BLOCK_NAMES.pab2cDevices]: Pab2cDevices,
  [BLOCK_NAMES.exampleBlock]: ExampleBlock,
  [BLOCK_NAMES.legal]: Legal,
  [BLOCK_NAMES.feedbackCustom]: FeedbackCustom,
  [BLOCK_NAMES.feedbackYandex1]: FeedbackYandex1,
  [BLOCK_NAMES.feedbackYandex2]: FeedbackYandex2,
  [BLOCK_NAMES.feedbackYandex3]: FeedbackYandex3,
  [BLOCK_NAMES.feedbackYandex4]: FeedbackYandex4,
  [BLOCK_NAMES.feedbackYandex5]: FeedbackYandex5,
  [BLOCK_NAMES.feedbackYandex6]: FeedbackYandex6,
  [BLOCK_NAMES.feedbackFlamp1]: FeedbackFlamp1,
  [BLOCK_NAMES.feedbackFlamp2]: FeedbackFlamp2,
  [BLOCK_NAMES.feedbackFlamp3]: FeedbackFlamp3,
  [BLOCK_NAMES.feedbackFlamp4]: FeedbackFlamp4,
  [BLOCK_NAMES.feedbackFlamp5]: FeedbackFlamp5,
  [BLOCK_NAMES.feedbackFlamp6]: FeedbackFlamp6,
  [BLOCK_NAMES.connectionSpeed]: Speed,
  [BLOCK_NAMES.banner]: Banner,
  [BLOCK_NAMES.productPage]: ProductPage,
  [BLOCK_NAMES.callToAction]: CallToAction,
  [BLOCK_NAMES.resiliencyBanner]: ResiliencyBanner,
  [BLOCK_NAMES.voiceControlBanner]: VoiceControlBanner,
  [BLOCK_NAMES.autoPayBanner]: AutoPayBanner,
  [BLOCK_NAMES.productSelector]: ProductSelector,
  [BLOCK_NAMES.announcements]: Announcements,
  [BLOCK_NAMES.teaser]: Teaser,
  [BLOCK_NAMES.voiceInfo]: VoiceCommunication,
  [BLOCK_NAMES.bonusProgramm]: BonusProgramm,
  [BLOCK_NAMES.timeshift]: Timeshift,
  [BLOCK_NAMES.ultraHD]: UltraHD,
  [BLOCK_NAMES.tvScreen]: TvScreen,
  [BLOCK_NAMES.smartTV]: SmartTV,
  [BLOCK_NAMES.paymentInfo]: PaymentInfo,
  [BLOCK_NAMES.payment]: Payment,
  [BLOCK_NAMES.autoPayment]: AutoPayment,
  [BLOCK_NAMES.textHTML]: TextHtml,
  [BLOCK_NAMES.sberATM]: SberATM,
  [BLOCK_NAMES.sberSMS]: SberSMS,
  [BLOCK_NAMES.anyPayments]: AnyPayments,
  [BLOCK_NAMES.contractTrusted]: DeferredPayment,
  [BLOCK_NAMES.avatar]: Avatar,
  [BLOCK_NAMES.video]: Video,
  [BLOCK_NAMES.myStoriesContest]: MyStoriesContest,
  [BLOCK_NAMES.singleAnnouncement]: SingleAnnouncement,
  [BLOCK_NAMES.pab2cContract]: Pab2cContract,
  [BLOCK_NAMES.softline]: Softline,
  [BLOCK_NAMES.igrovoy]: Igrovoy,
  [BLOCK_NAMES.vacation]: Vacation,
  [BLOCK_NAMES.myTV]: MyTV,
  [BLOCK_NAMES.secondMenu]: SecondMenu,
  [BLOCK_NAMES.timeline]: Timeline,
  [BLOCK_NAMES.productSwitcher]: ProductSwitcher,
  [BLOCK_NAMES.pab2cSettings]: Settings,
  [BLOCK_NAMES.chatlesExp]: ChatlesExp,
  [BLOCK_NAMES.pab2cSubscriptions]: Subscriptions,
  [BLOCK_NAMES.paymentResult]: PaymentResult,
  [BLOCK_NAMES.pab2cInternet]: Internet,
  [BLOCK_NAMES.pab2cContractState]: ContractState,
  [BLOCK_NAMES.help]: Help,
  [BLOCK_NAMES.pab2cVoice]: Voice,
  [BLOCK_NAMES.sendWish]: SendWish,
  [BLOCK_NAMES.pab2cSoftline]: Pab2cSoftline,
  [BLOCK_NAMES.pab2cMobile]: Pab2cMobile,
  [BLOCK_NAMES.mobile]: Mobile,
  [BLOCK_NAMES.mcBanner]: McBanner,
};

const BlockSelector: FC<BlockSelectorProps> = ({
  block,
}: BlockSelectorProps) => {
  if (Components[block?.template] !== undefined) {
    return (
      <ErrorBoundary>
        {createElement(Components[block.template], { content: block })}
      </ErrorBoundary>
    );
  }
  return createElement(() => <div>Not founded block {block?.template}</div>);
};

export default BlockSelector;
