/* @todo: по решению заказчика пока UHD-каналы должны называться 4K,
   @todo: при этом в блоке телевидения они называются UltraHD.
   @todo: Если вдруг случится единообразие - взять одноимённый enum из блока Television */
/* имена типов телеканалов в табе */
export enum CHANNEL_NAMES {
  all = 'Все',
  hd = 'HD',
  uhd = '4K',
}

/* Свойства стилизованного компонента StyledLeftColumn */
export interface StyledLeftColumnProps {
  /* Флаг Трансформера */
  isTransformer: boolean;
}
