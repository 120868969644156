import styled from '@emotion/styled';
import { Button } from 'cordis-core-ui-planeta';
import { desktop940 } from '~/components/Grid/constants';

export const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    margin: 16px 0 0 0;
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;
    > span {
      margin: 0 0 0 16px;
    }
  }
`;

export const StyledPrice = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-top: 12px;
  margin-bottom: 12px;

  div:first-of-type {
    margin-bottom: 12px;

    .colorContainer {
      width: fit-content;
      padding: 12px 16px 12px 6px;
    }
  }

  @media (min-width: ${desktop940}px) {
    flex-direction: row;
    align-items: end;

    div:first-of-type {
      margin-right: 12px;
      margin-bottom: 0;
    }
  }
`;

export const StyledButton = styled(Button)`
  margin-bottom: 12px;
`;
