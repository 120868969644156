/** libraries */
import styled from '@emotion/styled';
import { Button, H3, Text } from 'cordis-core-ui-planeta';

export const StyledNoSim = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

export const StyledDescription = styled.div`
  width: 300px;
`;

export const ImageWrapper = styled.div`
  width: 336px;
  margin-right: 69px;
`;

export const StyledH3 = styled(H3)`
  display: inline-block;
  margin-left: 16px;
  margin-bottom: 23px;
`;

export const StyledButton = styled(Button)`
  margin-top: 36px;
  white-space: nowrap;
`;

export const StyledText = styled(Text)`
  margin-bottom: 1.5em;
`;
