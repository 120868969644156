/** libraries */
import { defaultTheme, Tag } from 'cordis-core-ui-planeta';
import { useMediaQuery } from 'react-responsive';
/** styles */
import { StyledAnimation, StyledH3, StyledStorage, StyledText } from './styles';
/** constants */
import { McBannerTemplates } from '../../constants';
import { desktop940 } from '~/components/Grid/constants';
/** config */
import CONFIG from '../../config';

const Storage = (): JSX.Element => {
  const isDesktop940 = useMediaQuery({
    query: `(min-width: ${desktop940}px)`,
  });

  const { tag, header, info } = CONFIG[McBannerTemplates.storage].text;

  return (
    <StyledStorage cornerRadius={isDesktop940 ? 86 : 70} cornerSmoothing={1}>
      <Tag
        color={defaultTheme.colors.planeta}
        colorTag={defaultTheme.colors.pink}
        backgroundColor={defaultTheme.colors.white}
      >
        {tag}
      </Tag>
      <StyledH3>{header}</StyledH3>
      <StyledText>{info}</StyledText>
      <StyledAnimation />
    </StyledStorage>
  );
};

export default Storage;
