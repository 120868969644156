/** libraries */
import * as React from 'react';
import { Text, Icons, defaultTheme } from 'cordis-core-ui-planeta';
/** components */
import CallToActionRow from '../CallToActionRow/CallToActionRow';
/** styles */
import {
  StyledFeature,
  StyledH3,
  StyledLeftColumn,
} from '../CallToAction.style';
/** utils */
import { formatNumber } from '~/utils/utils';
/** stores */
import { useCallToActionStore } from '../stores/MainCallToAction/useMainCallToActionStore';

/* Дочерний компонент "Подключение на год", компонента CallToAction */
const CallToActionAnnual: React.FC = () => {
  const { migrationCostFirstPrice } = useCallToActionStore();
  return (
    <CallToActionRow title={<Text>Подключение на год</Text>}>
      <StyledLeftColumn>
        <StyledH3>
          {migrationCostFirstPrice === 0
            ? 'Бесплатно'
            : `${formatNumber(migrationCostFirstPrice)}\u0020\u20bd`}
        </StyledH3>
      </StyledLeftColumn>
      <StyledFeature>
        <Icons.OkIcon />
        {/* yaspeller ignore:start */}
        <Text color={defaultTheme.colors.shadow}>
          Один платёж в&nbsp;год&nbsp;и никаких забот
        </Text>
        {/* yaspeller ignore:end */}
      </StyledFeature>
    </CallToActionRow>
  );
};

export default React.memo(CallToActionAnnual);
