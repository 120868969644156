import styled from '@emotion/styled';
import { Squircle } from '@squircle-js/react';
import { Button, H3, Text } from 'cordis-core-ui-planeta';
import CONFIG from '../../config';
import { McBannerTemplates } from '../../constants';
import { desktop1100, desktop940 } from '~/components/Grid/constants';
import LinkWrapper from '~/components/LinkWrapper';
import { buttonHover } from '~/components/Blocks/Templates/McBanner/styles';

const { background, backgroundMobile } = CONFIG[
  McBannerTemplates.portation
].images;

export const StyledSquircle = styled(Squircle)`
  display: flex;
  flex-direction: column;
  height: 400px;
  padding: 39px 10px 40px 32px;
  background-image: url(${backgroundMobile});
  background-repeat: no-repeat;
  background-position: right;

  @media (min-width: ${desktop940}px) {
    background-image: url(${background});
    background-position: center;
  }
`;

export const StyledH3 = styled(H3)`
  max-width: 290px;
  margin-bottom: 20px;
`;

export const StyledInfo = styled(Text)`
  display: block;
  max-width: 230px;
  line-height: 24px;
`;

export const StyledButton = styled(Button)`
  width: 244px;
  border-radius: 106px;
  margin-bottom: 0;
  padding: 13px 25px;

  ${buttonHover};

  @media (min-width: ${desktop940}px) {
    width: 220px;
  }
  @media (min-width: ${desktop1100}px) {
    width: 244px;
  }
`;

export const StyledLinkWrapper = styled(LinkWrapper)`
  margin-top: auto;
`;
