/** libraries */
import React, { FC, useEffect, useState } from 'react';
import { Text } from 'cordis-core-ui-planeta';
import Image from 'next/image';
/** constants */
import { SUCCESS_IMAGE } from '../../constants';
import { EMAIL_CLASS_ID } from '~/components/Blocks/Shared/ContactsAndNotifications/constants';
/** styles */
import { ImageWrapper, StyledH3, StyledText, StyledWarning } from './styles';
/** utils */
import { maskPhone } from '~/utils/utils';
/** api */
import { getContacts } from '~/api/apiPab2c';
/** stores */
import { useRootStore } from '~/stores/RootStore';

const SuccessActivation: FC = () => {
  const {
    pab2cMobileStore: { getMobileInfo },
  } = useRootStore();

  const [phone, setPhone] = useState('');

  const imageLoader = ({ src, width, quality }) => {
    return `${process.env.STATIC_SERVER}/${src}?w=${width}&q=${quality}`;
  };

  useEffect(() => {
    (async () => {
      await getMobileInfo();

      try {
        const res = await getContacts();
        const onlyPhones = res.filter(
          (contact) => contact.contactValueClassId !== EMAIL_CLASS_ID,
        );
        if (onlyPhones.length) {
          setPhone(maskPhone(onlyPhones[0].value));
        }
      } catch (e) {
        console.error('getContacts', e);
      }
    })();
  }, []);

  return (
    <div>
      <ImageWrapper>
        <Image
          loader={imageLoader}
          src={SUCCESS_IMAGE}
          alt="Заказ совершён успешно"
          width={362}
          height={362}
          quality={100}
          layout="fixed"
        />
      </ImageWrapper>
      <StyledH3>Какой-то текст успеха</StyledH3>
      <StyledWarning>
        <Text>
          Мы свяжемся с&nbsp;вами по&nbsp;номеру, указанному в&nbsp;договоре:{' '}
          <Text fontWeightBold>{phone}</Text>
        </Text>
      </StyledWarning>
      <StyledText>Ура, успех, радуемся</StyledText>
    </div>
  );
};

export default SuccessActivation;
