/** Коллекция шаблонов блока */
export enum McBannerTemplates {
  main = 'main',
  services = 'services',
  storage = 'storage',
  singlePersonalAccount = 'singlePersonalAccount',
  tenSims = 'tenSims',
  magicalNumber = 'magicalNumber',
  portation = 'portation',
  bonus = 'bonus',
  help = 'help',
}
