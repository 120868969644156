/** libraries */
import { flow, Instance, types, getParent } from 'mobx-state-tree';
/** constants */
import { DEFAULT_RESULT } from '~/constants/common';
/** types */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
import { IMobileStore } from './MobileStore';
/** api */
import {
  getDocumentVerificationInfo,
  bindMobilePhoneNumberToSim,
  activateSimCards,
} from '~/api/apiPab2c';
import { DocumentVerificationInfo } from '../types';

const RequestsStateModel = types.model('SimCardActivationStoreRequestsState', {
  getDocumentVerificationInfo: createApiPathModel(
    'GET /DocumentVerification/GetDocumentVerificationInfo',
  ),
  bindMobilePhoneNumberToSim: createApiPathModel(
    'POST /Mobile/MobilePhoneNumber/SetMobilePhoneNumber',
  ),
  activateSimCards: createApiPathModel(
    'POST /Mobile/MobilePhoneNumber/ActivateSimCards',
  ),
});

const ResultModel = types.model('', {
  isResult: (types.boolean, false),
  isCorrect: (types.boolean, false),
  text: types.maybe(types.string),
});

export const SimCardActivationStore = types
  .model('SimCardActivationStore', {
    requestsState: RequestsStateModel,
    isShowSimCardActivation: types.boolean,
    result: ResultModel,
    /** Признак, показывающий, что паспорт подтвержден на госуслугах или в офисе */
    isDocumentVerified: types.boolean,
    /** Признак, показывающий, что было подтверждено согласие на использование ресурсов типа "Мобильный телефонный номер" договором. */
    isApproveUsageContractResourceMobile: types.maybeNull(types.boolean),
    selectedSimCardId: types.number,
  })
  .views((self) => ({
    get isLoading() {
      return self.requestsState.bindMobilePhoneNumberToSim.isLoading;
    },
    get isActivationLoading() {
      return self.requestsState.activateSimCards.isLoading;
    },
    get isGetActivationInfoLoading() {
      return self.requestsState.getDocumentVerificationInfo.isLoading;
    },
  }))
  .actions((self) => ({
    openSimCardActivation: () => {
      const { setDroplistSelectedNumberId }: IMobileStore = getParent(self);
      setDroplistSelectedNumberId('');
      self.isShowSimCardActivation = true;
    },
    setResult: (result) => {
      self.result = result;
    },
    setIsDocumentVerified: (isDocumentVerified: boolean) => {
      self.isDocumentVerified = isDocumentVerified;
    },
    setSelectedSimCardId: (simCardId: number) => {
      self.selectedSimCardId = simCardId;
    },
    resetSimCardActivationStoreSP: () => {
      self.isShowSimCardActivation = false;
      self.requestsState.getDocumentVerificationInfo.reset();
      self.result = DEFAULT_RESULT;
      const {
        changeNumberStore: { resetChangeNumberStore },
        bindNewNumberStore: { resetBindNewNumberStore },
      }: IMobileStore = getParent(self);
      resetChangeNumberStore();
      resetBindNewNumberStore();
    },
    bindMobilePhoneNumberToSim: flow(function* (
      simCardId: number,
      mobilePhoneNumberId: number,
    ) {
      self.requestsState.bindMobilePhoneNumberToSim.reset();
      self.requestsState.bindMobilePhoneNumberToSim.setLoading();
      try {
        yield bindMobilePhoneNumberToSim(simCardId, mobilePhoneNumberId);
        self.requestsState.bindMobilePhoneNumberToSim.setSuccess();
      } catch (e) {
        console.error('bindMobilePhoneNumberToSim', e);
        self.result = {
          isResult: true,
          isCorrect: false,
          text: '',
        };
        self.requestsState.bindMobilePhoneNumberToSim.setFail();
      }
    }),
    activateSimCards: flow(function* (simCardId: number[]) {
      self.requestsState.activateSimCards.reset();
      self.requestsState.activateSimCards.setLoading();
      try {
        yield activateSimCards(simCardId);
        self.result = {
          isResult: true,
          isCorrect: true,
          text: '',
        };
        self.requestsState.activateSimCards.setSuccess();
      } catch (e) {
        console.error('activateSimCards', e);
        self.result = {
          isResult: true,
          isCorrect: false,
          text: '',
        };
        self.requestsState.activateSimCards.setFail();
      }
    }),
  }))
  .actions((self) => ({
    getDocumentVerificationInfo: flow(function* () {
      self.requestsState.getDocumentVerificationInfo.reset();
      self.requestsState.getDocumentVerificationInfo.setLoading();
      try {
        const res: DocumentVerificationInfo = yield getDocumentVerificationInfo();
        if (res.isDocumentVerified) {
          self.isDocumentVerified = res.isDocumentVerified;
        }
        // if (res?.isApproveUsageContractResourceMobile) {
        //   self.isApproveUsageContractResourceMobile =
        //     res.isApproveUsageContractResourceMobile;
        // }
        self.requestsState.getDocumentVerificationInfo.setSuccess();
      } catch (e) {
        console.error('getDocumentVerificationInfo', e);
        self.requestsState.getDocumentVerificationInfo.setFail();
      }
    }),
  }));

export type ISimCardActivationStore = Instance<typeof SimCardActivationStore>;

export const simCardActivationStoreInstance = {
  requestsState: {
    getDocumentVerificationInfo: defaultModelState,
    bindMobilePhoneNumberToSim: defaultModelState,
    activateSimCards: defaultModelState,
  },
  isShowSimCardActivation: false,
  result: DEFAULT_RESULT,
  checkedIccid: [],
  isDocumentVerified: false,
  isApproveUsageContractResourceMobile: null,
  selectedSimCardId: 0,
};
