/* Библиотеки */
import * as React from 'react';
/* Типы */
import { ConnectionMethod } from '~/constants/common';
/* Стили */
import { StyledConnection } from './CallToActionConnection.style';
/* Компоненты */
import CallToActionRow from '../CallToActionRow/CallToActionRow';
import { Text, Tag, defaultTheme } from 'cordis-core-ui-planeta';
/* Утилиты */
import { formatNumber } from '~/utils/utils';
/** stores */
import { useCallToActionStore } from '../stores/MainCallToAction/useMainCallToActionStore';
import { StyledH3, StyledLeftColumn } from '../CallToAction.style';

/* Дочерний компонент "Стоимость подключения" компонента CallToAction */
const CallToActionConnection: React.FC = () => {
  const {
    connectionMethod,
    connectionFiberPrice,
    productConnectionPrice,
  } = useCallToActionStore();
  const { promoPrice, finishDt } = connectionFiberPrice;
  const isAction = productConnectionPrice !== connectionFiberPrice.promoPrice;
  return (
    <CallToActionRow
      title={
        <Text>
          {connectionMethod === ConnectionMethod.FTTH
            ? 'Стоимость подключения к оптической сети'
            : 'Стоимость подключения'}
        </Text>
      }
    >
      <StyledLeftColumn>
        <StyledConnection>
          {/* yaspeller ignore:start */}
          <span>
            <StyledH3>
              {promoPrice === 0
                ? 'Бесплатно'
                : `${formatNumber(promoPrice)}\u0020\u20bd`}
            </StyledH3>
            {isAction && (
              <Tag
                color={defaultTheme.colors.planeta}
                colorTag={defaultTheme.colors.pink}
              >
                АКЦИЯ
              </Tag>
            )}
          </span>
          {finishDt && (
            <Text>
              {promoPrice === 0
                ? `Бесплатное подключение\nдо\u0020${finishDt}`
                : `Акция до\u0020${finishDt}`}
            </Text>
          )}
          {/* yaspeller ignore:end */}
        </StyledConnection>
      </StyledLeftColumn>
    </CallToActionRow>
  );
};

export default React.memo(CallToActionConnection);
