/** libraries */
import { FC } from 'react';
import { observer } from 'mobx-react';
import { H2, Loader } from 'cordis-core-ui-planeta';
/** components */
import NoSim from './Components/NoSim/NoSim';
import MobileInfo from './Components/MobileInfo/MobileInfo';
import SidePages from './SidePages';
/** styles */
import { LoaderWrapper, StyledMobile } from './style';
/** hooks */
import { useQpHandlerHook } from './hooks/QpHandlerHook';
/** constants */
import { BUSINESS_GROUP_CODE } from '../../Summary/Summary.types';
/** stores */
import { useRootStore } from '~/stores/RootStore';
import { PAB2C_MOBILE_ID } from './constants';

const MobileContent: FC = () => {
  const {
    pab2cMobileStore: {
      subscriptions,
      isConvergentProduct,
      isLoading,
      mobilePhones,
    },
    summaryDataStore: { marketingGroupCode },
  } = useRootStore();

  useQpHandlerHook();

  const isBusinessProduct = [BUSINESS_GROUP_CODE].includes(marketingGroupCode);

  const isNoSim =
    !isConvergentProduct && !subscriptions.length && !mobilePhones.length;

  if (isBusinessProduct)
    return (
      <StyledMobile>
        <H2>Мобильная связь недоступна на&nbsp;вашем договоре</H2>
      </StyledMobile>
    );

  return (
    <StyledMobile id={PAB2C_MOBILE_ID}>
      {isLoading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}

      {!isLoading && isNoSim && <NoSim />}
      {!isLoading && !isNoSim && <MobileInfo />}

      <SidePages />
    </StyledMobile>
  );
};

export default observer(MobileContent);
