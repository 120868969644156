/* Библиотеки */

import * as React from 'react';
import { observer } from 'mobx-react';
import { CallToActionProps } from './CallToAction.types';
/** stores */
import { CallToActionProvider } from './stores/MainCallToAction/useMainCallToActionStore';
import CallToActionContent from './CallToActionContent';

/**
 * Блок "CallToAction"
 * https://ckb.itmh.ru/pages/viewpage.action?pageId=413528584
 * @param content
 */
const CallToAction: React.FC<CallToActionProps> = ({
  content,
}: CallToActionProps) => {
  return (
    <CallToActionProvider
      fields={content.fields}
      defaultImgs={content.defaultImgs}
    >
      <CallToActionContent />
    </CallToActionProvider>
  );
};

export default observer(CallToAction);
