/** libraries */
import * as React from 'react';
import { CallToActionPrepaymentProps } from './CallToActionPrepayment.types';
/** types */
import { Text } from 'cordis-core-ui-planeta';
/** styles */
import { StyledH3, StyledLeftColumn } from '../CallToAction.style';
/** components */
import CallToActionRow from '../CallToActionRow/CallToActionRow';
/** utils */
import { formatNumber } from '~/utils/utils';

/* Дочерний компонент "Итого, единовременный платёж" компонента CallToAction */
const CallToActionPrepayment: React.FC<CallToActionPrepaymentProps> = ({
  prepaymentPrice,
}: CallToActionPrepaymentProps) => (
  <CallToActionRow title={<Text>Итого, единовременный платёж</Text>}>
    <StyledLeftColumn>
      <StyledH3>
        {prepaymentPrice === 0
          ? 'Бесплатно'
          : `${formatNumber(prepaymentPrice)}\u0020\u20bd`}
      </StyledH3>
    </StyledLeftColumn>
  </CallToActionRow>
);

export default React.memo(CallToActionPrepayment);
