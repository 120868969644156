/* Libraries */
import { FC, memo } from 'react';
import { Text, H3, defaultTheme } from 'cordis-core-ui-planeta';
import Image from 'next/image';
/* Components */
import CallToActionRow from '../CallToActionRow/CallToActionRow';
/** styles */
import { StyledFixedPrice } from './CallToActionFixedPrice.style';
/** stores */
import { useCallToActionStore } from '../stores/MainCallToAction/useMainCallToActionStore';
import { StyledFeature, StyledLeftColumn } from '../CallToAction.style';

/** Блок "Фиксируем цены на год" */
const CallToActionFixedPrice: FC = () => {
  const { fixedPrice, migrationCostFirstPrice } = useCallToActionStore();
  const { dueDate, imgLink, discount } = fixedPrice;
  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  return (
    <CallToActionRow
      title={
        <Text>
          Фиксируем цены на&nbsp;год<sup>*</sup>
        </Text>
      }
    >
      <StyledLeftColumn>
        <StyledFixedPrice>
          <span>
            <H3
              color={defaultTheme.colors.disable}
            >{`${migrationCostFirstPrice}\u0020\u20bd`}</H3>
            <H3 color={defaultTheme.colors.planeta}>
              &#8722;&nbsp;{`${discount}%`}
            </H3>
          </span>
          <Text color={defaultTheme.colors.shadow}>{dueDate}</Text>
        </StyledFixedPrice>
      </StyledLeftColumn>
      {imgLink && (
        <StyledFeature>
          <Image
            loader={imageLoader}
            src={`${process.env.STATIC_SERVER}/${imgLink}`}
            alt="Фиксируем цены на год"
            width={126}
            height={70}
            quality={100}
          />
        </StyledFeature>
      )}
    </CallToActionRow>
  );
};

export default memo(CallToActionFixedPrice);
