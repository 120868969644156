/** libraries */
import { FC } from 'react';
/** interfaces */
import { McBannerProps } from './interfaces';
/** constants */
import { McBannerTemplates } from './constants';
/** components */
import {
  MagicalNumbers,
  Wow,
  RangeOfServices,
  Storage,
  SinglePersonalAccount,
  TenSims,
  Help,
  Portation,
  Bonus,
} from './Templates';

/** Блок «mcBanner»»
 *  Требования - https://ckb.itmh.ru/pages/viewpage.action?pageId=814323895
 *  Макеты - https://www.figma.com/design/yIsRe5Cej5r99qIPfidU0K/Planeta-OPRK?node-id=783-9&p=f&t=q6QA8rd27hsAORHd-0
 */
const McBanner: FC<McBannerProps> = ({ content }: McBannerProps) => {
  const { bannerTemplate } = content.fields;

  const templatesMap = {
    [McBannerTemplates.main]: <Wow />,
    [McBannerTemplates.services]: <RangeOfServices />,
    [McBannerTemplates.storage]: <Storage />,
    [McBannerTemplates.singlePersonalAccount]: <SinglePersonalAccount />,
    [McBannerTemplates.tenSims]: <TenSims />,
    [McBannerTemplates.magicalNumber]: <MagicalNumbers />,
    [McBannerTemplates.help]: <Help />,
    [McBannerTemplates.portation]: <Portation />,
    [McBannerTemplates.bonus]: <Bonus />,
  };

  return templatesMap[bannerTemplate];
};

export default McBanner;
