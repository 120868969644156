/** libraries */
import { useEffect, ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'react-responsive';
import { Input, SidePage, Button, Icons, H2, H3 } from 'cordis-core-ui-planeta';
/** styles */
import { StyledIcon, StyledText } from './styles';
/** stores */
import useMobileStore from '../../store/useMobileStore';
import { useRootStore } from '~/stores/RootStore';
/** utils */
import { maskPhone } from '~/utils/utils';
/** constants */
import { DEFAULT_ERROR } from '~/constants/common';
import { desktop1100 } from '~/components/Grid/constants';

const ChangeName = (): JSX.Element => {
  const {
    pab2cMobileStore: { getMobilePhone, setNewPhoneName },
  } = useRootStore();

  const {
    changeNameStore: {
      selectedNumberId,
      isShowChangeNameSP,
      changeNameForMobilePhone,
      result,
      resetChangeNameStore,
      isLoading,
      error,
      setError,
      name,
      setName,
    },
  } = useMobileStore();

  const isDesktop1100 = useMediaQuery({
    query: `(min-width: ${desktop1100}px)`,
  });

  const mobilePhone = selectedNumberId && getMobilePhone(selectedNumberId);

  useEffect(() => {
    if (isShowChangeNameSP) setName(mobilePhone?.subscriberName || 'Мой номер');
  }, [isShowChangeNameSP]);

  const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    if (error) setError('');
    setName(event.target.value);
  };

  const validateName = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length < 3) return setError('Минимум 3 символа');
    if (event.target.value.length > 64) return setError('Максимум 64 символа');
    return setError('');
  };

  const closeClickHandler = async () => {
    if (result.isResult && result.isCorrect)
      setNewPhoneName(selectedNumberId, name);
    resetChangeNameStore();
  };

  const Footer = () => (
    <Button
      onClick={async () => {
        await changeNameForMobilePhone(mobilePhone.mobilePhoneNumberId, name);
      }}
      disabled={
        !!error || name === (mobilePhone?.subscriberName || 'Мой номер')
      }
      loading={isLoading}
    >
      Изменить имя
    </Button>
  );

  if (!isShowChangeNameSP) return null;

  return (
    <SidePage
      show={isShowChangeNameSP}
      headerText={
        !result.isResult &&
        `Изменить имя номера ${
          selectedNumberId && maskPhone(mobilePhone?.msisdn)
        }`
      }
      onCloseClick={closeClickHandler}
      footerContainer={!result.isResult && <Footer />}
      showMobileHeader
    >
      {!result.isResult && (
        <>
          <StyledText lineHeight="24px">Новое имя</StyledText>
          <Input
            type="text"
            placeholder="Введите имя номера"
            value={name}
            onChange={onChangeName}
            onBlur={validateName}
            isFocus
            error={error}
          />
        </>
      )}

      {result.isResult && result.isCorrect && (
        <>
          <StyledIcon icon={<Icons.SuccessIconAnimated />} />
          {isDesktop1100 ? <H2>{result.text}</H2> : <H3>{result.text}</H3>}
        </>
      )}

      {result.isResult && !result.isCorrect && (
        <>
          <StyledIcon icon={<Icons.NotOkBigIcon />} />
          {isDesktop1100 ? <H2>{DEFAULT_ERROR}</H2> : <H3>{DEFAULT_ERROR}</H3>}
        </>
      )}
    </SidePage>
  );
};

export default observer(ChangeName);
