/** libraries */
import { observer } from 'mobx-react';
import {
  SidePage,
  Button,
  defaultTheme,
  ListItem,
} from 'cordis-core-ui-planeta';
/** stores */
import useMobileStore from '../../store/useMobileStore';
/** styles */
import {
  StyledText,
  StyledInput,
  StyledLeadingText,
  StyledUnorderedList,
} from './styles';
/** constants */
import { PHONE_NUMBER_LENGTH } from '~/constants/common';

const SpecifyTheTransferNumber = () => {
  const {
    specifyTheTransferNumberStore: {
      isShowSpecifyTheTransferNumber,
      onCloseSpecifyTheTransferNumber,
      candidateToPhoneNumber,
      phoneNumberError,
      onChangePhoneNumber,
      onBlurPhoneNumber,
      changePhoneNumberButtonHandler,
      isLoading,
    },
  } = useMobileStore();

  if (!isShowSpecifyTheTransferNumber) return null;

  return (
    <SidePage
      show={isShowSpecifyTheTransferNumber}
      headerText="Выберите способ подключения"
      onCloseClick={onCloseSpecifyTheTransferNumber}
      footerContainer={
        <Button
          disabled={
            !!phoneNumberError ||
            candidateToPhoneNumber.length < PHONE_NUMBER_LENGTH
          }
          loading={isLoading}
          onClick={() => changePhoneNumberButtonHandler(candidateToPhoneNumber)}
        >
          Перенести номер
        </Button>
      }
    >
      <StyledText lineHeight="24px">
        Номер, который вы хотите перенести
      </StyledText>
      <StyledInput
        type="phone"
        placeholder="Введите номер"
        value={candidateToPhoneNumber}
        error={phoneNumberError}
        onChangeCustomInput={onChangePhoneNumber}
        onBlur={onBlurPhoneNumber}
        isFocus
      />
      <StyledLeadingText color={defaultTheme.colors.black}>
        Условия переноса номера
      </StyledLeadingText>
      <StyledUnorderedList showBullet>
        <ListItem>Номер оформлен на вас.</ListItem>
        <ListItem>Оператор не менялся 60 дней.</ListItem>
        <ListItem>Номер оформлен в том же регионе, куда переносится.</ListItem>
        <ListItem>Нет задолженностей, номер обслуживается.</ListItem>
        <ListItem>
          Данные, указанные в договоре с прежним оператором не менялись (н-р,
          данные паспорта). В ином случае, нужно их обновить.
        </ListItem>
      </StyledUnorderedList>
    </SidePage>
  );
};

export default observer(SpecifyTheTransferNumber);
