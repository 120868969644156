/** libraires */
import { Instance, types, flow, getParent } from 'mobx-state-tree';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
/** api */
import {
  getAvailableMobilePhoneNumbers,
  getMobilePhoneNumberCategories,
  getReplaceMobilePhoneNumberAgreement,
  replaceMobilePhoneNumber,
} from '~/api/apiPab2c';
/** types */
import { IMobileStore } from './MobileStore';
/** utils */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
/** constants */
import { INITIAL_RESULT } from '~/constants/common';
import { BIND_NUMBER_ERROR_TYPE } from '../SidePages/BindNewNumber/constants';

const RequestsStateModel = types.model('State', {
  getMobilePhoneNumberCategories: createApiPathModel(
    'GET /Mobile/MobilePhoneNumber/GetMobilePhoneNumberCategories',
  ),
  getAvailableMobilePhoneNumbers: createApiPathModel(
    'GET /Mobile/MobilePhoneNumber/GetAvailableMobilePhoneNumbers',
  ),
  getReplaceNumberAgreement: createApiPathModel(
    'GET /Mobile/MobilePhoneNumber/GetReplaceMobilePhoneNumberAgreement',
  ),
  replaceMobilePhoneNumber: createApiPathModel(
    'POST /Mobile/MobilePhoneNumber/ReplaceMobilePhoneNumber',
  ),
});

const MobilePhoneNumberCategoryModel = types.model({
  name: types.string,
  price: types.number,
  id: types.number,
});

const AvailableMobilePhoneNumberModel = types.model({
  id: types.number,
  msisdn: types.string,
  categoryId: types.number,
  isFromUsed: types.boolean,
});

const ResultModel = types.model('', {
  isResult: (types.boolean, false),
  isCorrect: (types.boolean, false),
  text: types.maybe(types.string),
});

export const ChangeNumberStore = types
  .model('ChangeNumberStore', {
    requestsState: RequestsStateModel,
    isShowChangeNumberSp: types.boolean,
    selectedNumberId: types.string,
    isShowAvailableNumbersSp: types.boolean,
    categories: types.array(MobilePhoneNumberCategoryModel),
    availableNumbers: types.array(AvailableMobilePhoneNumberModel),
    activeCategoryTabIndex: types.number,
    newNumber: types.string,
    candidateToNewNumber: types.string,
    agreement: types.string,
    banTrimDateForReplaceNumber: types.string,
    result: ResultModel,
    errorType: types.string,
  })
  .views((self) => ({
    get numberCategoryNames() {
      return self.categories.map((category) => category.name);
    },
    get numberCategorieNamesMobile() {
      return self.categories.map((category, index) => ({
        label: category.name,
        value: index,
      }));
    },
    get unionCategoriesWithNumbers() {
      if (!self.availableNumbers.length || !self.categories.length) return null;
      return self.categories.reduce((acc, category) => {
        const numbersDataByCategory = self.availableNumbers.filter(
          (item) => item.categoryId === category.id,
        );
        const numbersByCategory = numbersDataByCategory.map(
          (data) => data.msisdn,
        );
        return { ...acc, [category.name]: numbersByCategory };
      }, {});
    },
    get newNumberInfo() {
      if (!self.newNumber) return null;
      const numberInfo = self.availableNumbers.find(
        (availableNumber) => availableNumber.msisdn === self.newNumber,
      );
      const numberCategory = self.categories.find(
        (category) => category.id === numberInfo?.categoryId,
      );
      if (!numberCategory) return null;
      return {
        ...numberInfo,
        price: numberCategory.price,
        categoryName: numberCategory.name,
      };
    },
    get isLoading() {
      return self.requestsState.replaceMobilePhoneNumber.isLoading;
    },
    get isDataLoading() {
      return (
        self.requestsState.getAvailableMobilePhoneNumbers.isLoading ||
        self.requestsState.getMobilePhoneNumberCategories.isLoading
      );
    },
    get isAgreementLoading() {
      return self.requestsState.getReplaceNumberAgreement.isLoading;
    },
    get parsedDateForReplaceNumber() {
      if (!self.banTrimDateForReplaceNumber) return '';
      return formatDistanceToNowStrict(
        parseISO(self.banTrimDateForReplaceNumber),
        { unit: 'day', locale: ru, roundingMethod: 'ceil' },
      );
    },
  }))
  .actions((self) => ({
    changeNumberButtonHandler: (msisdn: string) => {
      const { setDroplistSelectedNumberId }: IMobileStore = getParent(self);
      self.selectedNumberId = msisdn;
      self.isShowChangeNumberSp = true;
      setDroplistSelectedNumberId('');
    },
    setIsShowAvailableNumbersSp: (isShow: boolean) => {
      self.isShowAvailableNumbersSp = isShow;
    },
    setNewNumberButtonHandler: () => {
      self.newNumber = self.candidateToNewNumber;
      self.agreement = '';
      self.isShowAvailableNumbersSp = false;

      /** Обработка кейса активации SIM-карты */
      const {
        simCardActivationStore: { isShowSimCardActivation },
        bindNewNumberStore: { setIsShowBindNewNumberAgreementSP },
      }: IMobileStore = getParent(self);
      if (isShowSimCardActivation) {
        setIsShowBindNewNumberAgreementSP(true);
      }
    },
    setActiveCategoryTabIndex: (index: number) => {
      self.activeCategoryTabIndex = index;
    },
    setCandidateToNewNumber: (msisdn: string) => {
      self.candidateToNewNumber = msisdn;
    },
    getMobilePhoneNumberCategories: flow(function* () {
      self.requestsState.getMobilePhoneNumberCategories.reset();
      self.requestsState.getMobilePhoneNumberCategories.setLoading();
      try {
        const res = yield getMobilePhoneNumberCategories();
        self.categories = res.mobilePhoneNumberCategories;
        self.requestsState.getMobilePhoneNumberCategories.setSuccess();
      } catch (e) {
        self.requestsState.getMobilePhoneNumberCategories.setFail();
        throw e;
      }
    }),
    getAvailableMobilePhoneNumbers: flow(function* () {
      self.requestsState.getAvailableMobilePhoneNumbers.reset();
      self.requestsState.getAvailableMobilePhoneNumbers.setLoading();
      try {
        const res = yield getAvailableMobilePhoneNumbers();
        self.availableNumbers = res.availableMobilePhoneNumbers;
        self.requestsState.getAvailableMobilePhoneNumbers.setSuccess();
      } catch (e) {
        self.requestsState.getAvailableMobilePhoneNumbers.setFail();
        throw e;
      }
    }),
    getReplaceNumberAgreement: flow(function* () {
      self.requestsState.getReplaceNumberAgreement.reset();
      self.requestsState.getReplaceNumberAgreement.setLoading();
      try {
        const res = yield getReplaceMobilePhoneNumberAgreement();
        self.agreement = res;
        self.errorType = '';
        self.requestsState.getReplaceNumberAgreement.setSuccess();
      } catch (e) {
        console.error('getReplaceNumberAgreement', e);
        const error = JSON.parse(e.errorMessage);
        self.errorType = error.Type;
        if (error.Type === BIND_NUMBER_ERROR_TYPE.TIME) {
          self.banTrimDateForReplaceNumber =
            error.Extensions?.BanTrimDateForReplace;
        }
        self.requestsState.getReplaceNumberAgreement.setFail();
      }
    }),
    replaceMobilePhoneNumber: flow(function* (
      oldMobilePhoneNumberId: number,
      newMobilePhoneNumberId: number,
    ) {
      self.requestsState.replaceMobilePhoneNumber.reset();
      self.requestsState.replaceMobilePhoneNumber.setLoading();
      try {
        yield replaceMobilePhoneNumber(
          oldMobilePhoneNumberId,
          newMobilePhoneNumberId,
        );
        self.result = {
          isResult: true,
          isCorrect: true,
          text: 'Номер успешно изменён',
        };
        self.requestsState.replaceMobilePhoneNumber.setSuccess();
      } catch (e) {
        console.error('replaceMobilePhoneNumber', e);
        self.result = {
          isResult: true,
          isCorrect: false,
          text: '',
        };
        self.requestsState.replaceMobilePhoneNumber.setFail();
      }
    }),
    resetChangeNumberStore: () => {
      self.isShowChangeNumberSp = false;
      self.selectedNumberId = '';
      self.activeCategoryTabIndex = 0;
      self.candidateToNewNumber = '';
      self.newNumber = '';
      self.agreement = '';
      self.banTrimDateForReplaceNumber = '';
      self.errorType = '';
      self.result = INITIAL_RESULT;
    },
  }))
  .actions((self) => ({
    getChangeNumberData: async () => {
      await Promise.all([
        self.getMobilePhoneNumberCategories(),
        self.getAvailableMobilePhoneNumbers(),
      ]);
    },
  }));

export type IChangeNumberStore = Instance<typeof ChangeNumberStore>;

export const changeNumberStoreInstance = {
  requestsState: {
    getMobilePhoneNumberCategories: defaultModelState,
    getAvailableMobilePhoneNumbers: defaultModelState,
    getReplaceNumberAgreement: defaultModelState,
    replaceMobilePhoneNumber: defaultModelState,
  },
  isShowChangeNumberSp: false,
  selectedNumberId: '',
  isShowAvailableNumbersSp: false,
  categories: [],
  activeCategoryTabIndex: 0,
  newNumber: '',
  candidateToNewNumber: '',
  agreement: '',
  banTrimDateForReplaceNumber: '',
  result: INITIAL_RESULT,
  errorType: '',
};
