/** libraries */
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { SidePage, Text, Loader } from 'cordis-core-ui-planeta';
/** styles */
import { StyledButton } from './styles';
/** utils */
import { parseHtml } from '~/components/Blocks/Shared/Shared.utils';
/** stores */
import useMobileStore from '../../store/useMobileStore';
import { useRootStore } from '~/stores/RootStore';

const BindSubscriptionAgreement = (): JSX.Element => {
  const {
    pab2cMobileStore: { getMobileStorageBalances },
  } = useRootStore();

  const {
    bindSubscriptionStore: {
      unitsOfSelectedSubscription,
      isShowBindSubscriptionAgreementSP,
      agreement,
      getBindMobileSubscriptionAgreement,
      bindMobileSubscription,
      isLoading,
      onCloseBindSubscriptionAgreementSP,
      isBindRoaming,
    },
  } = useMobileStore();

  useEffect(() => {
    if (!isShowBindSubscriptionAgreementSP) return;
    (async () => {
      await getBindMobileSubscriptionAgreement();
    })();
  }, [isShowBindSubscriptionAgreementSP]);

  const bindNumberButtonHandler = async () => {
    try {
      await bindMobileSubscription();
      await getMobileStorageBalances();
    } catch (e) {
      console.error('bindNumberButtonHandler', e);
    }
  };

  const Footer = () => (
    <StyledButton
      disabled={!agreement}
      onClick={bindNumberButtonHandler}
      loading={isLoading}
    >
      Согласен
    </StyledButton>
  );

  if (!isShowBindSubscriptionAgreementSP) return null;

  return (
    <SidePage
      show={isShowBindSubscriptionAgreementSP}
      headerText={`Соглашение на\u00A0подключение пакета ${unitsOfSelectedSubscription} ${
        isBindRoaming ? 'в\u00A0роуминге' : ''
      }`}
      onCloseClick={onCloseBindSubscriptionAgreementSP}
      footerContainer={<Footer />}
      showMobileHeader
    >
      {!agreement && <Loader small />}
      {agreement && <Text>{parseHtml(agreement)}</Text>}
    </SidePage>
  );
};

export default observer(BindSubscriptionAgreement);
